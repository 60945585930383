import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import dayjs from 'dayjs'
import { RefreshOutlined } from '@mui/icons-material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { Box, Button, Card, CardContent, Grid, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, GridFooterContainer, GridPagination, gridClasses } from '@mui/x-data-grid-pro'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import StrategyTitle from '../../components/StrategyTitle'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import ErrorFallback from '../ErrorFallback'

const renderTableSkeleton = (header, hiddenColumns) => {
  if (hiddenColumns) { header = header.filter(col => !hiddenColumns.includes(col.field)) }
  return (
    <TableContainer mt={5}>
      <Table className='risk-page-table'>
        <TableHead>
          <TableRow>
            {header.map((item, index) => {
              return (
                <TableCell key={index}>{item.headerName}</TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: 5 }).map((_, i) => (
            <TableRow key={i}>
              {Array.from({ length: header.length }).map((_, j) => (
                <TableCell key={j}>
                  <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                </TableCell>))}
            </TableRow>))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const ModelChanges = () => {
  const { user } = useAuth()
  const params = useParams()
  const { showError } = useErrorToast()
  const [isModelChangesLoading, setIsModelChangesLoading] = useState(true)
  const [modelChangeData, setModelChangeData] = useState({ strategy: { strategyName: '', strategyCode: '' }, data: [] })
  const [holdingDate, setHoldingDate] = useState(null)
  const [prevHoldingDate, setPrevHoldingDate] = useState(null)
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [availableDates, setAvailableDates] = useState([])
  const [selectedStrategyInfo, setSelectedStrategyInfo] = useState(null)
  const [isSelectedStrategyInfoFetching, setIsSelectedStrategyInfoFetching] = useState(true)

  const fetchModelChanges = (date, keyFlag) => {
    // no selected strategy info exist and model changes API call inprogress
    if (!selectedStrategyInfo && !keyFlag) {
      setIsSelectedStrategyInfoFetching(true)
    }
    if (!keyFlag) {
      setIsModelChangesLoading(true)
    }
    const queryStringParameters = {}
    if (date) {
      queryStringParameters.holdingDate = date.format('YYYY-MM-DD')
    }
    if (keyFlag) {
      queryStringParameters.historyVariance = keyFlag
    }
    API.get(
      'baseUriCorporate',
      `corporate-action/v1/${user?.userGroup}/model-change/${params?.strategyId}`,
      {
        queryStringParameters
      }
    )
      .then((response) => {
        if (response.success) {
          if (keyFlag) {
            setAvailableDates(response.data)
          } else {
            const data = response?.data?.modelChange?.map(obj => ({ ...obj, id: randomId() }))
            setModelChangeData({ strategy: { strategyName: response?.data?.strategyName, strategyCode: response?.data?.strategyCd }, data })
            setSelectedStrategyInfo({ strategyName: response?.data?.strategyName, strategyCode: response?.data?.strategyCd })
            setIsModelChangesLoading(false)
            setIsSelectedStrategyInfoFetching(false)
          }
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to load model changes for strategy.')
        if (!keyFlag) {
          setIsModelChangesLoading(false)
        }
        if (!selectedStrategyInfo && !keyFlag) {
          setIsSelectedStrategyInfoFetching(false)
        }
      })
      .finally(() => {
        // setIsModelChangesLoading(false)
      })
  }

  useEffect(() => {
    setHoldingDate(prevHoldingDate)
  }, [prevHoldingDate])

  useEffect(() => {
    if (user) {
      setPrevHoldingDate(null)
      setHoldingDate(null)
      // call api 2 times on page load
      // 1. fetch data with latest holding date
      fetchModelChanges()
      // 2. fetch available date
      fetchModelChanges(undefined, true)
    }
  }, [])

  const modelChangeColumns = [
    { field: 'instrId', headerName: 'Instrument Id', flex: 1 },
    { field: 'instrName', headerName: 'Instrument Name', flex: 1 },
    {
      field: 'currentHoldingDate',
      headerName: 'Holding Date',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      renderCell: (params) => params?.row?.currentHoldingDate ? params?.row?.currentHoldingDate?.split('T')[0] : ''
    },
    {
      field: 'previousWeight',
      headerName: 'Previous Weight (%)',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      renderCell: (props) => props?.row?.previousWeight !== undefined && props?.row?.previousWeight !== null
        ? ((props?.row?.previousWeight) * 100).toFixed(4)
        : '0.0000',
      valueGetter: (props) => props?.row?.previousWeight !== undefined && props?.row?.previousWeight !== null
        ? Number(((props?.row?.previousWeight) * 100).toFixed(4))
        : 0.0000,
      flex: 1
    },
    {
      field: 'currentWeight',
      headerName: 'Current Weight (%)',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      renderCell: (props) => props?.row?.currentWeight !== undefined && props?.row?.currentWeight !== null
        ? ((props?.row?.currentWeight) * 100).toFixed(4)
        : '0.0000',
      valueGetter: (props) => props?.row?.currentWeight !== undefined && props?.row?.currentWeight !== null
        ? Number(((props?.row?.currentWeight) * 100).toFixed(4))
        : 0.0000,
      flex: 1
    },
    {
      field: 'weightVariance',
      headerName: 'Drift (%)',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      sortComparator: (a, b) => Math.abs(a) - Math.abs(b),
      renderCell: (params) => {
        const driftVal = params?.row?.weightVariance
        const invalid = Math.abs(parseFloat(driftVal * 100).toFixed(4)) === 0
        return (
          <>
            {driftVal < 0 && (params?.row?.metric !== 'INSTR_REMOVED' && params?.row?.metric !== 'INSTR_ADDED') && !invalid
              ? <ArrowCircleDownIcon fontSize='small' sx={{ color: '#FF6161', marginRight: '2px' }} />
              : (driftVal > 0 && (params?.row?.metric !== 'INSTR_REMOVED' && params?.row?.metric !== 'INSTR_ADDED')) && !invalid
                  ? <ArrowCircleUpIcon fontSize='small' sx={{ color: '#3BBFA3', marginRight: '2px' }} />
                  : params?.row?.metric === 'INSTR_REMOVED' && !invalid
                    ? <RemoveCircleOutlineIcon fontSize='small' sx={{ color: '#FF6161', marginRight: '2px' }} />
                    : params?.row?.metric === 'INSTR_ADDED' && !invalid
                      ? <AddCircleOutlineIcon fontSize='small' sx={{ color: '#3BBFA3', marginRight: '2px' }} />
                      : ''}
            <Typography
              fontSize='14px'
              fontWeight='600'
              color={parseFloat(driftVal * 100).toFixed(4) < 0 && !invalid
                ? '#FF6161'
                : (parseFloat(driftVal * 100).toFixed(4) > 0 && !invalid)
                    ? '#3BBFA3'
                    : ''}
              sx={{ width: '55px', textAlign: 'right', display: 'inline-block' }}
            >
              {Math.abs(parseFloat(driftVal * 100).toFixed(4)) === 0 ? '0.0000' : parseFloat(driftVal * 100).toFixed(4)}
            </Typography>
          </>
        )
      },
      valueGetter: (props) => props?.row?.weightVariance !== undefined && props?.row?.weightVariance !== null
        ? Math.abs(parseFloat(props?.row?.weightVariance * 100).toFixed(4)) === 0 ? 0.0000 : parseFloat(parseFloat(props?.row?.weightVariance * 100).toFixed(4))
        : 0.0000,
      flex: 1
    }
  ]

  const CustomFooter = () => {
    return (
      <GridFooterContainer sx={{ justifyContent: 'space-between', alignItems: 'center', paddingX: '8px', backgroundColor: '#fff', zIndex: 1, flexWrap: 'nowrap' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', flexWrap: 'wrap', mt: '4px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <AddCircleOutlineIcon fontSize='small' sx={{ color: '#3BBFA3' }} />
            <Typography sx={{ color: '#74788d', fontSize: '14px' }}>Security Added</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <RemoveCircleOutlineIcon fontSize='small' sx={{ color: '#FF6161' }} />
            <Typography sx={{ color: '#74788d', fontSize: '14px' }}>Security Removed</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <ArrowCircleUpIcon fontSize='small' sx={{ color: '#3BBFA3' }} />
            <Typography sx={{ color: '#74788d', fontSize: '14px' }}>Security Weight Increased</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <ArrowCircleDownIcon fontSize='small' sx={{ color: '#FF6161' }} />
            <Typography sx={{ color: '#74788d', fontSize: '14px' }}>Security Weight Decreased</Typography>
          </Box>
        </Box>
        {/* Render default pagination controls */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', flexShrink: 0 }}>
          <GridPagination />
        </Box>
      </GridFooterContainer>
    )
  }

  const handleResetButtonClick = () => {
    setPrevHoldingDate(null)
    fetchModelChanges()
  }

  function disableDates (dayjsDate) {
    return !availableDates.find(obj => dayjsDate.format('YYYY-MM-DD') === obj.currentHoldingDate)
  }

  const screen = 'not main'

  return (
    <>
      <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
        <Grid container>
          <Grid item xs={9}>
            <StrategyTitle title='Model Changes' strategyName={selectedStrategyInfo?.strategyName} strategyCode={selectedStrategyInfo?.strategyCode} infoLoading={isSelectedStrategyInfoFetching} />
          </Grid>
          <Grid item xs={3} alignContent='center' mb='20px'>
            <Stack direction='row' gap={2} justifyContent='end' alignItems='center'>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={holdingDate}
                  onChange={setHoldingDate}
                  open={isCalendarOpen}
                  closeOnSelect={false}
                  shouldDisableDate={disableDates}
                  onOpen={() => setIsCalendarOpen(true)}
                  onClose={() => (setIsCalendarOpen(false), setHoldingDate(prevHoldingDate))}
                  format='YYYY-MM-DD'
                  variant='outlined'
                  label='Weight Change Date'
                  slots={{ actionBar: CustomActionBar }}
                  slotProps={{
                    textField: {
                      size: 'small',
                      onClick: () => setIsCalendarOpen(true)
                    },
                    actionBar: {
                      actions: ['accept'],
                      submit: fetchModelChanges,
                      holdingDate,
                      setPrevHoldingDate
                    }
                  }}
                  sx={{
                    width: '210px'
                  }}
                />
              </LocalizationProvider>
              <Tooltip title='Reset' placement='top'>
                <Button
                  variant='outlined'
                  onClick={handleResetButtonClick}
                  sx={{
                    border: '2px solid #dee2e6',
                    padding: '10px',
                    minWidth: 'auto',
                    ':hover': {
                      background: 'transparent',
                      border: '2px solid #dee2e6'
                    }
                  }}
                >
                  <RefreshOutlined sx={{ height: 20, width: 20, color: '#74788D' }} />
                </Button>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent sx={{ pb: '8px !important' }}>
                <Box sx={{
                  position: 'relative',
                  overflowY: 'auto',
                  scrollbarWidth: 'none'
                }}
                >
                  {
                    isModelChangesLoading
                      ? renderTableSkeleton(modelChangeColumns)
                      : <DataGridPro
                          density='compact'
                          autoHeight
                          rows={modelChangeData?.data || []}
                          columns={modelChangeColumns}
                          disableSelectionOnClick
                          getRowId={(row) => row.id}
                          pagination
                          pageSizeOptions={[15, 25, 50, 75, 100]}
                          slots={{
                            footer: CustomFooter
                          }}
                          initialState={{
                            ...modelChangeData?.data?.initialState,
                            sorting: {
                              sortModel: [
                                {
                                  field: 'weightVariance',
                                  sort: 'desc'
                                }
                              ]
                            },
                            pagination: { paginationModel: { pageSize: 15 } }
                          }}
                          sx={(theme) => ({
                            '&.MuiDataGrid-root': {
                              height: 'calc(100vh - 48px - 15px - 20px - 66px - 20px - 16px - 24px - 20px)',
                              overflowY: 'auto',
                              scrollbarGutter: 'stable',
                              scrollbarWidth: 'none'
                            },
                            [`& .${gridClasses.main}`]: {
                              overflow: 'unset'
                            },
                            [`& .${gridClasses.columnHeaders}`]: {
                              position: 'sticky',
                              top: 0,
                              backgroundColor: theme.palette.background.paper,
                              zIndex: 1
                            },
                            [`& .${gridClasses.footerContainer}`]: {
                              position: 'sticky',
                              bottom: 0,
                              backgroundColor: theme.palette.background.paper,
                              zIndex: 1,
                              padding: '8px'
                            },
                            [`& .${gridClasses.toolbarContainer}`]: {
                              position: 'relative',
                              top: 0,
                              backgroundColor: theme.palette.background.paper,
                              zIndex: 1
                            }
                          })}
                        />

                  }
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </ErrorBoundary>
    </>
  )
}

const CustomActionBar = (props) => {
  const { onAccept, className, submit, holdingDate, setPrevHoldingDate } = props

  const handleSubmit = (date) => {
    onAccept()
    if (dayjs.isDayjs(date)) {
      submit(date)
      setPrevHoldingDate(date)
    }
  }

  return (
    <Box className={className} sx={{ display: 'flex', justifyContent: 'flex-end', padding: '10px', gap: '10px' }}>
      <Button
        variant='contained'
        onClick={() => handleSubmit(holdingDate)}
      >
        Submit
      </Button>
    </Box>
  )
}

export default ModelChanges

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Checkbox } from '@mui/material'
import { GridRowModes } from '@mui/x-data-grid-pro'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import CustomEditCheckComponent from './components/CustomCheckboxField'
import CustomTooltipIcon from './components/CustomTooltipIcon'
import { CustomEditZscoreField, CustomZscoreField } from './components/CustomZscoreField'
import DataTable from './components/DataTable'
import Loader from '../Loader'

const PersonalizationSector = ({ data, getUpdatedData, updateIndustryList }) => {
  const [sectorData, setSectorData] = useState(data?.data || [])
  const [rowModesModel, setRowModesModel] = useState({})

  const { showError } = useErrorToast()

  const { user } = useAuth()
  const params = useParams()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setSectorData(data?.data || [])
    setIsLoading(false)
  }, [data])

  const columns = [
    { field: 'assetClassDesc', headerName: 'Sector', flex: 2, editable: data.fieldEditAllowed?.assetClassDesc },
    { field: 'instruments', headerName: 'No. of Securities', flex: 1, editable: data.fieldEditAllowed?.instruments, type: 'number', align: 'right' },
    {
      field: 'weight',
      headerName: 'Weight(%)',
      flex: 1,
      editable: data?.fieldEditAllowed?.weight,
      type: 'number',
      align: 'right',
      valueGetter: (params) => {
        const value = params?.row?.weight
        return value !== null && value !== undefined ? (value * 100).toFixed(2) : '0.00'
      }
    },
    {
      field: 'sectorExclude',
      headerName: 'Exclude',
      flex: 1,
      sortable: false,
      editable: data?.fieldEditAllowed?.sectorExclude,
      renderCell: (params) => (
        <Checkbox
          checked={params?.value === 1}
          disabled={rowModesModel[params?.row?.id]?.mode !== GridRowModes.Edit}
        />
      ),
      renderEditCell: (params) => (
        <CustomEditCheckComponent {...params} />
      )
    },
    {
      field: 'min',
      headerName: 'Min',
      type: 'text',
      flex: 1,
      editable: data.fieldEditAllowed?.min,
      renderHeader: (params) => (
        <>
          Min
          <CustomTooltipIcon text='You can indicate a relative value by using the format B(+/-)(value). For example,  B-0.58 or B+0.58.' action='help' />
        </>
      ),
      renderCell: (params) => (
        <CustomZscoreField params={params} rowModesModel={rowModesModel} checkboxFieldName='sectorExclude' deleteFieldName='accountSectorRestrId' />
      ),
      renderEditCell: (params) => (
        <CustomEditZscoreField {...params} disabled={rowModesModel[params.row.id]?.mode !== GridRowModes.Edit} checkboxFieldName='sectorExclude' />
      )
    },
    {
      field: 'max',
      headerName: 'Max',
      type: 'text',
      flex: 1,
      editable: data.fieldEditAllowed?.max,
      renderHeader: (params) => (
        <>
          Max
          <CustomTooltipIcon text='You can indicate a relative value by using the format B(+/-)(value). For example,  B-0.58 or B+0.58.' action='help' />
        </>
      ),
      renderCell: (params) => (
        <CustomZscoreField params={params} rowModesModel={rowModesModel} checkboxFieldName='sectorExclude' deleteFieldName='accountSectorRestrId' />
      ),
      renderEditCell: (params) => (
        <CustomEditZscoreField {...params} disabled={rowModesModel[params.row.id]?.mode !== GridRowModes.Edit} checkboxFieldName='sectorExclude' />
      )
    }
  ]

  const editSectorData = (sector) => {
    setIsLoading(true)
    let sectorPayload = {
      sectorId: sector?.assetClass,
      sectorExclude: !!sector?.sectorExclude
    }
    if (!sector?.sectorExclude) {
      sectorPayload = {
        ...sectorPayload,
        min: sector?.min,
        max: sector?.max
      }
    }
    API.post('baseUriAccountOptimization', `account-customization/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/${params.accountId}/sector-restrictions`, {
      body: [
        {
          ...sectorPayload
        }
      ]
    })
      ?.then((response) => {
        if (response?.success && response?.data && response?.data?.length) {
          const newSectorData = sectorData.map((row) => {
            if (row?.id === sector?.id) {
              return { id: sector?.id, ...row, ...response?.data[0] }
            }
            return row
          })
          setSectorData(newSectorData)
          getUpdatedData(newSectorData)
          updateIndustryList(sectorPayload, 'update')
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to update sector.')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const deleteSectorData = (id) => {
    setIsLoading(true)
    API.del('baseUriAccountOptimization', `account-customization/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/${params?.accountId}/sector-restrictions`, {
      body: {
        accountSectorRestrictionId: [id]
      }
    })
      ?.then((response) => {
        if (response.success) {
          let disabledSector = {}
          const newSectorData = sectorData.map((row) => {
            if (row?.accountSectorRestrId === id) {
              disabledSector = { ...row, accountSectorRestrId: null, min: null, max: null, sectorExclude: null }
              return { ...row, accountSectorRestrId: null, min: null, max: null, sectorExclude: null }
            }
            return row
          })
          setSectorData(newSectorData)
          getUpdatedData(newSectorData)
          updateIndustryList({
            sectorId: disabledSector.assetClass,
            sectorExclude: disabledSector.sectorExclude
          }, 'disable')
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to delete sector.')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const customValidations = (row) => {
    return (row.minError || row.maxError) || (row.sectorExclude !== 1 && (!row.min || !row.max))
  }

  return (
    <>
      {isLoading ? <Loader /> : ''}
      <DataTable data={sectorData} initialColumns={columns} deleteKey='accountSectorRestrId' editData={editSectorData} deleteData={deleteSectorData} setRowModes={setRowModesModel} customValidations={customValidations} deleteIcon={<RefreshIcon />} />
    </>
  )
}

export default PersonalizationSector

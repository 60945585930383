import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { API } from 'aws-amplify'
import { Checkbox, Tooltip } from '@mui/material'
import { GridRowModes } from '@mui/x-data-grid-pro'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'

import CustomEditCheckComponent from './components/CustomCheckboxField'
import CustomTooltipIcon from './components/CustomTooltipIcon'
import { CustomEditZscoreField, CustomZscoreField } from './components/CustomZscoreField'
import DataTable from './components/DataTable'
import Loader from '../Loader'

const decTwo = (string, decimal) => {
  return (Number(string)).toFixed(decimal).toString()
}

const FactorTilts = ({ data, getUpdatedData }) => {
  const [factorTiltsData, setFactorTiltsData] = useState(data?.data || [])

  const [rowModesModel, setRowModesModel] = useState({})

  const { showError } = useErrorToast()

  const { user } = useAuth()
  const params = useParams()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setFactorTiltsData(data?.data || [])
    setIsLoading(false)
  }, [data])

  const editFactorUtils = (factorData) => {
    setIsLoading(true)
    API.post('baseUriAccountOptimization', `account-customization/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/factor-tilts/${params.accountId}`, {
      body: [
        {
          riskModelVerId: factorData.riskModelVerId,
          statsLevelValue: factorData.statsLevelValue,
          isDefault: factorData.isDefault === 1 ? true : factorData.isDefault,
          statsValue: factorData.statsValue
        }
      ]
    })
      .then((response) => {
        if (response.success && response.data) {
          const newFactorData = factorTiltsData.map((row) => {
            if (row.id === factorData.id) {
              return { id: factorData.id, ...row, ...response.data[0] }
            }
            return row
          })
          setFactorTiltsData(newFactorData)
          getUpdatedData(newFactorData)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to update factor tilts')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const deleteFactorUtils = (id) => {
    setIsLoading(true)
    API.del('baseUriAccountOptimization', `account-customization/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/factor-tilts/${params.accountId}`, {
      body: {
        accountFactorTiltIds: [id]
      }
    })
      .then((response) => {
        if (response.success) {
          const newFactorData = factorTiltsData.map((row) => {
            if (row.accountFactorTiltId === id) {
              return { ...row, accountFactorTiltId: null, statsValue: null, isDefault: null }
            }
            return row
          })
          setFactorTiltsData(newFactorData)
          getUpdatedData(newFactorData)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to delete factor tilts')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const customValidations = (row) => {
    return (row.error || !row.statsValue)
  }

  const columns = [
    { field: 'attrLabel', headerName: 'Factors', flex: 1, editable: data.fieldEditAllowed?.attrLabel },
    { field: 'strategyValue', headerName: 'Strategy', flex: 1, editable: data.fieldEditAllowed?.strategyValue, type: 'number', align: 'right', valueFormatter: ({ value }) => decTwo(value, 2), maxWidth: 80 },
    {
      field: 'range',
      headerName: 'Range',
      flex: 1,
      editable: data.fieldEditAllowed?.range,
      type: 'number',
      align: 'right',
      renderHeader: (params) => (
        <>
          Range
          <CustomTooltipIcon text='Range is based on two ETFs, find the ETFs used by hovering on numbers below' action='help' />
        </>
      ),
      renderCell: (params) => {
        let tooltipValue = ''
        switch (params.row?.attrLabel) {
          case 'Value':
            tooltipValue = 'S&P 500 pure growth - S&P 500 pure value'
            break
          case 'Growth':
            tooltipValue = 'S&P 500 pure value - S&P 500 pure growth'
            break
          case 'Volatility':
            tooltipValue = 'S&P 500 minimum volatility - S&P 500'
            break
          case 'Dividend Yield':
            tooltipValue = 'S&P 500 - S&P 500 high dividend'
            break
          case 'Size':
            tooltipValue = 'S&P 500 small cap - S&P 500 mega cap'
            break
          case 'Quality':
            tooltipValue = 'S&P 500 - S&P 500 quality'
            break
          default:
            tooltipValue = ''
            break
        }
        return (
          <>
            <Tooltip title={tooltipValue} placement='top'>
              <span>{params.row.range}</span>
            </Tooltip>
          </>
        )
      }
    },
    {
      field: 'isDefault',
      headerName: 'Default',
      width: 10,
      flex: 1,
      maxWidth: 80,
      sortable: false,
      editable: data.fieldEditAllowed?.isDefault,
      renderCell: (params) => (
        <Checkbox
          checked={params.value === 1}
          disabled={rowModesModel[params.row.id]?.mode !== GridRowModes.Edit}
        />
      ),
      renderEditCell: (params) => (
        <CustomEditCheckComponent {...params} />
      )
    },
    {
      field: 'statsValue',
      headerName: 'Absolute/Benchmark Relative',
      type: 'text',
      minWidth: 250,
      flex: 1,
      editable: data.fieldEditAllowed?.statsValue,
      renderHeader: (params) => (
        <>
          Absolute/Benchmark Relative
          <CustomTooltipIcon text='You can indicate a relative value by using the format B(+/-)(value). For example,  B-0.58 or B+0.58.' action='help' />

        </>
      ),
      renderCell: (params) => (
        <CustomZscoreField params={params} rowModesModel={rowModesModel} checkboxFieldName='isDefault' deleteFieldName='accountFactorTiltId' />
      ),
      renderEditCell: (params) => (
        <CustomEditZscoreField {...params} disabled={rowModesModel[params.row.id]?.mode !== GridRowModes.Edit} checkboxFieldName='isDefault' />
      )
    }
  ]

  return (
    <>
      {isLoading ? <Loader /> : ''}
      <DataTable data={factorTiltsData} initialColumns={columns} deleteKey='accountFactorTiltId' editData={editFactorUtils} deleteData={deleteFactorUtils} setRowModes={setRowModesModel} customValidations={customValidations} />
    </>
  )
}

export default FactorTilts

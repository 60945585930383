import { randomId } from '@mui/x-data-grid-generator'
import { createHierarchy } from '../../../utils/groupData'

export const assetAllocationChartDataProcess = (response, isScenarioUMA) => {
  const marketCapColor = ['#4B8CCA', '#4B8CCACC', '#4B8CCA80', '#4B8CCA4D']// ['#4B8CCA', '#4B8CCA80', '#34C38F', '#4B8CCA4D', '#4B8CCA1A']

  // Market Cap
  let marketCapLabels = []
  let marketCapData = []
  if (Array.isArray(response?.data?.marketCapAllocationData) && response?.data?.marketCapAllocationData?.length) {
    marketCapLabels = response?.data?.marketCapAllocationData?.map(item => item.marketCapName) || []
    marketCapData = Object.keys(response?.data?.fieldLabels || {}).map((key, index) => {
      const label = response.data.fieldLabels[key]
      const backgroundColor = (isScenarioUMA ? key === 'scenarioUMA' : key === 'originalUMA') ? '#34C38F' : (marketCapColor[index] || `#${Math.floor(Math.random() * 16777215).toString(16)}`)
      const values = (response?.data?.marketCapAllocationData || []).map(item => parseFloat(item[key]) * 100 || 0)

      return {
        label,
        data: values,
        backgroundColor,
        stack: `Stack ${index}`,
        grid: false,
        maxBarThickness: 50
      }
    })
  } else {
    marketCapData = []
  }

  // Factor
  const factorColor = ['#4B8CCA', '#4B8CCACC', '#4B8CCA80', '#4B8CCA4D']// ['#4B8CCA', '#4B8CCA80', '#34C38F', '#4B8CCA4D', '#4B8CCA1A']
  let factorLabels = []
  let factorData = []
  if (Array.isArray(response?.data?.factorAllocationData) && response?.data?.factorAllocationData?.length) {
    factorLabels = response?.data?.factorAllocationData?.map(item => item.factorName) || []
    factorData = Object.keys(response?.data?.fieldLabels || {}).map((key, index) => {
      const label = response?.data?.fieldLabels[key]
      const backgroundColor = (isScenarioUMA ? key === 'scenarioUMA' : key === 'originalUMA') ? '#34C38F' : (factorColor[index] || `#${Math.floor(Math.random() * 16777215).toString(16)}`)
      const values = (response?.data?.factorAllocationData || []).map(item => parseFloat(item[key]) || 0)

      return {
        label,
        data: values,
        backgroundColor,
        stack: `Stack ${index}`,
        grid: false,
        maxBarThickness: 50
      }
    })
  } else {
    factorData = []
  }

  // legend colors
  const labelsBackgroundColor = marketCapData?.map((data) => { return data.backgroundColor })

  return { marketCapData, factorData, labelsBackgroundColor, marketCapLabels, factorLabels }
}

export const assetAllocationTabDataProcess = (data) => {
  const sortData = (data) => {
    const order = ['account', 'uma', 'group']
    return data.sort((a, b) => {
      if (a.memberRefName === 'Untagged') {
        return 1
      }
      if (b.memberRefName === 'Untagged') {
        return -1
      }
      return order.indexOf(a.memberType) - order.indexOf(b.memberType)
    })
  }

  const createDatagridStructure = (array=[], hierarchy, finalArray) => {
    if (!array?.length) {
      return
    }
    const sortedData = sortData(array)
    sortedData?.forEach(member => {
      const newHierarchy = [...hierarchy, member?.memberId]
      finalArray.push({
        id: randomId(),
        groupName: member?.groupName,
        memberType: member?.memberType,
        memberRefId: member?.memberRefId,
        name: member?.memberRefName,
        memberId: member?.memberId,
        marketValue: member?.marketValue,
        allocationPercentage: member?.allocationPercentage,
        isActive: member?.isActive,
        level: member?.level,
        hierarchy: newHierarchy,
        groupType: member?.children?.length ? member?.children[0]?.groupType : member?.groupType,
      })
      createDatagridStructure(member?.children, newHierarchy, finalArray)
    })
  }
  const treeData = []
  createDatagridStructure(data, [], treeData)
  return treeData
}

export const sectorAllocationDataProcess = (response) => {
  // Sector
  let sectorColumns = []
  let sectorData = []
  if (Array.isArray(response?.data?.data)) {
    sectorColumns = [
      {
        headerName: 'Sector',
        field: 'sector',
        flex: 1
      },
      ...Object.entries(response?.data?.fieldLabels || [])
        .filter(([key, value]) => key !== 'sector')
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map(([key, value]) => ({
          headerName:
            key === 'strategy'
              ? 'Strategy (%)'
              : value + ' (%)',
          field: key,
          flex: 1,
          type: 'number',
          headerAlign: 'right',
          align: 'right',
          valueGetter: (params) => parseFloat(params?.value ? (params?.value * 100)?.toFixed(2) : params.value),
          renderCell: (params) => params?.row[key] !== null && params?.row[key] !== undefined ? (params?.row[key] * 100)?.toFixed(2) : 'N/A'
        }))]
    sectorData = (response?.data?.data || []).map((sectorObj) => ({ ...sectorObj, id: randomId() }))
  }

  return { sectorData, sectorColumns }
}

export const performanceDataProcess = (response, type) => {
  return [
    {
      name: 'Pre Tax',
      data: response.map(obj => ({
        x: obj?.dataDate,
        y: obj?.cumulatedPreTaxDailyReturns * 100,
      }))
    },
    ...(type === 'Trading' ? [
      {
        name: 'Post Tax',
        data: response.map(obj => ({
          x: obj?.dataDate,
          y: obj?.cumulatedPostTaxDailyReturns * 100
        }))
      }
    ] : []),
    ...(type === 'Scenario Transition' ? [
      {
        name: 'Pre Tax Original UMA',
        data: response.map(obj => ({
          x: obj?.dataDate,
          y: obj?.preTaxOriginalUmaReturns * 100
        }))
      }
    ] : [])
  ]
}

export const benchmarkDataProcess = (response, type) => {
  // return [
  //   {
  //     name: 'Strategy',
  //     data: response?.map((obj) => ({
  //       x: new Date(obj.dataDate).getTime(),
  //       y: obj?.strCumulatedPreTaxDailyReturns * 100
  //     }))
  //   },
  //   ...(type !== 'Scenario Transition' ? [
  //     {
  //       name: 'Portfolio',
  //       data: response.map(obj => ({
  //         x: new Date(obj.dataDate).getTime(),
  //         y: obj?.accCumulatedPreTaxDailyReturns * 100
  //       }))
  //     }
  //     // {
  //     //   name: 'Original UMA',
  //     //   data: response?.map(obj => ({
  //     //     x: new Date(obj.dataDate).getTime(),
  //     //     y: obj?.strPreTaxDailyReturns * 100
  //     //   }))
  //     // }
  //   ] : [])
  // ]

  const strategyWiseData = response?.length ? response[0]?.benchMarksReturn?.map((data) => ({
    name: data?.strategyName,
    data: []
  })) : []

  const noOfStrategies = strategyWiseData?.length
  response?.forEach((res) => {
    res?.benchMarksReturn.forEach((item, i) => {
      if (i <= noOfStrategies)
        strategyWiseData[i].data.push({
          x: item?.dataDate,
          y: item?.strCumulatedPreTaxDailyReturns * 100
        })
    })
  })

  return strategyWiseData
}

export const marketValueDataProcess = (response) => {
  return [
    {
      name: 'Market Value Returns',
      data: response?.map((obj) => ({
        x: new Date(obj.dataDate).getTime(),
        y: obj?.metric?.NET_MV_PLUS_CASH * 100
      }))
    }
  ]
}

export const taxlotDataProcess = (response, taxlot) => {
  if (taxlot) {
    const accountHierarchy = createHierarchy(response || [], 0, ['accountName'])
    return { accountHierarchy, rawData: response }
  }
  const dataGridRows = []
  response?.forEach(res => {
    dataGridRows.push({
      id: randomId(),
      name: res?.accountName,
      marketValue: res?.marketValue,
      urgl: res?.urgl,
      weight: res?.weight,
      hierarchy: [res?.accountName]
    })
    res?.taxlot?.forEach(taxlot => {
      const id = randomId()
      dataGridRows.push({
        id: id,
        name: taxlot?.companyName,
        modelWeight: taxlot?.modelWeight,
        marketValue: taxlot?.marketValue,
        urgl: taxlot?.urgl,
        weight: taxlot?.weight,
        hierarchy: [res?.accountName, taxlot?.companyName + taxlot?.instrId]
      })
      taxlot?.subTaxlot?.forEach(subTaxlot => {
        const id = randomId()
        dataGridRows.push({
          id,
          name: subTaxlot?.instrumentName,
          urgl: subTaxlot?.urgl,
          weight: subTaxlot?.weight,
          marketValue: subTaxlot?.marketValue,
          hierarchy: [res?.accountName, taxlot?.companyName + taxlot?.instrId, subTaxlot?.instrumentName + id]
        })
      })
    })
  })
  return { taxlotData: dataGridRows }
}

export const allocationDataProcess = (taxlot, scenario) => {
  // function generateArray(key, hierarchy, finalArray, index, datagridHierarchy, fieldsRequiredForSummation) {
  //   const arrayFromCurrentLevel = []
  //   datagridHierarchy.push(key)
  //   const aggregatedFieldsFromChild = hierarchyToArray(hierarchy[key], arrayFromCurrentLevel, index + 1, datagridHierarchy)
  //   if (key !== 'Untagged' && key !== 'null')
  //     arrayFromCurrentLevel.unshift({ ...aggregatedFieldsFromChild, hierarchy: [...datagridHierarchy], name: key, currLevel: index })
  //   datagridHierarchy.pop()
  //   // add the generated child hierarchy array into final array
  //   arrayFromCurrentLevel.forEach(obj => {
  //     finalArray.push(obj)
  //   })
  //   // add up the required fields properties from sibling to show in parent
  //   for (let key in fieldsRequiredForSummation) {
  //     fieldsRequiredForSummation[key] += aggregatedFieldsFromChild[key]
  //   }
  // }

  // function hierarchyToArray(hierarchy, finalArray, index, datagridHierarchy) {
  //   const fieldsRequiredForSummation = { weight: 0, marketValue: 0, urgl: 0, rgl: 0 }
  //   if (Array.isArray(hierarchy)) {
  //     fieldsRequiredForSummation.accountId = hierarchy.length ? hierarchy[0]?.accountId : null
  //     hierarchy.forEach((object) => {
  //       fieldsRequiredForSummation.weight += object?.weight || 0
  //       fieldsRequiredForSummation.marketValue += object?.marketValue || 0
  //       fieldsRequiredForSummation.urgl += object?.urgl || 0
  //       fieldsRequiredForSummation.rgl += object?.rgl || 0
  //     })
  //     return fieldsRequiredForSummation
  //   }
  //   let untaggedFlag = false
  //   for (const key in hierarchy) {
  //     if (key === 'Untagged' || key === 'null') {
  //       untaggedFlag = true
  //       continue
  //     }
  //     generateArray(key, hierarchy, finalArray, index, datagridHierarchy, fieldsRequiredForSummation)
  //   }
  //   if (untaggedFlag)
  //     generateArray('Untagged', hierarchy, finalArray, index, datagridHierarchy, fieldsRequiredForSummation)
  //   return fieldsRequiredForSummation
  // }

  // const taxlotData = []
  // hierarchyToArray(createHierarchy(taxlot, 0, ['accountName']), taxlotData, 0, [])
  // const scenarioData = []
  // hierarchyToArray(createHierarchy(scenario, 0, ['accountName']), scenarioData, 0, [])

  return { taxlot: taxlot, scenario: scenario }
}

export const summaryTabDataProcess = (response) => {
  const data = [], links = [], sourceNodes = [], targetNodes = []
  const transferDetails = response?.transferDetails || [], originalUMA = response?.originalUMA?.accountDetails || [], targetUMA = response?.scenarioUMA?.accountDetails || []
  let flowChartNodes = [], flowChartEdges = []
  const nodeObj = {
    type: 'custom',
    position: {
      x: 0,
      y: 0
    },
    width: 400,
    height: 145
  }
  // add original uma node to the source node array
  originalUMA?.forEach(obj => {
    data?.push({
      ...obj,
      name: obj?.accountName,
      type: 'TRANSITION',
      id: obj?.accountId
    })
    sourceNodes.push({
      ...nodeObj,
      id: obj?.accountId,
      data: {
        ...obj,
        type: 'TRANSITION'
      }
    })
  })

  // add scenario uma node to the target node array
  targetUMA.forEach(obj => {
    data?.push({
      ...obj,
      name: obj?.accountName,
      type: 'SCENARIO_TRANSITION',
      id: obj?.accountId
    })
    if (transferDetails?.find(edge => edge?.srcAccount === obj?.accountId || edge?.dstAccount === obj?.accountId)) {
      targetNodes.push({
        ...nodeObj,
        id: obj?.accountId,
        data: {
          ...obj,
          type: 'SCENARIO_TRANSITION'
        }
      })
    }
  })
  const getStrategyTypeNode = (name) => {
    // object for active/passive node
    return {
      ...nodeObj,
      height: 128,
      id: randomId(),
      data: {
        type: 'SCENARIO_TRANSITION',
        accountName: name,
        portMv: 0,
        transferredShares: 0,
        buyShares: 0,
        totalUrgl: 0,
        isVirtualAccount: true
      }
    }
  }

  if (transferDetails?.length) {
    const sourceToTypeEdges = [], typeToDestinationEdges = [], strTypeObj = {}
    transferDetails?.forEach((obj) => {
      // find destination account for summation based on strType
      const targetNode = targetUMA?.find(target => target?.accountId === obj?.dstAccount)

      if (!strTypeObj?.active && targetNode?.strType === 'MANAGED') {
        strTypeObj['active'] = getStrategyTypeNode('ACTIVE')
      } else if (!strTypeObj?.passive && (targetNode?.strType === 'DERIVED' || targetNode?.strType === 'SIMPLE')) {
        strTypeObj['passive'] = getStrategyTypeNode('PASSIVE')
      }

      const strTypeId = targetNode?.strType === 'MANAGED' ? strTypeObj['active']?.id : strTypeObj['passive']?.id

      const edgeObj = {
        data: {
          value: obj?.portMv
        },
        type: 'custom',
        style: {
          strokeWidth: 2,
          stroke: '#aaaaaa',
          strokeDasharray: [5]
        }
      }
      // find sourceindex to sum up the value in edge from same source to same strType
      const srcIndex = sourceToTypeEdges.findIndex(edge => edge?.source === obj?.srcAccount && edge?.target === strTypeId)
      if (srcIndex === -1) {
        sourceToTypeEdges.push({
          ...edgeObj,
          id: randomId(),
          // for source, edge will be from source node to active/passive node
          source: obj?.srcAccount,
          target: strTypeId,
        })
      } else {
        // sum up portMv if edge's source and destination is repeated
        sourceToTypeEdges[srcIndex] = {
          ...sourceToTypeEdges[srcIndex],
          data: {
            ...sourceToTypeEdges[srcIndex]?.data,
            value: sourceToTypeEdges[srcIndex]?.data?.value + obj?.portMv
          }
        }
      }
      const destIndex = typeToDestinationEdges.findIndex(edge => edge?.target === obj?.dstAccount && edge?.source === strTypeId)
      if (destIndex === -1) {
        // for target, edge will be from active/passive node to target node
        typeToDestinationEdges.push({
          ...edgeObj,
          id: randomId(),
          source: strTypeId,
          target: obj?.dstAccount,
        })
      } else {
        // sum up portMv if edge's source and destination is repeated
        typeToDestinationEdges[destIndex] = {
          ...typeToDestinationEdges[destIndex],
          data: {
            ...typeToDestinationEdges[destIndex]?.data,
            value: typeToDestinationEdges[destIndex]?.data?.value + obj?.portMv
          }
        }
      }
      links.push({
        source: obj?.srcAccount,
        target: obj?.dstAccount,
        value: obj.portMv
      })
    })

    function sumupFields(strTypeObj, field) {
      // from edges filter edges where source is active/passive
      // this will give all the edges that goes from active/passive to the target
      // since there can be multiple links outgoing to single node
      // find all unique nodes from the target
      // get the index of active/passive node to add the value in object
      // for all unique nodes from the active/passive sum up all the fields from nodes
      const activeEdges = typeToDestinationEdges.filter((edge) => edge?.source === strTypeObj?.[field]?.id)
      const uniqueNodes = []
      activeEdges.forEach(edge => {
        if (!uniqueNodes.find(node => node?.id === edge?.target)) {
          const node = targetNodes.find(node => node?.id === edge?.target)
          uniqueNodes.push(node)
        }
      })
      uniqueNodes.forEach((node) => {
        strTypeObj[field] = {
          ...strTypeObj[field],
          data: {
            ...strTypeObj[field]?.data,
            portMv: strTypeObj[field]?.data?.portMv + node?.data?.portMv,
            transferredShares: strTypeObj[field]?.data?.transferredShares + node?.data?.transferredShares,
            buyShares: strTypeObj[field]?.data?.buyShares + node?.data?.buyShares,
            totalUrgl: strTypeObj[field]?.data?.totalUrgl + node?.data?.totalUrgl
          }
        }
      })
    }

    if (strTypeObj?.active) {
      sumupFields(strTypeObj, 'active')
    }
    if (strTypeObj?.passive) {
      sumupFields(strTypeObj, 'passive')
    }

    // add active/passive node into flowChartNodes
    Object.keys(strTypeObj).forEach(obj => {
      flowChartNodes.push(strTypeObj[obj])
    })
    flowChartEdges = [...sourceToTypeEdges, ...typeToDestinationEdges]
    flowChartNodes = [...flowChartNodes, ...sourceNodes, ...targetNodes]
  }

  return { sankeyTransitionData: { data, links }, data: targetUMA, flowChart: { flowChartNodes, flowChartEdges } }
}

export const portfolioDataProcess = (response) => {
  const assetLevel = ['assetClassDesc', 'accountName']
  function generateArray(key, hierarchy, finalArray, index, datagridHierarchy, fieldsRequiredForSummation) {
    const arrayFromCurrentLevel = []
    datagridHierarchy.push(key)
    const aggregatedFieldsFromChild = hierarchyToArray(hierarchy[key], arrayFromCurrentLevel, index + 1, datagridHierarchy)
    if (key !== 'Untagged' && key !== 'null')
      arrayFromCurrentLevel.unshift({ ...aggregatedFieldsFromChild, id: randomId(), hierarchy: [...datagridHierarchy], name: key, currLevel: index })
    datagridHierarchy.pop()
    // add the generated child hierarchy array into final array
    arrayFromCurrentLevel.forEach(obj => {
      finalArray.push(obj)
    })
    // add up the required fields properties from sibling to show in parent
    for (let key in fieldsRequiredForSummation) {
      fieldsRequiredForSummation[key] += aggregatedFieldsFromChild[key]
    }
  }

  function hierarchyToArray(hierarchy, finalArray, index, datagridHierarchy) {
    const fieldsRequiredForSummation = { weight: 0, marketValue: 0 }
    // at the last level, type of hierarchy is array of object
    if (Array.isArray(hierarchy)) {
      if (hierarchy.length) {
        fieldsRequiredForSummation.accountId = hierarchy[0].accountId
      }
      hierarchy.forEach((object) => {
        const id = randomId()
        finalArray.push({ ...object, id, hierarchy: [...datagridHierarchy.filter(obj => obj !== 'Untagged'), object.instrumentName + id], name: object.instrumentName, lastLevelFlag: true, noHighlight: datagridHierarchy.includes('Untagged') })
        fieldsRequiredForSummation.weight += object?.weight || 0
        fieldsRequiredForSummation.marketValue += object?.marketValue || 0
      })
      return fieldsRequiredForSummation
    }
    let untaggedFlag = false
    for (const key in hierarchy) {
      if (key === 'Untagged' || key === 'null') {
        untaggedFlag = true
        continue
      }
      generateArray(key, hierarchy, finalArray, index, datagridHierarchy, fieldsRequiredForSummation)
    }
    if (untaggedFlag)
      generateArray('Untagged', hierarchy, finalArray, index, datagridHierarchy, fieldsRequiredForSummation)
    return fieldsRequiredForSummation
  }

  const finalArray = []
  hierarchyToArray(createHierarchy(response || [], 0, assetLevel), finalArray, 0, [])
  return finalArray
}

export const allocationChartDataProcess = (response) => {
  const selectedAssetLevel = 1
  const groupBy = ['assetClassDesc', 'accountName']

  function hierarchyToArray(hierarchy, finalArray, index) {
    const fieldsRequiredForSummation = { weight: 0 }
    // at the last level, type of hierarchy is array of object
    if (Array.isArray(hierarchy)) {
      // in allocation chart only account allocation value is required
      hierarchy.forEach((object) => {
        fieldsRequiredForSummation.weight += object?.weight
      })
      return fieldsRequiredForSummation
    }
    for (const key in hierarchy) {
      // using different array because of child and parent positioning
      const arrayFromCurrentLevel = []
      const aggregatedFieldsFromChild = hierarchyToArray(hierarchy[key], arrayFromCurrentLevel, index + 1)
      // only want the data of selected asset level and it can't be null
      // untagged data is not related to any level so it can be shown on any level
      if ((key !== 'null' && index === selectedAssetLevel - 1) || key === 'Untagged') {
        // every key is parent of value, so key should be added at start of array
        arrayFromCurrentLevel.unshift({ ...aggregatedFieldsFromChild, key })
      }
      // add the generated child hierarchy array into final array
      arrayFromCurrentLevel.forEach(obj => {
        finalArray.push(obj)
      })
      // add up the required fields properties from sibling to show in parent
      for (const key in fieldsRequiredForSummation) {
        fieldsRequiredForSummation[key] += aggregatedFieldsFromChild[key]
      }
    }
    return fieldsRequiredForSummation
  }

  const finalArray = []
  hierarchyToArray(createHierarchy(response || [], 0, groupBy), finalArray, 0, [])

  const series = []
  finalArray?.map(data => {
    if (data?.key !== null) {
      const index = series.findIndex(x => x.name === data?.key)
      if (index === -1) {
        series.push({ name: data?.key, data: [Number(data?.weight !== null ? data?.weight : 0)] })
      }
      else {
        series[index].data[0] += Number(data?.weight !== null ? data?.weight : 0)
      }
    }
  })

  series.sort((a, b) => b.data[0] - a.data[0])
  return series
}
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { API } from 'aws-amplify'
import { useErrorToast } from '../../../../hooks/useErrorToast'
import { useAuth } from '../../../../contexts/AuthContext'
import Loader from '../../../Loader'
import LayoutFlowComponent from './GroupLayout'

const AggregateTreeView2 = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [aggregateData, setAggregateData] = useState({ nodes: [], edges: [] })
  const { showError } = useErrorToast()
  const { user } = useAuth()
  const params = useParams()

  const fetchAggregateTreeData = async () => {
    setIsLoading(true)
    API.get(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user.userGroup}/client/graph/${params?.familyId}`
    )
      .then((response) => {
        if (response?.data && response?.data?.nodes && response?.data?.edges) {
          setAggregateData(response.data)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to fetch aggregate tree data.')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (user) {
      fetchAggregateTreeData()
    }
  }, [user])

  return (
    <>
      <div style={{ width: '100%', height: '100vh', position: 'relative' }}>
        {
          isLoading
            ? <Loader />
            : <LayoutFlowComponent aggregateData={aggregateData} />
        }
      </div>
    </>
  )
}

export default AggregateTreeView2

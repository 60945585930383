import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, IconButton, Modal, Typography } from '@mui/material'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'

const cashSwapModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  px: 2,
  py: 2,
  width: '100%',
  borderRadius: '4px'
}

const CashSwapPopup = ({ open, onClose, onConfirm, data }) => {
  const columns = [
    { field: 'accountCd', headerName: 'Account Code', flex: 1 },
    { field: 'accountName', headerName: 'Account Name', flex: 1 },
    { field: 'type', headerName: 'Type', flex: 1 },
    {
      headerName: 'Reason',
      field: 'message',
      align: 'left',
      flex: 2,
      headerAlign: 'left',
      sortable: false,
      disableColumnMenu: true,
    }
  ]

  return (
    <Modal
      onClose={onClose}
      open={open}
    >
      <Box sx={{ ...cashSwapModalStyle, outline: 'none', minWidth: '400px', maxWidth: '800px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '5px', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }}>
          <Typography variant='h6' sx={{ ml: '5px', fontWeight: 400, color: '#34475A' }}>Validate Account Changes</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DataGridPro
          autoHeight
          density='compact'
          rows={data}
          columns={columns}
          disableRowSelectionOnClick
          getRowHeight={() => 'auto'}
          getRowId={(row) => row?.id}
          pagination
          pageSizeOptions={[10]}
          initialState={{
            ...data?.initialState,
            pagination: { paginationModel: { pageSize: 10 } }
          }}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay
          }}
          slotProps={{
            noRowsOverlay: { msg: 'No Cash Swap Found.' }
          }}
          sx={(theme) => ({
            '& .MuiDataGrid-cell:focus': {
              outline: 'none'
            },
            [`.${gridClasses.main}`]: {
              height: 'min(100vh - 64px - 20px - 53px, 600px - 64px - 20px - 53px)'
            },
            [`.${gridClasses.columnHeaders}`]: {
              position: 'sticky',
              backgroundColor: theme.palette.background.paper,
              top: 0,
              zIndex: 1
            },
            [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
              color: '#74788d',
              fontWeight: 600
            },
            [`.${gridClasses.virtualScroller}`]: {
              overflowY: 'auto !important',
              scrollbarGutter: 'stable',
              scrollbarWidth: 'none'
            }
          })}
        />
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button onClick={onConfirm} variant='contained' size='small'>
            Approve
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

const CustomNoRowsOverlay = ({ msg }) => (
  <Typography sx={{ textAlign: 'center', margin: '16px auto' }}>
    {msg}
  </Typography>
)

export default CashSwapPopup

import React, { useEffect, useRef, useState } from 'react'
import { Auth } from 'aws-amplify'
import { NavLink } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import MailOutline from '@mui/icons-material/MailOutline'
import SaveIcon from '@mui/icons-material/Save'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { LoadingButton } from '@mui/lab'
import { Alert, Box, Button, Card, CardContent, FormControl, FormHelperText, IconButton, InputAdornment, Stack, Typography, styled } from '@mui/material'
import Link from '@mui/material/Link'
import ReCAPTCHA from 'react-google-recaptcha'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import arisLogoBlue from '../assets/images/arisNewLogoBlue.svg'
import ellipseShape from '../assets/images/ellipse-shape.svg'
import CustomOutlinedInput from '../components/CustomOutlinedInput'
import { useAuth } from '../contexts/AuthContext'
import { useErrorToast } from '../hooks/useErrorToast'
import { clearReviewPageAccountsStore } from '../store/account-review-reducer/account-review-reducer'
import { resetThemeSettings } from '../store/custom-sponsor-design-reducer/custom-sponsor-design-reducer'
import { clearStore } from '../store/dashboard-reducer/dashboard-reducer'
import { resetThemeCustomization } from '../store/pdf-customizer-reducer/Action/allPageAction'
import { resetTemplateData } from '../store/pdf-customizer-reducer/Action/chartDropAction'
import { chartDataState } from '../store/pdf-customizer-reducer/Action/chartStateAction'
import { clearUserStore } from '../store/user-reducer/user-reducer'
import { useTourContext } from '../contexts/TourContext'
import MfaQRCode from './mfa'

const CustomFormLabel = styled(Typography)(({ theme }) => ({
  color: '#002A59',
  fontFamily: 'Open Sans !important',
  fontWeight: '600 !important'
}))

const Login = () => {
  const { sendSentryError } = useErrorToast()
  const { setUserAclData, noAclError, setNoAclError } = useAuth()
  const { setIsNewUser, setFetchedAllApiResponse } = useTourContext()
  const [userDetails, setUserDetails] = useState({})
  const [showPassword, setShowPassword] = useState(false)
  const { showError } = useErrorToast()
  const [isLoading, setIsLoading] = useState(false)
  const recaptchaRef = useRef(null)
  const dispatch = useDispatch()
  const { modelsData, summaryData, accountData } = useSelector((state) => state.dashboard)

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required('Email required'),
    password: Yup.string().required('Password required')
  })

  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(validationSchema)
  })

  const onSubmitHandler = async (data) => {
    try {
      const token = await recaptchaRef.current.executeAsync()

      if (token) {
        setIsLoading(true)
      } else {
        setIsLoading(false)
      }

      const validateCaptchaURL = `${process.env.REACT_APP_BASE_URI_USER}user/v1/validate-captcha?response=${token}`

      const response = await fetch(validateCaptchaURL, {
        method: 'POST',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_USER,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          response: token
        })
      })

      const resData = await response.json()
      const { success } = resData

      if (success) {
        const authResponse = await Auth.signIn(data.email, data.password)
        setUserDetails(authResponse)
      } else {
        showError('Failed to login. Please contact administration')
      }
    } catch (error) {
      showError('Failed to login. Please contact administration')
      sendSentryError(error)
    } finally {
      setIsLoading(false)
      recaptchaRef?.current?.reset()
    }
  }

  useEffect(() => {
    const favIcon = document.getElementById('favicon')
    const title = document.getElementById('title')
    title.innerText = 'Aris Investing'
    favIcon.href = './ArisFavicon.svg'
    // Cleanup redux state, user ACL and product tour configurations
    setUserAclData({})
    setIsNewUser(false)
    setFetchedAllApiResponse(false)
    if (modelsData?.length || (summaryData && Object.keys(summaryData).length) || accountData?.length) {
      dispatch(clearStore('RESET'))
      dispatch(clearUserStore('RESET'))
      dispatch(clearReviewPageAccountsStore('RESET'))
      dispatch(resetThemeSettings())
    }
    dispatch(resetTemplateData())
    dispatch(resetThemeCustomization())
    dispatch(chartDataState(false, 'IS_API_CALL'))
    localStorage.clear()
  }, [])

  return (
    <>
      <Box className='auth-layout'>
        <img src={ellipseShape} alt='' className='ellipse-shape' />
        <Card>
          <CardContent className='login-form'>
            <Box className='auth-logo' mx='auto' textAlign='center'>
              <img src={arisLogoBlue} alt='aris logo' height={55} width={150} />
            </Box>
            {Object.keys(userDetails).length !== 0
              ? <MfaQRCode user={userDetails} handleBackToLogin={() => setUserDetails({})} />
              : <form onSubmit={handleSubmit(onSubmitHandler)} onChange={() => setNoAclError(false)} className='login-form-container' disabled={isLoading}>
                <Stack mt={3}>
                  <FormControl fullWidth margin='dense' error={Boolean(errors.email?.message)}>
                    <CustomFormLabel gutterBottom>
                      Email
                    </CustomFormLabel>
                    <CustomOutlinedInput
                      placeholder='Enter Email'
                      id='email'
                      name='email'
                      type='email'
                      {...register('email')}
                      disabled={isLoading}
                      error={Boolean(errors.email?.message)}
                      startAdornment={
                        <InputAdornment position='start'>
                          <MailOutline
                            aria-label='email'
                            edge='end'
                            fontSize='small'
                            sx={{
                              color: '#3369A6',
                              fontSize: '22px'
                            }}
                          />
                        </InputAdornment>
                      }
                    />
                    <FormHelperText sx={{ fontSize: '14px' }} error={Boolean(errors.email?.message)}>
                      {errors.email?.message || ''}
                    </FormHelperText>
                  </FormControl>
                  <FormControl fullWidth margin='dense' error={Boolean(errors.password?.message)}>
                    <CustomFormLabel gutterBottom>
                      Password
                    </CustomFormLabel>
                    <CustomOutlinedInput
                      id='password'
                      name='password'
                      placeholder='Enter Password'
                      type={showPassword ? 'text' : 'password'}
                      {...register('password')}
                      disabled={isLoading}
                      error={Boolean(errors.password?.message)}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() => setShowPassword(!showPassword)}
                            edge='end'
                            sx={{
                              color: '#817D86',
                              fontSize: '22px'
                            }}
                          >
                            {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                          </IconButton>
                        </InputAdornment>
                      }
                      startAdornment={
                        <InputAdornment position='start'>
                          <LockOutlinedIcon
                            aria-label='password'
                            edge='end'
                            fontSize='small'
                            sx={{
                              color: '#3369A6',
                              fontSize: '22px'
                            }}
                          />
                        </InputAdornment>
                      }
                    />
                    <FormHelperText sx={{ fontSize: '14px' }} error={Boolean(errors.password?.message)}>
                      {errors.password?.message || ''}
                    </FormHelperText>
                  </FormControl>
                  <Box sx={{ display: 'inline-block', textAlign: 'end' }}>
                    <Link to='/forgot-password' component={NavLink}>
                      Forgot password?
                    </Link>
                  </Box>
                  {
                    noAclError
                      ? (
                        <Box sx={{ marginTop: '10px' }}>
                          {/* alert */}
                          <Alert severity='error' icon={false} sx={{ borderRadius: '10px' }}>{'You don\'t have required access. Please contact administration.'}</Alert>
                        </Box>
                        )
                      : null
                  }
                  <Box sx={{ mt: noAclError ? 2 : 3 }}>
                    {
                      isLoading
                        ? (
                          <LoadingButton
                            loading
                            loadingPosition='start'
                            startIcon={<SaveIcon />}
                            variant='outlined'
                            fullWidth
                            sx={{
                              padding: 2
                            }}
                          >
                            Sign in
                          </LoadingButton>
                          )
                        : (
                          <Button
                            disabled={isLoading || noAclError}
                            fullWidth
                            type='submit'
                            variant='contained'
                            sx={{
                              padding: 2
                            }}
                          >
                            Sign in
                          </Button>
                          )
                    }
                    {showError && <Box className='error'>{showError}</Box>}
                  </Box>
                </Stack>
                </form>}
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_INVISIBLE_SITE_KEY}
              size='invisible'
              style={{ visibility: Object.keys(userDetails).length !== 0 ? 'hidden' : 'visible' }}
            />
          </CardContent>
        </Card>
      </Box>
    </>
  )
}

export default Login

import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import { useDispatch } from 'react-redux'
import { storeAccountData } from '../store/dashboard-reducer/dashboard-reducer'
import { Box, Chip, Grid, IconButton, Modal, Skeleton, TextField, Tooltip, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import CircleIcon from '@mui/icons-material/Circle'
import CloseIcon from '@mui/icons-material/Close'
// import { TbHierarchy2 } from 'react-icons/tb'
import redirect from '../assets/images/pop-out-line.svg'
import aggregateRedirectIcon from '../assets/images/redirect-uma-icon.svg'
import { useAuth } from '../contexts/AuthContext'
import { useErrorToast } from '../hooks/useErrorToast'
import { useAccountService } from '../hooks/useAccountService'
import { moduleConfig } from '../contexts/data'
import { ACCESS_LEVEL } from '../contstants/constants'
import PolicyStatusPopup from './PolicyStatusPopup'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  px: 2,
  py: 2,
  width: '100%',
  borderRadius: '4px'
}

const accountGroupCols = [
  {
    field: 'aggGroupId',
    headerName: 'Group Id',
    flex: 1
  },
  {
    field: 'aggGroupName',
    headerName: 'Group Name',
    flex: 1
  },
  {
    field: 'groupLabel',
    headerName: 'Group Type',
    flex: 1
  },
  {
    field: ' ',
    headerName: '',
    width: 50,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => (
      params?.row?.groupType !== 'ENTITYMAP'
        ? <Link
            to={`/aggregate/aggregate-dashboard/${params?.row?.aggGroupId}`}
            state={{
              aggGroupCd: params?.row?.aggGroupCd,
              aggGroupName: params?.row?.aggGroupName,
              groupType: params?.row?.groupType,
              groupLabel: params?.row?.groupLabel,
              ...(params?.row?.groupType === 'UMA' ? { umaType: params?.row?.umaType } : {})
            }}
          >
          <img src={redirect} alt='redirect-icon' />
          </Link>
        : <Link to={`/aggregate/entity-mapping/${params?.row?.aggGroupId}`}>
          <img src={redirect} alt='redirect-icon' />
        </Link>
    )
  }
]

const AccountTitle = ({ title, children }) => {
  const { user, checkAccess } = useAuth()
  const { getAccountListUtil } = useAccountService()
  const params = useParams()
  const location = useLocation()
  const dispatch = useDispatch()
  const { showError } = useErrorToast()
  const [custodianAccountNumber, setCustodianAccountNumber] = useState('')
  const [accountGroup, setAccountGroup] = useState([])
  const [copyAccountGroup, setCopyAccountGroup] = useState([])
  const [isGroupPopupOpen, setIsGroupPopupOpen] = useState(false)
  const [entityMapData, setEntityMapData] = useState(null)
  const [showPolicyPopup, setShowPolicyPopup] = useState(false)
  const [policyData, setPolicyData] = useState({})
  const [accountDetails, setAccountDetails] = useState({})
  const [accountDetailsLoading, setAccountDetailsLoading] = useState(true)

  const fetchAccountDetails = async () => {
    setAccountDetailsLoading(true)
    const datasecure = localStorage.getItem('object')
    const getStoreData = JSON.parse(datasecure)
    const filterSecuredata = getStoreData?.filter(
      (item) => item?.accountId === params?.accountId
    ) || []
    if (!filterSecuredata?.length) {
      try {
        const response = await API.get(
          'baseUriTransactionalMaster',
          `transactional-master/v1/${user?.userGroup}/account-detail/${params?.accountId}`
        )
        if (response?.data) {
          setAccountDetails(response?.data)
          getAccountsList()
        }
      } catch (error) {
        showError(error, false, {}, 'Failed to load account details.')
      }
    } else {
      setAccountDetails(filterSecuredata[0])
    }
    setAccountDetailsLoading(false)
  }

  const getAccountsList = async () => {
    getAccountListUtil({ serviceId: 'book-of-business', resourceId: 'book-of-business' })
      .then((response) => {
        if (response?.data?.accountsSummaryDetails) {
          dispatch(storeAccountData(response.data.accountsSummaryDetails))
          const storeArray = []
          response?.data?.accountsSummaryDetails?.forEach((element) => {
            storeArray.push({
              accountName: element.accountName,
              accountType: element.accountType,
              accountCode: element.accountCode,
              strategyId: element.strategyId,
              custodianAccountNumber: element?.custodianAccountNumber,
              portSpecName: element.portSpecName,
              accountId: element.accountId,
              priority: element.priority,
              totalMarketValue: element.totalMarketValue,
              isTaxable: element.isTaxable,
              strTypeCode: element.strTypeCode
            })
          })
          localStorage.setItem('object', JSON.stringify(storeArray))
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load account summary details.')
      })
  }

  const fetchAccountGroup = () => {
    API.post('baseUriAggregateMaster', `aggregate-master/v1/${user?.userGroup}/account/aggregate-grouping`, {
      body: {
        accountIds: [params?.accountId]
      },
      queryStringParameters: user?.userGroup === 'adv-classic'
        ? {
            resources: encodeURIComponent(JSON.stringify({
              serviceId: 'aggregate',
              resourceId: 'aggregate-dashboard'
            }))
          }
        : {}
    })
      .then((response) => {
        if (response?.data?.length > 0) {
          const data = response?.data?.map(obj => ({ ...obj, id: randomId() }))
          // const entityMapIndex = data.findIndex(obj => obj?.groupType === 'ENTITYMAP')
          // if (entityMapIndex > -1)
          //   setEntityMapData(data.splice(entityMapIndex, 1)[0])
          // else
          //   setEntityMapData(null)
          setAccountGroup(data)
          setCopyAccountGroup(data)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load account group.')
      })
  }

  const fetchAccountPolicy = (id) => {
    API.post('baseDataValidation', `data-validation/v1/${user.userGroup}/policies/accounts`, {
      body: {
        accountIds: [id]
      }
    })
      .then(res => {
        if (res?.data?.length) {
          setPolicyData(res?.data[0])
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load account policy.')
      })
  }

  useEffect(() => {
    if (user) {
      fetchAccountDetails()
      if (checkAccess(moduleConfig.AGGREGATE, ACCESS_LEVEL.SUB_MODULE_ACCESS, { subModuleName: moduleConfig.AGGREGATE_DASHBOARD })) { fetchAccountGroup() }
      if (title === 'Dashboard' && checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.ACCOUNT_DASHBOARD, component_name: moduleConfig.VIEW_ACCOUNT_POLICY_CHECK })) { fetchAccountPolicy(params?.accountId) }
    }
  }, [user, params?.accountId])

  useEffect(() => {
    if (accountDetails && accountDetails && accountDetails?.custodianAccountNumber && accountDetails?.accountType === 'TRADING') {
      setCustodianAccountNumber(` - ${accountDetails?.custodianAccountNumber}`)
    } else {
      setCustodianAccountNumber('')
    }
  }, [accountDetails])

  const getStatusColor = (status) => {
    if (status === 'green') { return '#3BBFA3' } else if (status === 'red') { return '#FF6161' } else if (status === 'yellow') { return '#D29922' } else if (status === 'gray') { return '#0000004d' }
    return '#c9c9c9'
  }

  return (
    <>
      <Grid container justifyContent='space-between' alignItems='center' mb={2}>
        <Grid item display='flex' flexDirection={{ xs: 'column', md: 'row' }} alignItems={{ xs: 'flex-start', md: 'center' }}>
          <Typography component='div' className='page-title' sx={{ paddingTop: '0 !important' }}>{title}</Typography>
          <Box className='client' mt={{ xs: 3, md: 0 }}>
            <Box sx={{
              borderRadius: '4px',
              width: '3.05px',
              height: '53.8px',
              mx: 2,
              background: accountDetails?.priority === 1 ? '#F05F5F' : accountDetails?.priority === 2 ? '#169D6C' : '#EFAE3A'
            }}
            />
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {accountDetailsLoading
                  ? (
                    <Skeleton variant='text' sx={{ width: '150px', fontSize: '20px' }} />
                    )
                  : (
                    <>
                      <Typography variant='h6' sx={{ display: 'flex', alignItems: 'center' }}>{accountDetails?.accountName || ''}</Typography>
                      <Chip label={accountDetails?.accountType} size='small' sx={{ background: '#50CAF1', color: 'white', fontSize: '10px', fontWeight: '500', ml: 1, height: '16px' }} />
                    </>
                    )}
                {
                  title === 'Dashboard' && checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.ACCOUNT_DASHBOARD, component_name: moduleConfig.VIEW_ACCOUNT_POLICY_CHECK }) && policyData?.accountPolicyColorStatus
                    ? (
                      <Tooltip title='Account Policies'>
                        <IconButton onClick={() => setShowPolicyPopup(true)} sx={{ p: '6px' }}>
                          <CircleIcon sx={{ color: getStatusColor(policyData?.accountPolicyColorStatus), height: '12px', width: '12px' }} />
                        </IconButton>
                      </Tooltip>
                      )
                    : ''
                }
                {
                  checkAccess(moduleConfig.AGGREGATE, ACCESS_LEVEL.SUB_MODULE_ACCESS, { subModuleName: moduleConfig.AGGREGATE_DASHBOARD }) && accountGroup?.length
                    ? <IconButton onClick={() => setIsGroupPopupOpen(true)}>
                      <img src={aggregateRedirectIcon} alt='redirect' height={20} width={20} />
                      </IconButton>
                    : ''
                }
                {/* {
                  entityMapData && checkAccess(moduleConfig.AGGREGATE, ACCESS_LEVEL.SUB_MODULE_ACCESS, { subModuleName: moduleConfig.ENTITY_MAPPING }) ?
                    <Link to={`/aggregate/entity-mapping/${entityMapData.aggGroupId}`}>
                      <IconButton>
                        <TbHierarchy2 fontSize='18px' color='#74788D' strokeWidth={1.5} />
                      </IconButton>
                    </Link>
                    : ''
                } */}
              </Box>
              {accountDetailsLoading
                ? <Skeleton variant='text' sx={{ width: '50px', fontSize: '14px', mr: 1, display: 'inline-block' }} />
                : (
                  <Typography variant='small' lineHeight='1.25rem' fontSize='0.875rem' mr={1}> {accountDetails?.accountCode}{custodianAccountNumber || ''}{' -> '}{accountDetails?.portSpecName}</Typography>
                  )}
              <Link to={location.pathname.replace(params.accountId, '').replace('/' + params.RequestId, '')} style={{ color: '#3A76D4' }}>
                Switch Account
              </Link>
            </Box>
          </Box>
        </Grid>
        <Grid item>
          {children}
        </Grid>
      </Grid>
      <Modal
        onClose={() => setIsGroupPopupOpen(false)}
        open={isGroupPopupOpen}
      >
        <Box sx={{ ...modalStyle, outline: 'none', minWidth: '400px', maxWidth: '600px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '5px', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }}>
            <Typography variant='h6' sx={{ ml: '5px', fontWeight: 400, color: '#34475A' }}>Group</Typography>
            <Box sx={{ display: 'flex' }}>
              <SearchField field='aggGroupName' fieldLabel='group name' data={copyAccountGroup} filterCb={(data) => setAccountGroup(data)} />
              <IconButton onClick={() => setIsGroupPopupOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <DataGridPro
            autoHeight
            density='compact'
            rows={accountGroup}
            columns={accountGroupCols}
            getRowId={(row) => row?.id}
            pagination
            pageSizeOptions={[10]}
            initialState={{
              ...accountGroup?.initialState,
              pagination: { paginationModel: { pageSize: 10 } },
              columns: {
                columnVisibilityModel: {
                  aggGroupId: false
                }
              }
            }}
            sx={(theme) => ({
              '& .MuiDataGrid-cell:focus': {
                outline: 'none'
              },
              [`.${gridClasses.main}`]: {
                height: 'min(100vh - 16px - 45px - 16px - 53px, 600px - 16px - 45px - 16px - 40px - 53px)'
              },
              [`.${gridClasses.columnHeaders}`]: {
                position: 'sticky',
                backgroundColor: theme.palette.background.paper,
                top: 0,
                zIndex: 1
              },
              [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                color: '#74788d',
                fontWeight: 600
              },
              [`.${gridClasses.virtualScroller}`]: {
                overflowY: 'auto !important',
                scrollbarGutter: 'stable',
                scrollbarWidth: 'none'
              }
            })}
          />
        </Box>
      </Modal>
      {title === 'Dashboard' && <PolicyStatusPopup showPolicyPopup={showPolicyPopup} onClose={() => setShowPolicyPopup(false)} data={policyData} canDownloadJson={checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.ACCOUNT_DASHBOARD, component_name: moduleConfig.DOWNLOAD_ACCOUNT_POLICY_CHECK })} title='account' />}
    </>
  )
}

const SearchField = ({ fieldLabel, field, data, filterCb }) => {
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      filterCb(data.filter(obj => obj?.[field]?.toLowerCase().includes(searchText?.toLowerCase())))
    }, [300])
    return () => clearTimeout(timeoutId)
  }, [searchText])

  return (
    <TextField
      size='small'
      label={`Search by ${fieldLabel || field || ''}`}
      variant='outlined'
      type='text'
      value={searchText || ''}
      autoComplete='off'
      onChange={(event) => setSearchText(event.target.value)}
    />
  )
}

export default AccountTitle

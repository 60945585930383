import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { Add, Close, Remove } from '@mui/icons-material'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import { TabContext, TabPanel } from '@mui/lab'
import { Box, Button, Checkbox, Chip, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, Menu, MenuItem, Radio, RadioGroup, Skeleton, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Tooltip, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, gridClasses, gridFilteredDescendantCountLookupSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro'
import { AiOutlineTrademarkCircle } from 'react-icons/ai'
import redirect from '../../../assets/images/pop-out-line.svg'
import { useAuth } from '../../../contexts/AuthContext'
import { moduleConfig } from '../../../contexts/data'
import { ACCESS_LEVEL, MODEL_WEIGHT_DISPLAY_MODULES } from '../../../contstants/constants'
import useDownloadLogs from '../../../hooks/useDownloadLogs'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { formatCurrency } from '../../Report/components/dataProcess/DataProcess'
import LivePriceAlert from '../components/LivePriceAlert'
import StyledTradePopupBox from '../components/StyledTradePopupBox'
import TradeFeasibilityReportTab from './TradeFeasibilityReportTab'
import {
  CustomHeader,
  detailColumns,
  modelChangesHeaders,
  positionGroupTableHeader,
  tradeBlotterHeaders,
  tableHeaders
} from './TradeDetailsColumns'

const renderOptTableSkeleton = () => {
  return (
    <Box sx={{ my: 3 }}>
      <Skeleton variant='rectangle' sx={{ height: '30px', width: '50%', my: 2 }} />
      <Skeleton variant='rectangle' sx={{ height: '30px', width: '30%', my: 2 }} />
      <Skeleton variant='rectangle' sx={{ height: '30px', width: '40%', my: 2 }} />
      <Skeleton variant='rectangle' sx={{ height: '30px', width: '20%', my: 2 }} />
      <Skeleton variant='rectangle' sx={{ height: '30px', width: '40%', my: 2 }} />
    </Box>
  )
}

const renderTableSkeleton = (header) => {
  return (
    <TableContainer mt={5}>
      <Table className='table-responsive'>
        <TableHead>
          <TableRow sx={{ height: '39px' }}>
            {header.map((item, index) => (
              <TableCell key={index} sx={{ fontSize: '12px !important', whiteSpace: 'nowrap', paddingBlock: 0 }}>{item.headerName}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: 5 }).map((_, index) => (
            <TableRow key={index} sx={{ height: '30px' }}>
              {Array.from({ length: header.length }).map((_, index) => (
                <TableCell key={index} sx={{ paddingBlock: 0 }}>
                  <Skeleton variant='text' sx={{ fontSize: '0.8rem' }} />
                </TableCell>))}
            </TableRow>))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const ViewTradeDetails = ({ accID, index, setIsShown, accountCode, optId, showTitle = true }) => {
  const { user, checkAccess } = useAuth()
  const [value, setValue] = useState('Trade')
  const [optmRunSumPro, setOptmRunSPro] = useState()
  const [positionData, setPositionData] = useState()
  const [optPropAssetData, setOptPropAssetData] = useState()
  const [optMapId] = useState({ index })
  const [currentPositionValue, setCurrentPositionValue] = useState('company')
  const [loading, setLoading] = useState(true)
  const [positionDataLoading, setPositionDataLoading] = useState(true)
  const [otherPropertiesLoading, setOtherPropertiesLoading] = useState(true)
  const [optPropAssetDataLoading, setOptPropAssetDataLoading] = useState(true)
  const [sectorData, setSectorData] = useState()
  const [tradeBlotterData, setTradeBlotterData] = useState([])
  const [optData, setOptData] = useState([])
  const [tradeDataLoading, setTradeDataLoading] = useState(true)
  const [industryData, setIndustryData] = useState()
  const [subIndustryData, setSubIndustryData] = useState()
  const [companyWiseData, setCompanyWiseData] = useState()
  const { showError } = useErrorToast()
  const { createCSVDownloadLog } = useDownloadLogs()
  const tradeDetailsTab = ['Trade', 'Taxlot', 'Position', 'Blotter', 'Feasibility Report', 'Model Variance']
  const [securityLevelTradeData, setSecurityLevelTradeData] = useState()
  const [liveCash, setLiveCash] = useState(0)
  const [liveCashInPercent, setLiveCashInPercent] = useState(null)
  const [selectedTrdCode, setSelectedTrdCode] = useState({ buy: true, sell: true, cash: false, modelChange: false })
  const [exportCSVAnchorEl, setExportCSVAnchorEl] = useState(null)
  const [optFeasibilityData, setOptFeasibilityData] = useState(null)
  const [optFeasibilityLoading, setOptFeasibilityLoading] = useState(true)
  const [tabWiseLatestLivePriceTime, setTabWiseLatestLivePriceTime] = useState({
    'Blotter': null,
    'Taxlot': null
  })

  const canAccessModelChangeWithTrade = checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.SHOW_MODEL_CHANGE_WITH_TRADE })

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    if (user) {
      getoptimSummaryPro(optMapId)
      getPositionData(optMapId)
      getOptPropAssetDriftData(optMapId)
      getOptimizationPersonalization(optId)
      getTradeBlotterData()
      getOptFeasibilityReport(optMapId)
    }
  }, [user])

  useEffect(() => {
    setSecurityLevelTradeData(
      positionData
        ? positionData?.filter(
          row =>
            (
              selectedTrdCode['buy'] || selectedTrdCode['sell']
                ? selectedTrdCode[row?.trdCode?.toLowerCase()]
                : false
            )
            ||
            (selectedTrdCode['cash'] ? row.instr_id === '__CASH' : false)
            ||
            (selectedTrdCode['modelChange'] ? row?.isModelTrd : false)
        )
        : [])
  }, [selectedTrdCode])

  const getPositionData = (optMapId) => {
    const accMapId = optMapId.index

    API.post(
      'baseOptimizationURL',
      `optimization/v1/${user?.userGroup}/opt-prop-drift/${accMapId}`,
      { body: { accOptMapId: [accMapId] } }
    )
      .then((response) => {
        setPositionData(response?.data ? response?.data.map((row) => ({ ...row, id: randomId() })) : [])
        setSecurityLevelTradeData(response?.data ? response?.data?.filter(row => row.trdCode !== 'NOP').map((row) => ({ ...row, id: randomId() })) : [])
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load position data.')
      })
      .finally(() => {
        setPositionDataLoading(false)
      })
  }

  const getOptimizationPersonalization = (optId) => {
    if (checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.MODEL_VARIANCE })) {
      // const accMapId = optMapId.index
      API.get('baseOptimizationURL', `optimization/v1/${user?.userGroup}/personalization/${optId}?source=MODEL_VARIANCE`)
        .then((response) => {
          if (response && response?.data) {
            setOptData(response?.data && response?.data?.length ? response?.data[0]?.value?.map((row) => ({ ...row, id: randomId() })) : [])
            setOtherPropertiesLoading(false)
          }
        })
        .catch((error) => {
          setOtherPropertiesLoading(false)
          showError(error, false, {}, 'Failed to load personalization data.')
        })
    } else {
      setOtherPropertiesLoading(false)
    }
  }

  const getoptimSummaryPro = (optMapId) => {
    if (checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.TRADE_OPT_TRADE_DETAILS })) {
      const accMapId = optMapId.index
      API.post(
        'baseOptimizationURL',
        `optimization/v1/${user?.userGroup}/opt-prop-details`,
        { body: { accOptMapId: [accMapId] } }
      )
        .then((response) => {
          setOptmRunSPro(response?.data?.data ? response?.data?.data?.map((row) => ({ ...row, id: randomId() })) : [])
          const taxlotData = response?.data?.data?.slice() || []
          const latestLivePriceTs = taxlotData?.length ? taxlotData?.sort((a, b) => new Date(b.livePriceTs).getTime() - new Date(a.livePriceTs).getTime())[0]?.livePriceTs : null
          setTabWiseLatestLivePriceTime(prev => ({
            ...prev,
            'Taxlot': latestLivePriceTs ? latestLivePriceTs : null
          }))
        })
        .catch((error) => {
          showError(error, false, {}, 'Failed to load taxlot data.')
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const getOptPropAssetDriftData = (optMapId) => {
    const accMapId = optMapId.index

    API.post(
      'baseOptimizationURL',
      `optimization/v1/${user?.userGroup}/opt-prop-asset-drift/${accMapId}`,
      { body: { accOptMapId: [accMapId] } }
    )
      .then((response) => {
        setOptPropAssetData(response?.data ? response?.data.map((row) => ({ ...row, id: randomId() })) : [])
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load property asset drift data.')
      })
      .finally(() => {
        setOptPropAssetDataLoading(false)
      })
  }

  const getTradeBlotterData = () => {
    API.get('baseUriTrade', `trade/v1/${user?.userGroup}/trade-blotter/accounts/${optMapId?.index}`).then((res) => {
      setLiveCash(res?.data?.liveCashInPortfolio?.liveCashInPortfolio || null)
      setLiveCashInPercent(res?.data?.liveCashInPortfolio?.liveCashInPortfolioInPercentage || null)
      setTradeBlotterData(res?.data ? res?.data?.blotterData?.map((row) => ({ ...row, id: randomId() })) : [])
      setTradeDataLoading(false)
      const blotterData = res?.data?.blotterData?.slice() || []
      const latestLivePriceTs = blotterData?.length ? blotterData?.sort((a, b) => new Date(b.livePriceTs).getTime() - new Date(a.livePriceTs).getTime())[0]?.livePriceTs : null
      setTabWiseLatestLivePriceTime(prev => ({
        ...prev,
        'Blotter': latestLivePriceTs ? latestLivePriceTs : null
      }))
    }).catch((error) => {
      setTradeDataLoading(false)
      showError(error, false, {}, 'Failed to load trade blotter data.')
    })
  }

  const getOptFeasibilityReport = async (optMapId) => {
    if (checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.FEASIBILITY_REPORT })) {
      try {
        const response = await API.post('baseOptimizationURL', `optimization/v1/${user?.userGroup}/opt-feasibility-report/`, { body: { accOptMapId: [optMapId?.index] } })
        if (response && response?.data?.length) {
          setOptFeasibilityData(response?.data[0]?.report)
        }
        setOptFeasibilityLoading(false)
      } catch (error) {
        setOptFeasibilityLoading(false)
        showError(error, false, {}, 'Failed to load feasibility report.')
      }
    }
  }

  const getData = (tableName) => {
    const parentRow = {}
    optPropAssetData?.forEach(item => {
      const currItem = item[tableName]
      if (parentRow?.hasOwnProperty(currItem)) {
        parentRow[currItem].tradeDrift += (item.propDrift - item.initDrift)
        parentRow[currItem].modelWeight += item.modelWeight
        parentRow[currItem].initWeight += item.initWeight
        parentRow[currItem].propDrift += item.propDrift
        parentRow[currItem].initDrift += item.initDrift
        parentRow[currItem].propWeight += item.propWeight
        parentRow[currItem].tradeWeight += item.propWeight - item.initWeight
      } else {
        const hierarchy = [item[tableName]]
        const modelWeight = item.modelWeight
        const initWeight = item.initWeight
        const propDrift = item.propDrift
        const initDrift = item.initDrift
        const propWeight = item.propWeight
        const tradeWeight = item.propWeight - item.initWeight
        const tradeDrift = item.propDrift - item.initDrift
        parentRow[currItem] = { hierarchy, initWeight, propDrift, initDrift, propWeight, modelWeight, tradeDrift, tradeWeight }
      }
    })

    const childRow = {}
    optPropAssetData?.forEach(item => {
      if (childRow.hasOwnProperty(item[tableName])) {
        // if this field is available then add up all the values
        let isCurrentObjectAdded = 0
        if (tableName !== 'companyName') {
          childRow[item[tableName]].forEach(obj => {
            // but add up fields only if companyName matches
            // e.g: one sector can have one company multiple times
            if (obj.companyName === item.companyName) {
              obj.modelWeight += item.modelWeight
              obj.initWeight += item.initWeight
              obj.propWeight += item.propWeight
              obj.initDrift += item.initDrift
              obj.propDrift += item.propDrift
              obj.tradeDrift += (item.propDrift - item.initDrift)
              isCurrentObjectAdded = 1
            }
          })
        } else {
          // roll up same instrument data
          childRow[item[tableName]].forEach(obj => {
            if (obj.instrId === item.instrId) {
              obj.modelWeight += item.modelWeight
              obj.initWeight += item.initWeight
              obj.propWeight += item.propWeight
              obj.initDrift += item.initDrift
              obj.propDrift += item.propDrift
              obj.tradeWeight += item.propWeight - item.initWeight
              obj.tradeDrift += (item.propDrift - item.initDrift)
              isCurrentObjectAdded = 1
            }
          })
        }
        if (isCurrentObjectAdded === 0) {
          // if field matches but companyName doesn't match (flag != 1) then it's a new company
          // add that into item[tableName]
          childRow[item[tableName]].push({
            instrId: item.instrId,
            hierarchy: tableName === 'companyName' ? [item.companyName, item.instrId] : [item[tableName], item.companyName],
            companyName: item.companyName,
            modelWeight: item.modelWeight,
            initWeight: item.initWeight,
            propWeight: item.propWeight,
            initDrift: item.initDrift,
            propDrift: item.propDrift,
            tradeDrift: item.propDrift - item.initDrift,
            tradeWeight: item.propWeight - item.initWeight,
            ...(tableName === 'companyName' ? { instrRestr: item.instrRestr } : {})
          })
          if (tableName === 'companyName' && item.instrRestr) {
            parentRow[item[tableName]].doesChildHaveInstrRestr = true
          }
        }
      } else {
        // if this field is not available then add that into array of object
        const temp = {
          instrId: item.instrId,
          hierarchy: [item[tableName], tableName === 'companyName' ? item.instrId : item.companyName],
          companyName: item.companyName,
          modelWeight: item.modelWeight,
          initWeight: item.initWeight,
          propWeight: item.propWeight,
          initDrift: item.initDrift,
          propDrift: item.propDrift,
          tradeDrift: item.propDrift - item.initDrift,
          tradeWeight: item.propWeight - item.initWeight,
          ...(tableName === 'companyName' ? { instrRestr: item.instrRestr } : {})
        }
        if (tableName === 'companyName' && item.instrRestr) {
          parentRow[item[tableName]].doesInstrRestrExists = true
        }
        childRow[item[tableName]] = [temp]
      }
    })

    let finalArray = []
    for (const key in parentRow) {
      finalArray.push(parentRow[key])
      finalArray = finalArray.concat(childRow[key])
    }

    finalArray = finalArray.map(obj => {
      return {
        ...obj,
        id: randomId(),
        modelWeight: obj.modelWeight?.toFixed(7),
        initWeight: obj.initWeight?.toFixed(7),
        tradeDrift: obj.tradeDrift?.toFixed(7),
        initDrift: obj.initDrift.toFixed(7),
        propDrift: obj.propDrift.toFixed(7),
        propWeight: obj.propWeight.toFixed(7),
        tradeWeight: obj.tradeWeight.toFixed(7)
      }
    })
    return finalArray
  }

  useEffect(() => {
    setSectorData(getData('sector'))
    setIndustryData(getData('industry'))
    setSubIndustryData(getData('subIndustry'))
    setCompanyWiseData(getData('companyName'))
  }, [optPropAssetData])

  const redirectToPersonalization = () => {
    localStorage.setItem('selectedPersonalizationTab', 'Security')
    window.open(`/account-review/personalization/${accID}`)
  }

  const positionSecurityTableHeader = [
    {
      field: 'instr_id',
      headerName: 'Instrument Id',
      flex: 1,
      renderHeader: (params) => <CustomHeader title='Instrument Id' />,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {params?.row?.instr_id}
          {
            params?.row?.instrRestr
              ? (
                <>
                  <Chip label={params?.row?.instrRestr} size='small' sx={{ background: '#50CAF1', color: 'white', fontSize: '10px', fontWeight: '500', ml: 1, height: '16px' }} />
                  <IconButton
                    onClick={redirectToPersonalization}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <img src={redirect} height={18} width={18} alt='redirect' />
                  </IconButton>
                </>
              )
              : ''
          }
        </Box>
      )
    },
    {
      field: 'trdCode',
      headerName: 'Trade Code',
      flex: 1,
      renderHeader: (params) => <CustomHeader title='Trade Code' />,
      renderCell: (params) => (<>
        <span style={{ color: params.value === 'SELL' ? '#FF6161' : params.value === 'BUY' ? '#3BBFA3' : '#34475A' }}>{params.value}</span>
      </>)
    },
    {
      field: 'modelWeight',
      headerName: 'Model Weight (%)',
      type: 'number',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => (params.value * 100).toFixed(2),
      renderCell: (params) => (params?.row?.modelWeight * 100).toFixed(2),
      renderHeader: (params) => <CustomHeader title='Model Weight (%)' text='M.W' />
    },
    {
      field: 'priceByMktVal',
      headerName: 'Price By Market Value (%)',
      type: 'number',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => (params.value * 100).toFixed(2),
      renderHeader: (params) => <CustomHeader title='Price By Market Value (%)' />,
      renderCell: (params) => (params?.row?.priceByMktVal * 100).toFixed(2)
    },
    {
      field: 'initWeight',
      headerName: 'Initial Weight (%)',
      type: 'number',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => (params.value * 100).toFixed(2),
      renderCell: (params) => (params?.row?.initWeight * 100).toFixed(2),
      renderHeader: (params) => {
        return <CustomHeader title='Initial Weight (%)' text='I.W' />
      }
    },
    {
      field: 'propWeight',
      headerName: 'Proposed Weight (%)',
      type: 'number',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => (params.value * 100).toFixed(2),
      renderCell: (params) => parseFloat(params?.row?.propWeight * 100).toFixed(2),
      renderHeader: (params) => <CustomHeader title='Proposed Weight (%)' text='P.W' />
    },
    {
      field: 'trdWeight',
      type: 'number',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => params?.row?.trdWeight !== undefined && params?.row?.trdWeight !== null ? parseFloat((params.row?.trdWeight * 100).toFixed(2)) : null,
      renderHeader: (params) => <CustomHeader title='Trade Weight (%)' text='T.W = P.W - I.W' />,
      renderCell: (params) => (
        <>
          {
            params?.value < 0
              ? <ArrowCircleDownIcon fontSize='small' sx={{ color: '#FF6161' }} />
              : params?.value > 0
                ? <ArrowCircleUpIcon fontSize='small' sx={{ color: '#3BBFA3' }} />
                : ''
          }
          {
            params?.value !== null
              ? <Typography fontSize='14px' fontWeight='600' color={params?.value < 0 ? '#FF6161' : (params?.value > 0) ? '#3BBFA3' : '#34475A'}>{params?.value?.toFixed(2)}</Typography>
              : ''
          }
        </>
      )
    },
    {
      field: 'propDrift',
      headerName: 'Proposed Drift (%)',
      type: 'number',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => (params.value * 100).toFixed(2),
      renderHeader: (params) => <CustomHeader title='Proposed Drift (%)' text='P.D = M.W - P.W' />,
      renderCell: (params) => parseFloat(params.row.propDrift * 100).toFixed(2)
    },
    {
      field: 'initDrift',
      headerName: 'Initial Drift (%)',
      type: 'number',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => (params.value * 100).toFixed(2),
      renderCell: (params) => (params?.row?.initDrift * 100).toFixed(2),
      renderHeader: (params) => <CustomHeader title='Initial Drift (%)' text='I.D = M.W - I.W' />
    },
    {
      field: 'tradeDrift',
      headerName: 'Trade Drift (%)',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      valueGetter: (params) => parseFloat((Math.abs(params?.row?.propDrift) - Math.abs(params?.row?.initDrift)) * 100)?.toFixed(2),
      renderHeader: (params) => <CustomHeader title='Trade Drift (%)' text='T.D = abs(P.D) - abs(I.D)' />,
      renderCell: (params) => {
        const absDiff = parseFloat(((Math.abs(params?.row?.propDrift) - Math.abs(params?.row?.initDrift)) * 100)?.toFixed(2))
        return (<>{absDiff < 0 ? <ArrowCircleDownIcon fontSize='small' sx={{ color: '#3BBFA3' }} /> : (absDiff > 0) ? <ArrowCircleUpIcon fontSize='small' sx={{ color: '#FF6161' }} /> : ''}<Typography fontSize='14px' fontWeight='600' color={absDiff < 0 ? '#3BBFA3' : (absDiff > 0) ? '#FF6161' : '#34475A'}>{absDiff?.toFixed(2)}</Typography></>)
      }
    }
  ]

  const companyColumns = [
    {
      field: 'companyName',
      headerName: 'Company',
      flex: 1,
      valueGetter: (params) => {
        const hierarchy = params?.row?.hierarchy
        return hierarchy ? hierarchy[hierarchy.length - 1] : null
      },
      renderCell: (params) => {
        return (<>
          {params?.value}
          {
            params?.row?.doesInstrRestrExists ? (
              <Box sx={{ height: '100%', paddingTop: '9px' }}>
                <AiOutlineTrademarkCircle color='#1976d2' style={{ marginLeft: '4px', height: '12px', width: '12px' }} />
              </Box>
            ) : ''
          }
          {
            params?.row?.instrRestr ? (
              <>
                <Chip label={params?.row?.instrRestr} size='small' sx={{ background: '#50CAF1', color: 'white', fontSize: '10px', fontWeight: '500', ml: 1, height: '16px' }} />
                <IconButton
                  onClick={redirectToPersonalization}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <img src={redirect} height={18} width={18} alt='redirect' />
                </IconButton>
              </>
            ) : ''
          }
        </>
        )
      }
    },
    {
      field: 'modelWeight',
      headerName: 'Model Weight (%)',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      valueGetter: (params) => (params.value * 100).toFixed(2),
      renderHeader: (params) => <CustomHeader title='Model Weight (%)' text='' />,
      renderCell: (params) => parseFloat(params?.row?.modelWeight * 100).toFixed(2)
    },
    {
      field: 'initWeight',
      headerName: 'Initial Weight (%)',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      valueGetter: (params) => (params.value * 100).toFixed(2),
      renderCell: (params) => parseFloat(params?.row?.initWeight * 100).toFixed(2),
      renderHeader: (params) => <CustomHeader title='Initial Weight (%)' text='I.W' />
    },
    {
      field: 'propWeight',
      headerName: 'Proposed Weight (%)',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      valueGetter: (params) => (params.value * 100).toFixed(2),
      renderCell: (params) => (params?.row?.propWeight * 100).toFixed(2),
      renderHeader: (params) => <CustomHeader title='Proposed Weight (%)' text='P.W' />
    },
    {
      field: 'tradeWeight',
      headerName: 'Trade Weight (%)',
      type: 'number',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => {
        const propWeight = params?.row?.propWeight || null
        const initWeight = params?.row?.initWeight || null
        return parseFloat(((propWeight - initWeight) * 100)?.toFixed(2)) ?? null
      },
      renderHeader: (params) => <CustomHeader title='Trade Weight (%)' text='T.W = P.W - I.W' />,
      renderCell: (params) => (
        <>
          {
            params?.value < 0
              ? <ArrowCircleDownIcon fontSize='small' sx={{ color: '#FF6161' }} />
              : params?.value > 0
                ? <ArrowCircleUpIcon fontSize='small' sx={{ color: '#3BBFA3' }} />
                : ''
          }
          {
            params?.value !== null
              ? <Typography fontSize='14px' fontWeight='600' color={params?.value < 0 ? '#FF6161' : (params?.value > 0) ? '#3BBFA3' : '#34475A'}>{params?.value?.toFixed(2)}</Typography>
              : ''
          }
        </>
      )
    },
    {
      field: 'propDrift',
      headerName: 'Proposed Drift (%)',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      valueGetter: (params) => (params.value * 100).toFixed(2),
      renderCell: (params) => (params?.row?.propDrift * 100).toFixed(2),
      renderHeader: (params) => <CustomHeader title='Proposed Drift (%)' text='P.D = M.W - P.W' />
    },
    {
      field: 'initDrift',
      headerName: 'Initial Drift (%)',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      valueGetter: (params) => (params.value * 100).toFixed(2),
      renderCell: (params) => parseFloat(params?.row?.initDrift * 100).toFixed(2),
      renderHeader: (params) => <CustomHeader title='Initial Drift (%)' text='I.D = M.W - I.W' />
    },
    {
      field: 'tradeDrift',
      headerName: 'Trade Drift (%)',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      valueGetter: (params) => parseFloat((Math.abs(params?.row?.propDrift) - Math.abs(params?.row?.initDrift)) * 100)?.toFixed(2),
      renderHeader: (params) => <CustomHeader title='Trade Drift (%)' text='T.D = abs(P.D) - abs(I.D)' />,
      renderCell: (params) => {
        const absDiff = parseFloat(((Math.abs(params?.row?.propDrift) - Math.abs(params?.row?.initDrift)) * 100)?.toFixed(2))
        return (<>{absDiff < 0 ? <ArrowCircleDownIcon fontSize='small' sx={{ color: '#3BBFA3' }} /> : (absDiff > 0) ? <ArrowCircleUpIcon fontSize='small' sx={{ color: '#FF6161' }} /> : ''}<Typography fontSize='14px' fontWeight='600' color={absDiff < 0 ? '#3BBFA3' : (absDiff > 0) ? '#FF6161' : '#34475A'}>{absDiff?.toFixed(2)}</Typography></>)
      }
    }
  ]

  // security level trade table
  const tradeTableHeaders = [
    {
      field: 'instr_id',
      headerName: 'Instrument Id',
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.instr_id}
          {
            params?.row?.instrRestr
              ? (
                <>
                  <Chip label={params?.row?.instrRestr} size='small' sx={{ background: '#50CAF1', color: 'white', fontSize: '10px', fontWeight: '500', ml: 1, height: '16px' }} />
                  <IconButton
                    onClick={redirectToPersonalization}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <img src={redirect} height={18} width={18} alt='redirect' />
                  </IconButton>
                </>
                )
              : ''
          }
        </>
      )
    },
    ...(canAccessModelChangeWithTrade ?
      [{
        field: 'localSymbol',
        headerName: 'Local Symbol',
        flex: 1
      }] : []),
    {
      field: 'trdCode',
      headerName: 'Trade Code',
      renderCell: (params) => (<>
        <span style={{ color: params.value === 'SELL' ? '#FF6161' : params.value === 'BUY' ? '#3BBFA3' : '#34475A' }}>{params.value}</span>
      </>),
      flex: 1
    },
    ...(canAccessModelChangeWithTrade ?
      [{
        field: 'modelDrift',
        headerName: 'Model Trade',
        type: 'number',
        flex: 1,
        valueGetter: (params) => params?.row?.modelDrift !== undefined && params?.row?.modelDrift !== null
          ? parseFloat((params?.row?.modelDrift * 100).toFixed(2))
          : null,
        renderCell: (params) => params?.value !== null
          ? <>
            {
              params?.value < 0
                ? <ArrowCircleDownIcon sx={{ color: '#FF6161', height: '16px', width: '16px' }} />
                : params?.value > 0
                  ? <ArrowCircleUpIcon sx={{ color: '#3BBFA3', height: '16px', width: '16px' }} />
                  : ''
            }
            {
              params?.value !== null
                ? <>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '600',
                      color: params?.value < 0 ? '#FF6161' : (params?.value > 0) ? '#3BBFA3' : '#34475A',
                      ml: '3px'
                    }}
                  >

                    {params?.value?.toFixed(2)}
                  </Typography>
                  {
                    params?.row?.modelCurWeight !== undefined && params?.row?.modelCurWeight !== null
                      ? <span style={{ marginLeft: '3px' }}>({parseFloat((params?.row?.modelCurWeight * 100)).toFixed(2)})</span>
                      : ''
                  }
                </>
                : ''
            }
          </>
          : ''
      },
      {
        field: 'tradeWeight',
        headerName: 'Account Trade',
        type: 'number',
        flex: 1,
        align: 'right',
        headerAlign: 'right',
        valueGetter: (params) => {
          const propWeight = params?.row?.propWeight || null
          const initWeight = params?.row?.initWeight || null
          return parseFloat(((propWeight - initWeight) * 100)?.toFixed(2)) ?? null
        },
        renderCell: (params) => (
          <>
            {
              params?.value < 0
                ? <ArrowCircleDownIcon sx={{ color: '#FF6161', height: '16px', width: '16px' }} />
                : params?.value > 0
                  ? <ArrowCircleUpIcon sx={{ color: '#3BBFA3', height: '16px', width: '16px' }} />
                  : ''
            }
            {
              params?.value !== null
                ? <>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '600',
                      color: params?.value < 0 ? '#FF6161' : (params?.value > 0) ? '#3BBFA3' : '#34475A',
                      ml: '3px'
                    }}
                  >
                    {params?.value?.toFixed(2)}
                  </Typography>
                  {
                    params?.row?.propWeight !== undefined && params?.row?.propWeight !== null
                      ? <span style={{ marginLeft: '3px' }}>({parseFloat((params?.row?.propWeight * 100)).toFixed(2)})</span>
                      : ''
                  }
                </>
                : ''
            }
          </>
        )
      }] : []),
    { field: 'initShares', headerName: 'Initial Shares', type: 'number', valueFormatter: (params) => params?.value?.toFixed(2), align: 'right', headerAlign: 'right', flex: 1 },
    { field: 'propShares', headerName: 'Proposed Shares', type: 'number', valueFormatter: (params) => params?.value?.toFixed(2), align: 'right', headerAlign: 'right', flex: 1 },
    ...(canAccessModelChangeWithTrade ? [{
      field: 'priceByMktVal',
      headerName: 'Price By Market Value',
      headerAlign: 'right',
      type: 'number',
      flex: 1,
      valueGetter: (params) => (params.value * 100).toFixed(2),
      renderCell: (params) => (params?.row?.priceByMktVal * 100).toFixed(2)
    },
    {
      field: 'livePriceRgl',
      headerName: 'Live Price RGL',
      headerAlign: 'right',
      type: 'number',
      flex: 1,
      valueGetter: (params) => params?.row?.livePriceRgl !== undefined && params?.row?.livePriceRgl !== null
        ? params?.row?.trdCode !== 'BUY' ? Number((params?.row?.livePriceRgl).toFixed(2)) : null
        : null,
      renderCell: (params) => params?.value !== null
        ? params?.row?.livePriceRgl < 0
          ? params?.row?.livePriceRgl?.toFixed(2) === '-0.00'
            ? '$0.00'
            : '-$' + formatCurrency(Math.abs(params?.row?.livePriceRgl), 2)
          : '$' + formatCurrency(params?.row?.livePriceRgl, 2)
        : ''
    },
    {
      field: 'tradeValue',
      headerName: 'Trade Value',
      headerAlign: 'right',
      type: 'number',
      flex: 1,
      valueGetter: (params) => params?.row?.tradeValue !== undefined && params?.row?.tradeValue !== null
        ? Number((params?.row?.tradeValue).toFixed(2))
        : null,
      renderCell: (params) => params?.value !== null
        ? params?.row?.tradeValue < 0
          ? params?.row?.tradeValue?.toFixed(2) === '-0.00'
            ? '$0.00'
            : '-$' + formatCurrency(Math.abs(params?.row?.tradeValue), 2)
          : '$' + formatCurrency(params?.row?.tradeValue, 2)
        : ''
    }] : []),
    {
      field: 'trdQty',
      headerAlign: 'right',
      headerName: 'Trade Quantity',
      type: 'number',
      valueFormatter: (params) => params?.value?.toFixed(2),
      align: 'right',
      flex: 1
    },
  ]

  const getRowId = (row) => row.id

  const handlePositionChange = (e) => {
    setCurrentPositionValue(e.target.value)
  }

  const getDetailPanelContent = (params) => {
    const detailRows = params.row.propDetail.map((details) => ({ ...details, id: randomId() }))

    return (
      <Box sx={{ width: '100%' }}>
        <DataGridPro
          density='compact'
          autoHeight
          rows={detailRows}
          columns={detailColumns}
          getRowId={(row) => row.id}
          hideFooter
          disableSelectionOnClick
          sx={() => ({
            [`.${gridClasses.main}`]: {
              height: 'auto !important'
            },
            [`.${gridClasses.columnHeaders}`]: {
              minHeight: 'auto !important'
            },
            [`.${gridClasses.cell}, .${gridClasses.cellContent}, .${gridClasses.columnHeaderTitle}`]: {
              fontSize: '12px'
            }
          })}
        />
      </Box>
    )
  }

  const renderDataGridPro = (rows, column, header, detailPanel = 0, getDetailPanelContent) => {
    const hiddenColumns = column.filter(column => column.defaultHidden)
    const columnVisibilityModel = {}
    if (hiddenColumns?.length) {
      hiddenColumns?.forEach(columnObj => {
        columnVisibilityModel[columnObj?.field || ''] = false
      })
    }
    return (
      <DataGridPro
        autoHeight
        rows={rows || []}
        columns={column}
        getRowId={getRowId}
        rowHeight={30}
        columnHeaderHeight={39}
        disableSelectionOnClick
        disableRowSelectionOnClick
        getDetailPanelHeight={detailPanel ? (row) => 'auto' : null}
        getDetailPanelContent={detailPanel ? getDetailPanelContent : null}
        pagination
        pageSizeOptions={[15, 25, 50, 75, 100]}
        initialState={{
          ...rows?.initialState,
          pagination: { paginationModel: { pageSize: 15 } },
          columns: {
            columnVisibilityModel: Object.keys(columnVisibilityModel)?.length ? columnVisibilityModel : {}
          }
        }}
        sx={(theme) => ({
          [`.${gridClasses.main}`]: {
            height: 'calc(100vh - 8px - 8px - 40px - 32px - 53px)'
          },
          [`.${gridClasses.cell}, .${gridClasses.cellContent}, .${gridClasses.columnHeaderTitle}`]: {
            fontSize: '12px'
          },
          [`.${gridClasses.columnHeaders}`]: {
            fontSize: '12px',
            position: 'sticky',
            backgroundColor: theme.palette.background.paper,
            zIndex: 1,
            top: 0,
            minHeight: (header === 'Blotter') ? '60px !important' : 'unset !important' // Set minHeight conditionally
          },
          [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
            color: '#74788d',
            fontWeight: 600
          },
          [`.${gridClasses.virtualScroller}`]: {
            overflowY: 'auto !important',
            scrollbarGutter: 'stable',
            scrollbarWidth: 'none',
            marginTop: 'auto !important'
          }
        })}
      />
    )
  }

  const renderDataPanel = () => {
    switch (currentPositionValue) {
      case 'security':
        return (
          <>
            {positionDataLoading
              ? renderTableSkeleton(positionSecurityTableHeader)
              : <DataGridPro
                density='compact'
                autoHeight
                rows={positionData || []}
                columns={positionSecurityTableHeader}
                getTreeDataPath={(row) => ([getRowId(row)])}
                getRowId={getRowId}
                disableSelectionOnClick
                disableRowSelectionOnClick
                rowHeight={30}
                getDetailPanelHeight={({ row }) => 'auto'}
                getDetailPanelContent={getDetailPanelContent}
                pagination
                pageSizeOptions={[15, 25, 50, 75, 100]}
                initialState={{
                  ...positionData?.initialState,
                  pagination: { paginationModel: { pageSize: 15 } }
                }}
                sx={(theme) => ({
                  [`.${gridClasses.main}`]: {
                    height: 'calc(100vh - 8px - 8px - 40px - 32px - 53px)'
                  },
                  [`.${gridClasses.cell}, .${gridClasses.cellContent}, .${gridClasses.columnHeaderTitle}`]: {
                    fontSize: '12px'
                  },
                  [`.${gridClasses.columnHeaders}`]: {
                    fontSize: '12px',
                    position: 'sticky',
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 1,
                    top: 0,
                    minHeight: '60px !important'
                  },
                  [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                    color: '#74788d',
                    fontWeight: 600
                  },
                  [`.${gridClasses.virtualScroller}`]: {
                    overflowY: 'auto !important',
                    scrollbarGutter: 'stable',
                    scrollbarWidth: 'none',
                    marginTop: 'auto !important'
                  }
                })}
              />}
          </>
        )
      case 'sector':
        let headerSector = [
          {
            field: 'sector',
            headerName: 'Sector',
            flex: 1,
            valueGetter: (params) => {
              const hierarchy = params?.row?.hierarchy
              return hierarchy ? hierarchy[hierarchy.length - 1] : null
            }
          }
        ]
        headerSector = headerSector.concat(positionGroupTableHeader)
        return (
          <PositionDataGrid rows={sectorData} columns={headerSector} loading={optPropAssetDataLoading} />
        )
      case 'industry':
        let headerIndustry = [
          {
            field: 'industry',
            headerName: 'Industry',
            flex: 1,
            valueGetter: (params) => {
              const hierarchy = params?.row?.hierarchy
              return hierarchy ? hierarchy[hierarchy.length - 1] : null
            }
          }
        ]
        headerIndustry = headerIndustry.concat(positionGroupTableHeader)
        return (
          <PositionDataGrid rows={industryData} columns={headerIndustry} loading={optPropAssetDataLoading} />
        )
      case 'sub-industry':
        let headerSubIndustry = [
          {
            field: 'subIndustry',
            headerName: 'Sub-Industry',
            flex: 1,
            valueGetter: (params) => {
              const hierarchy = params?.row?.hierarchy
              return hierarchy ? hierarchy[hierarchy.length - 1] : null
            }
          }
        ]
        headerSubIndustry = headerSubIndustry.concat(positionGroupTableHeader)
        return (
          <PositionDataGrid rows={subIndustryData} columns={headerSubIndustry} loading={optPropAssetDataLoading} />
        )
      case 'company':
        return (
          <PositionDataGrid rows={companyWiseData} columns={companyColumns} loading={optPropAssetDataLoading} />
        )
      default:
        return ''
    }
  }

  const renderTabPanel = (item) => {
    switch (item) {
      case 'Taxlot':
        return (
          <>
            {!loading
              ? renderDataGridPro(optmRunSumPro, tableHeaders)
              : renderTableSkeleton(tableHeaders)}
          </>
        )
      case 'Position':
        return (
          renderDataPanel()
        )
      case 'Trade':
        return (
          <>
            {!positionDataLoading
              ? renderDataGridPro(securityLevelTradeData, tradeTableHeaders)
              : renderTableSkeleton(tradeTableHeaders)}
          </>
        )
      case 'Blotter':
        return (
          <>
            {
              !tradeDataLoading ? renderDataGridPro(tradeBlotterData, tradeBlotterHeaders, 'Blotter') : renderTableSkeleton(tradeBlotterHeaders)
            }
          </>
        )
      case 'Feasibility Report':
        return (
          <Box sx={{ height: 'calc(100vh - 8px - 8px - 40px - 32px)', overflowY: 'auto' }}>
            {optFeasibilityLoading
              ? renderOptTableSkeleton()
              : <TradeFeasibilityReportTab optFeasibilityReport={optFeasibilityData || {}} />}
          </Box>
        )
      case 'Model Variance':
        return (
          <>
            {!otherPropertiesLoading ? renderDataGridPro(optData, modelChangesHeaders) : renderTableSkeleton(modelChangesHeaders)}
          </>
        )
      default:
        return 'Something went wrong'
    }
  }

  const downloadCsv = (summaryOnly) => {
    let fileName = ''
    let dataCsv = null

    if (value === 'Trade') {
      const headers = ['Instrument ID', ...(canAccessModelChangeWithTrade ? ['Local Symbol'] : []), 'Trade Code',
        ...(canAccessModelChangeWithTrade ? ['Model Drift', 'Trade Drift'] : []),
        'Initial Shares', 'Proposed Shares', ...(canAccessModelChangeWithTrade ? ['Price By Market Value', 'Live Price RGL', 'Trade Value'] : []), 'Trade Quantity']
      dataCsv = [headers.join(',')]

      for (let i = 0; i < securityLevelTradeData?.length; i++) {
        const { instr_id, localSymbol, trdCode, initShares, propShares, trdQty, 
          modelDrift, tradeDrift, priceByMktVal, livePriceRgl, tradeValue } = securityLevelTradeData[i]
        dataCsv.push([
          instr_id || '', ...(canAccessModelChangeWithTrade ? [localSymbol || ''] : []), trdCode || '',
          ...(canAccessModelChangeWithTrade ? [modelDrift, tradeDrift] : []), initShares,
          propShares, ...(canAccessModelChangeWithTrade ? [priceByMktVal, livePriceRgl, tradeValue] : []), trdQty
        ].join(','))
      }
      fileName = 'trade'
    } else if (value === 'Taxlot') {
      const headers = ['Instrument ID', 'Local Symbol', 'Trade Reason', 'Trade Code',
        'Trade Quantity', 'Trade Value', 'Initial Shares', 'Proposed Shares', 'Initial Weight',
        'Proposed Weight', 'Initial Market Value', 'Proposed Market Value',
        'Age', 'Cost Basis', 'Purchase Date', 'Original Purchase Price',
        'Last Closed Price', 'Initial Unrealized Gain', 'Live Price',
        'Realized Gain', 'Trade Date']

      dataCsv = [headers.join(',')]

      for (let i = 0; i < optmRunSumPro?.length; i++) {
        const {
          instrId, localSymbol, tradeTags, trdCode, trdQty, trdValue, initShares,
          propShares, initWeight, propWeight, initMarketValue, propMarketValue,
          age, costBasis, purchaseDate, orgPurchasePrice, liveMarketPrice,
          lastClosePrice, rglLive, initUrgl, rgl, trdDate
        } = optmRunSumPro[i]

        const tradeTag = JSON.stringify(tradeTags?.map(tag => tag.reasonCd).join(', ')) || ''

        dataCsv.push([
          instrId || '', localSymbol || '', tradeTag, trdCode || '', trdQty || '', trdValue || '',
          initShares || '', propShares || '', initWeight || '', propWeight || '',
          initMarketValue || '', propMarketValue || '', age || '',
          costBasis || '', purchaseDate ? purchaseDate.split('T')[0] : '',
          orgPurchasePrice || '',
          lastClosePrice || '', initUrgl || '', liveMarketPrice || '',
          rgl || '', rglLive || '', trdDate ? trdDate.split('T')[0] : ''
        ].join(','))
      }

      fileName = 'taxlot'
    } else if (value === 'Position') {
      if (currentPositionValue === 'security') {
        fileName = currentPositionValue
        const headers = ['Instrument ID', 'Trade Code', 'Model Weight', 'Price By Market Value', 'Initial Weight', 'Proposed Weight', 'Trade Weight', 'Proposed Drift', 'Initial Drift', 'Trade Drift']
        dataCsv = [headers.join(',')]

        for (let i = 0; i < positionData?.length; i++) {
          const { instr_id, trdCode, initDrift, initWeight, modelWeight, priceByMktVal, propDrift, propWeight, tradeDrift, trdWeight } = positionData[i]
          dataCsv.push([instr_id || '', trdCode || '', modelWeight, priceByMktVal, initWeight, propWeight, trdWeight, propDrift, initDrift, tradeDrift].join(','))
        }
        createCSVDownloadLog(MODEL_WEIGHT_DISPLAY_MODULES.TRADE_SECURITY_POSITION, optMapId?.index, `${accountCode}-${fileName?.toUpperCase()}.csv`)
      } else {
        fileName = summaryOnly ? currentPositionValue + '-summary' : currentPositionValue
        let headers = []
        if (summaryOnly) {
          headers = ['', 'Model Weight', 'Initial Weight', 'Proposed Weight', 'Proposed Drift', 'Initial Drift', 'Trade Drift']
        } else {
          headers = ['', 'Company Name', 'Model Weight', 'Initial Weight', 'Proposed Weight', 'Proposed Drift', 'Initial Drift', 'Trade Drift']
        }

        if (currentPositionValue === 'sector') {
          headers[0] = 'Sector'
          dataCsv = [headers.join(',')]

          for (let i = 0; i < sectorData?.length; i++) {
            const { hierarchy, modelWeight, initWeight, initDrift, propWeight, propDrift, tradeDrift } = sectorData[i]
            if (summaryOnly && hierarchy.length === 1) { dataCsv.push([hierarchy[0].replace(',', ''), modelWeight, initWeight, propWeight, propDrift, initDrift, tradeDrift]) } else if (!summaryOnly) { dataCsv.push([hierarchy[0].replace(',', ''), hierarchy[hierarchy.length - 1], modelWeight, initWeight, propWeight, propDrift, initDrift, tradeDrift]) }
          }
          createCSVDownloadLog(MODEL_WEIGHT_DISPLAY_MODULES.TRADE_SECTOR_POSITION, optMapId?.index, `${accountCode}-${fileName?.toUpperCase()}.csv`)
        } else if (currentPositionValue === 'industry') {
          headers[0] = 'Industry'
          dataCsv = [headers.join(',')]

          for (let i = 0; i < industryData.length; i++) {
            const { hierarchy, modelWeight, initWeight, initDrift, propWeight, propDrift, tradeDrift } = industryData[i]
            if (summaryOnly && hierarchy.length === 1) { dataCsv.push([hierarchy[0].replace(',', ''), modelWeight, initWeight, propWeight, propDrift, initDrift, tradeDrift]) } else if (!summaryOnly) { dataCsv.push([hierarchy[0].replace(',', ''), hierarchy[hierarchy.length - 1].replace(',', ''), modelWeight, initWeight, propWeight, propDrift, initDrift, tradeDrift]) }
          }
          createCSVDownloadLog(MODEL_WEIGHT_DISPLAY_MODULES.TRADE_INDUSTRY_POSITION, optMapId?.index, `${accountCode}-${fileName?.toUpperCase()}.csv`)
        } else if (currentPositionValue === 'sub-industry') {
          headers[0] = 'Sub Industry'
          dataCsv = [headers.join(',')]

          for (let i = 0; i < subIndustryData.length; i++) {
            const { hierarchy, modelWeight, initWeight, initDrift, propWeight, propDrift, tradeDrift } = subIndustryData[i]
            if (summaryOnly && hierarchy.length === 1) { dataCsv.push([hierarchy[0], modelWeight, initWeight, propWeight, propDrift, initDrift, tradeDrift]) } else if (!summaryOnly) { dataCsv.push([hierarchy[0], hierarchy[hierarchy.length - 1] || '', modelWeight, initWeight, propWeight, propDrift, initDrift, tradeDrift]) }
          }
          createCSVDownloadLog(MODEL_WEIGHT_DISPLAY_MODULES.TRADE_SUBINDUSTRY_POSITION, optMapId?.index, `${accountCode}-${fileName?.toUpperCase()}.csv`)
        } else if (currentPositionValue === 'company') {
          headers = ['Company Name', ...(summaryOnly ? [] : ['Instrument']), 'Model Weight', 'Initial Weight', 'Proposed Weight', 'Trade Weight', 'Proposed Drift', 'Initial Drift', 'Trade Drift']
          dataCsv = [headers.join(',')]
          for (let i = 0; i < companyWiseData.length; i++) {
            const { hierarchy, modelWeight, initWeight, initDrift, propWeight, propDrift, tradeDrift, tradeWeight } = companyWiseData[i]
            if (summaryOnly && hierarchy.length === 1) {
              dataCsv.push([hierarchy[0], modelWeight, initWeight, propWeight, tradeWeight, propDrift, initDrift, tradeDrift])
            } else if (!summaryOnly) {
              dataCsv.push([hierarchy[0], hierarchy[hierarchy.length - 1], modelWeight, initWeight, propWeight, tradeWeight, propDrift, initDrift, tradeDrift])
            }
          }
          createCSVDownloadLog(MODEL_WEIGHT_DISPLAY_MODULES.TRADE_COMPANY_POSITION, optMapId?.index, `${accountCode}-${fileName?.toUpperCase()}.csv`)
        }
      }
    } else if (value === 'Blotter') {
      const headers = ['Instrument Id', 'Local Symbol', 'Last Close Price', 'Live Price', 'Market Value',
        'Live Market Value', 'Proposed Market Value', 'Price Change (%)', 'Trade Value Change', 'Side', 'Quantity', 'Split Effective Date', 'Split Ratio', 'Delist Effective Date']
      dataCsv = [headers.join(',')]

      for (let i = 0; i < tradeBlotterData?.length; i++) {
        const {
          instrId, localSymbol, lastClosePrice, livePrice, marketValue, liveMarketValue, priceChange, marketValueChange, side,
          quantity, splitEffectiveDate, splitRatio, delistEffectiveDate, propMarketValue
        } = tradeBlotterData[i]

        // Format the priceChange and marketValueChange values as percentages with two decimal places
        const formattedPriceChange = (priceChange * 100).toFixed(2)
        const formattedMarketValueChange = (marketValueChange * 100).toFixed(2)

        dataCsv.push([instrId, localSymbol || '', lastClosePrice, livePrice, marketValue,
          liveMarketValue, propMarketValue, formattedPriceChange, formattedMarketValueChange, side, quantity, splitEffectiveDate || '', splitRatio || '', delistEffectiveDate || ''].join(','))
      }
      fileName = 'blotter'
    } else if (value === 'Model Variance') {
      const headers = ['Instrument Id', 'Trade Code', 'Previous Weight', 'Current Weight', 'Weight Change']
      dataCsv = [headers.join(',')]

      for (let i = 0; i < optData?.length; i++) {
        const {
          instrId, trdCode, previousSecurityWeight, currentSecurityWeight, drift
        } = optData[i]

        dataCsv.push([instrId || '', trdCode || '', previousSecurityWeight || '', currentSecurityWeight || '', drift || ''].join(','))
      }
      fileName = 'model-variance'
      createCSVDownloadLog(MODEL_WEIGHT_DISPLAY_MODULES.TRADE_MODEL_VARIANCE, optId, `${accountCode}-${fileName?.toUpperCase()}.csv`)
    }

    const csvBlob = new Blob([dataCsv.join('\n')], { type: 'text/csv' })
    const csvUrl = URL.createObjectURL(csvBlob)

    // Create a temporary link and trigger the download
    const tempLink = document.createElement('a')
    tempLink.href = csvUrl
    tempLink.setAttribute('download', `${accountCode}-${fileName?.toUpperCase()}.csv`)
    tempLink.click()

    // Clean up the URL object
    URL.revokeObjectURL(csvUrl)
  }

  const showExportCSVButton = () => {
    if ((value === 'Trade') && (securityLevelTradeData?.length > 0)) {
      return {
        summaryExport: false,
        export: true
      }
    } else if ((value === 'Taxlot') && (optmRunSumPro?.length > 0)) {
      return {
        summaryExport: false,
        export: true
      }
    } else if ((value === 'Position')) {
      if ((value === 'Position' && currentPositionValue === 'security') && (positionData?.length > 0)) {
        return {
          summaryExport: false,
          export: true
        }
      } else if ((value === 'Position' && currentPositionValue === 'sector') && (sectorData?.length > 0)) {
        return {
          summaryExport: true,
          export: true
        }
      } else if ((value === 'Position' && currentPositionValue === 'industry') && (industryData?.length > 0)) {
        return {
          summaryExport: true,
          export: true
        }
      } else if ((value === 'Position' && currentPositionValue === 'sub-industry') && (subIndustryData?.length > 0)) {
        return {
          summaryExport: true,
          export: true
        }
      } else if ((value === 'Position' && currentPositionValue === 'company') && (companyWiseData?.length > 0)) {
        return {
          summaryExport: true,
          export: true
        }
      }
    } else if (value === 'Blotter') {
      if (tradeBlotterData?.length > 0) {
        return {
          summaryExport: false,
          export: true
        }
      }
    } else if (value === 'Model Variance') {
      if (optData?.length > 0) {
        return {
          summaryExport: false,
          export: true
        }
      }
    }
    return {
      summaryExport: false,
      export: false
    }
  }

  const filteredTradeDetailsTab = tradeDetailsTab.filter(item => {
    if (item === 'Feasibility Report') {
      return checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.FEASIBILITY_REPORT })
    } else if (item === 'Model Variance') {
      return checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.MODEL_VARIANCE })
    }
    return true
  })

  return (
    <>
      {
        showTitle ? <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant='h6' style={{ fontWeight: 400, color: '#34475A' }}>Trade Details</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
            {
              showExportCSVButton()?.export || showExportCSVButton()?.summaryExport
                ? <Button
                  sx={{ textTransform: 'none' }}
                  onClick={(e) => setExportCSVAnchorEl(e.currentTarget)}
                >
                  Export CSV
                </Button>
                : ''
            }
            <LivePriceAlert dateTime={tabWiseLatestLivePriceTime[value]} />
            <Menu
              open={Boolean(exportCSVAnchorEl)}
              onClose={() => setExportCSVAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              MenuListProps={{
                sx: { py: '4px' }
              }}
              anchorEl={exportCSVAnchorEl}
            >
              {
                showExportCSVButton()?.summaryExport
                  ? <MenuItem
                    sx={{ fontSize: '14px' }}
                    onClick={() => (downloadCsv(true), setExportCSVAnchorEl(null))}
                  >
                    Export Summary CSV
                  </MenuItem>
                  : ''
              }
              {
                showExportCSVButton()?.export
                  ? <MenuItem
                    sx={{ fontSize: '14px' }}
                    onClick={() => (downloadCsv(false), setExportCSVAnchorEl(null))}
                  >
                    Export Detailed CSV
                  </MenuItem>
                  : ''
              }
            </Menu>
            <IconButton aria-label='close' edge='end' onClick={() => setIsShown()}>
              <Close />
            </IconButton>
          </Box>
        </Box> : ''
      }
      <TabContext value={value}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant='standard'
              scrollButtons={true}
              className='report-tab-header'
              TabIndicatorProps={{
                style: { backgroundColor: '#34475A' }
              }}
              sx={{ minHeight: '32px' }}
              indicatorColor='#34475A'
            >
              {
                filteredTradeDetailsTab?.map((item, index) => (
                  <Tab className='reportCardNav'
                    key={index}
                    value={item}
                    label={item}
                    sx={{
                      fontSize: '14px !important', padding: '6px 8px', minHeight: '32px'
                    }}
                  />
                ))
              }
              <LivePriceAlert dateTime={tabWiseLatestLivePriceTime[value]} />
            </Tabs>
            <Box display={'flex'} alignItems={'center'}>
              {
                !showTitle ?
                  <>
                    {
                      showExportCSVButton()?.export || showExportCSVButton()?.summaryExport
                        ? <Button
                          sx={{ textTransform: 'none', mr: 2 }}
                          onClick={(e) => setExportCSVAnchorEl(e.currentTarget)}
                        >
                          Export CSV
                        </Button>
                        : ''
                    }
                    <Menu
                      open={Boolean(exportCSVAnchorEl)}
                      onClose={() => setExportCSVAnchorEl(null)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      MenuListProps={{
                        sx: { py: '4px' }
                      }}
                      anchorEl={exportCSVAnchorEl}
                    >
                      {
                        showExportCSVButton()?.summaryExport
                          ? <MenuItem
                            sx={{ fontSize: '14px' }}
                            onClick={() => (downloadCsv(true), setExportCSVAnchorEl(null))}
                          >
                            Export Summary CSV
                          </MenuItem>
                          : ''
                      }
                      {
                        showExportCSVButton()?.export
                          ? <MenuItem
                            sx={{ fontSize: '14px' }}
                            onClick={() => (downloadCsv(false), setExportCSVAnchorEl(null))}
                          >
                            Export Detailed CSV
                          </MenuItem>
                          : ''
                      }
                    </Menu>
                  </>
                  :
                  ''
              }
              {
                value === 'Position'
                  ? <FormControl sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', color: '#34475A' }}>
                    <FormLabel id='position-controlled-radio-buttons-group' />
                    <RadioGroup row name='position-radio-group' value={currentPositionValue} onChange={handlePositionChange}>
                      <FormControlLabel value='security' control={<Radio size='small' />} label='Security' />
                      <FormControlLabel value='sector' control={<Radio size='small' />} label='Sector' />
                      <FormControlLabel value='industry' control={<Radio size='small' />} label='Industry' />
                      <FormControlLabel value='sub-industry' control={<Radio size='small' />} label='Sub-Industry' />
                      <FormControlLabel value='company' control={<Radio size='small' />} label='Company' />
                    </RadioGroup>
                  </FormControl>
                  : value === 'Trade' && positionData?.length
                    ? <>
                      <FormControl sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                        <FormLabel id='trade-controlled-checkbox-buttons-group' />
                        <FormGroup row name='trade-checkbox-group'>
                          {
                            canAccessModelChangeWithTrade ? (
                              <FormControlLabel
                                value='CASH'
                                control={
                                  <Checkbox
                                    checked={selectedTrdCode.cash}
                                    onChange={(event) => setSelectedTrdCode({
                                      ...selectedTrdCode,
                                      [event.target.name]: event.target.checked
                                    })}
                                  />
                                }
                                label='Cash' name='cash'
                              />
                            ) : ''
                          }
                          <FormControlLabel
                            value='SELL' control={<Checkbox
                              checked={selectedTrdCode.sell} onChange={(event) => setSelectedTrdCode({
                                ...selectedTrdCode,
                                [event.target.name]: event.target.checked
                              })}
                            />} label='Sell' name='sell'
                          />
                          <FormControlLabel
                            value='BUY' control={<Checkbox
                              checked={selectedTrdCode.buy} onChange={(event) => setSelectedTrdCode({
                                ...selectedTrdCode,
                                [event.target.name]: event.target.checked
                              })}
                            />} label='Buy' name='buy'
                          />
                          {
                            canAccessModelChangeWithTrade ? (
                              <FormControlLabel
                                value='modelChange'
                                control={
                                  <Checkbox
                                    checked={selectedTrdCode.modelChange}
                                    onChange={(event) => setSelectedTrdCode({
                                      ...selectedTrdCode,
                                      [event.target.name]: event.target.checked
                                    })}
                                  />
                                }
                                label='Model Change' name='modelChange'
                              />
                            ) : ''
                          }
                        </FormGroup>
                      </FormControl>
                    </>
                    : <></>
              }
              {
                value === 'Blotter' && liveCashInPercent !== null && liveCash !== null
                  ? <Box sx={{ display: 'flex', fontFamily: 'Open Sans', fontWeight: 600, fontSize: '14px' }}>
                    <Typography color='#74788d' fontSize='14px'>Live Cash in Port: </Typography>
                    <Typography color={liveCashInPercent < 0.5 ? 'red' : '#34475a'} fontSize='14px' ml={1}>{liveCash < 0 ? `-$${formatCurrency(Math.abs(liveCash))}` : `$${formatCurrency(liveCash)}`}</Typography>
                    <Typography color={liveCashInPercent < 0.5 ? 'red' : '#34475a'} fontSize='14px' ml={1}>{`(${liveCashInPercent?.toFixed(2)}%)`}</Typography>
                  </Box>
                  : ''
              }
            </Box>
          </Box>
          <Divider sx={{
            bgcolor: 'rgba(116, 120, 141, 0.15)'
          }}
          />
        </Box>
        <Box>
          {filteredTradeDetailsTab?.map((item, index) => (
            <TabPanel
              key={index}
              index={index}
              value={item}
              sx={{ padding: 0 }}
            >
              {renderTabPanel(item)}
            </TabPanel>
          ))}
        </Box>
      </TabContext>
    </>
  )
}

const CustomGridTreeDataGroupingCell = (props) => {
  const { id, field, rowNode } = props
  const apiRef = useGridApiContext()
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  )
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0

  const handleClick = (event) => {
    if (rowNode.type !== 'group') {
      return
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
    apiRef.current.setCellFocus(id, field)
    event.stopPropagation()
  }

  return (
    <Box sx={{ ml: rowNode.depth * 4 }}>
      <div>
        {filteredDescendantCount > 0
          ? (
            <IconButton onClick={handleClick} tabIndex={-1} size='small'>
              {rowNode.childrenExpanded ? <Remove fontSize='small' /> : <Add fontSize='small' />}
            </IconButton>
            )
          : (
            <span />
            )}
      </div>
    </Box>
  )
}

const PositionDataGrid = ({ columns, rows, loading }) => {
  return (
    loading
      ? renderTableSkeleton(columns)
      : <DataGridPro
        treeData
        getTreeDataPath={(row) => row.hierarchy}
        autoHeight
        rows={rows || []}
        columns={columns}
        getRowId={(row) => row?.id}
        rowHeight={30}
        disableSelectionOnClick
        disableRowSelectionOnClick
        groupingColDef={{
          headerName: '',
          width: 50,
          renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />
        }}
        pagination
        pageSizeOptions={[15, 25, 50, 75, 100]}
        initialState={{
          ...rows?.initialState,
          pagination: { paginationModel: { pageSize: 15 } }
        }}
        sx={(theme) => ({
          [`.${gridClasses.main}`]: {
            height: 'calc(100vh - 8px - 8px - 40px - 32px - 53px)',
          },
          [`.${gridClasses.cell}, .${gridClasses.cellContent}, .${gridClasses.columnHeaderTitle}`]: {
            fontSize: '12px'
          },
          [`.${gridClasses.columnHeaders}`]: {
            fontSize: '12px',
            position: 'sticky',
            backgroundColor: theme.palette.background.paper,
            zIndex: 1,
            top: 0,
            minHeight: '60px !important'
          },
          [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
            color: '#74788d',
            fontWeight: 600
          },
          [`.${gridClasses.virtualScroller}`]: {
            overflowY: 'auto !important',
            scrollbarGutter: 'stable',
            scrollbarWidth: 'none',
            marginTop: 'auto !important'
          }
        })}
      />
  )
}

export default ViewTradeDetails

import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { ArrowBack, MailOutlineOutlined } from '@mui/icons-material'
import SaveIcon from '@mui/icons-material/Save'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Button, FormControl, FormHelperText, InputAdornment, Link, Typography } from '@mui/material'
import { Formik } from 'formik'
import { object, string } from 'yup'
import arisLogoBlue from '../../assets/images/arisNewLogoBlue.svg'
import ellipseShape from '../../assets/images/ellipse-shape.svg'
import CustomOutlinedInput from '../../components/CustomOutlinedInput'
import { useErrorToast } from '../../hooks/useErrorToast'
import { ForgotPasswordChange } from './ForgotPasswordChange'

export const UserEmailVerification = () => {
  const [submittedEmail, setSubmittedEmail] = useState('')
  const [state, setState] = useState('show-email')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const { showError, sendSentryError } = useErrorToast()

  const checkUserExist = async (email) => {
    const validateUserEmailUrl = `${process.env.REACT_APP_BASE_URI_USER}cognito-user/v1/adv-classic/user/email-verification`
    return await fetch(validateUserEmailUrl, {
      method: 'POST',
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY_USER,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email
      })
    }).then((res) => res.json())
      .then((res) => res.data)
      .catch((error) => {
        sendSentryError(error)
      })
  }

  const _handleSubmit = async ({ email }) => {
    // verify if the email is valid and then only send the verifications code
    setIsSubmitted(true)
    const validEmail = await checkUserExist(email)
    if (validEmail) {
      setSubmittedEmail(email)
      Auth.forgotPassword(email)
        .then(() => {
          setState('show-otp-input')
        })
        .catch(error => {
          showError(error, false, {}, 'Failed to verify given email.')
        })
        .finally(() => setIsSubmitted(false))
    } else {
      showError('No user exists with given email')
      setIsSubmitted(false)
    }
  }

  return (
    <Box className='auth-layout'>
      <img src={ellipseShape} alt='' className='ellipse-shape' />

      <Box className='login-form'>
        <Box className='auth-logo mx-auto' display='flex' justifyContent='center'>
          <img src={arisLogoBlue} alt='aris logo' height={55} width={150} className='mx-auto' />
        </Box>
        {state === 'show-email' &&
          <Box>
            <Typography
              variant='h3'
              gutterBottom
              sx={{
                fontFamily: 'Open Sans',
                color: '#002A59',
                fontWeight: '500',
                textAlign: 'center',
                fontSize: '24px',
                marginTop: '20px',
                marginBottom: '25px'
              }}
            >
              Forgot Password?
            </Typography>
            <Typography
              gutterBottom sx={{
                color: '#74788D',
                fontSize: '14px'
              }}
            >
              Please enter the email associated with your account.
            </Typography>
            <Formik
              initialValues={{ email: '' }}
              validateOnMount
              validate={(values) => {
                const errors = {}
                if (!values.email) {
                  errors.email = 'Email is required'
                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                  errors.email = 'Invalid email address'
                }
                return errors
              }}
              validationSchema={object().shape({
                email: string().required('Email is required').email('Invalid email address')
              })}
              onSubmit={({ email }) =>
                _handleSubmit({ email })}
            >
              {(props) => {
                const { values, handleChange, handleBlur, touched, errors, handleSubmit, isSubmitting, isValid } = props
                return (
                  <form onSubmit={handleSubmit} style={{ marginTop: '16px' }}>
                    <FormControl fullWidth margin='dense'>
                      <CustomOutlinedInput
                        placeholder='Enter Email'
                        label='Email'
                        variant='outlined'
                        type='email'
                        name='email'
                        fullWidth
                        required
                        margin='none'
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isSubmitted}
                        error={Boolean(touched.email && errors.email)}
                        startAdornment={
                          <InputAdornment position='start'>
                            <MailOutlineOutlined
                              aria-label='password'
                              edge='end'
                              fontSize='small'
                              sx={{
                                color: '#3369A6',
                                fontSize: '22px'
                              }}
                            />
                          </InputAdornment>
                        }
                      />
                      <FormHelperText error={Boolean(touched.email && errors.email)}>
                        {touched.email && errors.email ? errors.email : ''}
                      </FormHelperText>
                    </FormControl>
                    <Box sx={{ ml: 'auto', mt: 3 }}>
                      {isSubmitted
                        ? (<LoadingButton
                            fullWidth
                            loading
                            loadingPosition='start'
                            startIcon={<SaveIcon />}
                            variant='outlined'
                            sx={{
                              padding: 2
                            }}
                           >
                          Submit
                           </LoadingButton>)
                        : <Button
                            fullWidth
                            disabled={Boolean(!isValid || isSubmitting)}
                            type='submit'
                            variant='contained'
                            sx={{
                              padding: 2
                            }}
                          >
                          Submit
                          </Button>}
                    </Box>
                  </form>
                )
              }}
            </Formik>
          </Box>}
        {state === 'show-otp-input' && <ForgotPasswordChange email={submittedEmail} />}
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
          <Link
            component={NavLink} to='/login' sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontFamily: 'Open Sans',
              color: '#464F5E',
              fontWeight: '500',
              fontSize: '14px',
              alignSelf: 'center',
              textTransform: 'none',
              textDecoration: 'none',
              '&:hover': {
                color: '#464F5E'
              }
            }}
          >
            <ArrowBack sx={{ fontSize: '14px', mr: 0.5 }} />
            Back to log in
          </Link>
        </Box>
      </Box>
    </Box>
  )
}

import React, { useEffect, useState } from 'react'
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import PortfolioIcon from '../../../assets/images/Portfolio-icon.svg'
import '../../../assets/styles/account-onboarding.scss'
import { FundingSource } from '../../../contstants/constants'
import '../../AccountOnboarding/AccountOnboarding.scss'
import { formatNumbers } from '../../Report/components/dataProcess/DataProcess'
import ViewPersonalizationData from './ViewPersonalizationData'
import ViewPortfolioPopup from './ViewPortfolioPopup'

const NewConfirmationTab = ({ umaDetails, uploadedPortfolioData, currentAllocation, targetAllocation, transitionUploadPortfolioData }) => {
  const apiRef = useGridApiRef()
  const currentAllocationApiRef = useGridApiRef()
  const [isViewPersonalizationDataOpen, setIsViewPersonalizationDataOpen] = useState(false)
  const [viewPortfolioPopup, setViewPortfolioPopup] = useState(false)
  const [currentPortfolioId, setCurrentPortfolioId] = useState(null)
  const [selectedPersonalization, setSelectedPersonalization] = useState({})
  const [targetAllocationGridState, setTargetAllocationGridState] = useState(null)
  const [currentAllocationGridState, setCurrentAllocationGridState] = useState(null)

  // Function to save the current state of the DataGridPro
  const handleAllocationsTableChange = () => {
    if (apiRef?.current?.exportState) {
      const state = apiRef?.current?.exportState()
      setTargetAllocationGridState(state)
    }
  }

  // Effect to restore the grid state whenever targetAllocationGridState changes
  useEffect(() => {
    if (apiRef?.current?.restoreState && targetAllocationGridState) {
      const resetGridStateObj = {
        columns: targetAllocationGridState?.columns || {},
        pinnedColumns: targetAllocationGridState?.pinnedColumns || {},
        sorting: targetAllocationGridState?.sorting || []
      }
      apiRef?.current?.restoreState(resetGridStateObj)
    }
  }, [targetAllocationGridState, isViewPersonalizationDataOpen])

  // Function to save the current state of the current allocation DataGridPro
  const handleCurrentAllocationsTableChange = () => {
    if (currentAllocationApiRef?.current?.exportState) {
      const state = currentAllocationApiRef?.current?.exportState()
      setCurrentAllocationGridState(state)
    }
  }

  // Effect to restore the grid state whenever currentAllocationGridState changes
  useEffect(() => {
    if (currentAllocationApiRef?.current?.restoreState && currentAllocationGridState) {
      const resetGridStateObj = {
        columns: currentAllocationGridState?.columns || {},
        pinnedColumns: currentAllocationGridState?.pinnedColumns || {},
        sorting: currentAllocationGridState?.sorting || []
      }
      currentAllocationApiRef?.current?.restoreState(resetGridStateObj)
    }
  }, [currentAllocationGridState, viewPortfolioPopup])

  const closeShowPortfolioPopup = () => {
    setViewPortfolioPopup(false)
  }

  const formateToUsdCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
    // updating into USD Currency
  })

  // format date to YYYY-MM-DD
  const formatDate = (dateStr) => {
    const date = new Date(dateStr)
    const year = date?.getFullYear()
    const month = String(date?.getMonth() + 1).padStart(2, '0')
    const day = String(date?.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  const handlePersonalizationClick = (id) => {
    const selectedRow = targetAllocation
      .flatMap((allocation) => allocation?.data)
      .find((item) => item.id === id)

    if (selectedRow) {
      setSelectedPersonalization(selectedRow?.personalizationData)
      setIsViewPersonalizationDataOpen(true)
    }
  }

  const handlePortfolioIconClick = (id) => {
    setViewPortfolioPopup(true)
    // Find the current allocation row based on the id
    const matchedAllocation = currentAllocation.find(allocation => allocation.id === id)
    if (matchedAllocation) {
      // Set the current portfolio ID to retrieve the correct data from transitionUploadPortfolioData
      setCurrentPortfolioId(matchedAllocation?.id)
    }
  }

  const columns = [
    { field: 'account', headerName: 'Account Name', flex: 1 },
    {
      field: 'marketValue',
      headerName: 'Market Value',
      type: 'number',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      renderHeader: () => (
        <Box sx={{ 
          display: 'flex',
          alignItems: 'center',
          color: '#74788D',
          fontWeight: 600
        }}>
          Market Value
          <Tooltip title='Market value = Security Market Value + Cash' placement='top'>
            <InfoOutlinedIcon
              fontSize='small'
              sx={{
                ml: 1
              }}
            />
          </Tooltip>
        </Box>
      ),
      valueGetter: (params) => {
        const data = transitionUploadPortfolioData?.[params?.row?.id]?.fundingInformation
        if (!data) return null
        const marketValue = data.fundingSource === 'CASH'
          ? data?.totalCashValue
          : data?.totalPortfolioMarketValue
        return (marketValue !== null && marketValue !== undefined)
          ? parseFloat(parseFloat(marketValue).toFixed(2))
          : null
      },
      renderCell: (params) => {
        const value = params?.value
        if (value !== null && value !== undefined) {
          const formattedValue = formatNumbers(Math.abs(value), 2, '$')
          return value < 0 ? `-${formattedValue}` : formattedValue
        }
        return 'N/A'
      }
    },
    {
      field: 'totalCash',
      headerName: 'Total Cash',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      valueGetter: (params) => {
        const data = transitionUploadPortfolioData?.[params?.row?.id]?.fundingInformation
        if (!data) return null
        const totalCashValue = data?.totalCashValue
        if (totalCashValue === '') return null
        return totalCashValue !== undefined && totalCashValue !== null
          ? parseFloat(parseFloat(totalCashValue).toFixed(2))
          : null
      },
      renderCell: (params) => {
        const value = params?.value
        if (value !== null && value !== undefined) {
          const formattedValue = formatNumbers(Math.abs(value), 2, '$')
          return value < 0 ? `-${formattedValue}` : formattedValue
        }
        return 'N/A'
      }
    },
    {
      field: 'portfolio',
      headerName: 'Portfolio',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <IconButton onClick={() => handlePortfolioIconClick(params?.row?.id)}>
          <img src={PortfolioIcon} alt='Portfolio Icon' />
        </IconButton>
      ),
      flex: 1
    }
  ]
  const stagedDataColumns = [
    { field: 'name', headerName: 'Name', flex: 2 },
    {
      field: 'symbol',
      headerName: 'Symbol'
    },
    {
      field: 'shares',
      headerName: 'Shares',
      type: 'number'
    },
    {
      field: 'purchaseCost',
      headerName: 'Purchase Cost',
      type: 'number',
      renderCell: (params) => {
        // Check if the symbol is CASH or USD
        if (params?.row?.symbol === '__CASH' || params?.row?.symbol === '__USD') {
          const sharesValue = params?.row?.shares || 0
          if (sharesValue < 0) {
            return `-${formatNumbers(Math.abs(sharesValue), 2, '$')}`
          }
          return formatNumbers(sharesValue, 2, '$')
        }
        return formatNumbers(params?.value || 0, 2, '$')
      },
      flex: 1
    },
    {
      field: 'purchaseDate',
      headerName: 'Purchase Date',
      valueFormatter: (params) => formatDate(params.value), // purchaseDate to YYYY-MM-DD format
      flex: 1
    },
    {
      field: 'isoCountryCode',
      headerName: 'ISO Country Code',
      flex: 1
    }
  ]

  const targetAllocationColumns = [
    {
      field: 'strategy',
      headerName: 'Strategy',
      flex: 1,
      valueGetter: (params) => params?.row?.strategy?.strategyName || 'N/A'
    },
    {
      field: 'allocation',
      headerName: 'Weight',
      type: 'number',
      valueFormatter: (params) => params?.value !== undefined ? `${params?.value}%` : 0,
      valueGetter: (params) => {
        return params?.row?.allocation !== undefined ? params?.row?.allocation : ''
      },
      flex: 1
    }
    // {
    //   field: 'personalization',
    //   headerName: 'Personalization',
    //   renderCell: (params) => (
    //     <IconButton onClick={() => handlePersonalizationClick(params?.row?.id)}>
    //       <VisibilityOutlinedIcon />
    //     </IconButton>
    //   ),
    //   flex: 1
    // }
  ]

  return (
    <Box className='account-details-container'>
      <Typography sx={{ fontWeight: 600, color: '#74788D', fontSize: '18px', fontFamily: 'Open Sans' }}>Summary</Typography>
      <hr style={{ borderBottom: '1px solid #dee2e6', borderTop: '0' }} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card className='card-layout'>
              <CardContent>
                <Typography variant='h3' className='sub-title'>Basic Information</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Box display='flex' alignItems='baseline'>
                      <Typography className='sub-labels' sx={{ mr: 1 }}>
                        Account Type :
                      </Typography>
                      <Typography className='label-value' style={{ textTransform: 'capitalize' }}>
                        {umaDetails?.accountType || ''}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box display='flex' alignItems='baseline'>
                      <Typography className='sub-labels' sx={{ mr: 1 }}>Name : </Typography>
                      <Typography className='label-value'>
                        {umaDetails?.name || ''}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box display='flex' alignItems='baseline'>
                      <Typography className='sub-labels' sx={{ mr: 1 }}>
                        Client Email :
                      </Typography>
                      <Typography className='label-value'>
                        {umaDetails?.email || ''}
                      </Typography>
                    </Box>
                  </Grid>
                  {umaDetails?.accountType === 'trading'
                    ? <>
                      <Grid item xs={4} sx={{ pt: '12px !important' }}>
                        <Box display='flex' alignItems='baseline'>
                          <Typography className='sub-labels' sx={{ mr: 1 }}>
                            Funding Source :
                          </Typography>
                          <Typography className='label-value'>
                            {FundingSource[uploadedPortfolioData?.fundingSource] || ''}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={4} sx={{ pt: '12px !important' }}>
                        <Box display='flex' alignItems='baseline'>
                          <Typography className='sub-labels' sx={{ mr: 1 }}>
                            Custodian :
                          </Typography>
                          <Typography className='label-value'>
                            {umaDetails?.custodian?.custodianName || ''}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4} sx={{ pt: '12px !important' }}>
                        <Box display='flex' alignItems='baseline'>
                          <Typography className='sub-labels' sx={{ mr: 1 }}>
                            Custodian Number :
                          </Typography>
                          <Typography className='label-value'>
                            {umaDetails?.custodianNumber || ''}
                          </Typography>
                        </Box>
                      </Grid>
                      {
                        umaDetails?.custodian?.custodianName === 'Charles Schwab'
                          ? <Grid item xs={4} sx={{ pt: '12px !important' }}>
                            <Box display='flex' alignItems='baseline'>
                              <Typography className='sub-labels' sx={{ mr: 1 }}>
                                Master Account ID :
                              </Typography>
                              <Typography className='label-value'>
                                {umaDetails?.masterAccountId || ''}
                              </Typography>
                            </Box>
                            </Grid>
                          : ''
                      }
                      {
                        uploadedPortfolioData?.fundingSource === 'CASH'
                          ? <Grid item xs={4} sx={{ pt: '12px !important' }}>
                            <Box display='flex' alignItems='baseline'>
                              <Typography className='sub-labels' sx={{ mr: 1 }}>
                                Total Cash :
                              </Typography>
                              <Typography className='label-value'>
                                {uploadedPortfolioData?.totalCashValue
                                  ? formateToUsdCurrency.format(uploadedPortfolioData?.totalCashValue) || '0'
                                  : 0}
                              </Typography>
                            </Box>
                            </Grid>
                          : ''
                      }
                      </>
                    : null}
                </Grid>
              </CardContent>
            </Card>
            {umaDetails?.taxManaged
              ? <Card className='card-layout'>
                <CardContent>
                  <Typography variant='h6' className='sub-title'>
                    Tax Information
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Box display='flex' alignItems='baseline'>
                        <Typography className='sub-labels' sx={{ mr: 1 }}>
                          Is tax managed? :
                        </Typography>
                        <Typography className='label-value'>
                          {umaDetails?.taxManaged ? 'Yes' : 'No'}
                        </Typography>
                      </Box>
                    </Grid>
                    {umaDetails?.taxManaged
                      ? (
                        <>
                          <Grid item xs={4}>
                            <Box display='flex' alignItems='baseline'>
                              <Typography className='sub-labels' sx={{ mr: 1 }}>
                                Tax State :
                              </Typography>
                              <Typography className='label-value'>
                                {umaDetails?.taxState?.stateName || ''}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            <Box display='flex' alignItems='baseline'>
                              <Typography className='sub-labels' sx={{ mr: 1 }}>
                                Short Term Tax :
                              </Typography>
                              <Typography variant='h3' className='label-value'>
                                {
                                  umaDetails?.shortTermTaxRate || '0'
                                }
                                %
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={4} sx={{ pt: '12px !important' }}>
                            <Box display='flex' alignItems='baseline'>
                              <Typography className='sub-labels' sx={{ mr: 1 }}>
                                Long Term Tax :
                              </Typography>
                              <Typography variant='h3' className='label-value'>
                                {
                                  umaDetails?.longTermTaxRate || '0'
                                }
                                %
                              </Typography>
                            </Box>
                          </Grid>
                        </>)
                      : ''}
                    {umaDetails?.taxManaged && umaDetails?.showAdvancedTax
                      ? <>
                        <Grid item xs={4} sx={{ pt: '12px !important' }}>
                          <Box display='flex' alignItems='baseline'>
                            <Typography className='sub-labels' sx={{ mr: 1 }}>
                              External realized long gain or losses :
                            </Typography>
                            <Typography className='label-value'>
                              {formateToUsdCurrency.format(
                                umaDetails?.externalLongGainLoss
                              ) || '0'}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sx={{ pt: '12px !important' }}>
                          <Box display='flex' alignItems='baseline'>
                            <Typography className='sub-labels' sx={{ mr: 1 }}>
                              External realized short gain or losses :
                            </Typography>
                            <Typography className='label-value'>
                              {formateToUsdCurrency.format(
                                umaDetails?.externalShortGainLoss
                              ) || '0'}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sx={{ pt: '12px !important' }}>
                          <Box display='flex' alignItems='baseline'>
                            <Typography className='sub-labels' sx={{ mr: 1 }}>
                              Tax Year :
                            </Typography>
                            <Typography className='label-value'>
                              {new Date().getFullYear()}
                            </Typography>
                          </Box>
                        </Grid>
                        </>
                      : ''}
                  </Grid>
                </CardContent>
                </Card>
              : null}
          </Grid>

        </Grid>
        {umaDetails?.accountType === 'trading'
          ? (
            <>
              <Card className='card-layout'>
                <CardContent sx={{ marginBottom: '12px' }}>
                  <Typography variant='h3' className='sub-title'>Target Allocation Model</Typography>
                  <Grid container spacing={3}>
                    {targetAllocation?.map((allocation, index) => {
                      let xsValue
                      if (targetAllocation?.length === 1) {
                        xsValue = 12
                      } else if (targetAllocation?.length === 2) {
                        xsValue = 6
                      } else if (targetAllocation?.length === 3 || targetAllocation?.length === 4) {
                        xsValue = 4
                      } else {
                        xsValue = 4
                      }

                      return (
                        <Grid
                          item
                          xs={xsValue}
                          key={allocation.id}
                          sx={targetAllocation?.length === 4 && index === 3 ? { marginTop: 3 } : {}}
                        >
                          <Box>
                            <DataGridPro
                              autoHeight
                              density='compact'
                              rows={allocation?.data?.map((item) => ({
                                ...item,
                                allocationName: allocation.id
                              })) || []}
                              columns={targetAllocationColumns}
                              getRowId={(row) => row.id}
                              hideFooter
                              disableRowSelectionOnClick
                              onColumnWidthChange={() => handleAllocationsTableChange()}
                              onColumnOrderChange={() => handleAllocationsTableChange()}
                              onSortModelChange={() => handleAllocationsTableChange()}
                              initialState={{
                                ...targetAllocationGridState?.initialState,
                                pagination: { paginationModel: { pageSize: 5 } }
                              }}
                              sx={{
                                '& .MuiDataGrid-cell:focus': {
                                  outline: 'none !important'
                                }
                              }}
                            />
                          </Box>
                          {
                            viewPortfolioPopup && (
                              <ViewPortfolioPopup
                                transitionUploadPortfolioData={transitionUploadPortfolioData[currentPortfolioId] || {}}
                                open={viewPortfolioPopup}
                                closeModal={closeShowPortfolioPopup}
                              />
                            )
                          }
                          {
                            isViewPersonalizationDataOpen && (
                              <ViewPersonalizationData
                                isOpen={isViewPersonalizationDataOpen}
                                setIsOpen={setIsViewPersonalizationDataOpen}
                                personalization={selectedPersonalization || {}}
                              />
                            )
                          }
                        </Grid>
                      )
                    })}
                  </Grid>
                </CardContent>
              </Card>
              {uploadedPortfolioData?.fundingSource !== 'CASH' && (
                <Card className='card-layout'>
                  <CardContent sx={{ marginBottom: '12px' }}>
                    <Box>
                      <Box display='flex' justifyContent='space-between'>
                        <Typography variant='h3' className='sub-title'>Current Portfolio</Typography>
                        <Box display='flex' justifyContent='space-between' alignItems='center'>
                          <Typography variant='h3' className='sub-title' mr={2} sx={{ fontSize: '12px !important' }}>
                            {`Total Cash Value: ${uploadedPortfolioData?.totalCashValue !== undefined
                              ? (uploadedPortfolioData?.totalCashValue < 0 ? '-' : '') +
                              formateToUsdCurrency.format(Math.abs(uploadedPortfolioData?.totalCashValue) || 0)
                              : '0'
                              }`}
                          </Typography>
                          <Typography variant='h3' className='sub-title' sx={{ fontSize: '12px !important' }}>
                            {`Total Portfolio Value: ${uploadedPortfolioData?.totalPortfolioMarketValue !== undefined
                              ? (uploadedPortfolioData?.totalPortfolioMarketValue < 0 ? '-' : '') +
                              formateToUsdCurrency.format(Math.abs(uploadedPortfolioData?.totalPortfolioMarketValue) || 0)
                              : '0'
                              }`}
                          </Typography>
                        </Box>
                      </Box>
                      <DataGridPro
                        autoHeight
                        density='compact'
                        rows={uploadedPortfolioData?.stagedData
                          ? uploadedPortfolioData?.stagedData.map((security) => ({
                            ...security,
                            id: randomId(),
                            purchaseCost: (security.symbol === '__USD' || security.symbol === '__CASH')
                              ? security?.purchaseCost * security?.shares
                              : security?.purchaseCost
                          }))
                          : []}
                        columns={stagedDataColumns}
                        pagination
                        pageSizeOptions={[5]}
                        initialState={{
                          ...uploadedPortfolioData?.stagedData?.initialState,
                          pagination: { paginationModel: { pageSize: 5 } }
                        }}
                        getRowId={(row) => row?.id}
                        disableRowSelectionOnClick
                        sx={{
                          '& .MuiDataGrid-cell:focus': {
                            outline: 'none !important'
                          }
                        }}
                      />
                    </Box>
                  </CardContent>
                </Card>
              )}
            </>
            )
          : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card className='card-layout'>
                  <CardContent sx={{ marginBottom: '12px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant='h3' className='sub-title'>Current Allocation Model</Typography>
                      {umaDetails?.accountType === 'trading'
                        ? <Box display='flex'>
                          <Typography variant='h3' className='sub-title' mr={2} sx={{ fontSize: '12px !important' }}>
                            <span style={{ color: '#74788d' }}>Total Cash Value: </span>
                            <span style={{ color: '#34475a' }}>
                              {uploadedPortfolioData?.totalCashValue
                                ? formateToUsdCurrency.format(uploadedPortfolioData?.totalCashValue) || '0'
                                : 0}
                            </span>
                          </Typography>

                          <Typography variant='h3' className='sub-title' sx={{ fontSize: '12px !important' }}>
                            <span style={{ color: '#74788d' }}>Total Portfolio Value: </span>
                            <span style={{ color: '#34475a' }}>
                              {uploadedPortfolioData?.totalPortfolioMarketValue
                                ? formateToUsdCurrency.format(uploadedPortfolioData?.totalPortfolioMarketValue) || '0'
                                : 0}
                            </span>
                          </Typography>
                          </Box>
                        : ''}
                    </Box>
                    <Box>
                      <DataGridPro
                        apiRef={currentAllocationApiRef}
                        autoHeight
                        density='compact'
                        rows={currentAllocation || []}
                        columns={columns}
                        getRowId={(row) => row.id}
                        pagination
                        pageSizeOptions={[5]}
                        disableRowSelectionOnClick
                        onColumnWidthChange={() => handleCurrentAllocationsTableChange()}
                        onColumnOrderChange={() => handleCurrentAllocationsTableChange()}
                        onSortModelChange={() => handleCurrentAllocationsTableChange()}
                        initialState={{
                          ...currentAllocationGridState?.initialState,
                          pagination: { paginationModel: { pageSize: 5 } }
                        }}
                        sx={{
                          '& .MuiDataGrid-cell:focus': {
                            outline: 'none !important'
                          }
                        }}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            )}
        {umaDetails?.accountType === 'transition'
          ? (
            <Card className='card-layout'>
              <CardContent sx={{ marginBottom: '12px' }}>
                <Typography variant='h3' className='sub-title'>Target Allocation Model</Typography>
                <Grid container spacing={3}>
                  {targetAllocation?.map((allocation, index) => {
                    let xsValue
                    if (targetAllocation?.length === 1) {
                      xsValue = 12
                    } else if (targetAllocation?.length === 2) {
                      xsValue = 6
                    } else if (targetAllocation?.length === 3) {
                      xsValue = 4
                    } else if (targetAllocation?.length === 4) {
                      xsValue = 4
                    } else {
                      xsValue = 4
                    }

                    return (
                      <Grid
                        item
                        xs={xsValue}
                        key={allocation.id}
                        sx={targetAllocation?.length === 4 && index === 3 ? { marginTop: 3 } : {}}
                      >
                        <Box>
                          <Typography sx={{ fontSize: '14px', fontWeight: 600, fontFamily: 'Open Sans', pb: 1, pl: 1 }}>
                            {allocation?.allocationTitle || `Allocation ${index + 1}`}
                          </Typography>
                          <DataGridPro
                            apiRef={apiRef}
                            autoHeight
                            density='compact'
                            rows={allocation?.data?.map((item) => ({
                              ...item,
                              allocationName: allocation.id
                            })) || []}
                            columns={targetAllocationColumns}
                            getRowId={(row) => row.id}
                            hideFooter
                            disableRowSelectionOnClick
                            onColumnWidthChange={() => handleAllocationsTableChange()}
                            onColumnOrderChange={() => handleAllocationsTableChange()}
                            onSortModelChange={() => handleAllocationsTableChange()}
                            initialState={{
                              ...targetAllocationGridState?.initialState,
                              pagination: { paginationModel: { pageSize: 5 } }
                            }}
                            sx={{
                              '& .MuiDataGrid-cell:focus': {
                                outline: 'none !important'
                              }
                            }}
                          />
                        </Box>
                        {viewPortfolioPopup && (
                          <ViewPortfolioPopup
                            transitionUploadPortfolioData={transitionUploadPortfolioData[currentPortfolioId] || {}}
                            open={viewPortfolioPopup}
                            closeModal={closeShowPortfolioPopup}
                          />
                        )}
                        {isViewPersonalizationDataOpen && (
                          <ViewPersonalizationData
                            isOpen={isViewPersonalizationDataOpen}
                            setIsOpen={setIsViewPersonalizationDataOpen}
                            personalization={selectedPersonalization || {}}
                          />)}
                      </Grid>
                    )
                  })}
                </Grid>
              </CardContent>
            </Card>)
          : ''}
    </Box>
  )
}

export default NewConfirmationTab

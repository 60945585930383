import { TextField } from '@mui/material'
import { useGridApiContext } from '@mui/x-data-grid-pro'

const NumberFieldEditCell = (params) => {
  const { id, field, value } = params
  const apiRef = useGridApiContext()

  const handleNumberFieldEditChange = (event) => {
    const newValue = event.target.value
    apiRef.current.setEditCellValue({ id, field, value: newValue })
    apiRef.current.updateRows([{ tradeId: id, error: (params?.row?.trdCode === 'SELL' && field === 'trdQty' && newValue >= 0) || (params?.row?.trdCode === 'BUY' && field === 'trdQty' && newValue <= 0) }])
  }

  return (
    <TextField
      type='number'
      margin='dense'
      size='small'
      sx={
        (params?.row?.trdCode === 'SELL' && field === 'trdQty' && value >= 0) || (params?.row?.trdCode === 'BUY' && field === 'trdQty' && value <= 0)
          ? {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'red !important'
              }
            }
          : {}
      }
      fullWidth
      autoComplete={false}
      id={`number-field-edit-${params?.row?.id}`}
      name={`number-field-edit-${params?.row?.id}`}
      value={value}
      onChange={handleNumberFieldEditChange}
    />
  )
}

export default NumberFieldEditCell

import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import { useAuth } from '../../contexts/AuthContext'
import { moduleConfig } from '../../contexts/data'
import { ACCESS_LEVEL } from '../../contstants/constants'
import { useAccountService } from '../../hooks/useAccountService'
import { useErrorToast } from '../../hooks/useErrorToast'
import ExportAccount from './ExportAccount'
import ImportAccount from './ImportAccount'

const AccountMigration = () => {
  const { checkAccess } = useAuth()
  const { user } = useAuth()
  const { getAccountListUtil } = useAccountService()
  const { showError } = useErrorToast()
  const [accounts, setAccounts] = useState([])
  const [copyAccountData, setCopyAccountData] = useState([])
  const [accountsLoading, setAccountsLoading] = useState(false)

  // Fetch the list of accounts based on user group
  const getAccountsList = () => {
    setAccountsLoading(true)
    getAccountListUtil({ serviceId: 'book-of-business', resourceId: 'book-of-business' })
      .then(response => {
        if (response?.data?.accountsSummaryDetails) {
          setAccounts([...response?.data?.accountsSummaryDetails])
          setCopyAccountData([...response?.data?.accountsSummaryDetails])
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to load account data.')
      })
      .finally(() => {
        setAccountsLoading(false)
      })
  }

  useEffect(() => {
    if (user) {
      getAccountsList()
    }
  }, [user])

  // checks for ACL for import and export account access
  const hasImportAccountAccess = checkAccess(moduleConfig.ACCOUNT_MIGRATION, ACCESS_LEVEL.COMPONENT_ACCESS,
    { subModuleName: moduleConfig.ACCOUNT_MIGRATION, component_name: moduleConfig.IMPORT_ACCOUNT })

  const hasExportAccountAccess = checkAccess(moduleConfig.ACCOUNT_MIGRATION, ACCESS_LEVEL.COMPONENT_ACCESS,
    { subModuleName: moduleConfig.ACCOUNT_MIGRATION, component_name: moduleConfig.EXPORT_ACCOUNT })

  return (
    <>
      {
        hasImportAccountAccess
          ? <ImportAccount accounts={accounts} accountsLoading={accountsLoading} />
          : ''
      }
      {
        hasExportAccountAccess
          ? <ExportAccount accounts={accounts} accountsLoading={accountsLoading} setAccounts={setAccounts} copyAccountData={copyAccountData} hasImportAccountAccess={hasImportAccountAccess} />
          : ''
      }
    </>
  )
}

export default AccountMigration

import { ExpandMore } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import { gridFilteredDescendantCountLookupSelector, gridClasses, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro'

const CustomGridTreeDataGroupingCell = (props) => {
  const { id, field, rowNode, showDropdown } = props
  const apiRef = useGridApiContext()
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector,
  )
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0

  const handleClick = (event) => {
    if (rowNode.type !== 'group') {
      return
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
    apiRef.current.setCellFocus(id, field)
    event.stopPropagation()
  }

  return (
    <Box sx={{ ml: rowNode.depth * 2 }}>
      <div>
        {showDropdown(filteredDescendantCount, props?.row?.hierarchy) ? (
          <>
            <IconButton
              onClick={handleClick}
              size="small"
              tabIndex={-1}
              aria-label={rowNode.childrenExpanded ? 'Close' : 'Open'}
            >
              <ExpandMore
                sx={{
                  transform: `rotateZ(${rowNode.childrenExpanded ? 360 : 270}deg)`,
                  transition: (theme) =>
                    theme.transitions.create('transform', {
                      duration: theme.transitions.duration.shortest,
                    }),
                }}
                fontSize="inherit"
              />
            </IconButton>
            <span style={{ marginLeft: rowNode.depth * 2 }}>
              {props?.row?.name}
            </span>
          </>
        ) : (
          <span style={{ marginLeft: rowNode.depth * 8 }}>{props?.row?.name}</span>
        )}
      </div>
    </Box>
  )
}

export default CustomGridTreeDataGroupingCell
import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined'
import { Box, Divider, Tooltip, Typography } from '@mui/material'
import { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { useQuery } from '@tanstack/react-query'
import { v4 as uuidv4 } from 'uuid'
import { useAuth } from '../contexts/AuthContext'
import { useErrorToast } from '../hooks/useErrorToast'
import PolicyBreakCheckTableLoader from './components/PolicyBreakCheckTableLoader'

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFFFF',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 250,
    fontSize: theme.typography.pxToRem(14),
    padding: '16px',
    boxShadow: '0px 7px 29px rgba(100, 100, 111, 0.2)',
    borderRadius: '10px'
  }
}))

const ContactsTable = () => {
  const { user } = useAuth()
  const { sendSentryError } = useErrorToast()
  const [rows, setRows] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const fetchContactsByPage = async (page = 1) => {
    return await API.get(
      'baseDataValidation',
        `data-validation/v1/${user?.userGroup}/policies/status`,
        { queryStringParameters: { page, perPage: 100 } }
    )
  }

  const policyBreakCheckQuery = useQuery({
    queryKey: ['policy-checks', user?.sub],
    queryFn: () => fetchContactsByPage(),
    enabled: user !== null,
    retry: false
  })

  useEffect(() => {
    setIsLoading(policyBreakCheckQuery?.isLoading)
    if (policyBreakCheckQuery?.status === 'success') {
      if (policyBreakCheckQuery.data && policyBreakCheckQuery?.data?.data?.getPolicyBreakStatusData) {
        const formattedData = policyBreakCheckQuery?.data?.data?.getPolicyBreakStatusData?.map((element) => {
          const inputDate = new Date(element?.lastPolicyCheckTime)
          const month = String(inputDate.getMonth() + 1).padStart(2, '0')
          const day = String(inputDate.getDate()).padStart(2, '0')
          const year = inputDate.getFullYear()

          return {
            id: uuidv4(),
            accountCd: element?.accountCd,
            accountType: element?.accountType,
            policyCode: element?.policyCode,
            policyStatus: element?.policyStatus,
            updTs: `${month}-${day}-${year}`, // Formatted date to MM-DD-YYYY
            reason: element?.reason
          }
        })
        setRows(formattedData || [])
      }
    } else if (policyBreakCheckQuery?.status === 'error') {
      sendSentryError(policyBreakCheckQuery?.error)
    }
  }, [policyBreakCheckQuery.isFetched])

  // const fetchContactsByPage = async ({ page = 1 }) => {
  //   setIsLoading(true)
  //   const { data } = await API.get(
  //     'baseDataValidation',
  //     `data-validation/v1/${user?.userGroup}/policies/status`,
  //     { queryStringParameters: { page, perPage: 100 } }
  //   )

  //   const formattedData = data?.getPolicyBreakStatusData?.map((element) => {
  //     const inputDate = new Date(element?.lastPolicyCheckTime)
  //     const month = String(inputDate.getMonth() + 1).padStart(2, '0')
  //     const day = String(inputDate.getDate()).padStart(2, '0')
  //     const year = inputDate.getFullYear()

  //     return {
  //       id: uuidv4(),
  //       accountCd: element?.accountCd,
  //       accountType: element?.accountType,
  //       policyCode: element?.policyCode,
  //       policyStatus: element?.policyStatus,
  //       updTs: `${month}-${day}-${year}`, // Formatted date to MM-DD-YYYY
  //       reason: element?.reason
  //     }
  //   })
  //   setRows(formattedData || [])
  //   setIsLoading(false)
  // }

  // useEffect(() => {
  //   fetchContactsByPage({ page: 1 })
  // }, [])

  function getRowId (row) {
    return row.id
  }

  const columns = [
    { field: 'accountCd', headerName: 'Account Code', flex: 1 },
    { field: 'accountType', headerName: 'Account Type', flex: 1 },
    { field: 'policyCode', headerName: 'Policy Code', flex: 2 },
    { field: 'policyStatus', headerName: 'Policy Status', flex: 1 },
    { field: 'updTs', headerName: 'Last Policy Check Time', flex: 2 },
    {
      field: 'reason',
      headerName: 'Reason',
      flex: 1,
      renderCell: (params) => {
        if (params.row.reason) {
          return (
            <HtmlTooltip
              title={
                <>
                  <Typography color='inherit' fontFamily='Open Sans' fontWeight={600}>Reason</Typography>
                  <Divider sx={{ backgroundColor: '#000000' }} />
                  <Typography sx={{ fontSize: '14px' }} my={1}>{params.row.reason}</Typography>
                </>
              }
            >
              <AssignmentLateOutlinedIcon />
            </HtmlTooltip>
          )
        } else {
          return null
        }
      }
    }
  ]

  return (
    <Box
      style={{ width: '100%' }}
      sx={{
        maxHeight: 550,
        overflow: 'auto',
        '.MuiDataGrid-columnHeaderTitle': {
          color: '#74788D',
          fontWeight: 600,
          fontFamily: 'Open Sans'
        },
        '.MuiDataGrid-cellContent': {
          color: '#34475A',
          fontWeight: 600,
          fontFamily: 'Open Sans'
        },
        '.MuiDataGrid-footerContainer': {
          border: 'none'
        },
        '.MuiDataGrid-root': {
          border: 'none'
        },
        '.MuiTablePagination-toolbar': {
          alignItems: 'baseline'
        }
      }}
    >
      {isLoading
        ? <PolicyBreakCheckTableLoader headers={columns} />
        : <DataGridPro
            autoHeight
            rows={rows}
            columns={columns}
            getRowId={getRowId}
            pagination
            pageSizeOptions={[10]}
            initialState={{
              ...rows?.initialState,
              pagination: { paginationModel: { pageSize: 10 } }
            }}
          />}
    </Box>
  )
}

export default ContactsTable

import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { Box, Button, Chip, Divider, IconButton, Modal, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import { randomId } from '@mui/x-data-grid-generator'
import CircleIcon from '@mui/icons-material/Circle'
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined'
import RefreshOutlined from '@mui/icons-material/RefreshOutlined'
import CloseIcon from '@mui/icons-material/Close'
import ErrorIcon from '@mui/icons-material/Error'
import { HtmlTooltip } from '../../../components/HtmlTooltip'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { moduleConfig } from '../../../contexts/data'
import { ACCESS_LEVEL } from '../../../contstants/constants'
import ViewPolicyJson from '../../../components/ViewPolicyJson'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  px: 2,
  py: 2,
  width: '100%',
  borderRadius: '4px'
}

const renderTableSkeleton = (header, hiddenColumns) => {
  if (hiddenColumns) { header = header.filter(col => !hiddenColumns.includes(col.field)) }
  return (
    <TableContainer mt={5}>
      <Table className='risk-page-table'>
        <TableHead>
          <TableRow>
            {header.map((item, index) => {
              return (
                <TableCell key={index}>{item.headerName}</TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: 5 }).map((_, index) => (
            <TableRow key={index}>
              {Array.from({ length: header.length }).map((_, index) => (
                <TableCell key={index}>
                  <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                </TableCell>))}
            </TableRow>))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const getStatusColor = (color) => {
  if (color === 'green') { return '#3BBFA3' } else if (color === 'red') { return '#FF6161' } else if (color === 'yellow') { return '#D29922' } else if (color === 'gray') { return '#0000004d' }
  return '#c9c9c9'
}

const policyColumns = [
  {
    headerName: 'Policy',
    field: 'policyDesc',
    flex: 2
  },
  {
    headerName: 'Policy Status',
    field: 'policyStatus',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => (
      <Tooltip title={params?.row?.policyStatus}>
        <CircleIcon sx={{ color: getStatusColor(params?.row?.policyColorStatus), height: '12px', width: '12px' }} />
      </Tooltip>
    )
  },
  {
    headerName: 'Last Policy Check',
    field: 'lastUpdatedDate',
    flex: 1,
    type: 'date',
    valueGetter: (params) => {
      if (!params?.value) return params?.value
      const date = new Date(params?.value)
      return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
    },
    renderCell: (params) => params?.row?.lastUpdatedDate ? params?.row?.lastUpdatedDate?.split('T')[0] : ''
  },
  {
    headerName: 'Reason',
    field: 'reason',
    align: 'center',
    width: 100,
    renderCell: (params) => (
      params.row.reason
        ? (
          <HtmlTooltip
            title={
              <>
                <Typography color='inherit' fontFamily='Open Sans' fontWeight={600}>Reason</Typography>
                <Divider sx={{ backgroundColor: '#000000' }} />
                <Typography sx={{ fontSize: '14px' }} my={1}>{params.row.reason}</Typography>
              </>
          }
          >
            <AssignmentLateOutlinedIcon sx={{ cursor: 'pointer' }} />
          </HtmlTooltip>
          )
        : ''
    )
  }
]

const PolicyStatusPopup = ({ showPolicyPopup, onClose, params, policySource, title }) => {
  const { showError } = useErrorToast()
  const { user, checkAccess } = useAuth()
  const [policyData, setPolicyData] = useState({})
  const [isPolicyLoading, setIsPolicyLoading] = useState(true)
  const [showReportPopup, setShowReportPopup] = useState(false)

  const fetchPolicy = (accOptMapId, policySource) => {
    setIsPolicyLoading(true)
    API.post('baseDataValidation', `data-validation/v1/${user.userGroup}/policies/trades`, {
      body: {
        accountOptMapIds: [accOptMapId],
        policySource
      }
    })
      .then(res => {
        if (res?.data?.length) {
          setPolicyData(res?.data[0])
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load policy data.')
      })
      .finally(() => {
        setIsPolicyLoading(false)
      })
  }

  useEffect(() => {
    if (user) { fetchPolicy(params?.row?.accOptMapId, policySource) }
  }, [user])

  return (
    <>
      <Modal
        onClose={onClose}
        open={Boolean(showPolicyPopup)}
      >
        <Box sx={{ ...modalStyle, outline: 'none', minWidth: '600px', maxWidth: '800px', height: 'min(100vh, 600px)' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '5px', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }}>
            <Typography variant='h6' sx={{ ml: '5px', fontWeight: 400, color: '#34475A' }}>{`${title} Policy Check`}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              {
                policyData?.policyRunStatus === 'IN_PROGRESS' || policyData?.policyRunStatus === 'FAILED'
                  ? (
                    <Chip
                      variant='outlined'
                      sx={{
                        backgroundColor: '#F9F4D8',
                        color: '#947330',
                        borderRadius: '5px',
                        border: '1px solid #947330',
                        fontSize: '12px',
                        fontWeight: 600,
                        maxWidth: '500px',
                        height: 'auto',
                        minHeight: '32px',
                        '& .MuiChip-label': {
                          whiteSpace: 'break-spaces'
                        }
                      }}
                      icon={<ErrorIcon sx={{ color: '#947330 !important', height: '18px', width: '18px' }} />}
                      label={policyData?.reason || ''}
                    />
                    )
                  : policyData?.policies?.length && checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.DOWNLOAD_POLICY_STATUS })
                    ? (
                      <Button
                        variant='text'
                        onClick={() => setShowReportPopup(true)}
                        sx={{ textTransform: 'capitalize' }}
                      >
                        View Policy Report
                      </Button>
                      )
                    : ''
              }
              {/* {
              !isPolicyLoading && (
                <IconButton onClick={() => fetchPolicy(params?.row?.accOptMapId, policySource)} sx={{ ml: '10px' }}>
                  <RefreshOutlined sx={{ height: 20, width: 20, color: '#74788D' }} />
                </IconButton>
              )
            } */}
              <IconButton onClick={onClose}>
                <CloseIcon sx={{ height: 20, width: 20, color: '#74788D' }} />
              </IconButton>
            </Box>
          </Box>
          {
            isPolicyLoading
              ? renderTableSkeleton(policyColumns)
              : (
                <DataGridPro
                  autoHeight
                  density='compact'
                  rows={policyData?.policies?.map(policy => ({ ...policy, id: randomId() })) || []}
                  columns={policyColumns}
                  getRowId={(row) => row?.id}
                  pagination
                  pageSizeOptions={[10]}
                  initialState={{
                    ...policyData?.initialState,
                    pagination: { paginationModel: { pageSize: 10 } }
                  }}
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay
                  }}
                  slotProps={{
                    noRowsOverlay: { msg: 'No Policies Found.' }
                  }}
                  sx={(theme) => ({
                    '& .MuiDataGrid-cell:focus': {
                      outline: 'none'
                    },
                    [`.${gridClasses.main}`]: {
                      height: 'min(100vh - 16px - 45px - 16px - 53px, 600px - 16px - 45px - 16px - 53px)'
                    },
                    [`.${gridClasses.columnHeaders}`]: {
                      position: 'sticky',
                      backgroundColor: theme.palette.background.paper,
                      top: 0,
                      zIndex: 1
                    },
                    [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                      color: '#74788d',
                      fontWeight: 600
                    },
                    [`.${gridClasses.virtualScroller}`]: {
                      overflowY: 'auto !important',
                      scrollbarGutter: 'stable',
                      scrollbarWidth: 'none'
                    }
                  })}
                />
                )
          }
        </Box>
      </Modal>
      <ViewPolicyJson policyData={policyData} showReportPopup={showReportPopup} onClose={() => setShowReportPopup(false)} title={title} />
    </>
  )
}

const CustomNoRowsOverlay = ({ msg }) => (
  <Typography sx={{ textAlign: 'center', margin: '16px auto' }}>
    {msg}
  </Typography>
)

export default PolicyStatusPopup

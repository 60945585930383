import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { API } from 'aws-amplify'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Card, CardContent, Grid, Tab, Typography, styled } from '@mui/material'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import ErrorFallback from '../../ErrorFallback'
import LivePriceAlert from '../components/LivePriceAlert'
import './mandatory-trades.css'
import DelistingTable from './DelistingTable'
import SplitsTable from './SplitsTable'
import MergerAndAcquisition from './MergerAndAcquisition'
import SecFile from './SecFile'
import MiscTab from './MiscTab'
import DateRangePickerWithCustomActionBar from './DateRangePickerWithCustomActionBar'

const CustomTab = styled(Tab)(({ theme, selected }) => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  textTransform: 'capitalize',
  fontSize: '16px',
  color: '#34475A',
  fontWeight: 400,
  '&.Mui-selected': {
    fontWeight: 400,
    color: '#34475A'
  }
}))

const MandatoryTrades = () => {
  const tabsList = ['Delist', 'Splits', 'Merger & Acquisition', 'Sec File', 'Miscellaneous']
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const [selectedTab, setSelectedTab] = useState(tabsList[0])
  // data with loader state for apiQueryParamsValue
  const [delistData, setDelistData] = useState({ loading: true, data: null })
  const [splitsData, setSplitsData] = useState({ loading: true, data: null })
  const [mergerAndAcquisitionData, setMergerAndAcquisitionData] = useState({ loading: true, data: null })
  const [SECData, setSECData] = useState({ loading: true, data: null })
  const [miscData, setMiscData] = useState({ loading: true, data: [] })
  const [delistDate, setDelistDate] = useState([null, null])
  const [delistPrevDate, setDelistPrevDate] = useState([null, null])
  const [splitsDate, setSplitsDate] = useState([null, null])
  const [splitsPrevDate, setSplitsPrevDate] = useState([null, null])
  const [latestLivePriceTime, setLatestLivePriceTime] = useState(null)

  useEffect(() => {
    setDelistDate(delistPrevDate)
  }, [delistPrevDate])

  useEffect(() => {
    setSplitsDate(splitsPrevDate)
  }, [splitsPrevDate])

  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue)
  }

  const fetchMergerAndAcquisitionData = async () => {
    setMergerAndAcquisitionData({ loading: true, data: null })
    API.get(
      'baseUriCorporate',
      `corporate-action/v1/${user?.userGroup}/corporate-action-data`,
      {
        queryStringParameters: {
          'action-type': 'MERGER_AND_ACQUISITION',
          ...(user?.userGroup === 'adv-classic') ? { resources: encodeURIComponent(JSON.stringify({ serviceId: 'trade', resourceId: 'mandatory-trades' })) } : ''
        }
      }
    )
      .then((response) => {
        if (response.success) {
          setMergerAndAcquisitionData({ loading: false, data: response?.data })
        }
      })
      .catch(error => {
        setMergerAndAcquisitionData({ loading: false, data: null })
        showError(error, false, {}, 'Failed to load merger and acquisition data.')
      })
  }

  const fetchDelistActions = async (startDate, endDate) => {
    setDelistData({ loading: true, data: null })
    API.get(
      'baseUriCorporate',
      `corporate-action/v1/${user?.userGroup}/corporate-action-data`,
      {
        queryStringParameters: {
          'action-type': 'DELIST',
          ...(user?.userGroup === 'adv-classic') ? { resources: encodeURIComponent(JSON.stringify({ serviceId: 'trade', resourceId: 'mandatory-trades' })) } : '',
          ...(startDate && endDate
            ? {
                'start-date': startDate,
                'end-date': endDate
              }
            : null)
        }
      }
    )
      .then((response) => {
        if (response.success) {
          setDelistData({ loading: false, data: response?.data })
        }
      })
      .catch(error => {
        setDelistData({ loading: false, data: null })
        showError(error, false, {}, 'Failed to load delist records.')
      })
  }

  const fetchSplitsActions = async (startDate, endDate) => {
    setSplitsData({ loading: true, data: null })
    API.get(
      'baseUriCorporate',
      `corporate-action/v1/${user?.userGroup}/corporate-action-data`,
      {
        queryStringParameters: {
          'action-type': 'SPLITS',
          ...(user?.userGroup === 'adv-classic') ? { resources: encodeURIComponent(JSON.stringify({ serviceId: 'trade', resourceId: 'mandatory-trades' })) } : '',
          ...(startDate && endDate
            ? {
                'start-date': startDate,
                'end-date': endDate
              }
            : null)
        }
      }
    )
      .then((response) => {
        if (response.success) {
          setSplitsData({ loading: false, data: response?.data })
          const splits = response?.data?.splits?.slice() || []
          const latestLivePriceTs = splits?.length ? splits?.sort((a, b) => new Date(b.livePriceTs).getTime() - new Date(a.livePriceTs).getTime())[0]?.livePriceTs : null
          setLatestLivePriceTime(latestLivePriceTs ? latestLivePriceTs : null)
        }
      })
      .catch(error => {
        setSplitsData({ loading: false, data: null })
        showError(error, false, {}, 'Failed to load split actions.')
      })
  }

  const fetchSECFileData = async (startDate, endDate) => {
    API.get(
      'baseUriCorporate',
      `corporate-action/v1/${user?.userGroup}/corporate-action-sec-data`
    )
      .then((response) => {
        if (response.success) {
          setSECData({ loading: false, data: response?.data })
        }
      })
      .catch(error => {
        setSECData({ loading: false, data: null })
        showError(error, false, {}, 'Failed to load SEC data.')
      })
  }

  const fetchMiscellaneousData = async () => {
    setMiscData({ loading: true, data: [] })
    API.get(
      'baseUriCorporate',
      `corporate-action/v1/${user?.userGroup}/dump-misc`
    )
      .then((response) => {
        if (response.success) {
          setMiscData({ loading: false, data: response.data })
        }
      })
      .catch(error => {
        setMiscData({ loading: false, data: [] })
        showError(error, false, {}, 'Failed to load miscellaneous data.')
      })
  }

  useEffect(() => {
    if (user) {
      fetchDelistActions()
      fetchSplitsActions()
      fetchMergerAndAcquisitionData()
      fetchSECFileData()
      fetchMiscellaneousData()
    }
  }, [user])

  const getUpdatedData = (data) => {
    if (selectedTab === 'Delist') {
      setDelistData({ ...data })
    } else if (selectedTab === 'Splits') {
      setSplitsData({ ...data })
    }
  }

  const renderTabPanel = (tab, index) => {
    switch (tab) {
      case 'Delist':
        // getUpdatedRows: modify data in parent
        return <DelistingTable rows={delistData} getUpdatedRows={getUpdatedData} fetchDelistActions={() => delistDate?.length === 2 && delistDate[0] && delistDate[1] ? fetchDelistActions(delistDate[0].format('YYYY-MM-DD'), delistDate[1].format('YYYY-MM-DD')) : fetchDelistActions()} />
      case 'Splits':
        return <SplitsTable rows={splitsData} getUpdatedRows={getUpdatedData} fetchSplitsActions={() => splitsDate?.length === 2 && splitsDate[0] && splitsDate[1] ? fetchSplitsActions(splitsDate[0].format('YYYY-MM-DD'), splitsDate[1].format('YYYY-MM-DD')) : fetchSplitsActions()} />
      case 'Merger & Acquisition':
        return <MergerAndAcquisition rows={mergerAndAcquisitionData} />
      case 'Sec File':
        // return <SecFile data={SECData} fetchData={() => date.length === 2 && date[0] && date[1] ? fetchSECFileData(date[0].format('YYYY-MM-DD'), date[1].format('YYYY-MM-DD')) : null} />
        return <SecFile data={SECData} fetchData={fetchSECFileData} />
      case 'Miscellaneous':
        return <MiscTab data={miscData} />
      default:
        return 'No Data Available'
    }
  }

  return (
    <Box className='mandatory-trades'>
      <Grid container>
        <Grid item xs={12} mb={2}>
          <Box
            sx={{
              display: 'flex',
              alignItems: { xs: 'start', md: 'center' },
              justifyContent: 'space-between'
            }}
          >
            <Typography component='h3' className='page-title' sx={{ marginRight: '20px', whiteSpace: 'nowrap' }}>Mandatory Trades</Typography>
            <Box sx={{ display: 'flex', gap: '10px' }}>
              {
                // show refresh button on delist and splits
                selectedTab === 'Delist' || selectedTab === 'Splits'
                  ? <Box sx={{ display: 'flex', gap: '10px' }}>
                    <DateRangePickerWithCustomActionBar hidden={selectedTab === 'Delist'} fetchData={fetchDelistActions} date={delistDate} setDate={setDelistDate} setPrevDate={setDelistPrevDate} prevDate={delistPrevDate} />
                    <DateRangePickerWithCustomActionBar hidden={selectedTab === 'Splits'} fetchData={fetchSplitsActions} date={splitsDate} setDate={setSplitsDate} setPrevDate={setSplitsPrevDate} prevDate={splitsPrevDate} />
                    </Box>
                  : ''
              }
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <TabContext value={selectedTab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <TabList
                    onChange={handleTabChange}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: '#34475A'
                      }
                    }}
                    indicatorColor='#34475A'
                  >
                    {tabsList.map((tab, index) => (
                      <CustomTab key={index} label={tab} value={tab} />
                    ))}
                  </TabList>
                  {selectedTab === 'Splits' ? <LivePriceAlert dateTime={latestLivePriceTime} /> : ''}
                </Box>
                {tabsList.map((tab, index) => (
                  <TabPanel key={index} value={tab} sx={{ px: 0, pt: '5px', pb: 0 }}>
                    <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen='not main' />)}>
                      {renderTabPanel(tab, index)}
                    </ErrorBoundary>
                  </TabPanel>
                ))}
              </TabContext>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default MandatoryTrades

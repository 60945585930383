import { Fragment, useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { Box, Grid, FormControl, RadioGroup, FormControlLabel, Radio, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useAuth } from '../../contexts/AuthContext'
import { useSuccessToast } from '../../hooks/useSuccessToast'
import { useErrorToast } from '../../hooks/useErrorToast'
import { ACLLoadingTable } from './ACLLoadingTable'

const ManageAggregateGroupsTable = (props) => {
  const {
    allAggregateGroupsList,
    aggregateGroupsLoading,
    selectionModel,
    allAggregateGroupsFlag,
    setAllAggregateGroupsFlag,
    setSelectionModel,
    defaultCheckBoxApiLoading,
    setSpnGroupAccountsFlag,
    spnGroupAccountsFlag,
    modelProps
  } = props

  const { user } = useAuth()
  const [currentPage, setCurrentPage] = useState(0)
  const [row, setRow] = useState([])
  const { showSuccess } = useSuccessToast()
  const { showError } = useErrorToast()
  const [isSortProcessLoading, setIsSortProcessLoading] = useState(true)
  const [currentTab, setCurrentTab] = useState('')
  const [idSaveLoading, setIdSaveLoading] = useState(false)
  const [allAggregateGroups, setAllAggregateGroups] = useState([])
  const [allSpnGroups, setAllSpnGroups] = useState([])
  const [isAggregateGroupsSorting, setIsAggregateGroupsSorting] = useState(true)
  const [sortAggregateGroupsFlag, setSortAggregateGroupsFlag] = useState(true)

  const columns = [
    {
      field: 'groupName',
      headerName: 'Aggregate Group Name',
      flex: 2
    },
    {
      field: 'groupType',
      headerName: 'Aggregate Group Type',
      flex: 2
    },
    {
      field: 'groupCd',
      headerName: 'Aggregate Group Code',
      flex: 2
    },
    {
      field: 'aggGroupId',
      headerName: 'Aggregate Group Id',
      flex: 2,
      hidden: true
    }
  ]

  useEffect(() => {
    if (!aggregateGroupsLoading && !defaultCheckBoxApiLoading) {
      if (allAggregateGroupsFlag) {
        setCurrentTab('All Groups')
        const selectedAccounts = allAggregateGroupsList?.map((data) => data.aggGroupId)
        setAllAggregateGroups(selectedAccounts)
      } else if (spnGroupAccountsFlag) {
        setCurrentTab('All Sponsor Groups')
        const spnGroupList = allAggregateGroupsList?.filter((d) => d.sponsorId === modelProps?.sponsor?.sponsorId)?.map((data) => data.aggGroupId)
        setAllSpnGroups(spnGroupList)
      } else {
        setCurrentTab('Select Manually')
      }
    }
  }, [allAggregateGroupsFlag, spnGroupAccountsFlag, aggregateGroupsLoading, defaultCheckBoxApiLoading])

  useEffect(() => {
    if (selectionModel?.length > 0 && !allAggregateGroupsFlag && !spnGroupAccountsFlag) {
      if (allAggregateGroupsList) {
        if (sortAggregateGroupsFlag && selectionModel?.length > 0) {
          const reorderedRows = [...allAggregateGroupsList].sort((a, b) => {
            const isSelectedA = sortAggregateGroupsFlag && selectionModel?.includes(a?.aggGroupId)
            const isSelectedB = sortAggregateGroupsFlag && selectionModel?.includes(b?.aggGroupId)
            return isSelectedB - isSelectedA
          })
          if (reorderedRows && reorderedRows.length > 0 && selectionModel?.length > 0) {
            setIsAggregateGroupsSorting(false)
            setRow(reorderedRows)
          }
          setIsSortProcessLoading(false)
        }
      }
    } else {
      if (spnGroupAccountsFlag) {
        const allSpnAccounts = allAggregateGroupsList?.filter((d) => d.sponsorId === modelProps?.sponsor?.sponsorId)
        setRow(allSpnAccounts)
        setIsAggregateGroupsSorting(false)
        setIsSortProcessLoading(false)
      } else if (allAggregateGroupsList && !spnGroupAccountsFlag) {
        setRow(allAggregateGroupsList)
        setIsAggregateGroupsSorting(false)
        setIsSortProcessLoading(false)
      }
    }
  }, [allAggregateGroupsList, modelProps, sortAggregateGroupsFlag, spnGroupAccountsFlag, selectionModel, allAggregateGroupsFlag])

  const handleAccountTypeChange = (e) => {
    if (e.target.value === 'Select Manually') {
      setCurrentTab(e.target.value)
      setAllAggregateGroupsFlag(false)
      setSpnGroupAccountsFlag(false)
      setSortAggregateGroupsFlag(true)
    } else if (e.target.value === 'All Sponsor Groups') {
      setCurrentTab(e.target.value)
      const spnGroupList = allAggregateGroupsList?.filter((d) => d.sponsorId === modelProps?.sponsor?.sponsorId)?.map((data) => data.aggGroupId)
      setAllSpnGroups(spnGroupList)
      setSpnGroupAccountsFlag(true)
      setAllAggregateGroupsFlag(false)
    } else {
      setCurrentTab(e.target.value)
      const selectedAccounts = allAggregateGroupsList?.map((data) => data.aggGroupId)
      setAllAggregateGroups(selectedAccounts)
      setAllAggregateGroupsFlag(true)
      setSpnGroupAccountsFlag(false)
    }
  }

  const handleAccountIdsSave = () => {
    setIdSaveLoading(true)
    API.patch('baseAclURL', `user-access-control/v1/${user?.userGroup}/user/aggregate/${modelProps?.userId}`, {
      queryStringParameters: {
        'event-type': 'GROUP'
      },
      body: [{
        serviceId: modelProps?.serviceId,
        resourceId: modelProps?.resourceId,
        allAggGroups: currentTab === 'All Groups',
        allSpnAggGroups: currentTab === 'All Sponsor Groups',
        aggGroupIds: currentTab === 'Select Manually' ? selectionModel : currentTab === 'All Sponsor Groups' ? allSpnGroups : allAggregateGroups
      }]
    }).then((res) => {
      if (res && res?.success) {
        showSuccess(res?.message)
        setIdSaveLoading(false)
      }
    }).catch((error) => {
      setIdSaveLoading(false)
      showError(error, false, {}, 'Failed to update account access.')
    }).finally(() => {
      setIdSaveLoading(false)
    })
  }

  return (
    <Box sx={{ width: 'auto', height: '500px', minHeight: '500px', maxHeight: '500px' }}>
      <Grid container xs={12} justifyContent='space-between' alignItems='center' mb={1} mt={1}>
        <FormControl style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '5px 0 0 16px' }}>
          <RadioGroup
            name='radio-buttons-group'
            style={{ display: 'flex', flexDirection: 'row', marginLeft: '0px' }}
          >
            <FormControlLabel
              checked={currentTab === 'Select Manually' && !allAggregateGroupsFlag && !spnGroupAccountsFlag && !isAggregateGroupsSorting && !isSortProcessLoading && !aggregateGroupsLoading && !defaultCheckBoxApiLoading} value='Select Manually' onChange={handleAccountTypeChange} control={<Radio />} label='Select Manually'
              disabled={defaultCheckBoxApiLoading || aggregateGroupsLoading || isAggregateGroupsSorting || isSortProcessLoading}
            />
            <FormControlLabel
              checked={currentTab === 'All Groups' && allAggregateGroupsFlag && !isAggregateGroupsSorting && !isSortProcessLoading && !aggregateGroupsLoading && !defaultCheckBoxApiLoading} value='All Groups' onChange={handleAccountTypeChange} control={<Radio />} label='All Groups'
              disabled={defaultCheckBoxApiLoading || aggregateGroupsLoading || isAggregateGroupsSorting || isSortProcessLoading}
            />
            <FormControlLabel
              disabled={defaultCheckBoxApiLoading || aggregateGroupsLoading || isAggregateGroupsSorting || isSortProcessLoading} value='All Sponsor Groups'
              onChange={handleAccountTypeChange} control={<Radio />} label='All Sponsor Groups'
              defaultChecked={false}
              checked={currentTab === 'All Sponsor Groups' && spnGroupAccountsFlag && !isAggregateGroupsSorting && !isSortProcessLoading && !aggregateGroupsLoading && !defaultCheckBoxApiLoading}
            />
          </RadioGroup>
        </FormControl>
        {idSaveLoading
          ? (
            <LoadingButton
              loading
              loadingPosition='start'
              variant='outlined'
              sx={{ float: 'right', width: '120px' }}
            >
              Apply
            </LoadingButton>
            )
          : (
            <Button
              disabled={defaultCheckBoxApiLoading || aggregateGroupsLoading || row?.length < 1 || isAggregateGroupsSorting}
              variant='contained' color='primary' sx={{ float: 'right', width: '100px' }} onClick={handleAccountIdsSave}
            >Apply
            </Button>
            )}
      </Grid>
      <Grid container xs={12} justifyContent='space-between' alignItems='center' mb={2}>
        {(isSortProcessLoading || defaultCheckBoxApiLoading || aggregateGroupsLoading || isAggregateGroupsSorting || !currentTab)
          ? (
            <>
              <ACLLoadingTable headers={columns?.filter(col => !col.hidden)} />
            </>
            )
          : (
            <DataGridPro
              autoHeight
              rows={row}
              columns={columns}
              rowSelectionModel={currentTab === 'Select Manually' ? selectionModel : currentTab === 'All Sponsor Groups' ? allSpnGroups : allAggregateGroups}
              onRowSelectionModelChange={(e) => {
                setSortAggregateGroupsFlag(false)
                setSelectionModel(e)
              }}
              getRowId={(row) => row?.aggGroupId}
              checkboxSelection
              density='compact'
              sx={{
                '&.MuiDataGrid-root .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
                  display: currentTab === 'All Groups' || currentTab === 'All Sponsor Groups' ? 'none' : ''
                }
              }}
              pageSizeOptions={[10]}
              isRowSelectable={(params) => currentTab !== 'All Groups' && currentTab !== 'All Sponsor Groups'}
              disableMultipleRowSelection={currentTab === 'All Groups' || currentTab === 'All Sponsor Groups'}
              onPaginationModelChange={(event) => setCurrentPage(event.page)}
              pagination
              paginationModel={{ pageSize: 10, page: currentPage }}
              initialState={{
                ...row?.initialState,
                columns: {
                  columnVisibilityModel: {
                    aggGroupId: false
                  }
                }
              }}
            />
            )}
      </Grid>
    </Box>
  )
}

export default ManageAggregateGroupsTable

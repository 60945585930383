import React, { useState } from 'react'
import { API } from 'aws-amplify'
import { Cached } from '@mui/icons-material'
import { Box, Tooltip } from '@mui/material'
import dayjs from 'dayjs'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { getColorProperties } from './getColorProperties'

const FixTradeStatusColumnCell = (params) => {
  const [isSyncing, setIsSyncing] = useState(false)
  const { showError } = useErrorToast()
  const { user } = useAuth()

  const getLatestFixTradeStatus = (optMapId) => {
    API.get(
      'baseUriTrade',
            `trade/v1/${user.userGroup}/fix-trade-status/${optMapId}`
    )
      .then((response) => {
        if (response?.data?.length > 0) {
          const data = response.data[0]
          if (params?.updateOptmRun) {
            params?.updateOptmRun(data, optMapId)
          }
        }
        setIsSyncing(false)
      })
      .catch((error) => {
        setIsSyncing(false)
        showError(error, false, {}, 'Failed to load latest fix trade status.')
      })
  }

  const handleSyncClick = (e, id) => {
    setIsSyncing(true)
    getLatestFixTradeStatus(id)
  }

  const id = params?.row?.optDetails?.length ? params?.row?.optDetails[0]?.accOptMapId : ''
  const currentDate = dayjs().format('YYYY-MM-DD')
  const date = params?.row?.optDate?.split('T')[0]
  const data = params.row?.optDetails?.length ? (params.row?.optDetails[0]?.summaryDetails?.fixTradeStatus || 'N/A') : 'N/A'
  const syncStatusIcon = ((
    data === 'TRADE_FAILED' ||
        data === 'TRADE_PARTIAL_FILLED' ||
        data === 'TRADE_STOPPED' ||
        (data === 'TRADE_IN_PROGRESS' && params?.inProgressRefreshIconAccess)

  ) && currentDate === date)
    ? <Tooltip title='Refresh trade status'><Cached sx={{ width: '16px', height: '16px', ml: '5px', cursor: 'pointer' }} className={`sync-icon-rotate ${isSyncing ? 'rotate' : ''}`} fontSize='small' onClick={(e) => handleSyncClick(e, id)} /></Tooltip>
    : ''
  return data !== 'N/A'
    ? <><Box sx={{ borderRadius: '20px', fontSize: '10px', px: '10px', py: '2px', ...getColorProperties(data), fontWeight: 600 }}>{data}</Box>{syncStatusIcon}</>
    : <>{data}</>
}

export default FixTradeStatusColumnCell

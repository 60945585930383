import React, { useState } from 'react'
import { API } from 'aws-amplify'
import { Cached } from '@mui/icons-material'
import { Box, Tooltip } from '@mui/material'
import dayjs from 'dayjs'
import { useAuth } from '../../../../contexts/AuthContext'
import { useErrorToast } from '../../../../hooks/useErrorToast'
import { getColorProperties } from '../../TrdaeApproved/getColorProperties'

const TradeStatusColumnCell = (params) => {
  const [syncIconClick, setSyncIconClick] = useState(false)
  const { showError } = useErrorToast()
  const { user } = useAuth()

  const getLatestFixTradeStatus = (orderId) => {
    API.get(
      'baseUriTrade',
            `trade/v1/${user?.userGroup}/adhoc/trades/${orderId}`
    )
      .then((response) => {
        if (response?.success && response?.data) {
          const data = response.data
          if (params?.updateAdhocTradeData) {
            params?.updateAdhocTradeData(data, orderId)
          }
        }
        setSyncIconClick(false)
      })
      .catch((error) => {
        setSyncIconClick(false)
        showError(error, false, {}, 'Failed to load latest fix trade status.')
      })
  }

  const handleSyncClick = (e, orderId) => {
    setSyncIconClick(true)
    getLatestFixTradeStatus(orderId)
  }

  const id = params?.row?.orderId
  const currentDate = dayjs().format('YYYY-MM-DD')
  const trdDate = params?.row?.trdDate?.split('T')[0]
  const fixTradeStatus = params?.row?.fixTradeStatus || 'N/A'
  const syncStatusIcon = (!(fixTradeStatus === 'TRADE_COMPLETED' || fixTradeStatus === 'TRADE_FAILED') && currentDate === trdDate) ? <Tooltip title='Refresh trade status'><Cached sx={{ width: '16px', height: '16px', ml: '5px', cursor: 'pointer' }} className={`sync-icon-rotate ${syncIconClick ? 'rotate' : ''}`} fontSize='small' onClick={(e) => handleSyncClick(e, id)} /></Tooltip> : ''
  return fixTradeStatus !== 'N/A'
    ? <><Box sx={{ borderRadius: '20px', fontSize: '10px', px: '10px', py: '2px', ...getColorProperties(fixTradeStatus), fontWeight: 600 }}>{fixTradeStatus}</Box>{syncStatusIcon}</>
    : <>{fixTradeStatus}</>
}

export default TradeStatusColumnCell

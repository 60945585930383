import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { Add, Cancel, Close, DeleteOutlined, Edit, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, IconButton, Modal, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, GridActionsCellItem, GridRowEditStopReasons, GridRowModes, GridToolbarContainer, gridClasses } from '@mui/x-data-grid-pro'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { useSuccessToast } from '../../../hooks/useSuccessToast'
import Loader from '../../Loader'
import StyledTradePopupBox from '../components/StyledTradePopupBox'

const corporateModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  px: 2,
  py: 2,
  width: '100%',
  minWidth: '600px',
  maxWidth: '900px',
  borderRadius: '4px',
  '.MuiDataGrid-root': {
    border: 'none'
  },
  '.MuiDataGrid-main': {
    // remove overflow hidden overwise sticky does not work
    overflow: 'unset'
  },
  '.MuiDataGrid-columnHeaders': {
    position: 'sticky',
    top: '-4px',
    zIndex: 99,
    background: 'white'
  },
  '.MuiDataGrid-virtualScroller': {
    // remove the space left for the header
    marginTop: '-4px!important'
  }
}

const renderTableSkeleton = (header, hiddenColumns) => {
  if (hiddenColumns) { header = header.filter(col => !hiddenColumns.includes(col.field)) }
  return (
    <TableContainer mt={5}>
      <Table className='risk-page-table'>
        <TableHead>
          <TableRow>
            {header.map((item, index) => {
              return (
                <TableCell key={index}>{item.headerName}</TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: 5 }).map((_, index) => (
            <TableRow key={index}>
              {Array.from({ length: header.length }).map((_, index) => (
                <TableCell key={index}>
                  <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                </TableCell>))}
            </TableRow>))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const SecFile = ({ data, fetchData }) => {
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const { showSuccess } = useSuccessToast()
  const [rows, setRows] = useState([])
  const [isKeywordLoading, setKeywordsLoading] = useState(false)
  const [openKeywordsPopup, setOpenKeywordsPopup] = useState(false)
  const [rowModesModel, setRowModesModel] = useState({})
  const [keywordsCopy, setKeywordsCopy] = useState([])
  const [keywordApplying, setKeywordApplying] = useState(false)
  const [rowData, setRowData] = useState([])

  useEffect(() => {
    if (!data.loading && data?.data?.length) { setRowData(data?.data?.map(obj => ({ ...obj, id: randomId() }))) }
  }, [data])

  const getKeywords = async () => {
    setKeywordsLoading(true)
    API.get(
      'baseUriCorporate',
      `corporate-action/v1/${user?.userGroup}/sec-keyword`
    )
      .then((response) => {
        if (response.success) {
          const newRows = response.data.map(obj => ({ ...obj, id: randomId() }))
          setRows(newRows)
          setKeywordsCopy(newRows)
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to load SEC keywords data.')
      })
      .finally(() => {
        setKeywordsLoading(false)
      })
  }

  const createKeywords = (keyword) => {
    return API.post(
      'baseUriCorporate',
      `corporate-action/v1/${user?.userGroup}/sec-keyword/`,
      {
        body: {
          keyword: typeof keyword === 'string' ? keyword.toUpperCase() : ''
        }
      }
    )
  }

  const deleteSECKeyword = (keyId) => {
    return API.del(
      'baseUriCorporate',
      `corporate-action/v1/${user?.userGroup}/sec-keyword/${keyId}`
    )
  }

  const updateSECKeyword = (keyId, keyword) => {
    return API.patch(
      'baseUriCorporate',
      `corporate-action/v1/${user?.userGroup}/sec-keyword/${keyId}`,
      {
        body: {
          keyword: typeof keyword === 'string' ? keyword.toUpperCase() : ''
        }
      }
    )
  }

  const storeSchwabSECData = async () => {
    setKeywordApplying(true)
    API.post(
      'baseUriCorporate',
      `corporate-action/v1/${user?.userGroup}/corporate-action-sec-data`
    )
      .then((response) => {
        if (response.success) {
          showSuccess(response.message)
          handlePopupClose()
          fetchData()
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to store keywords.')
      })
      .finally(() => {
        setKeywordApplying(false)
      })
  }

  const [secFileColumns] = useState([
    { field: 'instrId', headerName: 'Instrument Id', width: 150 },
    {
      field: 'dataDate',
      headerName: 'Date',
      width: 100,
      headerAlign: 'right',
      align: 'right',
      type: 'date',
      valueGetter: (params) => {
        if (!params?.value) return params?.value
        const date = new Date(params?.value)
        return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
      },
      renderCell: (params) => params?.row?.dataDate ? params?.row?.dataDate?.split('T')[0] : ''
    },
    { field: 'previousDesc', headerName: 'Previous Description', flex: 1 },
    { field: 'currentDesc', headerName: 'Current Description', flex: 1 }
  ])

  const keywordColumns = [
    {
      field: 'keyword',
      headerName: 'Keyword',
      flex: 1,
      editable: true,
      preProcessEditCellProps: (params) => {
        const hasError = rows.find(obj => obj.keyword === params.props.value) !== undefined || params.props.value.trim() === ''
        return { ...params.props, error: hasError }
      },
      renderCell: (params) => <Typography sx={{ textTransform: 'uppercase', color: '#34475A', fontSize: '14px', fontWeight: 600 }}>{params.value}</Typography>
    },
    {
      field: 'action',
      type: 'actions',
      headerName: 'Action',
      width: 100,
      getActions: (params) => {
        const isInEditMode = rowModesModel[params?.id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<Save />}
              label='Save'
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(params?.id)}
            />,
            <GridActionsCellItem
              icon={<Cancel />}
              label='Cancel'
              className='textPrimary'
              onClick={handleCancelClick(params?.id)}
              color='inherit'
            />
          ]
        }

        return [
          <GridActionsCellItem
            icon={<Edit />}
            label='Edit'
            className='textPrimary'
            onClick={handleEditClick(params?.id)}
            color='inherit'
          />,
          <GridActionsCellItem
            icon={<DeleteOutlined />}
            label='Delete'
            onClick={handleDeleteClick(params?.id, params?.row?.keyId)}
            color='inherit'
          />
        ]
      }
    }
  ]

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleDeleteClick = (id, key) => async () => {
    setKeywordApplying(true)
    return deleteSECKeyword(key)
      .then((response) => {
        if (response.success) { setRows(rows.filter((row) => row.id !== id)) }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to delete SEC keyword data.')
      })
      .finally(() => {
        setKeywordApplying(false)
      })
  }

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })

    const editedRow = rows.find((row) => row.id === id)
    if (editedRow?.isNew) {
      setRows(rows.filter((row) => row.id !== id))
    }
  }

  const processRowUpdate = (newRow, oldRow) => {
    if (newRow.keyword.trim() === '') {
      // don't go to view/save if text is empty
      setRowModesModel({ ...rowModesModel, [newRow.id]: { mode: GridRowModes.Edit } })
      return rows
    }
    if (oldRow?.keyword === newRow?.keyword) {
      setRowModesModel({ ...rowModesModel, [newRow.id]: { mode: GridRowModes.Edit } })
      return rows
    }
    setKeywordApplying(true)
    // keyid won't be inside new row
    if (newRow?.keyId) {
      return updateSECKeyword(newRow?.keyId, newRow?.keyword)
        .then((response) => {
          const updatedRow = { ...newRow, isNew: false, keyword: response?.data?.keyword, keyId: response?.data?.keyId }
          setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)))
          return updatedRow
        })
        .catch((error) => {
          showError(error, false, {}, 'Failed to update the SEC keyword data.')
          setRowModesModel({ ...rowModesModel, [newRow.id]: { mode: GridRowModes.Edit } })
          return rows
        })
        .finally(() => {
          setKeywordApplying(false)
        })
    }
    return createKeywords(newRow?.keyword)
      .then((response) => {
        if (response?.success) {
          const updatedRow = { ...newRow, isNew: false, keyword: response?.data?.keyword, keyId: response?.data?.keyId }
          setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)))
          return updatedRow
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to update the SEC keyword data.')
        setRowModesModel({ ...rowModesModel, [newRow.id]: { mode: GridRowModes.Edit } })
        return rows
      })
      .finally(() => {
        setKeywordApplying(false)
      })
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    // used for changing view/edit mode of row
    setRowModesModel(newRowModesModel)
  }

  const compareKeywordsAndKeywordsCopy = () => {
    let flag = true
    if (keywordsCopy.length !== rows.length) {
      return !flag
    }
    for (let i = 0; i < Math.min(keywordsCopy.length, rows.length); i++) {
      if (keywordsCopy[i].keyword !== rows[i].keyword) {
        flag = false
      }
    }
    return flag
  }

  const handlePopupClose = () => {
    setOpenKeywordsPopup(false)
    setRowModesModel({})
  }

  return (
    <>
      {keywordApplying ? <Loader /> : ''}
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Button variant='text' onClick={() => (setOpenKeywordsPopup(true), getKeywords())}>
          Manage Keywords
        </Button>
      </Box>
      <Box sx={{
        position: 'relative',
        overflowY: 'auto',
        scrollbarWidth: 'none',
        height: 'calc(100vh - 48px - 15px - 39px - 16px - 16px - 48px - 36.5px - 24px - 20px - 10px)'
      }}
      >
        {data.loading
          ? renderTableSkeleton(secFileColumns)
          : <DataGridPro
              density='compact'
              autoHeight
              getRowHeight={() => 'auto'}
              rows={rowData || []}
              columns={secFileColumns}
              disableRowSelectionOnClick
              getRowId={(row) => row?.id}
              pagination
              pageSizeOptions={[20]}
              initialState={{
                ...rowData?.initialState,
                pagination: { paginationModel: { pageSize: 20 } }
              }}
              sx={(theme) => ({
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                  py: '8px'
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none'
                },
                [`.${gridClasses.main}`]: {
                  overflow: 'unset'
                },
                [`.${gridClasses.columnHeaders}`]: {
                  position: 'sticky',
                  backgroundColor: theme.palette.background.paper,
                  top: 0,
                  zIndex: 1
                },
                [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                  color: '#74788d',
                  fontWeight: 600
                },
                [`.${gridClasses.footerContainer}`]: {
                  position: 'sticky',
                  bottom: '-1px',
                  backgroundColor: theme.palette.background.paper,
                  zIndex: 1
                }
              })}
            />}
      </Box>
      {
        openKeywordsPopup
          ? <Modal
              onClose={handlePopupClose}
              open={openKeywordsPopup}
            >
            <Box sx={{ ...corporateModalStyle, outline: 'none', minWidth: '400px', maxWidth: '600px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '5px', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }}>
                <Typography variant='h6' sx={{ ml: '5px', fontWeight: 400, color: '#34475A' }}>Manage Keywords</Typography>
                <IconButton aria-label='close' onClick={handlePopupClose}>
                  <Close />
                </IconButton>
              </Box>
              <StyledTradePopupBox
                className='table-responsive' sx={{
                  height: '600px',
                  scrollbarGutter: 'stable'
                }}
              >
                {
                  isKeywordLoading
                    ? renderTableSkeleton(keywordColumns)
                    : <DataGridPro
                        autoHeight
                        density='compact'
                        rows={rows || []}
                        columns={keywordColumns}
                        getRowId={(row) => row?.id}
                        editMode='row'
                        localeText={{ noRowsLabel: 'No Keywords Found' }}
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        onRowEditStop={handleRowEditStop}
                        processRowUpdate={processRowUpdate}
                        slots={{
                          toolbar: EditToolbar
                        }}
                        slotProps={{
                          toolbar: { setRows, setRowModesModel }
                        }}
                        pagination
                        pageSizeOptions={[10]}
                        initialState={{
                          ...rows?.initialState,
                          pagination: { paginationModel: { pageSize: 10 } }
                        }}
                        sx={(theme) => ({
                          '& .MuiInputBase-input': {
                            padding: '0 10px',
                            textTransform: 'uppercase'
                          },
                          '& .MuiDataGrid-cell:focus': {
                            outline: 'none'
                          },
                          [`.${gridClasses.main}`]: {
                            overflow: 'unset'
                          },
                          [`.${gridClasses.columnHeaders}`]: {
                            position: 'sticky',
                            backgroundColor: theme.palette.background.paper,
                            top: 0,
                            zIndex: 1
                          },
                          [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                            color: '#74788d',
                            fontWeight: 600
                          },
                          [`.${gridClasses.footerContainer}`]: {
                            position: 'sticky',
                            bottom: '-1px',
                            backgroundColor: theme.palette.background.paper,
                            zIndex: 1
                          }
                        })}
                      />
                }
              </StyledTradePopupBox>
              <Box sx={{ float: 'right' }}>
                <Button
                  disabled={isKeywordLoading || keywordApplying || compareKeywordsAndKeywordsCopy()}
                  type='submit'
                  variant='contained'
                  onClick={storeSchwabSECData}
                >
                  Apply
                </Button>
              </Box>
            </Box>
            </Modal>
          : ''
      }
    </>
  )
}

function EditToolbar (props) {
  const { setRows, setRowModesModel } = props

  const handleClick = () => {
    const id = randomId()
    setRows((oldRows) => [...oldRows, { id, keyword: '', isNew: true }])
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'keyword' }
    }))
  }

  return (
    <GridToolbarContainer>
      <Button color='primary' startIcon={<Add />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  )
}

export default SecFile

import { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import { yupResolver } from '@hookform/resolvers/yup'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import EditIcon from '@mui/icons-material/Edit'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import SaveIcon from '@mui/icons-material/Save'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  ThemeProvider,
  Typography,
  createTheme
} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import Papa from 'papaparse'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc' // Import the UTC plugin
import filesize from 'filesize'
import { v4 as uuidv4 } from 'uuid'
import { randomId } from '@mui/x-data-grid-generator'
import * as Yup from 'yup'
import addIcon from '../../../assets/images/icon-add-plus-circle.svg'
import fileIcon from '../../../assets/images/icon-file.svg'
import { useAuth } from '../../../contexts/AuthContext'
import SchwabDataPopup from '../../../components/SchwabDataPopup'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { isNumberOnly, isNumberWithSign, numberOnlyPasteHandler, numberWithSignPasteHandler } from '../../../utils/NumberUtils'
import { InputAdornmentTextField } from '../../AccountOnboarding/components/inputAdornmentTextfield'
import Loader from '../../Loader'
import '../import-portfolio.scss'
import { SuccessUploadModal } from './SuccessUploadModal'
import { SampleTaxlotTable } from './sampleTaxlotTable'
import { customTheme } from '../../../theme'
import { moduleConfig } from '../../../contexts/data'
import { ACCESS_LEVEL } from '../../../contstants/constants'
import InvalidRowEntriesPopup from '../../../components/InvalidRowEntriesPopup'
import { checkInstrumentSearchQuery } from '../../../utils/searchQueryUtils'

// Extend dayjs with the utc plugin
dayjs.extend(utc)

const UploadForm = ({ submitRef, getTaxlot, selectedAccount }) => {
  const { user, checkAccess } = useAuth()
  const { showError } = useErrorToast()
  const params = useParams()
  const validationSchema = Yup.object({
    uploadType: Yup.string(),
    totalPortfolioMarketValue: Yup.string(),
    totalCashValue: Yup.string(),
    securityTaxLotsFile: Yup.mixed().required('A file is required')
  })
  const [isFileUploading, setIsFileUploading] = useState(false)
  const [showSecondMsg, setShowSecondMsg] = useState(true)
  const [modalShow, setModalShow] = useState(false)
  const [successMessage, setSuccessMessage] = useState('Validating')
  const [isSecurityOptionsLoading, setIsSecurityOrCountryOptionsLoading] = useState(false)
  const [file, setFile] = useState(null)
  const [fileId, setFileId] = useState('')
  const [count, setCount] = useState(0)
  const [stagedData, setStagedData] = useState([])
  const [totalCashValue, setTotalCashValue] = useState()
  const [options, setOptions] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [getFDSource, setGetFDSource] = useState()
  const [showcashfield, setShowCashfield] = useState(false)
  const [showThirdMsg, setShowThirdMsg] = useState(false)
  const datasecure = localStorage.getItem('object')
  const [invalidStagedData, setInvalidStagedData] = useState([])
  const [invalidCopyData, setInvalidCopyData] = useState([])
  const [validStagedData, setValidStagedData] = useState([])
  const [showInvalid, setShowInvalid] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([15, 25, 100])
  const [anchorEl, setAnchorEl] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [filterColumn, setFilterColumn] = useState('name')
  const [filteredData, setFilteredData] = useState([])
  const [slicedData, setSlicedData] = useState([])
  const [isoCodeInputValue, setIsoCodeInputValue] = useState(null)
  const [isoCodeInputValueList, setIsoCodeInputValueList] = useState([])
  const [isoCodeEditIndex, setIsoCodeEditIndex] = useState([])
  const [countryOptions, setCountryOptions] = useState([])
  const [portfolioDate, setPortfolioDate] = useState()
  const [incorrectValue, setIncorrectValue] = useState(false)
  const [incorrectCountryCode, setIncorrectCountryCode] = useState(false)
  const [cashFieldError, setCashFieldError] = useState(false)
  const [cashFieldErrorMessage, setCashFieldErrorMessage] = useState('')
  const scrollToTopRef = useRef(null)
  const [isErrorModelOpen, setIsErrorModelOpen] = useState(false)
  const [errorModalContent, setErrorModalContent] = useState([])
  const [portfolioDataFromSchwab, setPortfolioDataFromSchwab] = useState({})
  const [validatedPortfolioData, setValidatedPortfolioData] = useState({})
  const [isSchwabDataModelOpen, setIsSchwabDataModelOpen] = useState(false)
  const [unsavedRowCount, setUnsavedRowCount] = useState(0) // same for valid and invalid rows

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target?.value))
    setPage(0)
  }

  const accountTypeCode = selectedAccount?.accountType
  const CheckAccountType = () => {
    if (accountTypeCode === 'TRANSITION') {
      setShowThirdMsg(false)
    } else {
      setShowThirdMsg(true)
    }
  }

  useEffect(() => {
    if (modalShow) {
      getTaxlot()
    }
  }, [modalShow])

  useEffect(() => {
    if (user) {
      getAccountFundingSource()
      fetchISOCountryCodeList()
    }
  }, [user])

  const {
    register,
    formState: { errors },
    setValue,
    control,
    watch
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      uploadType: 'manual'
    }
  })

  const uploadTypeWatch = watch('uploadType')

  const getAccountFundingSource = () => {
    API.get(
      'baseURL',
      `account-master/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/funding-sources/${params.accountId}`
    )
      .then((response) => {
        if (response && response?.data) {
          setGetFDSource(response?.data)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load funding source.')
      })
  }

  const addRowTable = () => {
    const data = {
      id: uuidv4(),
      symbol: '',
      newRow: true,
      shares: '1',
      name: '',
      purchaseCost: '1',
      selectedDate: new Date(),
      clicked: true,
      isoCountryCode: 'US',
      originalIsoCountryCode: 'US',
      schwabLotMv: null,
      schwabLastClosePrice: null,
      hasSecurityError: false,
      hasOtherFieldsError: false,
      purchaseDate: new Date().toISOString()
    }
    const newData = [data, ...stagedData]
    setStagedData(newData)
    setValidStagedData([data, ...validStagedData])
    // Update the total count
    setCount(newData?.length)

    // Reset pagination to the first page
    setPage(0)

    // Scroll to the top of the container
    if (scrollToTopRef.current) {
      scrollToTopRef.current.scrollTo(0, 0)
    }
    setUnsavedRowCount(prev => prev + 1)
  }
  const handleChangePage = (event, newPage, type) => {
    if (
      (newPage > page || newPage < page) &&
      !isDataRowValid(stagedData[page * rowsPerPage])
    ) {
      showError(
        'Please enter valid data in the current row before proceeding to the next page.'
      )
    } else {
      setPage(newPage)
    }
  }

  // Handle pagination for invalidStagedData similarly to stagedData
  const [slicedInvalidData, setSlicedInvalidData] = useState([])

  const isDataRowValid = (rowData) => {
    return Object?.values(rowData || {})?.every(
      (value) => value !== undefined && value !== ''
    )
  }

  // filtered by the search endpoint, so no need to do it again.
  const onSecurityNameChange = (query, index) => {
    setIsSecurityOrCountryOptionsLoading(true)
    const indexInStagedData = page * rowsPerPage + index
    if (checkInstrumentSearchQuery(query)) {
      API.get('baseUriTransactionalMaster', `transactional-master/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/instruments`, {
        queryStringParameters: { search: query }
      })
        .then((response) => {
          if (response?.data) {
            setOptions((prevOptions) => {
              const updatedSecurityOptions = [...prevOptions]
              updatedSecurityOptions[indexInStagedData] = [...response?.data]
              return updatedSecurityOptions
            })
          }
        })
        .catch((error) => {
          showError(error, false, {}, 'Failed to load security name.')
        })
        .finally(() => {
          setIsSecurityOrCountryOptionsLoading(false)
        })
    } else {
      setIsSecurityOrCountryOptionsLoading(false)
    }
  }

  const fetchISOCountryCodeList = () => {
    API.get('baseUriTransactionalMaster', `transactional-master/v1/${user.userGroup}/iso-country-codes`)
      .then((response) => {
        if (response?.data) {
          setCountryOptions(response?.data)
          setIsSecurityOrCountryOptionsLoading(false)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load country code.')
      })
  }

  const openModal = () => {
    document.body.classList.add('modal-open', 'pe-0')
    setShowModal(true)
  }

  const closeModal = () => {
    if (document.querySelectorAll('.invalid-entry')?.length > 0) {
      showError('Please correct data')
    } else {
      setShowModal(false)
      setTimeout(() => {
        document.body.classList.remove('modal-open', 'pe-0')
      }, 200)
    }
  }

  const formateToUsdCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  const csvConfig = {
    headers: [
      {
        name: 'Symbol',
        inputName: 'Symbol',
        required: true
      },
      {
        name: 'Purchase Date',
        inputName: 'purchaseDate',
        required: true
      },
      {
        name: 'Total Purchase Cost',
        inputName: 'purchaseCost',
        required: true
      },
      {
        name: 'Quantity / Shares',
        inputName: 'Quantity / Shares',
        required: true
      },
      {
        name: 'ISO Country Code',
        inputName: 'isoCountryCode',
        required: false
      }
    ]
  }

  const handleUpload = async () => {
    let updatedCsvData
    const fileSize = (file.size / 1024).toFixed(2) // KB
    if (file.type !== 'text/csv' && file.type !== 'application/vnd.ms-excel') {
      showError('Please upload csv file')
      setFile('')
    } else if (fileSize > 1024) {
      showError('Please upload file size less than 1 MB')
      setFile('')
    } else {
      // Passing file data to parse using Papa.parse
      Papa.parse(file, {
        skipEmptyLines: true,
        complete: (results) => {
          const errors = {
            headerErrors: [],
            rowErrors: []
          }
          const portfolioJsonData = []
          if (results?.data?.length) {
            const headersArray = results.data[0]
            const originalFileContent = results.data

            // find mandatory columns are present or not and store error message for missing header
            const mandatoryColumns = csvConfig?.headers?.filter(column => column.required)
            const validationOfColumns = mandatoryColumns?.every(ele => headersArray.includes(ele.name))
            if (!validationOfColumns) {
              const headerErrors = mandatoryColumns?.filter(column => !headersArray.includes(column.name)).map(column => `Header name ${column.name} is not correct or missing`)
              errors.headerErrors = [...headerErrors]
            }

            // store index of column position in file data
            const positionOfColumnInFileJson = {}
            for (const column of csvConfig?.headers) {
              // ignore column without target field
              if (column?.inputName) {
                positionOfColumnInFileJson[column?.inputName] = headersArray.findIndex(name => name === column?.name)
              }
            }

            for (let rowIndex = 1; rowIndex < originalFileContent.length; rowIndex++) {
              const columns = originalFileContent[rowIndex]
              const rowJson = {}
              csvConfig?.headers?.forEach(header => {
                // ignore column without target field
                if (header?.inputName) {
                  rowJson[header?.inputName] = (positionOfColumnInFileJson && columns[positionOfColumnInFileJson[header?.inputName]]) || null
                }
              })
              // if all mandatory column are present, store row
              if (mandatoryColumns.every(header => rowJson[header?.inputName])) {
                portfolioJsonData.push({ ...rowJson })
              } else {
                errors.rowErrors = [...errors?.rowErrors, `Mandatory field ${mandatoryColumns.map(column => column?.name || '').join(' or ')} is missing in the row ${rowIndex}`]
              }
            }
            if ((errors?.rowErrors?.length > 0) || (errors?.headerErrors?.length > 0)) {
              setErrorModalContent(errors)
              setIsErrorModelOpen(true)
            } else {
              setErrorModalContent([])
              setIsErrorModelOpen(false)
              if (Array.isArray(portfolioJsonData) && portfolioJsonData?.length > 0) {
                setIsFileUploading(true)
                updatedCsvData = portfolioJsonData?.map((item) => {
                  if (item?.isoCountryCode === null || item?.isoCountryCode === '') {
                    item.isoCountryCode = 'US'
                  }
                  return item
                })
                if (totalCashValue) {
                  if (totalCashValue === '') {
                    getSecurityDetails(updatedCsvData || portfolioJsonData)
                  } else {
                    // const isCashOrUSDExist = portfolioJsonData?.some(
                    //   (item) => item?.Symbol === '__USD' || item?.Symbol === '__CASH'
                    // )
                    // let updatedCsvData = []
                    // if (isCashOrUSDExist) {
                    //   updatedCsvData = portfolioJsonData?.map((item) => {
                    //     if (item?.Symbol === '__USD' || item?.Symbol === '__CASH') {
                    //       return {
                    //         ...item,
                    //         purchaseCost: 1,
                    //         'Quantity / Shares': totalCashValue,
                    //         isoCountryCode: 'US'
                    //       }
                    //     } else {
                    //       return item
                    //     }
                    //   })
                    // } else {
                    // Format the current date to YYYYMMDD
                    const formattedDate = dayjs().format('YYYYMMDD')
                    updatedCsvData = [
                      {
                        Symbol: '__USD',
                        purchaseDate: formattedDate,
                        purchaseCost: 1,
                        'Quantity / Shares': totalCashValue,
                        isoCountryCode: 'US'
                      },
                      ...(updatedCsvData || portfolioJsonData)
                    ]
                  }
                  getSecurityDetails(updatedCsvData)
                  // }
                } else {
                  setIsFileUploading(true)
                  getSecurityDetails(updatedCsvData || portfolioJsonData)
                }
              } else {
                showError('No rows available in the uploaded file. Please re-upload file with rows')
              }
            }
          }
        }
      })
    }
  }

  const getSecurityDetails = (csvDataProcessed) => {
    if (!Array.isArray(csvDataProcessed)) {
      return
    }
    let hasError = false
    let id
    API.post(
      'baseURL',
      `account-master/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/account-imports/preprocessed-data`,
      { body: [...csvDataProcessed] }
    )
      .then((response) => {
        if (response?.data) {
          const template = response?.data?.map((data, index) => {
            return { ...data, clicked: false, id: uuidv4(), hasSecurityError: false, hasOtherFieldsError: false }
          })
          const invalidEntries = template?.filter((item) => item?.name === null)
          setInvalidStagedData(invalidEntries)
          setInvalidCopyData(invalidEntries.map((obj) => ({ ...obj }))) // deep copy
          const validEntries = template?.filter((item) => item?.name !== null)
          const fileId = template[0]?.fileId || 'no file ID'
          setFileId(fileId)
          if (validEntries?.length === 0 && invalidEntries?.length > 0) {
            setShowInvalid(true)
          }
          const formattedData = validEntries.map((e, index) => {
            return {
              id: uuidv4(),
              symbol: e?.symbol,
              purchaseDate: e?.purchaseDate,
              purchaseCost: (e?.symbol === '__CASH' || e?.symbol === '__USD') ? 1 : e?.purchaseCost,
              shares: e?.shares,
              isoCountryCode: e?.isoCountryCode,
              originalIsoCountryCode: e?.isoCountryCode,
              hasSecurityError: e?.hasSecurityError,
              hasOtherFieldsError: e?.hasOtherFieldsError,
              instrId: e?.instrId,
              name: e?.name,
              clicked: e?.clicked
            }
          })
          setStagedData(formattedData)
          setValidStagedData(formattedData.map((obj) => ({ ...obj }))) // deep copy
          openModal()
          setIsFileUploading(false)
        }
      })
      .catch((error) => {
        hasError = true
        showError(error, false, {}, 'Failed to load security taxlot.')
        setIsFileUploading(false)
      })
  }

  const checkStagingData = () => {
    const uniqueValues = stagedData?.filter((item) => item?.name === 'Cash' || item?.name === 'Cash (__USD)')
    const emptyFields = stagedData?.filter(
      (item) => item?.symbol === '' || item?.name === null || item?.name === ''
    )?.length
    const zeroValue = stagedData
      .slice()
      .filter(
        (item) =>
          !(item?.symbol === '__USD' || item?.symbol === '__CASH') &&
          (item?.shares <= 0 || item?.shares === '')
      )?.length
    const zeroPurchasevalue = stagedData
      .slice()
      .filter(
        (item) => item.purchaseCost < 0 || item.purchaseCost === ''
      )?.length
    if (emptyFields && emptyFields > 0) {
      showError('Please check for errors and enter valid values')
    } else if (zeroValue && zeroValue > 0) {
      showError('Shares must be greater than 0')
    } else if (zeroPurchasevalue && zeroPurchasevalue > 0) {
      showError('Total Purchase Cost must be greater than 0')
    }
    // commenting duplicate check for cash
    // else if (uniqueValues?.length > 1) {
    //   const removeDuplicates = stagedData.filter(
    //     (item) => item.symbol !== '__USD'
    //   )
    //   showError('Please remove duplicate Cash value')
    //   setShowInvalid(true)
    //   setStagedData(removeDuplicates)
    //   setInvalidStagedData(uniqueValues)
    //   showError('Please remove duplicate Cash value')
    // }
    else {
      setIsFileUploading(true)
      const formattedData = stagedData?.map((e) => {
        return {
          symbol: e?.symbol === '__CASH' ? '__USD' : e?.symbol,
          purchase_date: dayjs(e?.purchaseDate).format('YYYY-MM-DD'),
          purchase_cost: (e?.symbol === '__CASH' || e?.symbol === '__USD') ? 1 : e?.purchaseCost,
          shares: e?.shares,
          instrId: e?.instrId,
          ...{
            ...uploadTypeWatch === 'urlupload'
              ? {
                  org_purchase_price: e?.orgPurchasePrice,
                  schwabLotMv: e?.schwabLotMv,
                  schwabLastClosePrice: e?.schwabLastClosePrice,
                  org_purchase_price_unadj: e?.orgPurchasePriceUnadj ?? ((e?.symbol === '__CASH' || e?.symbol === '__USD') ? 1 : e?.purchaseCost),
                  purchase_cost_unadj: e?.purchaseCostUnadj ?? ((e?.symbol === '__CASH' || e?.symbol === '__USD') ? 1 : e?.purchaseCost),
                  shares_unadj: e?.sharesUnadj ?? e?.shares
                }
              : {}
          }
        }
      })
      const requestBody = {
        securities: formattedData,
        ...(uploadTypeWatch === 'urlupload'
          ? {
              schwabPortfolioMarketValue: portfolioDataFromSchwab?.schwabPortfolioMarketValue,
              schwabTotalLots: portfolioDataFromSchwab?.schwabTotalLots
            }
          : {})
      }
      API.post(
        'baseURL',
        `account-master/v1/${user?.userGroup}/account-imports/submit/${fileId}?validate-only=true&account-id=${params?.accountId}`,
        { body: requestBody }
      )
        .then((response) => {
          if (response && response?.data) {
            setValidatedPortfolioData({
              ...response?.data,
              portfolio: response?.data?.portfolio?.map(item => ({
                ...item,
                id: randomId()
              }))
            })
            setIsSchwabDataModelOpen(true)
          }
        })
        .catch((error) => {
          if (Array.isArray(error.response?.data?.errorInfo?.userMessage)) {
            error.response?.data?.errorInfo?.userMessage?.forEach((element) => {
              showError({ message: element.message }, false, {}, 'Failed to upload validate taxlot.')
            })
          } else {
            showError(error, false, {}, 'Failed to upload validate taxlot.')
          }
        })
        .finally(() => setIsFileUploading(false))
    }
  }

  const submitTaxlotData = () => {
    setIsFileUploading(true)
    const formattedData = stagedData?.map((e) => {
      return {
        symbol: e?.symbol === '__CASH' ? '__USD' : e?.symbol,
        purchase_date: dayjs(e?.purchaseDate).format('YYYY-MM-DD'),
        purchase_cost: (e?.symbol === '__CASH' || e?.symbol === '__USD') ? 1 : e?.purchaseCost,
        shares: e?.shares,
        instrId: e?.instrId,
        ...{
          ...uploadTypeWatch === 'urlupload'
            ? {
                org_purchase_price: e?.orgPurchasePrice,
                schwabLotMv: e?.schwabLotMv,
                schwabLastClosePrice: e?.schwabLastClosePrice,
                org_purchase_price_unadj: e?.orgPurchasePriceUnadj ?? ((e?.symbol === '__CASH' || e?.symbol === '__USD') ? 1 : e?.purchaseCost),
                purchase_cost_unadj: e?.purchaseCostUnadj ?? ((e?.symbol === '__CASH' || e?.symbol === '__USD') ? 1 : e?.purchaseCost),
                shares_unadj: e?.sharesUnadj ?? e?.shares
              }
            : {}
        }
      }
    })
    const requestBody = {
      securities: formattedData,
      dbPortfolioMarketValue: validatedPortfolioData?.dbPortfolioMarketValue,
      ...(uploadTypeWatch === 'urlupload'
        ? {
            schwabPortfolioMarketValue: portfolioDataFromSchwab?.schwabPortfolioMarketValue,
            schwabTotalLots: portfolioDataFromSchwab?.schwabTotalLots,
            cashSwappedData: portfolioDataFromSchwab?.cashSwappedData
          }
        : {})
    }
    API.post(
      'baseURL',
      `account-master/v1/${user?.userGroup}/account-imports/submit/${fileId}?account-id=${params?.accountId}`,
      { body: requestBody }
    )
      .then((response) => {
        if (response && response?.data) {
          setModalShow(true)
          setShowSecondMsg(true)
          setSuccessMessage(response?.message)
          setValue('totalPortfolioMarketValue', response?.data?.dbPortfolioMarketValue || 0)
          if (stagedData?.some((item) => item?.symbol === '__USD')) {
            const totalCashValue = stagedData
              .filter((item) => item?.symbol === '__USD' || item?.symbol === '__CASH')
              .reduce((init, item) => init + (1 * parseFloat(item?.shares)), 0)
            setTotalCashValue(totalCashValue.toString())
          }
          setTimeout(() => {
            closeModal()
            setIsSchwabDataModelOpen(false)
          }, 2000)
        }
      })
      .catch((error) => {
        if (Array.isArray(error.response?.data?.errorInfo?.userMessage)) {
          error.response?.data?.errorInfo?.userMessage?.forEach((element) => {
            showError({ message: element.message }, false, {}, 'Failed to load schwab taxlot.')
          })
        } else {
          showError(error, false, {}, 'Failed to load schwab taxlot.')
        }
      })
      .finally(() => setIsFileUploading(false))
  }

  const handleCancel = () => {
    setFile('')
    setStagedData([])
    setShowCashfield(false)
    setValue('totalPortfolioMarketValue', '')
    setValue('securityTaxLotsFile', '')
  }

  const onChangeHandler = (event, value, index, rowId) => {
    const indexInStagedData = page * rowsPerPage + index
    if (showInvalid === true) {
      const updatedInvalidStagedData = [...invalidStagedData]
      if (value) {
        updatedInvalidStagedData[indexInStagedData].name = value?.name
        updatedInvalidStagedData[indexInStagedData].symbol = value?.localSymbol
        updatedInvalidStagedData[indexInStagedData].instrId = value?.instrId
        updatedInvalidStagedData[indexInStagedData].isoCountryCode = value?.isoCountryCode
        updatedInvalidStagedData[indexInStagedData].originalIsoCountryCode = value?.isoCountryCode
        updatedInvalidStagedData[indexInStagedData].hasSecurityError = false
        setInvalidStagedData(updatedInvalidStagedData)
      }
      setIsoCodeInputValue(updatedInvalidStagedData[indexInStagedData]?.isoCountryCode)
    } else {
      const indexInStagedData = stagedData.findIndex(obj => obj?.id === rowId)
      const updatedStagedData = [...stagedData]
      if (value) {
        updatedStagedData[indexInStagedData].name = value?.name
        updatedStagedData[indexInStagedData].symbol = value?.localSymbol
        updatedStagedData[indexInStagedData].instrId = value?.instrId
        updatedStagedData[indexInStagedData].isoCountryCode = value?.isoCountryCode
        updatedStagedData[indexInStagedData].originalIsoCountryCode = value?.isoCountryCode
        updatedStagedData[indexInStagedData].hasSecurityError = false
        setStagedData(updatedStagedData)
      }

      const updatedIsoCountryCodes = [...isoCodeInputValueList]
      updatedIsoCountryCodes[index] = value?.isoCountryCode
      setIsoCodeInputValueList(updatedIsoCountryCodes)

      setIsoCodeInputValue(updatedStagedData[indexInStagedData]?.isoCountryCode)
    }
    setIncorrectCountryCode(false)
  }

  const onISOCodeChangeHandler = (event, value, index, rowId) => {
    if (value) {
      if (showInvalid === true) {
        const indexInStagedData = page * rowsPerPage + index
        const updatedInvalidStagedData = [...invalidStagedData]
        if (updatedInvalidStagedData[indexInStagedData]?.originalIsoCountryCode === value?.code) {
          updatedInvalidStagedData[indexInStagedData].isoCountryCode = value?.code
          updatedInvalidStagedData[indexInStagedData].hasSecurityError = false
          setIncorrectCountryCode(false)
        } else {
          updatedInvalidStagedData[indexInStagedData].isoCountryCode = value?.code
          updatedInvalidStagedData[indexInStagedData].hasSecurityError = true
          setIncorrectCountryCode(true)
        }
        setInvalidStagedData(updatedInvalidStagedData)
      } else {
        const updatedStagedData = [...stagedData]
        const indexInStagedData = stagedData.findIndex(obj => obj?.id === rowId)
        if (updatedStagedData[indexInStagedData]?.originalIsoCountryCode === value?.code) {
          updatedStagedData[indexInStagedData].isoCountryCode = value?.code
          updatedStagedData[indexInStagedData].hasSecurityError = false
          setIncorrectCountryCode(false)
        } else {
          updatedStagedData[indexInStagedData].isoCountryCode = value?.code
          updatedStagedData[indexInStagedData].hasSecurityError = true
          setIncorrectCountryCode(true)
        }
        setStagedData(updatedStagedData)
      }
    }
  }

  function formatDate (dateString) {
    return dayjs(dateString).format('YYYY-MM-DD')
  }

  const filterInvalid = () => {
    setShowInvalid(!showInvalid)
    setOptions([])
    setPage(0) // Reset page to 0 when switching between valid and invalid entries
  }

  if (showInvalid) {
    if (invalidStagedData?.length === 0) {
      setShowInvalid(false)
      setUnsavedRowCount(0)
    }
  }
  const closePopupDialog = () => {
    setShowInvalid(false)
    setShowModal(false)
    setPage(0)
    setSearchTerm('')
    setAnchorEl(null)
    setSelectedRows([])
    setIncorrectCountryCode(false)
    handleCancel()
    setUnsavedRowCount(0)
  }

  const handleFilterClick = (event) => {
    setAnchorEl(event?.currentTarget)
    setFilterColumn('name')
  }
  const handleFilterSymbol = (event) => {
    setAnchorEl(event?.currentTarget)
    setFilterColumn('symbol')
  }
  const handleFilterClose = () => {
    setAnchorEl(null)
  }

  const handleFilterChange = (event) => {
    setSearchTerm(event?.target.value)
  }
  const theme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '8px' // customize padding here
          }
        }
      },
      MuiTablePagination: {
        styleOverrides: {
          displayedRows: {
            margin: 0
          },
          selectLabel: {
            margin: 0
          }
        }
      },
      MuiStack: {
        styleOverrides: {
          root: {
            padding: '0px !important',
            float: 'right'
          }
        }
      }
    }
  })
  const isButtonDisabled = invalidStagedData?.length === 0

  const applyFilter = (array) => {
    if (!searchTerm) {
      setFilteredData([])
      return
    }
    const filteredData = array?.filter((item) => {
      if (filterColumn === 'name') {
        return item?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      } else {
        return item?.symbol?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      }
    })
    setFilteredData(filteredData)
    if (filteredData.length < (page * rowsPerPage)) {
      setPage(Math.floor(filteredData.length / rowsPerPage))
    }
  }

  useEffect(() => {
    applyFilter(stagedData)
  }, [searchTerm, filterColumn])

  useEffect(() => {
    if (showInvalid) {
      const slicedInvalidData = invalidStagedData?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      )
      // Update sliced data state
      setSlicedInvalidData(slicedInvalidData)
      return
    }
    if (!searchTerm) {
      const slicedData = stagedData?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      )
      setSlicedData(slicedData)
    } else {
      const slicedData = filteredData?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      )
      setSlicedData(slicedData)
    }
  }, [page, rowsPerPage, stagedData, invalidStagedData, filteredData, showInvalid])

  const handleFilterClear = () => {
    setSearchTerm('')
    setAnchorEl(null)
  }

  const [selectedRows, setSelectedRows] = useState([])

  // Handle checkbox click
  const handleCheckboxClick = (event, index) => {
    setSelectedRows((prevSelectedRows) => {
      if (event?.target?.checked) {
        // Add index to selected rows if it's not already included
        return [...prevSelectedRows, index]
      } else {
        // Remove index from selected rows
        return prevSelectedRows?.filter((i) => i !== index)
      }
    })
  }

  // Handle delete all button click
  const handleDeleteAllClick = () => {
    let rowsCntInEditAndCheck = 0
    const filteredData = invalidStagedData?.filter((data, index) => {
      const doesInclude = selectedRows?.includes(index)
      if (data?.clicked === true && doesInclude) {
        rowsCntInEditAndCheck += 1
      }
      return !doesInclude
    })
    setUnsavedRowCount(prev => prev - rowsCntInEditAndCheck)
    setInvalidStagedData(filteredData)
    setSelectedRows([])
    if (filteredData.length <= (page * rowsPerPage)) {
      setPage(Math.max(0, Math.ceil(filteredData.length / rowsPerPage) - 1))
    }
  }

  const handleSelectAllClick = (event) => {
    const invalidData = [...invalidStagedData]
    const { checked } = event.target
    if (checked) {
      const indices = (invalidData && invalidData?.length ? invalidData : [])?.map((_, index) => index)
      setSelectedRows(indices)
    } else {
      setSelectedRows([])
    }
  }

  const fetchPortfolioFromSchwab = async () => {
    try {
      setIsFileUploading(true)
      const queryParams = {
        accountId: params?.accountId
      }
      API.get('baseURL', `account-master/v1/${user?.userGroup}/schwab-portfolio/import`, { queryStringParameters: queryParams })
        .then((response) => {
          if (response && response?.data) {
            setPortfolioDataFromSchwab(response?.data)
            if (response?.data?.fundingSource) {
              setGetFDSource(response?.data?.fundingSource)
            }
            if (response?.data.portfolioDate) {
              setPortfolioDate(response?.data?.portfolioDate)
            }
            if (response?.data?.securities) {
              const template = response?.data?.securities?.map((data, index) => {
                return { ...data, clicked: false, id: uuidv4(), hasSecurityError: false, hasOtherFieldsError: false }
              })
              const invalidEntries = template?.filter((item) => item?.name === null)
              setInvalidStagedData(invalidEntries)
              setInvalidCopyData(invalidEntries.map((obj) => ({ ...obj }))) // deep copy
              const validEntries = template?.filter((item) => item?.name !== null)
              if (template && template?.length) {
                setFileId(template[0]?.fileId)
              }
              setIsFileUploading(false)
              const formattedData = validEntries?.map((e, index) => {
                return {
                  id: uuidv4(),
                  symbol: e.symbol,
                  purchaseDate: e.purchaseDate,
                  purchaseCost: (e.symbol === '__CASH' || e.symbol === '__USD') ? 1 : e?.purchaseCost,
                  shares: e?.shares,
                  orgPurchasePrice: e.orgPurchasePrice,
                  name: e.name,
                  hasSecurityError: e?.hasSecurityError,
                  hasOtherFieldsError: e?.hasOtherFieldsError,
                  isoCountryCode: 'US',
                  originalIsoCountryCode: e?.isoCountryCode || 'US',
                  instrId: e?.instrId,
                  clicked: e.clicked,
                  schwabLotMv: e?.schwabLotMv,
                  schwabLastClosePrice: e?.schwabLastClosePrice,
                  orgPurchasePriceUnadj: e?.orgPurchasePriceUnadj,
                  purchaseCostUnadj: e?.purchaseCostUnadj,
                  sharesUnadj: e?.sharesUnadj
                }
              })
              setStagedData(formattedData)
              setValidStagedData(formattedData.map((obj) => ({ ...obj }))) // deep copy
              if (response?.data?.fundingSource === 'CASH' && response?.data?.securities?.some((item) => item?.symbol === '__USD')) {
                const purchaseCost = response?.data?.securities
                  .filter((item) => item?.symbol === '__USD')
                  .reduce((init, item) => init + (item?.shares), 0)
                setTotalCashValue(purchaseCost?.toString())
              }
              openModal()
            }
          }
        })
        .catch((error) => {
          setIsFileUploading(false)
          showError(error, false, {}, 'Failed to fetch schwab portfolio.')
        })
    } catch (error) {
      showError(error, false, {}, 'Failed to fetch schwab portfolio.')
    }
  }

  // Helper function to check if a string is a valid number
  const isValidNumber = (value) => {
    // Use parseFloat to check if the value is a valid number
    const parsedValue = parseFloat(value)
    return !isNaN(parsedValue)
  }

  const checkValidStagedDataPagesEntries = (stagedData) => {
    const slicedData = stagedData?.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    )
    if (slicedData?.length === 0) {
      setPage(Math.max(0, page - 1))
    }
  }

  const checkInvalidStagedDataPagesEntries = (invalidStagedData) => {
    const slicedData = invalidStagedData?.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    )
    if (slicedData?.length === 0) {
      setPage(Math.max(0, page - 1))
    }
  }

  return (
    <Box className='uploadImportDiv'>
      {isFileUploading ? <Loader /> : ''}
      <Box
        component='form' noValidate onSubmit={(event) => {
          event.preventDefault() // Prevents page reload on form submission
        }}
      >
        <Button ref={submitRef} type='submit' style={{ display: 'none' }} />
        <Grid container>
          <Grid item xs={12}>
            <Box width='100%' my={1}>
              {
                  accountTypeCode === 'TRADING'
                    ? <Controller
                        name='uploadType'
                        control={control}
                        render={({ field }) => (
                          <RadioGroup
                            row
                            aria-labelledby='demo-row-radio-buttons-group-label'
                            value={field.value || 'manual'}
                            {...field}
                            onChange={(e) => {
                              field.onChange(e)
                              setValue('userType', e.target.value)
                              setFile('')
                              setFileId('')
                              setStagedData([])
                              setValue('totalPortfolioMarketValue', '')
                              setTotalCashValue('')
                              if (e.target.value === 'urlupload') {
                                fetchPortfolioFromSchwab()
                              }
                            }}
                          >
                            <FormControlLabel
                              value='manual'
                              control={<Radio size='small' />}
                              label='Manually Upload Portfolio'
                            />
                            <FormControlLabel
                              value='urlupload'
                              control={<Radio size='small' onClick={() => uploadTypeWatch === 'urlupload' && !stagedData?.length ? fetchPortfolioFromSchwab() : ''} />}
                              label='Import from API'
                            />
                          </RadioGroup>
                        )}
                      />
                    : ''
                }
            </Box>
          </Grid>
        </Grid>
        {(uploadTypeWatch === 'manual' || (uploadTypeWatch === 'urlupload' && stagedData?.length))
          ? <>
            <Grid container rowGap={1} columnGap={2}>
              <Grid item md={6} lg={3}>
                <FormControl fullWidth>
                  <Controller
                    name='totalPortfolioMarketValue'
                    control={control}
                    render={({ field }) => (
                      <InputAdornmentTextField
                        id='total-portfolio-market-value'
                        type='text'
                        label='Total Portfolio Market Value'
                        name='totalPortfolioMarketValue'
                        readOnly
                        disabled
                        {...register('totalPortfolioMarketValue')}
                        onChange={field.onChange}
                        value={(field?.value && !isNaN(field?.value)) ? Number(field?.value).toFixed(2) : ''}
                        aria-describedby='total-portfolio-market-value'
                        sx={{
                          width: '100%'
                        }}
                        adornment={<AttachMoneyOutlinedIcon />}
                      />
                    )}
                  />
                  {errors.totalPortfolioMarketValue && (
                    <FormHelperText error>{errors.totalPortfolioMarketValue.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={6} lg={3}>
                <FormControl fullWidth>
                  <InputAdornmentTextField
                    id='total-cash-value'
                    name='totalCashValue'
                    type='text'
                    label='Total Cash Value *'
                    value={totalCashValue}
                    onKeyDown={(event) => isNumberWithSign(event)}
                    onPaste={numberWithSignPasteHandler}
                    onChange={(event) => {
                      if (event.target.value === '0') {
                        setCashFieldError(true)
                        setCashFieldErrorMessage('Total Cash Value Should be greater than 0')
                      } else {
                        setCashFieldError(false)
                        setCashFieldErrorMessage('')
                      }
                      setTotalCashValue(event.target.value)
                    }}
                    sx={{
                      width: '100%'
                    }}
                    adornment={<AttachMoneyOutlinedIcon />}
                    error={Boolean(errors.totalCashValue) || cashFieldError}
                    helperText={(errors.totalCashValue && errors.totalCashValue.message) || cashFieldErrorMessage}
                  />
                  {errors.totalCashValue && (
                    <FormHelperText error>{errors.totalCashValue.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={12} lg={12}>
                <FormControl fullWidth sx={{ marginTop: '16px', marginBottom: '24px' }}>
                  <Typography sx={{ color: '#74788d', fontSize: '16px', fontWeight: 600 }} mb={1}>Upload Security Tax Lots</Typography>
                  <Box
                    className='import-portfolio file-upload-block'
                    onDragEnter={(e) => e.preventDefault()}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => {
                      e.preventDefault()
                      const droppedFile = e?.dataTransfer?.files[0]
                      setFile(droppedFile)
                    }}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
                  >
                    <Box>
                      <input
                        type='file'
                        style={{ position: 'absolute', width: '100%', height: '100%', cursor: 'pointer' }}
                        disabled={cashFieldError}
                        {...register('securityTaxLotsFile')}
                        onChange={(e) => {
                          setFile(e?.target?.files[0])
                          e.target.value = ''
                        }}
                        accept='.csv'
                      />
                      <Box sx={{ display: 'flex' }}>
                        <img
                          style={{ cursor: 'pointer' }}
                          src={addIcon}
                          alt='add'
                          height={30}
                          width={30}
                        />
                        <Typography
                          pl={1}
                          sx={{ color: '#34475a', fontWeight: 500 }}
                        >
                          Drop your files here or browse
                        </Typography>
                      </Box>
                    </Box>
                    {file && (
                      <Box className='uploaded-file' sx={{ width: '420px', maxWidth: '100%', height: '125px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box className='btn-soft-blue' sx={{ backgroundColor: 'rgba(23, 92, 194, 0.5)', padding: '7px', color: 'white', width: '24px', height: '24px', display: 'flex', justifyContent: 'center' }} align='center' mr={1}>
                            <img
                              src={fileIcon}
                              alt=''
                              height={10}
                              width={10}
                            />
                          </Box>
                          <Box>
                            <Typography>Name: {file?.name}</Typography>
                            <Typography>Size: {filesize(file?.size)}</Typography>
                            <Typography>Last Modified: {file?.lastModifiedDate?.toLocaleDateString()}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex' }} p={1}>
                            <Button
                              className=''
                              type='button'
                              onClick={handleUpload}
                              size='small'
                              variant='contained'
                              disabled={cashFieldError}
                              sx={{ fontSize: '12px !important' }}
                            >
                              Upload
                            </Button>
                            <Button
                              className='btnRemove btn-soft-red rounded-pill'
                              type='button'
                              onClick={handleCancel}
                              size='small'
                              sx={{ fontSize: '12px !important', marginLeft: '10px' }}
                            >
                              Cancel
                            </Button>
                          </Box>
                          {isFileUploading ? <Loader /> : null}
                        </Box>
                      </Box>
                    )}
                  </Box>
                  {errors?.totalPortfolioMarketValue && (
                    <FormHelperText error>{errors.totalPortfolioMarketValue.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            </>
          : ''}
      </Box>
      {uploadTypeWatch === 'manual' ? <SampleTaxlotTable /> : ''}
      <Dialog open={showModal} onClose={closePopupDialog} fullScreen>
        <DialogTitle>
          <IconButton
            onClick={closePopupDialog}
            style={{ position: 'absolute', top: 0, right: 0 }}
          >
            <CloseIcon />
          </IconButton>
            {uploadTypeWatch === 'urlupload' && stagedData?.length && portfolioDate ? <h6 style={{ margin: 0, fontSize: '16px' }}>Latest Portfolio Date: {portfolioDate}</h6> : ''}
            {portfolioDataFromSchwab?.cashSwapReasonMessage
              ? <Alert
                  severity='warning'
                  icon={<InfoOutlinedIcon fontSize="small" />}
                  sx={{
                    position: 'absolute',
                    marginBottom: '10px',
                    padding: '1px 8px',
                    right: '34px',
                    top: '6px',
                    zIndex: 98,
                    '& .MuiAlert-icon': {
                      padding: '3px 0'
                    },
                    '& .MuiAlert-message': {
                      padding: '3px 0'
                    }
                  }}
                >
                {portfolioDataFromSchwab?.cashSwapReasonMessage}
                </Alert>
              : ''}
        </DialogTitle>
        <DialogContent ref={scrollToTopRef}>
          <TableContainer>
            <ThemeProvider theme={theme}>
              <Table
                fullWidth
                stickyHeader
                sx={{
                  '.MuiTableCell-root': {
                    padding: '0px 8px !important'
                  }
                }}
              >
                <TableHead style={{ font: 'Open Sans', fontWeight: 600 }}>
                  {showInvalid
                    ? <TableRow>
                      <TableCell>
                        <Checkbox
                          checked={selectedRows?.length === invalidStagedData?.length}
                          indeterminate={selectedRows && selectedRows?.length ? selectedRows?.length !== invalidStagedData?.length : false}
                          onChange={handleSelectAllClick}
                        />
                      </TableCell>
                      <TableCell variant='head' style={{ paddingLeft: '27px' }}>
                        Name
                      </TableCell>
                      <TableCell variant='head'>Symbol</TableCell>
                      <TableCell variant='head' align='right'>Shares</TableCell>
                      <TableCell variant='head' align='right'>Total Purchase Cost</TableCell>
                      <TableCell variant='head' align='right'>Purchase Date</TableCell>
                      <TableCell variant='head' align='right'>ISO Country Code</TableCell>
                      <TableCell variant='head' align='right'>Actions</TableCell>
                      </TableRow>
                    : <TableRow>
                      <TableCell variant='head' style={{ paddingLeft: '27px' }}>
                        Name
                        {filterColumn === 'name' && searchTerm?.length > 0 && (
                          <FilterAltIcon
                            style={{
                              marginLeft: 5,
                              color: 'grey',
                              height: 15
                            }}
                          />
                        )}
                        <IconButton
                          aria-label='edit'
                          size='small'
                          onClick={handleFilterClick}
                        >
                          <MoreVertIcon sx={{ height: 20 }} />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleFilterClose}
                        >
                          <MenuItem sx={{ height: 40, background: 'white' }}>
                            <IconButton onClick={handleFilterClear}>
                              <CloseIcon />
                            </IconButton>
                            <Input
                              placeholder={`Filter by ${filterColumn}`}
                              value={searchTerm}
                              onChange={handleFilterChange}
                            />
                          </MenuItem>
                        </Menu>
                      </TableCell>
                      <TableCell>
                        Symbol{' '}
                        {filterColumn === 'symbol' &&
                          searchTerm?.length > 0 && (
                            <FilterAltIcon
                              style={{
                                marginLeft: 5,
                                color: 'grey',
                                height: 15
                              }}
                            />
                        )}
                        <IconButton
                          aria-label='edit'
                          size='small'
                          onClick={handleFilterSymbol}
                        >
                          <MoreVertIcon sx={{ height: 20 }} />
                        </IconButton>
                      </TableCell>
                      <TableCell align='right'>Shares</TableCell>
                      <TableCell align='right'>Total Purchase Cost</TableCell>
                      <TableCell align='right'>Purchase Date</TableCell>
                      <TableCell align='right'>ISO Country Code</TableCell>
                      <TableCell align='right'>Actions</TableCell>
                      </TableRow>}
                </TableHead>
                <TableBody>
                  {showInvalid
                    ? <>
                      {slicedInvalidData?.map(
                        (data, index) => {
                          const indexInStagedData = page * rowsPerPage + index
                          return (
                            <TableRow
                              key={index}
                              className={
                                (data?.name === null || data?.purchaseCost < 0) &&
                                  !(data?.symbol === '__USD' && data?.shares < 0)
                                  ? 'invalid-entry'
                                  : ''
                              }
                              onDoubleClick={() => {
                                const updatedInvalidStagedData = [...invalidStagedData]
                                const originalIndex = invalidStagedData?.findIndex(
                                  (item) => item.id === data?.id
                                )
                                if (updatedInvalidStagedData[originalIndex] && !updatedInvalidStagedData[originalIndex]?.clicked) {
                                  updatedInvalidStagedData[originalIndex].clicked = true
                                  setUnsavedRowCount(prev => prev + 1)
                                }
                                setInvalidStagedData(updatedInvalidStagedData)
                              }}
                            >
                              <TableCell>
                                <Checkbox
                                  checked={selectedRows?.includes(indexInStagedData)}
                                  onChange={(event) =>
                                    handleCheckboxClick(event, indexInStagedData)}
                                />
                              </TableCell>
                              <TableCell>
                                {data?.clicked
                                  ? <Autocomplete
                                      loading={isSecurityOptionsLoading}
                                      size='small'
                                      sx={{ padding: '8px 8px 8px 0px', minWidth: '350px' }}
                                      value={data?.name}
                                      options={options[indexInStagedData] || []}
                                      getOptionLabel={(option) => option?.name || data?.name}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label='Security Name'
                                          error={data?.name && data?.hasSecurityError}
                                          helperText={
                                          data?.name && data?.hasSecurityError
                                            ? 'Security does not exist in selected country code'
                                            : ''
                                        }
                                          placeholder={data?.name}
                                          InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                              <>
                                                {params.InputProps.endAdornment}
                                              </>
                                            )
                                          }}
                                        />
                                      )}
                                      onInputChange={(event, value) => {
                                        onSecurityNameChange(value, index)
                                      }}
                                      onChange={(event, value) => {
                                        onChangeHandler(event, value, index)
                                      }}
                                    />
                                  : data?.name
                                    ? data?.name
                                    : ''}
                                {!data?.name && invalidStagedData[indexInStagedData]?.clicked
                                  ? <FormHelperText sx={{ margin: '0px 5px 5px 14px' }} error> Name Required</FormHelperText>
                                  : <></>}
                                {data?.name === '' && invalidStagedData[indexInStagedData]?.clicked
                                  ? <FormHelperText sx={{ margin: '0px 5px 5px 14px' }} error> Name Required</FormHelperText>
                                  : <></>}
                              </TableCell>
                              <TableCell>
                                {data?.symbol === '__USD'
                                  ? '__CASH'
                                  : data?.symbol}
                              </TableCell>
                              <TableCell align='right'>
                                {data?.clicked
                                  ? <TextField
                                      type='text'
                                      size='small'
                                      sx={{ padding: '8px 0px 8px 8px' }}
                                      value={data?.shares}
                                      onKeyDown={(event) => {
                                        if (data?.symbol === '__USD' || data?.symbol === '__CASH') {
                                          isNumberWithSign(event)
                                        } else {
                                          isNumberOnly(event)
                                        }
                                      }}
                                      onPaste={(event) => {
                                        if (data?.symbol === '__USD' || data?.symbol === '__CASH') {
                                          numberWithSignPasteHandler(event)
                                        } else {
                                          numberOnlyPasteHandler(event)
                                        }
                                      }}
                                      onChange={(e) => {
                                        const updatedInvalidStagedData = [...invalidStagedData]
                                        const originalIndex = invalidStagedData?.findIndex(
                                          (item) => item.id === data?.id
                                        )
                                        updatedInvalidStagedData[originalIndex].shares = e.target.value
                                        if (uploadTypeWatch === 'urlupload') {
                                          updatedInvalidStagedData[originalIndex].sharesUnadj = e.target.value
                                        }
                                        if (
                                          ((data?.symbol === '__USD' || data?.symbol === '__CASH') &&
                                          (updatedInvalidStagedData[index]?.purchaseCost === '' ||
                                            (updatedInvalidStagedData[index]?.purchaseCost && updatedInvalidStagedData[index]?.purchaseCost < 0) ||
                                            (updatedInvalidStagedData[index]?.shares === '' || (updatedInvalidStagedData[index]?.shares && updatedInvalidStagedData[index]?.shares < 0)))) ||
                                        (
                                          !(updatedInvalidStagedData[index]?.purchaseCost === '' ||
                                            (updatedInvalidStagedData[index]?.purchaseCost && updatedInvalidStagedData[index]?.purchaseCost < 0) ||
                                            (updatedInvalidStagedData[index]?.shares === '' || (updatedInvalidStagedData[index]?.shares && updatedInvalidStagedData[index]?.shares <= 0))))
                                        ) {
                                          setIncorrectValue(false)
                                          updatedInvalidStagedData[index].hasOtherFieldsError = false
                                        } else {
                                          setIncorrectValue(true)
                                          updatedInvalidStagedData[index].hasOtherFieldsError = true
                                        }
                                        setInvalidStagedData(updatedInvalidStagedData)
                                        setIncorrectValue(false)
                                      }}
                                    />

                                  : data?.shares
                                    ? data?.shares
                                    : ''}
                                {
                                  data?.shares === '' || ((data?.symbol === '__USD' || data?.symbol === '__CASH') && data?.shares === '0')
                                    ? (
                                      <FormHelperText error type='invalid'>
                                        {data.shares === '' ? 'Shares Required' : 'Shares cannot be 0'}
                                      </FormHelperText>
                                      )
                                    : <></>
                                }

                                {data?.shares && data?.shares <= 0 && !(data?.symbol === '__USD' || data?.symbol === '__CASH')
                                  ? (
                                    <FormHelperText error type='invalid'>
                                      Shares should be greater than 0
                                    </FormHelperText>
                                    )
                                  : <></>}
                              </TableCell>
                              <TableCell align='right'>
                                {(data?.symbol === '__USD' || data?.symbol === '__CASH')
                                  ? (!data?.clicked || (data?.symbol === '__USD' || data?.symbol === '__CASH'))
                                      ? (data?.purchaseCost !== undefined && data?.purchaseCost !== null && data?.purchaseCost !== '')
                                          ? formateToUsdCurrency?.format(data?.shares !== '-' ? data?.shares : 0)
                                          : ''
                                      : <>
                                        {data?.purchaseCost}
                                      </>
                                  : data.clicked
                                    ? <TextField
                                        type='text'
                                        size='small'
                                        sx={{ padding: '8px 0px 8px 8px' }}
                                        value={data?.purchaseCost}
                                        onKeyDown={(event) => isNumberOnly(event)}
                                        onPaste={numberOnlyPasteHandler}
                                        onChange={(e) => {
                                          const updatedInvalidStagedData = [...invalidStagedData]
                                          const originalIndex = invalidStagedData?.findIndex(
                                            (item) => item.id === data?.id
                                          )
                                          updatedInvalidStagedData[originalIndex].purchaseCost = e?.target?.value
                                          if (uploadTypeWatch === 'urlupload') {
                                            updatedInvalidStagedData[originalIndex].purchaseCostUnadj = e.target.value
                                          }
                                          if (updatedInvalidStagedData[originalIndex]?.purchaseCost === '' || (updatedInvalidStagedData[originalIndex]?.purchaseCost && updatedInvalidStagedData[originalIndex]?.purchaseCost < 0) || (updatedInvalidStagedData[originalIndex]?.shares === '' || (updatedInvalidStagedData[originalIndex]?.shares && updatedInvalidStagedData[originalIndex]?.shares <= 0))) {
                                            setIncorrectValue(true)
                                            updatedInvalidStagedData[originalIndex].hasOtherFieldsError = true
                                          } else {
                                            setIncorrectValue(false)
                                            updatedInvalidStagedData[originalIndex].hasOtherFieldsError = false
                                          }
                                          setInvalidStagedData(updatedInvalidStagedData)
                                        }}
                                      />
                                    : (data?.purchaseCost !== undefined && data?.purchaseCost !== null && data?.purchaseCost !== '')
                                        ? formateToUsdCurrency?.format(data?.purchaseCost)
                                        : ''}
                                {data?.purchaseCost === ''
                                  ? <FormHelperText error>
                                    PurchaseCost Required
                                  </FormHelperText>
                                  : <></>}
                                {(data?.purchaseCost !== undefined &&
                                  data?.purchaseCost !== null &&
                                  data?.purchaseCost !== '') &&
                                  data.purchaseCost < 0
                                  ? <FormHelperText error>
                                    PurchaseCost should not be less than 0
                                  </FormHelperText>
                                  : <></>}
                              </TableCell>

                              <TableCell align='right'>
                                {data?.newRow
                                  ? <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                      slotProps={{ textField: { size: 'small' } }}
                                      sx={{ padding: '8px 0px 8px 8px' }}
                                      value={dayjs(data?.selectedDate)}
                                      onChange={(date) => {
                                        const updatedInvalidStagedData = [...invalidStagedData]
                                        updatedInvalidStagedData[indexInStagedData].selectedDate = dayjs(date)
                                        updatedInvalidStagedData[indexInStagedData].purchaseDate =
                                          dayjs(date)
                                        setInvalidStagedData(updatedInvalidStagedData)
                                      }}
                                      format='YYYY-MM-DD'
                                      maxDate={dayjs()}
                                    />
                                    </LocalizationProvider>
                                  : data?.clicked
                                    ? <LocalizationProvider dateAdapter={AdapterDayjs}>
                                      <DatePicker
                                        slotProps={{ textField: { size: 'small' } }}
                                        value={dayjs(data?.purchaseDate)}
                                        onChange={(date) => {
                                          const updatedInvalidStagedData = [...invalidStagedData]
                                          updatedInvalidStagedData[indexInStagedData].selectedDate = dayjs(date)
                                          updatedInvalidStagedData[indexInStagedData].purchaseDate =
                                            dayjs(date)
                                          setInvalidStagedData(updatedInvalidStagedData)
                                        }}
                                        format='YYYY-MM-DD'
                                        maxDate={dayjs()}
                                      />
                                      </LocalizationProvider>
                                    : formatDate(data?.purchaseDate)}
                              </TableCell>
                              <TableCell style={{ width: '250px' }} align='right'>
                                {data?.clicked && uploadTypeWatch !== 'urlupload'
                                  ? (
                                    <Autocomplete
                                      loading={isSecurityOptionsLoading}
                                      size='small'
                                      sx={{ padding: '8px 0px 8px 8px' }}
                                      isOptionEqualToValue={(option, value) => {
                                        return option?.code === value
                                      }}
                                      options={countryOptions || []}
                                      getOptionDisabled={(option) =>
                                        option?.code === data?.isoCountryCode}
                                      getOptionLabel={(option) => typeof option === 'string' || option instanceof String ? option : option?.name}
                                      value={data?.isoCountryCode}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label='Country Code'
                                          placeholder={data?.code}
                                          InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                              <>
                                                {params.InputProps.endAdornment}
                                              </>
                                            )
                                          }}
                                        />
                                      )}
                                      onChange={(event, value) => {
                                        onISOCodeChangeHandler(event, value, index)
                                      }}
                                    />
                                    )
                                  : uploadTypeWatch !== 'urlupload'
                                    ? data?.isoCountryCode
                                    : data?.isoCountryCode === null
                                      ? 'US'
                                      : data?.isoCountryCode
                                        ? data?.isoCountryCode
                                        : ''}
                              </TableCell>
                              <TableCell align='right'>
                                <Box display='flex' alignItems='center' justifyContent='flex-end'>
                                  {data?.clicked
                                    ? <IconButton
                                        aria-label='save'
                                        size='medium'
                                        disabled={
                                        ((invalidStagedData[index]?.hasSecurityError || invalidStagedData[index]?.hasOtherFieldsError)
                                        ) ||
                                        ((data?.symbol === '__USD' || data?.symbol === '__CASH') && (!isValidNumber(data?.shares) || data?.shares === '')) ||
                                        ((data?.symbol !== '__USD' && data?.symbol !== '__CASH') && (!isValidNumber(data?.shares) || data?.shares <= 0)) || !data?.name
                                      }
                                        onClick={() => {
                                          if (
                                            data?.purchaseDate &&
                                          data?.name &&
                                          (data?.purchaseCost !== undefined &&
                                            data?.purchaseCost !== null &&
                                            data?.purchaseCost !== '') &&
                                          data?.symbol &&
                                          data?.purchaseDate && data?.purchaseCost >= 0 &&
                                          ((data?.symbol === '__USD' || data?.symbol === '__CASH') && data?.shares !== undefined) ||
                                          (data?.symbol !== '__USD' && data?.symbol !== '__CASH') && (data?.shares === undefined || data?.shares > 0) &&
                                          data?.isoCountryCode
                                          ) {
                                            const updatedInvalidStagedData = [...invalidStagedData]
                                            const updatedStagedData = [...stagedData]
                                            updatedInvalidStagedData[indexInStagedData].clicked = false
                                            updatedStagedData?.unshift(updatedInvalidStagedData[indexInStagedData])
                                            setStagedData(updatedStagedData)
                                            setValidStagedData([{ ...data, clicked: false }, ...validStagedData])
                                            updatedInvalidStagedData?.splice(indexInStagedData, 1)
                                            setInvalidStagedData(updatedInvalidStagedData)
                                            setInvalidCopyData(invalidCopyData.filter((_, i) => i !== indexInStagedData))
                                            // go to previous if current page have no data
                                            checkInvalidStagedDataPagesEntries(updatedInvalidStagedData)
                                            setUnsavedRowCount(prev => prev - 1)
                                          } else {
                                            showError('Please check for errors and enter valid values')
                                          }
                                        }}
                                      >
                                      <SaveIcon sx={{ width: '20px', height: '20px', color: '#34475A' }} />
                                      </IconButton>
                                    : <IconButton
                                        aria-label='edit'
                                        size='medium'
                                        onClick={() => {
                                          const updatedInvalidStagedData = [...invalidStagedData]
                                          if (updatedInvalidStagedData[indexInStagedData]) {
                                            updatedInvalidStagedData[indexInStagedData].clicked = true
                                            setIsoCodeInputValue(updatedInvalidStagedData[indexInStagedData]?.isoCountryCode)

                                            const updatedIsoCountryCodes = [...isoCodeEditIndex]
                                            updatedIsoCountryCodes[indexInStagedData] = updatedInvalidStagedData[indexInStagedData]?.isoCountryCode
                                            setIsoCodeEditIndex(updatedIsoCountryCodes)

                                            setInvalidStagedData(updatedInvalidStagedData)
                                            setUnsavedRowCount(prev => prev + 1)
                                          }
                                        }}
                                      >
                                      <EditIcon sx={{ width: '20px', height: '20px', color: '#34475A' }} />
                                      </IconButton>}
                                  {
                                    data?.clicked ? (
                                      <IconButton
                                        aria-label='close'
                                        size='medium'
                                        onClick={() => {
                                          const updatedInvalidStagedData = [...invalidStagedData]
                                          updatedInvalidStagedData.splice(indexInStagedData, 1, { ...invalidCopyData[indexInStagedData], clicked: false })
                                          setInvalidStagedData(updatedInvalidStagedData)
                                          setIncorrectValue(false)
                                          setIncorrectCountryCode(false)
                                          setUnsavedRowCount(prev => prev - 1)
                                        }}
                                      >
                                        <CloseIcon sx={{ width: '20px', height: '20px', color: '#34475A' }} />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        aria-label='delete'
                                        size='medium'
                                        onClick={() => {
                                          const updatedInvalidStagedData = [...invalidStagedData]
                                          updatedInvalidStagedData?.splice(indexInStagedData, 1)
                                          setIncorrectValue(false)
                                          setIncorrectCountryCode(false)
                                          setInvalidStagedData(updatedInvalidStagedData)
                                          setSelectedRows((prevSelectedRows) =>
                                            prevSelectedRows
                                              ?.filter((i) => i !== indexInStagedData) // Remove the deleted index
                                              ?.map((i) => (i > indexInStagedData ? i - 1 : i)) // Adjust the remaining indexes
                                          )
                                          setInvalidCopyData(invalidCopyData.filter((_, i) => i !== indexInStagedData))
                                          checkInvalidStagedDataPagesEntries(updatedInvalidStagedData)
                                        }}
                                      >
                                        <DeleteIcon sx={{ width: '20px', height: '20px', color: '#34475A' }} />
                                      </IconButton>
                                    )
                                  }
                                </Box>
                              </TableCell>
                            </TableRow>
                          )
                        }
                      )}
                    </>
                    : <>
                      {slicedData?.length > 0
                        ? <>
                          {slicedData?.map((data, index) => {
                            const indexInStagedData = page * rowsPerPage + index
                            return (
                              <TableRow
                                key={index}
                                className={
                                  (data?.name === null || data?.purchaseCost < 0) &&
                                    !(data?.symbol === ('__USD' || '__CASH') && data?.shares < 0)
                                    ? 'invalid-entry'
                                    : ''
                                }
                                onDoubleClick={() => {
                                  const updatedStagedData = [...stagedData]
                                  const originalIndex = stagedData.findIndex(
                                    (item) => item.id === data?.id
                                  )
                                  if (updatedStagedData[originalIndex] && !updatedStagedData[originalIndex]?.clicked) {
                                    updatedStagedData[originalIndex].clicked = true
                                    setUnsavedRowCount(prev => prev + 1)
                                  }
                                  setStagedData(updatedStagedData)
                                }}
                              >
                                <TableCell
                                  style={{ width: '500px' }}
                                >
                                  {data?.clicked
                                    ? <Autocomplete
                                        loading={isSecurityOptionsLoading}
                                        sx={{ padding: '8px 8px 8px 0px' }}
                                        size='small'
                                        value={data?.name}
                                        options={options[indexInStagedData] || []}
                                        getOptionLabel={(option) => option?.name || data?.name}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            error={data?.name && data?.hasSecurityError}
                                            helperText={
                                            data?.name && data?.hasSecurityError
                                              ? 'Security does not exist in selected country code'
                                              : ''
                                          }
                                            label='Security Name'
                                            placeholder={data?.name}
                                            InputProps={{
                                              ...params.InputProps,
                                              endAdornment: (
                                                <>
                                                  {params.InputProps.endAdornment}
                                                </>
                                              )
                                            }}
                                          />
                                        )}
                                        onInputChange={(event, value) => {
                                          onSecurityNameChange(value, index)
                                        }}
                                        onChange={(event, value) => {
                                          onChangeHandler(event, value, index, data?.id)
                                        }}
                                      />
                                    : data?.name
                                      ? data?.name
                                      : ''}
                                  {!data?.newRow && data?.name === ''
                                    ? <FormHelperText error sx={{ margin: '0px 5px 5px 14px' }} type='invalid'>Name Required</FormHelperText>
                                    : <></>}
                                  {data?.newRow && data?.name === ''
                                    ? <FormHelperText error sx={{ margin: '0px 5px 5px 14px' }} type='invalid'>Name Required</FormHelperText>
                                    : <></>}
                                </TableCell>
                                <TableCell>
                                  {data?.symbol === '__USD' ? '__CASH' : data?.symbol}
                                </TableCell>
                                <TableCell align='right'>
                                  {data?.clicked
                                    ? <TextField
                                        type='text'
                                        sx={{ padding: '8px 8px 8px 0px' }}
                                        size='small'
                                        value={data?.shares}
                                        onKeyDown={(event) => {
                                          if (data?.symbol === '__USD' || data?.symbol === '__CASH') {
                                            isNumberWithSign(event)
                                          } else {
                                            isNumberOnly(event)
                                          }
                                        }}
                                        onPaste={(event) => {
                                          if (data?.symbol === '__USD' || data?.symbol === '__CASH') {
                                            numberWithSignPasteHandler(event)
                                          } else {
                                            numberOnlyPasteHandler(event)
                                          }
                                        }}
                                        onChange={(e) => {
                                          const updatedStagedData = [...stagedData]
                                          const originalIndex = stagedData?.findIndex(
                                            (item) => item.id === data?.id
                                          )
                                          updatedStagedData[originalIndex].shares = e.target.value
                                          if (uploadTypeWatch === 'urlupload') {
                                            updatedStagedData[originalIndex].sharesUnadj = e.target.value
                                          }
                                          // Check if the symbol is '__USD' or '__CASH' before validating the shares value
                                          if (
                                            ((data?.symbol === '__USD' || data?.symbol === '__CASH') &&
                                            (updatedStagedData[index]?.purchaseCost === '' ||
                                              (updatedStagedData[index]?.purchaseCost && updatedStagedData[index]?.purchaseCost < 0) ||
                                              (updatedStagedData[index]?.shares === '' || (updatedStagedData[index]?.shares && updatedStagedData[index]?.shares < 0)))) ||
                                          (
                                            !(updatedStagedData[index]?.purchaseCost === '' ||
                                              (updatedStagedData[index]?.purchaseCost && updatedStagedData[index]?.purchaseCost < 0) ||
                                              (updatedStagedData[index]?.shares === '' || (updatedStagedData[index]?.shares && updatedStagedData[index]?.shares <= 0))))
                                          ) {
                                            setIncorrectValue(false)
                                            updatedStagedData[index].hasOtherFieldsError = false
                                          } else {
                                            setIncorrectValue(true)
                                            updatedStagedData[index].hasOtherFieldsError = true
                                          }
                                          setIncorrectValue(false)
                                          setStagedData(updatedStagedData)
                                        }}
                                      />
                                    : data?.shares
                                      ? data?.shares
                                      : ''}
                                  {
                                    data?.shares === '' || ((data?.symbol === '__USD' || data?.symbol === '__CASH') && data?.shares === '0')
                                      ? (
                                        <FormHelperText error type='invalid'>
                                          {data?.shares === '' ? 'Shares Required' : 'Shares cannot be 0'}
                                        </FormHelperText>
                                        )
                                      : <></>
                                  }

                                  {data?.shares && data?.shares <= 0 && !(data?.symbol === '__USD' || data?.symbol === '__CASH')
                                    ? (
                                      <FormHelperText error type='invalid'>
                                        Shares should be greater than 0
                                      </FormHelperText>
                                      )
                                    : <></>}
                                </TableCell>
                                <TableCell align='right'>
                                  {(data?.symbol === '__USD' || data?.symbol === '__CASH')
                                    ? (!data.clicked || (data?.symbol === '__USD' || data?.symbol === '__CASH'))
                                        ? (data?.purchaseCost !== undefined && data?.purchaseCost !== null && data?.purchaseCost !== '')
                                            ? formateToUsdCurrency?.format(data?.shares !== '-' ? data?.shares : 0)
                                            : ''
                                        : <>
                                          {data?.purchaseCost}
                                          </>
                                    : data.clicked
                                      ? <TextField
                                          type='text'
                                          sx={{ padding: '8px 8px 8px 0px' }}
                                          size='small'
                                          value={data?.purchaseCost}
                                          onKeyDown={(event) => isNumberOnly(event)}
                                          onPaste={numberOnlyPasteHandler}
                                          onChange={(e) => {
                                            const updatedStagedData = [...stagedData]
                                            const originalIndex = stagedData?.findIndex(
                                              (item) => item.id === data.id
                                            )
                                            updatedStagedData[originalIndex].purchaseCost =
                                            e.target.value
                                            if (uploadTypeWatch === 'urlupload') {
                                              updatedStagedData[originalIndex].purchaseCostUnadj = e.target.value
                                            }
                                            if (updatedStagedData[originalIndex]?.purchaseCost === '' || (updatedStagedData[originalIndex]?.purchaseCost && updatedStagedData[originalIndex]?.purchaseCost < 0) || (updatedStagedData[originalIndex]?.shares === '' || (updatedStagedData[originalIndex]?.shares && updatedStagedData[originalIndex]?.shares <= 0))) {
                                              setIncorrectValue(true)
                                              updatedStagedData[originalIndex].hasOtherFieldsError = true
                                            } else {
                                              setIncorrectValue(false)
                                              updatedStagedData[originalIndex].hasOtherFieldsError = false
                                            }
                                            setStagedData(updatedStagedData)
                                          }}
                                        />
                                      : (data?.purchaseCost !== undefined && data?.purchaseCost !== null && data?.purchaseCost !== '')
                                          ? formateToUsdCurrency.format(data?.purchaseCost)
                                          : ''}
                                  {data?.purchaseCost === ''
                                    ? <FormHelperText error>
                                      PurchaseCost Required
                                      </FormHelperText>
                                    : <></>}
                                  {(data?.purchaseCost !== undefined &&
                                    data?.purchaseCost !== null &&
                                    data?.purchaseCost !== '') &&
                                    data.purchaseCost < 0
                                    ? <FormHelperText error>
                                      PurchaseCost should not be less than 0
                                      </FormHelperText>
                                    : <></>}
                                </TableCell>

                                <TableCell align='right'>
                                  {data?.clicked
                                    ? <LocalizationProvider dateAdapter={AdapterDayjs}>
                                      <DatePicker
                                        slotProps={{ textField: { size: 'small' } }}
                                        sx={{ padding: '8px 0px 8px 8px' }}
                                        value={dayjs(data?.purchaseDate)}
                                        onChange={(date) => {
                                          const updatedStagedData = [...stagedData]
                                          const originalIndex = stagedData?.findIndex(
                                            (item) => item?.id === data?.id
                                          )
                                          updatedStagedData[originalIndex].selectedDate =
                                            dayjs(date)
                                          updatedStagedData[originalIndex].purchaseDate =
                                            dayjs(date)
                                          setStagedData(updatedStagedData)
                                        }}
                                        format='YYYY-MM-DD'
                                        maxDate={dayjs()}
                                      />
                                      </LocalizationProvider>
                                    : formatDate(data?.purchaseDate)}
                                </TableCell>
                                <TableCell style={{ width: '250px' }} align='right'>
                                  {data?.clicked && uploadTypeWatch !== 'urlupload'
                                    ? (
                                      <Autocomplete
                                        loading={isSecurityOptionsLoading}
                                        sx={{ padding: '8px 8px 8px 0px' }}
                                        size='small'
                                        isOptionEqualToValue={(option, value) => {
                                          return option?.code === value
                                        }}
                                        options={countryOptions || []}
                                        getOptionDisabled={(option) =>
                                          option?.code === data?.isoCountryCode}
                                        getOptionLabel={(option) => typeof option === 'string' || option instanceof String ? option : option?.name}
                                        value={data?.isoCountryCode}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label='Country Code'
                                            placeholder={data?.code}
                                            InputProps={{
                                              ...params.InputProps,
                                              endAdornment: (
                                                <>
                                                  {params?.InputProps?.endAdornment}
                                                </>
                                              )
                                            }}
                                          />
                                        )}
                                        onChange={(event, value) => {
                                          onISOCodeChangeHandler(event, value, index, data?.id)
                                        }}
                                      />
                                      )
                                    : uploadTypeWatch !== 'urlupload'
                                      ? data.isoCountryCode
                                      : data?.isoCountryCode === null
                                        ? 'US'
                                        : data?.isoCountryCode
                                          ? data?.isoCountryCode
                                          : ''}
                                </TableCell>
                                <TableCell align='right'>
                                  <Box display='flex' alignItems='center' justifyContent='flex-end'>
                                    {data?.clicked
                                      ? <IconButton
                                          aria-label='save'
                                          disabled={
                                          ((data?.hasSecurityError || data?.hasOtherFieldsError)
                                          ) ||
                                          ((data?.symbol === '__USD' || data?.symbol === '__CASH') && (!isValidNumber(data?.shares) || data?.shares === '')) ||
                                          ((data?.symbol !== '__USD' && data?.symbol !== '__CASH') && (!isValidNumber(data?.shares) || data?.shares <= 0)) ||
                                          !data?.name
                                        }
                                          size='medium'
                                          onClick={() => {
                                            if (
                                              data?.purchaseDate &&
                                            data?.name &&
                                            (data?.purchaseCost !== undefined &&
                                              data?.purchaseCost !== null &&
                                              data?.purchaseCost !== '') &&
                                            data?.symbol &&
                                            data?.purchaseDate && data?.purchaseCost >= 0 &&
                                            ((data?.symbol === '__USD' || data?.symbol === '__CASH') && data?.shares !== undefined) ||
                                            (data?.symbol !== '__USD' && data?.symbol !== '__CASH') && (data?.shares === undefined || data?.shares > 0) &&
                                            data?.isoCountryCode
                                            ) {
                                              const updatedStagedData = [...stagedData]
                                              const originalIndex =
                                              stagedData?.findIndex(
                                                (item) => item.id === data?.id
                                              )
                                              updatedStagedData[originalIndex].clicked = false
                                              if (updatedStagedData[originalIndex].newRow) { updatedStagedData[originalIndex].newRow = false }
                                              setStagedData(updatedStagedData)
                                              setValidStagedData(validStagedData.map((obj, i) => (
                                                i === originalIndex
                                                  ? {
                                                      ...data,
                                                      clicked: false,
                                                      ...(obj?.newRow ? { newRow: false } : null)
                                                    }
                                                  : obj
                                              )))
                                              applyFilter(updatedStagedData)
                                              // go to previous if current page have no data
                                              checkValidStagedDataPagesEntries(updatedStagedData)
                                              setUnsavedRowCount(prev => prev - 1)
                                            } else {
                                              showError('Please check for errors and enter valid values')
                                            }
                                          }}
                                        >
                                        <SaveIcon sx={{ width: '20px', height: '20px', color: '#34475A' }} />
                                        </IconButton>
                                      : <IconButton
                                          aria-label='edit'
                                          size='medium'
                                          onClick={() => {
                                            const updatedStagedData = [...stagedData]
                                            const originalIndex = stagedData?.findIndex(
                                              (item) => item?.id === data?.id
                                            )
                                            if (updatedStagedData[originalIndex]) {
                                              updatedStagedData[originalIndex].clicked = true
                                              setIsoCodeInputValue(updatedStagedData[originalIndex]?.isoCountryCode)

                                              const updatedIsoCountryCodes = [...isoCodeEditIndex]
                                              updatedIsoCountryCodes[originalIndex] = updatedStagedData[originalIndex]?.isoCountryCode
                                              setIsoCodeEditIndex(updatedIsoCountryCodes)

                                              setStagedData(updatedStagedData)
                                              setUnsavedRowCount(prev => prev + 1)
                                            }
                                          }}
                                        >
                                        <EditIcon sx={{ width: '20px', height: '20px', color: '#34475A' }} />
                                        </IconButton>}
                                    {
                                      data?.clicked ? (
                                        <IconButton
                                          aria-label='close'
                                          size='medium'
                                          onClick={() => {
                                            const originalIndex = stagedData?.findIndex(
                                              (item) => item?.id === data?.id
                                            )
                                            if (data?.newRow) {
                                              // incorrect state updation, proceed with care
                                              const updatedStagedData = [...stagedData]
                                              updatedStagedData.splice(originalIndex, 1)
                                              setStagedData(updatedStagedData)
                                              setValidStagedData(validStagedData.filter((_, i) => i !== originalIndex))
                                            } else {
                                              const updatedStagedData = [...stagedData]
                                              updatedStagedData.splice(originalIndex, 1, { ...validStagedData[originalIndex] })
                                              applyFilter(updatedStagedData)
                                              setStagedData(updatedStagedData)
                                            }
                                            setIncorrectValue(false)
                                            setIncorrectCountryCode(false)
                                            setUnsavedRowCount(prev => prev - 1)
                                          }}
                                        >
                                          <CloseIcon sx={{ width: '20px', height: '20px', color: '#34475A' }} />
                                        </IconButton>
                                      ) : (
                                        <IconButton
                                          aria-label='delete'
                                          size='medium'
                                          onClick={() => {
                                            const updatedStagedData = [...stagedData]
                                            const originalIndex = stagedData.findIndex(obj => obj?.id === data?.id)
                                            updatedStagedData.splice(originalIndex, 1)
                                            setStagedData(updatedStagedData)
                                            setValidStagedData(validStagedData.filter((_, i) => i !== originalIndex))
                                            setIncorrectValue(false)
                                            setIncorrectCountryCode(false)
                                            applyFilter(updatedStagedData)
                                            checkValidStagedDataPagesEntries(updatedStagedData)
                                          }}
                                        >
                                          <DeleteIcon sx={{ width: '20px', height: '20px', color: '#34475A' }} />
                                        </IconButton>
                                      )
                                    }
                                  </Box>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                          </>
                        : <TableRow><TableCell colSpan={7} sx={{ fontSize: '16px', fontWeight: 500, fontFamily: 'Open Sans', textAlign: 'center', padding: '16px' }}>No Valid Entries</TableCell></TableRow>}
                      </>}
                </TableBody>
              </Table>
            </ThemeProvider>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Box>
            {searchTerm?.length > 0 || showInvalid
              ? ''
              : <>
                <Button
                  variant='contained'
                  startIcon={<AddIcon />}
                  onClick={() => addRowTable()}
                >
                  Add
                </Button>
                <Button
                  style={{
                    backgroundColor: isButtonDisabled || incorrectValue || incorrectCountryCode ||
                    stagedData?.some((data) => data?.hasSecurityError || data?.name === '' || data?.clicked || data?.hasOtherFieldsError || ((data?.symbol === '__USD' || data?.symbol === '__CASH') && (!isValidNumber(data?.shares) || data?.shares === '')) ||
                      ((data?.symbol !== '__USD' && data?.symbol !== '__CASH') && (!isValidNumber(data?.shares) || data?.shares <= 0)) || data?.shares === '')
                      ? ''
                      : '#EA3D2F',
                    margin: '8px'
                  }}
                  variant='contained'
                  endIcon={<ErrorOutlineIcon />}
                  onClick={() => filterInvalid()}
                  className='PortfolioEndBtn'
                  disabled={isButtonDisabled || incorrectValue || incorrectCountryCode ||
                    stagedData?.some((data) => data?.hasSecurityError || data?.clicked || data?.name === '' || data?.hasOtherFieldsError || ((data?.symbol === '__USD' || data?.symbol === '__CASH') && (!isValidNumber(data?.shares) || data?.shares === '')) ||
                      ((data?.symbol !== '__USD' && data?.symbol !== '__CASH') && (!isValidNumber(data?.shares) || data?.shares <= 0)) || data?.shares === '')}
                >
                  Invalid Entries
                </Button>
                <Button
                  variant='contained'
                  onClick={() => checkStagingData() || CheckAccountType()}
                  className='PortfolioEndBtn'
                  disabled={!isButtonDisabled || !stagedData?.length || incorrectValue || incorrectCountryCode ||
                    stagedData?.some((data) => data?.hasSecurityError || data?.name === '' || data?.hasOtherFieldsError || ((data?.symbol === '__USD' || data?.symbol === '__CASH') && (!isValidNumber(data?.shares) || data?.shares === '')) ||
                      ((data?.symbol !== '__USD' && data?.symbol !== '__CASH') && (!isValidNumber(data?.shares) || data?.shares <= 0)) || data?.clicked)}
                >
                  Validate
                </Button>
                </>}
            {showInvalid && (
              <Box>
                <Button
                  style={{
                    backgroundColor: isButtonDisabled ? '' : 'auto',
                    marginRight: '5px'
                  }}
                  variant='contained'
                  onClick={() => filterInvalid()}
                  className='PortfolioEndBtn'
                  disabled={isButtonDisabled || incorrectValue || incorrectCountryCode || invalidStagedData?.some((data) => {
                    if (data?.hasSecurityError || data?.name === '' || data?.hasOtherFieldsError || data?.clicked) {
                      return true
                    }
                    return false
                  })}
                >
                  Valid Entries
                </Button>
                {selectedRows?.length > 0
                  ? <Button
                      style={{
                        backgroundColor: '#EA3D2F',
                        color: 'white',
                        textTransform: 'capitalize',
                        border: '1px solid transparent !important',
                        borderRadius: '10px',
                        fontFamily: 'Open Sans',
                        fontSize: '15px'
                      }}
                      variant='contained'
                      endIcon={<DeleteIcon />}
                      onClick={() => handleDeleteAllClick()}
                      className='PortfolioEndBtn'
                    >
                    {selectedRows?.length === invalidStagedData?.length ? 'Delete All' : 'Delete Selected'}
                    </Button>
                  : ''}
              </Box>
            )}
          </Box>
        </DialogActions>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Box>
            {
              unsavedRowCount > 0
                ? <Typography sx={{ fontSize: '14px', color: '#D29922', paddingLeft: '24px' }}>{`You have ${unsavedRowCount}/${showInvalid ? invalidStagedData.length : stagedData.length} unsaved rows.`}</Typography>
                : ''
            }
          </Box>
          <ThemeProvider theme={theme}>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component='div'
              count={showInvalid ? invalidStagedData?.length : searchTerm ? filteredData?.length : stagedData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={({ from, to, count }) =>
                `Page ${page + 1} (${from}-${to} of ${count})`}
            />
          </ThemeProvider>
        </DialogActions>
      </Dialog>
      <ThemeProvider theme={customTheme}>
        <Dialog open={modalShow} onClose={closeModal} centered>
          <DialogContent className='oboardSuccess' align='center'>
            <SuccessUploadModal successMessage={successMessage} showSecondMsg={showSecondMsg} showThirdMsg={showThirdMsg} />
          </DialogContent>
        </Dialog>
      </ThemeProvider>
      {
        isErrorModelOpen ? <InvalidRowEntriesPopup isErrorModelOpen={isErrorModelOpen} errorModalContent={errorModalContent} setIsErrorModelOpen={setIsErrorModelOpen} /> : ''
      }
      {
        isSchwabDataModelOpen
          ? <SchwabDataPopup validatedPortfolioData={validatedPortfolioData} isSchwabDataModelOpen={isSchwabDataModelOpen} setIsSchwabDataModelOpen={setIsSchwabDataModelOpen} showSubmitButton submitPortfolio={submitTaxlotData} isUploadPortfolioScreen />
          : ''
      }
    </Box>
  )
}
export default UploadForm

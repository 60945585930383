import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { Box, Grid } from '@mui/material'
import { useAuth } from '../../../contexts/AuthContext'
import { useSponsorIdContext } from '../../../contexts/IPSSponsorContext'
import Loader from '../../Loader'
import MonitoringChart from '../components/MonitoringChart'
import { MonitoringHeaderCard } from '../components/MonitoringHeaderCard'
import MonitoringTabs from '../components/MonitoringTabs'
import { useNavigate } from 'react-router-dom'
import { useErrorToast } from '../../../hooks/useErrorToast'

export const monitoringHeaderCardDataContext = React.createContext()
export const policyDataContext = React.createContext()
export const accountDataContext = React.createContext()

export const Monitoring = () => {
  const { sendSentryError } = useErrorToast()
  const [isLoading, setIsLoading] = useState(false)
  const [monitoringHeaderCardData, setMonitoringHeaderCardData] = useState([])
  const [policyData, setPolicyData] = useState([])
  const [accountData, setAccountData] = useState([])
  const [monitoringChartData, setMonitoringChartData] = useState([])
  const { user } = useAuth()
  const { selectedSponsorId } = useSponsorIdContext()
  const navigate = useNavigate()

  const monitoringChartDataAndOptions = {
    data: {
      labels: [
        'No.of accounts',
        'Policy-administered accounts some long long name'
      ],
      datasets: [
        {
          label: 'Account Details',
          data: [
            34,
            34
          ],
          backgroundColor: [
            '#4B8CCACC',
            '#ADD1F3'
          ]
        }
      ]
    },
    data2: {
      labels: [
        'No.of policy applied',
        'Action complete',
        'Action required'
      ],
      datasets: [
        {
          label: 'Action Action Details',
          data: [
            11,
            0,
            34
          ],
          backgroundColor: [
            '#4B8CCACC',
            '#ADD1F3'
          ]
        }
      ]
    },
    options: {
      cutout: 40,
      width: '170px',
      height: '170px',
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
          labels: {
            boxWidth: 15,
            padding: 8
          }
        },
        datalabels: {
          color: 'white'
        }
      }
    },
    options2: {
      cutout: 40,
      width: '170px',
      height: '170px',
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
          labels: {
            boxWidth: 15,
            padding: 10
          }
        },
        datalabels: {
          color: 'white'
        }
      }
    }
  }

  const getMonitoringData = async () => {
    API
      .get('baseUriSubAdvisoryPolicy', `sub-advisory-policy/v1/${user.userGroup}/ips/monitoring/${selectedSponsorId?.sponsorId || ''}`)
      .then(response => {
        setMonitoringChartData(response?.data?.monitoringChartData)
        setMonitoringHeaderCardData(response?.data?.monitoringHeaderCardData)
        setPolicyData(response?.data?.policyData)
        setAccountData(response?.data?.accountData)
        setIsLoading(false)
      })
      .catch(error => {
        setIsLoading(false)
        sendSentryError(error)
      })
  }

  useEffect(() => {
    if (user && selectedSponsorId?.sponsorId && selectedSponsorId?.sponsorId !== '-1') {
      setIsLoading(true)
      getMonitoringData()
    }
  }, [selectedSponsorId?.sponsorId, user])

  const showTradeData = (policyName, accountList) => {
    if (!policyName && accountList && accountList.length === 1) {
      navigate('/trade/trade-approvals', { state: { tradeData: accountList, filterName: `Account Code: ${accountList[0]}` } })
    } else if (policyName) {
      const accountListWithPolicyFilter = accountData?.rows?.filter((account) => account.policyName === policyName && account.reviewerStatus === 'Review Required').map((account) => account.accountCd)
      navigate('/trade/trade-approvals', { state: { tradeData: accountListWithPolicyFilter, filterName: `Policy Name: ${policyName}` } })
    }
  }

  return (
    <>
      {
        isLoading
          ? <Loader />
          : <>
            <Box>
              {!isLoading
                ? (selectedSponsorId?.sponsorId !== '-1'
                    ? (!policyData.rows?.length && !accountData.rows?.length)
                        ? <p style={{ marginTop: '10px' }}>No data found</p>
                        : (<Grid container>
                          <Grid item xs={12} sm={9} pr={2}>
                            <Grid item sm={12}>
                              <monitoringHeaderCardDataContext.Provider value={monitoringHeaderCardData}>
                                <MonitoringHeaderCard />
                              </monitoringHeaderCardDataContext.Provider>
                            </Grid>
                            <Grid item sm={12} mt={3}>
                              <policyDataContext.Provider value={{ policyData, showTradeData }}>
                                <accountDataContext.Provider value={{ accountData, setAccountData, showTradeData }}>
                                  <MonitoringTabs />
                                </accountDataContext.Provider>
                              </policyDataContext.Provider>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Grid item sm={12}>
                              {monitoringChartData?.data
                                ? <MonitoringChart data={monitoringChartData?.data} options={monitoringChartDataAndOptions?.options} height='240px' chartTitle='Account Details' />
                                : ''}
                            </Grid>
                            <Grid item sm={12} mt={3}>
                              {monitoringChartData?.data2
                                ? <MonitoringChart data={monitoringChartData?.data2} options={monitoringChartDataAndOptions?.options2} height='250px' chartTitle='Account Action Details' />
                                : ''}
                            </Grid>
                          </Grid>
                        </Grid>)
                    : (user?.userGroup === 'pm' && <p style={{ marginTop: '10px' }}>Select sponsor</p>))
                : ''}

            </Box>
            </>
      }
    </>
  )
}

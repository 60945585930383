import { MenuItem, Select } from '@mui/material'
import { useGridApiContext } from '@mui/x-data-grid-pro'

const FixFlyerStatusCell = (params) => {
  const { id, field, value, selectedTrades } = params
  const apiRef = useGridApiContext()

  const handleFixConnectivityStatusChange = (event) => {
    const newValue = event.target.value
    // if selected row's fixFlyerStatus is changed then only change this field for all selected rows
    if (selectedTrades.includes(id)) {
      apiRef.current.getAllRowIds().forEach(rowId => {
        if (selectedTrades.includes(rowId)) {
          apiRef.current.setEditCellValue({ id: rowId, field, value: newValue }, event)
        }
      })
    } else {
      apiRef.current.setEditCellValue({ id, field, value: newValue })
    }
  }

  return (
    <Select
      margin='dense'
      size='small'
      fullWidth
      id={`fix-flyer-status-${params?.row?.id}`}
      name={`fix-flyer-status-${params?.row?.id}`}
      value={value === 'NA' ? '' : (value || '')}
      onChange={handleFixConnectivityStatusChange}
    >
      <MenuItem value=''>Select</MenuItem>
      {
        (params?.fixFlyerStatusList || [])?.map((option, index) => (<MenuItem value={option} key={index}>{option}</MenuItem>))
      }
    </Select>
  )
}

export default FixFlyerStatusCell

import { GridRowModes } from '@mui/x-data-grid-pro'
import { API } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import DataTable from './components/DataTable'
import { CustomEditConstraintField, CustomConstraintField } from './components/CustomConstraintField'
import CustomTooltipIcon from './components/CustomTooltipIcon'
import Loader from '../Loader'

const CashConstraints = ({ data, getUpdatedData }) => {
  const [cashConstraintData, setCashConstraintsData] = useState(data?.data || [])

  const [rowModesModel, setRowModesModel] = useState({})

  const { showError } = useErrorToast()

  const { user } = useAuth()
  const params = useParams()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setCashConstraintsData(data?.data || [])
    setIsLoading(false)
  }, [data])

  const editCashConstraints = (constraintData) => {
    setIsLoading(true)
    API.post('baseUriAccountOptimization', `account-customization/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/cash-and-other-constraints/${params.accountId}`, {
      // if constraintData has multiple field(in array formate)
      body: Array.isArray(constraintData)
        ? constraintData.map(item => {
          return {
            propertyCode: item.propertyCode,
            propertyValue: item.propertyValue
          }
        })
        : [{
            propertyCode: constraintData.propertyCode,
            propertyValue: constraintData.propertyValue
          }]
    })
      .then((response) => {
        if (response.success) {
          const newConstraintData = cashConstraintData.map((row) => {
            // if there is multiple fields
            if (Array.isArray(constraintData)) {
              const minCashLevelData = response.data.find(item => item.propertyCode === 'MIN_CASH_LEVEL')
              const maxCashLevelData = response.data.find(item => item.propertyCode === 'MAX_CASH_LEVEL')
              const updatedRow = constraintData.find(updatedItem => updatedItem.id === row.id)
              if (updatedRow && updatedRow.propertyCode === 'MIN_CASH_LEVEL' && row.id === updatedRow.id) {
                return { id: updatedRow.id, ...row, ...minCashLevelData }
              } else if (updatedRow && updatedRow.propertyCode === 'MAX_CASH_LEVEL' && row.id === updatedRow.id) {
                return { id: updatedRow?.id, ...row, ...maxCashLevelData }
              }
            }
            // if there is single fields
            else if (row?.id === constraintData?.id) {
              return { id: constraintData?.id, ...row, ...response.data[0] }
            }
            return row
          })
          setCashConstraintsData(newConstraintData)
          getUpdatedData(newConstraintData)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to update cash constraint')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const deleteCashConstraints = (id) => {
    setIsLoading(true)
    API.del('baseUriAccountOptimization', `account-customization/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/cash-and-other-constraints/${params.accountId}`, {
      body: {
        accountPropertiesIds: Array.isArray(id) ? id : [id]
      }
    })
      .then((response) => {
        if (response.success) {
          const newConstraintData = cashConstraintData.map((row) => {
            if (Array.isArray(id) ? id.includes(row.accountPropertiesId) : id === row.accountPropertiesId) {
              return { ...row, accountPropertiesId: null, propertyValue: null }
            }
            return row
          })
          setCashConstraintsData(newConstraintData)
          getUpdatedData(newConstraintData)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to delete cash constraint')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const customValidations = (row) => {
    return (row.propertyValue === null || row.propertyValue === '' || row.error)
  }

  const columns = [
    {
      field: 'propertyDesc',
      headerName: 'Name',
      flex: 1,
      editable: data.fieldEditAllowed?.propertyDesc,
      maxWidth: 300,
      renderCell: (params) => (
        <>
          {params.value}
          {params.row.error
            ? (
              <CustomTooltipIcon text={params.row.errorMessage} action='error' />
              )
            : (
                params.row.disableAction && params.row.reason
                  ? <CustomTooltipIcon text={params.row.reason} action='warning' />
                  : <CustomTooltipIcon
                      text={(params.row.propertyCode === 'CASH_RAISE' || params.row.propertyCode === 'MINCASH')
                        ? 'Value must be greater than 0'
                        : 'Value must be between 0 to 100'} action='info'
                    />
              )}
        </>
      )
    },
    {
      field: 'propertyValue',
      headerName: 'Value',
      flex: 1,
      sortable: false,
      maxWidth: 300,
      type: 'text',
      editable: data?.fieldEditAllowed?.propertyValue,
      renderCell: (params) => (
        <CustomConstraintField params={params} rowModesModel={rowModesModel} />
      ),
      renderEditCell: (params) => (
        <CustomEditConstraintField {...params} disabled={rowModesModel[params.row.id]?.mode !== GridRowModes.Edit} />
      )
    }
  ]

  return (
    <>
      {isLoading ? <Loader /> : ''}
      <DataTable data={cashConstraintData} initialColumns={columns} showPagination={false} deleteKey='accountPropertiesId' editData={editCashConstraints} deleteData={deleteCashConstraints} setRowModes={setRowModesModel} customValidations={customValidations} />
    </>
  )
}

export default CashConstraints

import { useState } from 'react'
import { API } from 'aws-amplify'
import { IconButton, Tooltip } from '@mui/material'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import Loader from '../../Loader'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'

const DownloadTradeLogsCell = (params) => {
  const { user } = useAuth()

  const [tradeLogsLoading, setTradeLogsLoading] = useState(false)
  const { showError } = useErrorToast()

  const handleDownloadTradeLogs = async (row) => {
    setTradeLogsLoading(true)
    API.post('baseUriTrade', `trade/v1/${user?.userGroup}/trade-logs`, {
      body: {
        tradeIds: [(row?.tradeId) || '']
      }
    })
      .then(response => {
        if (response?.data) {
          const jsonBlob = new Blob([JSON.stringify(response?.data, null, 2)], { type: 'application/json' })
          const url = URL.createObjectURL(jsonBlob)
          const fileName = `Trade_logs_${row?.instrId}.json`
          const linkTag = document.createElement('a')
          linkTag.href = url
          linkTag.download = fileName
          document.body.appendChild(linkTag)
          linkTag.click()
          document.body.removeChild(linkTag)
          URL.revokeObjectURL(url)
        } else {
          showError(response?.message || 'No data available')
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to download trade logs.')
      })
      .finally(() => {
        setTradeLogsLoading(false)
      })
  }

  return (
    <>
      {tradeLogsLoading ? <Loader /> : ''}
      <IconButton
        className='action-clock-btn'
        onClick={() => {
          handleDownloadTradeLogs(params?.row)
        }}
      >
        <Tooltip title='Download Trade Logs'>
          <FileDownloadOutlinedIcon sx={{ color: '#74788d', fontSize: '20px' }} />
        </Tooltip>
      </IconButton>
    </>
  )
}

export default DownloadTradeLogsCell

import { useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Box, Chip, IconButton, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { DataGridPro, gridClasses, gridFilteredDescendantCountLookupSelector, useGridApiContext, useGridApiRef, useGridSelector } from '@mui/x-data-grid-pro'
import { ExpandMore } from '@mui/icons-material'
import redirect from '../../../assets/images/pop-out-line.svg'

const columns = [
  {
    field: 'memberId',
    headerName: 'Member Id',
    flex: 1
  },
  {
    field: 'groupType',
    headerName: 'Type',
    flex: 1,
    valueGetter: (params) => params?.row?.memberType === 'group'
      ? params?.value
      : params?.row?.memberType === 'uma'
        ? 'UMA'
        : ''
  },
  {
    field: 'allocationPercentage',
    headerName: 'Account Allocation (%)',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.value !== undefined && params?.value !== null
      ? (params?.value * 100).toFixed(2)
      : params?.value,
  },
  {
    field: 'marketValue',
    headerName: 'Market Value',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.value !== undefined && params?.value !== null ? params?.value?.toFixed(0) : null,
    renderCell: (params) => (
      params?.value !== null
        ? params?.row?.marketValue < 0
          ? '-$' + Math.abs(Number(params?.value))?.toLocaleString()
          : '$' + Number(params?.value)?.toLocaleString()
        : ''
    )
  },
  {
    field: 'action',
    headerName: ' ',
    type: 'actions',
    align: 'center',
    width: 50,
    getActions: (params) => {
      const url = params?.row?.memberType === 'account'
        ? `/account-review/account-dashboard/`
        : `/aggregate/aggregate-dashboard/`
      return [
        <Link to={`${url}${params?.row?.memberRefId}`} style={(params?.row?.isActive === 0 || params?.row?.memberRefId === 'AGGTAG') ? { opacity: '0.3', pointerEvents: 'none' } : {}}>
          <img src={redirect} alt='' />
        </Link>
      ]
    }
  }
]

const renderTableSkeleton = (header, hiddenColumns) => {
  if (hiddenColumns) { header = header.filter(col => !hiddenColumns.includes(col.field)) }
  return (
    <TableContainer mt={5}>
      <Table className='risk-page-table'>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            {header.map((item, index) => {
              return (
                <TableCell key={index}>{item.headerName}</TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: 5 }).map((_, index) => (
            <TableRow key={index}>
              {Array.from({ length: header.length + 1 }).map((_, index) => (
                <TableCell key={index}>
                  <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                </TableCell>))}
            </TableRow>))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

let expansionLookup = {}

const AssetAllocationTab = ({ data = [], loading }) => {
  const apiRef = useGridApiRef()

  useEffect(() => {
    if (apiRef?.current?.subscribeEvent) {
      apiRef?.current?.subscribeEvent('rowExpansionChange', (node) => {
        if (node?.groupingKey) {
          expansionLookup[node?.groupingKey] = node?.childrenExpanded
        }
      })
    }
    return () => {
      expansionLookup = {}
    }
  }, [apiRef, data])
  

  const isGroupExpandedByDefault = useCallback((node) => {
    return expansionLookup[node?.groupingKey] ?? true
  }, [])

  return (
    <>
      <Box className='aggregate-portfolio-table'>
        {
          loading
            ? renderTableSkeleton(columns, ['memberId'])
            : <DataGridPro
              apiRef={apiRef}
              density='compact'
              treeData
              rows={data}
              columns={columns}
              autoHeight
              getRowId={(row) => row?.id}
              disableRowSelectionOnClick
              getTreeDataPath={(row) => row.hierarchy}
              groupingColDef={{
                headerName: 'Name',
                width: 250,
                filterable: true,
                sortable: true,
                disableColumnMenu: false,
                cellClassName: 'name-column-row',
                headerClassName: 'name-column-header',
                valueGetter: (params) => params?.row?.name,
                renderCell: (params) => (
                    <CustomGridTreeDataGroupingCell
                      showDropdown={(descendantCount) => descendantCount > 0}
                      {...params}
                    />
                )
              }}
              localeText={{ noRowsLabel: 'No Portfolio Found' }}
              isGroupExpandedByDefault={isGroupExpandedByDefault}
              hideFooter={data?.length === 0}
              pageSizeOptions={[15, 25, 50, 100]}
              initialState={{
                pagination: { paginationModel: { pageSize: 25 } },
                columns: {
                  columnVisibilityModel: {
                    memberId: false
                  }
                }
              }}
              pagination
              sx={(theme) => ({
                '.name-column-row': {
                  paddingLeft: 0
                },
                '.name-column-header': {
                  paddingLeft: '28px'
                },
                [`.${gridClasses.main}`]: {
                  overflow: 'unset'
                },
                [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                  color: '#74788d',
                  fontWeight: 600
                },
                [`.${gridClasses.columnHeaders}`]: {
                  position: 'sticky',
                  top: 0,
                  zIndex: 3,
                  backgroundColor: 'white'
                },
                [`.${gridClasses.footerContainer}`]: {
                  position: 'sticky',
                  bottom: '-1px',
                  zIndex: 3,
                  backgroundColor: 'white'
                }
              })}
            />
        }
      </Box>
    </>
  )
}

const CustomGridTreeDataGroupingCell = (props) => {
  const { id, field, rowNode, showDropdown } = props
  const apiRef = useGridApiContext()
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector,
  )
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0

  const handleClick = (event) => {
    if (rowNode.type !== 'group') {
      return
    }
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
    apiRef.current.setCellFocus(id, field)
    event.stopPropagation()
  }

  return (
    <>
      {
        showDropdown(filteredDescendantCount, props?.row?.hierarchy) ? (
          <div style={{ marginLeft: rowNode.depth ? rowNode.depth * 23 : 0 }}>
            <IconButton
              onClick={handleClick}
              size='small'
              tabIndex={-1}
              aria-label={rowNode.childrenExpanded ? 'Close' : 'Open'}
            >
              <ExpandMore
                sx={{
                  transform: `rotateZ(${rowNode.childrenExpanded ? 360 : 270}deg)`,
                  transition: (theme) =>
                    theme.transitions.create('transform', {
                      duration: theme.transitions.duration.shortest,
                    }),
                }}
                fontSize='inherit'
              />
            </IconButton>
            <span>
              {props?.row?.name}
            </span>
          </div>
        ) : (
          <span style={{ marginLeft: rowNode.depth ? rowNode.depth * 23 + 28 : 28 }}>{props?.row?.name}</span>
        )
      }
      {
        props?.row?.isActive === 0 ? (
          <Chip label={'INACTIVE'} size='small' sx={{ background: '#ff616199', color: '#79454f', fontSize: '10px', fontWeight: '500', ml: 1, height: '16px' }} />
        ) : ''
      }
    </>
  )
}

export default AssetAllocationTab
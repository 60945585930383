import { useGridApiContext } from '@mui/x-data-grid-pro'
import { useEffect, useState } from 'react'

const CustomEditDate = (props) => {
  const { id, field, value: valueProp, minDate } = props
  const apiRef = useGridApiContext()
  const [value, setValue] = useState(valueProp)

  const formatDate = (date) => {
    const parsedDate = date ? date?.split('T')[0] : null
    return parsedDate
  }

  const handleDateChange = (e) => {
    const newValue = e.target.value
    apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs: 200 })
    setValue(newValue)
  }

  useEffect(() => { setValue(valueProp) }, [valueProp])

  return (
    <input
      type='date'
      value={formatDate(value)}
      onChange={handleDateChange}
      min={minDate}
      style={{ height: '100%', width: '100%', background: 'rgba(25, 118, 210, 0)', outline: 'none', border: 'none' }}
    />
  )
}

export default CustomEditDate

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import { Autocomplete, Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, Select, TextField, Tooltip, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import utc from 'dayjs/plugin/utc'
import CustomEditDate from '../../components/CustomEditDate'
import { useAuth } from '../../contexts/AuthContext'
import { moduleConfig } from '../../contexts/data'
import { ACCESS_LEVEL } from '../../contstants/constants'
import { useErrorToast } from '../../hooks/useErrorToast'
import { checkInstrumentSearchQuery } from '../../utils/searchQueryUtils'
import Loader from '../Loader'
import DataTable from './components/DataTable'

dayjs.extend(isSameOrAfter) // Extend dayjs with the isSameOrAfter plugin
dayjs.extend(utc)

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '16px 24px',
  width: '500px',
  borderRadius: '4px'
}

export default function SecurityRestrictions (props) {
  const { showError } = useErrorToast()
  const { user } = useAuth()
  const [restrictedCodes, setRestrictedCodes] = useState([])
  const params = useParams()
  const { checkAccess } = useAuth()
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const [startDate, setStartDate] = useState(dayjs.utc().startOf('day')) // Use UTC for initial value
  const [endDate, setEndDate] = useState(dayjs.utc().startOf('day')) // Use UTC for initial value
  const [securityWeight, setSecurityWeight] = useState(null)
  const [selectKey, setSelectKey] = useState('')
  const [selectInstrID, setSelectInstrId] = useState('')
  const [selectedValues, setSelectedValues] = useState([])
  const [selectValue, setSelectValue] = useState('')
  const [autocompleteValue, setAutocompleteValue] = useState('')
  const [customErrors, setCustomErrors] = useState({})
  const [sourceOptions, setSourceOptions] = useState([])
  const [sourceName, setSourceName] = useState([])
  const [customErrors2, setCustomErrors2] = useState({})
  const [isLoadingSource, setIsLoadingSource] = useState(false)
  const [errorName, setErrorName] = useState(false)
  const [errorCode, setErrorCode] = useState(false)
  const [weightError, setWeightError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [rows, setRows] = React.useState(props?.securityData || [])
  const [errorEndDate, setErrorEndDate] = useState(null)

  const handleClose = () => {
    setOpen(false)
    setErrorName(false)
    setErrorCode(false)
    setAutocompleteValue('')
    setSelectValue('')
    setSelectInstrId('')
    setSelectKey('')
    setSelectedValues([])
    setStartDate(dayjs.utc().startOf('day'))
    setEndDate(dayjs.utc().startOf('day'))
    setIsLoading(false)
  }
  const dataSecure = localStorage.getItem('object')
  const getStoreData = dataSecure ? JSON.parse(dataSecure) : []
  const filterSecureData = getStoreData?.filter((item) => item?.accountId === params?.accountId)

  useEffect(() => {
    setRows(props?.securityData || [])
  }, [props?.securityData])

  const addSecurityRestrictions = async () => {
    const id = randomId()
    setIsLoading(true)
    try {
      const response = await API.post(
        'baseUriAccountOptimization',
        `account-customization/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/${params?.accountId}/instr-restriction`,
        {
          body: {
            startDate: startDate?.toISOString()?.slice(0, 10),
            instrId: selectInstrID,
            restrictionId: selectKey,
            endDate: endDate.toISOString().slice(0, 10),
            ...(selectValue === 'Hold Minimum' || selectValue === 'Hold Maximum'
              ? { weight: Number(Number(securityWeight / 100).toFixed(7)) }
              : {})
          }
        }
      )
      if (response.success && response?.data && response?.data?.length) {
        const updatedSecurityList = [{ id, ...response?.data[0] }, ...rows]
        setRows(updatedSecurityList)
        props?.onData && props?.onData(updatedSecurityList)
        handleClose()
      }
    } catch (error) {
      showError(error, false, {}, 'Failed to add security restriction.')
      setIsLoading(false)
    }
  }

  const handleWeightChange = (value) => {
    setWeightError(false)
    setSecurityWeight(value)
  }

  const onRestrictionCodeChange = (event, key) => {
    if (event.target.value !== '') {
      const temp = customErrors
      delete temp.restrictionCode
      setCustomErrors(temp)
      setSelectValue(event.target.value)
      setSelectKey(key)
      setSelectedValues([...selectedValues, selectValue])
      setErrorCode(false)
    }
  }
  const onSourceNameChange = (query, newValue) => {
    setIsLoadingSource(true)
    setAutocompleteValue(newValue)
    setSelectInstrId(newValue?.instrId || '')
    if (newValue) {
      setSelectedValues([...selectedValues, newValue])
    }
    if (errorName && newValue?.name) {
      setErrorName(false)
    }
  }

  const getRestrictedCodes = async () => {
    API.get(
      'baseUriTransactionalMaster',
      `transactional-master/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/restriction-codes`,
      { queryStringParameters: {} }
    )
      .then((response) => {
        if (response.data) {
          setRestrictedCodes([...response.data])
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load restriction codes.')
      })
  }

  const onInputChange = (event) => {
    const query = (event?.target?.value || '').toString().trim()
    if (query === '') {
      setSourceName([])
    }
    if (sourceName?.length === 0 && query === '') {
      setCustomErrors2({ sourceName: { message: 'Source Name required' } })
    } else {
      if (checkInstrumentSearchQuery(query)) {
        setIsLoadingSource(true)
        API.get('baseUriTransactionalMaster', `transactional-master/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/instruments`, {
          queryStringParameters: { search: query }
        })
          .then((response) => {
            if (response?.data) {
              setSourceOptions([...response?.data])
              const tempErrors = customErrors2
              delete tempErrors.sourceName
              setCustomErrors2(tempErrors)
            }
          })
          .catch((error) => {
            showError(error, false, {}, 'Failed to load securities list.')
          })
          .finally(() => {
            setIsLoadingSource(false)
          })
      }
    }
  }

  const submitSecurityHandler = (event) => {
    if (securityWeight && (selectValue === 'Hold Minimum' || selectValue === 'Hold Maximum')) {
      setWeightError(false)
    }
    if (autocompleteValue?.name && selectValue && (errorEndDate === null)) {
      event.preventDefault()
      setErrorName(false)
      setErrorCode(false)
      if (!securityWeight && (selectValue === 'Hold Minimum' || selectValue === 'Hold Maximum')) {
        setWeightError(true)
        return
      }
      addSecurityRestrictions()
    } else {
      if (!autocompleteValue?.name) {
        setErrorName(true)
      }
      if (!selectValue) {
        setErrorCode(true)
      }
    }
  }

  const securityRowUpdateApi = (row, baseUri, endpoint, body) => {
    API.patch(baseUri, endpoint, { body })
      .then(response => {
        if (response.data && response.success) {
          const updatedList = rows.map(item => {
            if (item.accountId === row.accountId && item.instrId === row.instrId && item.restrictionId === row.restrictionId) {
              return { ...item, startDate: row.startDate, endDate: row.endDate }
            }
            return item
          })
          setRows(updatedList)
          props?.onData && props?.onData(updatedList)
          setIsLoading(false)
        }
      }).catch(error => {
        showError(error, false, {}, 'Failed to update security data.')
        setIsLoading(false)
      })
  }

  const handleConfirmEdit = (row) => {
    setIsLoading(true)
    const { isExternal, accountInstrRestrId, aggGroupInstrRestrId, endDate, sourceId, source } = row
    if (endDate) {
      const payload = {
        endDate: endDate ? dayjs.utc(endDate).format('YYYY-MM-DD') : null
      }
      if (isExternal && source === 'GROUP') {
        securityRowUpdateApi(row, 'baseUriAggregateMaster2', `aggregate-master/v1/${user?.userGroup}/agg-group/restrictions/${sourceId}`, {
          aggGroupInstrRestrId,
          isExternal: isExternal !== undefined ? isExternal : 0,
          ...(payload.endDate ? { endDate: payload.endDate } : {})
        })
      } else {
        securityRowUpdateApi(row, 'baseUriAccountOptimization', `account-customization/v1/${user?.userGroup}/accounts/instr-restriction/${accountInstrRestrId}`, {
          ...(payload.endDate ? { endDate: payload.endDate } : {}),
          accountInstrRestrId,
          ...(row?.restrictionDesc === 'Hold Minimum' || row?.restrictionDesc === 'Hold Maximum'
            ? { weight: row?.weight }
            : {})
        })
      }
    } else { setIsLoading(false) }
  }

  const columns = [
    {
      field: 'instrId',
      headerName: 'Instrument Id',
      flex: 1,
      editable: false
    },
    {
      field: 'localSymbol',
      headerName: 'Symbol',
      flex: 1,
      editable: false
    },
    {
      field: 'name',
      headerName: 'Security Name',
      flex: 1,
      editable: false
    },
    {
      field: 'restrictionDesc',
      headerName: 'Restriction Type',
      flex: 1,
      editable: false
    },
    {
      field: 'weight',
      headerName: 'Weight (%)',
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      type: 'number',
      editable: true,
      renderCell: (props) => props?.row?.weight !== undefined && props?.row?.weight !== null ? (props?.row?.weight * 100)?.toFixed(2) : '',
      valueGetter: (props) => props?.row?.weight !== undefined && props?.row?.weight !== null
        ? Number((props?.row?.weight)?.toFixed(2))
        : null
    },
    {
      field: 'source',
      headerName: 'Source',
      flex: 1,
      editable: false
    },
    {
      field: 'sourceName',
      headerName: 'Source Name',
      flex: 1,
      editable: false
    },
    {
      field: 'groupName',
      headerName: 'Washsale Group Name',
      flex: 1,
      editable: false
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      flex: 1,
      editable: false,
      valueFormatter: (params) =>
        params.value ? dayjs.utc(params.value).format('ddd MMM DD YYYY') : '--------'
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      flex: 1,
      editable: true,
      valueFormatter: (params) => params.value ? dayjs.utc(params.value).format('ddd MMM DD YYYY') : '',
      renderEditCell: (params) => <CustomEditDate {...params} minDate={dayjs.utc().format('YYYY-MM-DD')} />,
      preProcessEditCellProps: (params) => {
        const currentDate = dayjs().startOf('day').unix() * 1000
        const startDate = dayjs(params.row.startDate).startOf('day').unix() * 1000
        const endDate = dayjs(params.props.value).startOf('day').unix() * 1000
        const isEndDateValid = (endDate >= currentDate) && (endDate >= startDate)
        const hasError = !isEndDateValid
        const style = hasError
          ? {
              border: '2px solid red',
              height: '50px'
            }
          : {}
        return { ...params.props, error: hasError, style }
      }
    }
  ]

  useEffect(() => {
    if (user) {
      getRestrictedCodes()
    }
  }, [user])

  const deleteSecurityRowApi = (row, id, baseUri, endpoint, payload) => {
    API.del(baseUri, endpoint, {
      ...(payload ? { body: payload } : {})
    })
      .then((response) => {
        if (response.success) {
          const updatedSecurityList = rows.filter((row) => {
            return row?.isExternal ? row.aggGroupInstrRestrId !== id : row.accountInstrRestrId !== id
          })
          setRows(updatedSecurityList)
          props?.onData(updatedSecurityList)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to delete security.')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const deleteSecurityRestriction = (id) => {
    setIsLoading(true)
    const row = rows.find((row) =>
      row?.isExternal ? row.aggGroupInstrRestrId === id : row.accountInstrRestrId === id
    )
    const { isExternal, aggGroupInstrRestrId, accountInstrRestrId, source, sourceId } = row
    if (isExternal && source === 'GROUP') {
      deleteSecurityRowApi(row, id, 'baseUriAggregateMaster2', `aggregate-master/v1/${user?.userGroup}/agg-group/restrictions/${sourceId}`, {
        isExternal: isExternal || 0,
        aggGroupInstrRestrId
      })
    } else {
      deleteSecurityRowApi(row, id, 'baseAccountURL', `data-maintenance/v1/${user?.userGroup}/instrument-restriction/${id}`, {
        accountInstrRestrId
      })
    }
  }

  return (
    <>
      {isLoading ? <Loader /> : ''}
      <Box
        sx={{
          width: '100%',
          '& .actions': {
            color: 'text.secondary'
          },
          '& .textPrimary': {
            color: 'text.primary'
          },
          '.MuiDataGrid-footerContainer': {
            border: 'none'
          },
          '.MuiDataGrid-root': {
            border: 'none'
          }
        }}
      >
        {
          ((filterSecureData && filterSecureData[0]?.accountType === 'TRADING' &&
          checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS,
            { subModuleName: moduleConfig.PERSONALIZATION, component_name: moduleConfig.EDIT_TRADING_PERSONALIZATION })) ||
          ((filterSecureData && filterSecureData[0]?.accountType === 'TRANSITION' &&
           checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS,
             { subModuleName: moduleConfig.PERSONALIZATION, component_name: moduleConfig.EDIT_TRANSITION_PERSONALIZATION })))) && (
               <>
                 <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                   <Tooltip
                     disableFocusListener
                     disableTouchListener
                     placement='top'
                     title='Apply restrictions at a security level'
                   >
                     <Button
                       variant='outlined'
                       color='primary'
                       startIcon={<AddIcon />}
                       onClick={handleOpen}
                       sx={{
                         fontFamily: 'Open Sans'
                       }}
                     >
                       Add Security
                     </Button>
                   </Tooltip>
                 </Box>
                 <Modal
                   open={open}
                   onClose={handleClose}
                   aria-labelledby='modal-modal-title'
                   aria-describedby='modal-modal-description'
                 >
                   <Box sx={style}>
                     <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                       <Typography id='modal-modal-title' variant='h6' sx={{ fontWeight: 400, color: '#34475A' }}>
                         Add Security
                       </Typography>
                       <IconButton onClick={() => setOpen(false)}>
                         <CloseIcon />
                       </IconButton>
                     </Box>
                     <Box id='modal-modal-description' sx={{ mt: 2 }}>
                       <Box component='form'>
                         <Grid container spacing={2}>
                           <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                             <Typography sx={{ paddingRight: '99px', color: '#74788d!important', fontSize: '16px', fontWeight: 500 }}>Security *</Typography>
                             <Autocomplete
                               autoWidth
                               id='my-autocomplete'
                               options={sourceOptions}
                               loading={isLoadingSource}
                               getOptionLabel={(option) =>
                                 option?.name && option?.isoCountryCode
                                   ? `${option.name} - ${option.isoCountryCode}`
                                   : option?.name || ''}
                               onInputChange={onInputChange}
                               onChange={onSourceNameChange}
                               value={autocompleteValue}
                               sx={{
                                 width: '260px'
                               }}
                               renderInput={(params) => (
                                 <TextField
                                   {...params}
                                   label='Security Name'
                                   error={errorName}
                                   variant='outlined'
                                   InputProps={{ ...params.InputProps, placeholder: 'Security Name here' }}
                                   required
                                   sx={{
                                     width: '260px'
                                   }}
                                 />
                               )}
                             />
                             {customErrors.securityName && (
                               <Typography color='error' variant='caption'>
                                 {customErrors.securityName.message}
                               </Typography>
                             )}
                           </Grid>
                           <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                             <Typography sx={{ paddingRight: '38px', color: '#74788d!important', fontSize: '16px', fontWeight: 500 }}>Restriction Type *</Typography>
                             <FormControl variant='outlined' error={errorCode} size='small'>
                               <InputLabel
                                 id='demo-simple-select-label'
                                 sx={{
                                   '&.MuiInputLabel-shrink': {
                                     marginTop: 0 // Margin when label is in shrinked state
                                   },
                                   '&:not(.MuiInputLabel-shrink)': {
                                     marginTop: '7px' // Margin when label is not in shrinked state
                                   }
                                 }}
                               >Action *
                               </InputLabel>
                               <Select
                                 labelId='my-dropdown-label'
                                 label='Action'
                                 id='my-dropdown'
                                 value={selectValue}
                                 onChange={(e) => onRestrictionCodeChange(e, restrictedCodes.find(option => option.restrictionDesc === e.target.value).restrictionId)}
                                 className='actions-select'
                                 native={false}
                                 required
                                 sx={{ width: '260px', height: '56px' }}
                               >
                                 {restrictedCodes?.map((option, index) => {
                                   if (option.restrictionCode) {
                                     return (
                                       <MenuItem key={option.restrictionId} value={option.restrictionDesc}>
                                         {option.restrictionDesc}
                                       </MenuItem>
                                     )
                                   }
                                 })}
                               </Select>
                             </FormControl>
                             {customErrors.restrictionCode && (
                               <Typography color='error' variant='caption'>
                                 {customErrors.restrictionCode.message}
                               </Typography>
                             )}
                           </Grid>
                           {
                            selectValue === 'Hold Minimum' || selectValue === 'Hold Maximum'
                              ? (
                                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Typography sx={{ paddingRight: '84px', color: '#74788d!important', fontSize: '16px', fontWeight: 500 }}>Weight *</Typography>
                                  <TextField
                                    required
                                    value={securityWeight}
                                    error={weightError}
                                    onChange={(e) => {
                                      let value = parseFloat(e?.target?.value)
                                      if (value > 100) value = 100
                                      handleWeightChange(value)
                                    }}
                                    InputProps={{
                                      inputProps: {
                                        type: 'number',
                                        step: '0.1',
                                        min: 0.1,
                                        max: 100
                                      }
                                    }}
                                    helperText={weightError ? 'Please enter a value between 0.1 and 100' : ''}
                                    label='Weight'
                                    sx={{
                                      width: '260px',
                                      marginLeft: '22px'
                                    }}
                                  />
                                </Grid>
                                )
                              : null
                           }
                           <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                             <Typography sx={{ paddingRight: '84px', color: '#74788d!important', fontSize: '16px', fontWeight: 500 }}>Start date *</Typography>
                             <LocalizationProvider dateAdapter={AdapterDayjs}>
                               <DatePicker
                                 label='Start date *'
                                 value={startDate}
                                 required
                                 disablePast
                                 onChange={setStartDate}
                                 sx={{ width: '260px' }}
                               />
                             </LocalizationProvider>
                           </Grid>
                           <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                             <Typography sx={{ paddingRight: '92px', color: '#74788d!important', fontSize: '16px', fontWeight: 500 }}>End date *</Typography>
                             <LocalizationProvider dateAdapter={AdapterDayjs}>
                               <DatePicker
                                 label='End date *'
                                 value={endDate}
                                 sx={{ width: '260px' }}
                                 onChange={
                              setEndDate
                            }
                                 minDate={startDate}
                                 onError={setErrorEndDate}
                                 required
                                 disablePast
                               />
                             </LocalizationProvider>
                           </Grid>
                           <Grid item xs={12}>
                             <Box sx={{ marginTop: '10px', marginRight: '18px', display: 'flex', justifyContent: 'end' }}>
                               <Button
                                 variant='contained'
                                 onClick={submitSecurityHandler}
                               >
                                 Submit
                               </Button>
                             </Box>
                           </Grid>
                         </Grid>
                       </Box>
                     </Box>
                   </Box>
                 </Modal>
               </>
          )
        }
        <DataTable data={rows} initialColumns={columns} showPagination editData={handleConfirmEdit} deleteKey='accountInstrRestrId' deleteData={deleteSecurityRestriction} />
      </Box>
    </>
  )
}

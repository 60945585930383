import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CancelIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import { Box, Button, Divider, Modal, Typography } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { DataGridPro, GridActionsCellItem, GridRowModes, useGridApiRef } from '@mui/x-data-grid-pro'
import { useAuth } from '../../../contexts/AuthContext'
import { moduleConfig } from '../../../contexts/data'
import { ACCESS_LEVEL } from '../../../contstants/constants'
import CustomTooltipIcon from './CustomTooltipIcon'

const deletePopupStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius: '4px'
}

const DataTable = ({ data, initialColumns, deleteKey, editData, deleteData, setRowModes, customValidations, deleteIcon = null, showPagination = true }) => {
  const [rowModesModel, setRowModesModel] = useState({})
  const apiRef = useGridApiRef()
  const { checkAccess } = useAuth()
  const params = useParams()
  const [updatedRowValues, setUpdatedRowValues] = useState({})
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [rowToDelete, setRowToDelete] = useState(null)

  const dataSecure = localStorage.getItem('object')
  const getStoreData = dataSecure ? JSON.parse(dataSecure) : []
  const filterSecureData = getStoreData?.filter((item) => item?.accountId === params?.accountId)

  const handleDeleteClick = (id, row) => () => {
    setOpenDeleteDialog(true)
    setRowToDelete({ id, row })
  }

  const columns = [
    ...initialColumns,
    ...((
      filterSecureData && filterSecureData[0]?.accountType === 'TRADING' &&
      checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS,
        { subModuleName: moduleConfig.PERSONALIZATION, component_name: moduleConfig.EDIT_TRADING_PERSONALIZATION })) ||
      (filterSecureData && filterSecureData[0]?.accountType === 'TRANSITION' &&
        checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS,
          { subModuleName: moduleConfig.PERSONALIZATION, component_name: moduleConfig.EDIT_TRANSITION_PERSONALIZATION })))
      ? [
          {
            field: 'actions',
            type: 'actions',
            headerName: (
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                Action
                <CustomTooltipIcon
                  text={
                    <>
                      Edit - You can edit existing constraint. <br />
                      Delete/Reset - You can remove active constraint.
                    </>
              }
                  action='help'
                />
              </Box>
            ),
            width: 100,
            getActions: ({ id, row }) => {
              const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
              let hasEditAccess = true
              if (row?.propertyCode === 'DEFAULT_SCENARIO_CODE') {
                const isTaxable = filterSecureData[0]?.isTaxable === 1
                const isValidStrType = ['SIMPLE', 'DERIVED'].includes(filterSecureData[0]?.strTypeCode)
                hasEditAccess = isTaxable && isValidStrType
              }
              const dynamicDeleteKey = row?.source === 'GROUP' && row?.isExternal ? 'aggGroupInstrRestrId' : deleteKey
              if (isInEditMode) {
                const actions = [
                  <GridActionsCellItem
                    key='save'
                    disabled={row.error || row.minError || row.maxError}
                    icon={<SaveIcon />}
                    label='Save'
                    onClick={handleSaveClick(id, row)}
                  />,
                  <GridActionsCellItem
                    key='cancel'
                    icon={<CancelIcon />}
                    label='Cancel'
                    onClick={handleCancelClick(id, row)}
                    color='inherit'
                  />
                ]
                return actions
              }

              if (!deleteKey) {
                return [
                  <GridActionsCellItem
                    icon={<EditIcon />}
                    key='edit'
                    label='Edit'
                    onClick={handleEditClick(id, row)}
                    color='inherit'
                    disabled={!hasEditAccess || (deleteKey === 'accountInstrRestrId' && !(row?.source === 'ACCOUNT' || row?.source === 'GROUP'))}
                  />
                ]
              }

              const actions = [
                <Tooltip title='Edit Constraint' key='edit'>
                  <GridActionsCellItem
                    icon={<EditIcon />}
                    key='edit'
                    label='Edit'
                    disabled={
                    !hasEditAccess ||
                    row.disableAction || (deleteKey === 'accountInstrRestrId' && !(row?.source === 'ACCOUNT' || row?.source === 'GROUP'))
                  }
                    onClick={handleEditClick(id, row)}
                    color='inherit'
                  />
                </Tooltip>,
                <Tooltip title={deleteIcon ? 'Reset Constraint' : 'Delete Constraint'} key='delete'>
                  <GridActionsCellItem
                    key='delete'
                    disabled={
                    !row[dynamicDeleteKey] ||
                    row.disableAction
                  }
                    icon={deleteIcon || <DeleteIcon />}
                    label='Delete'
                    onClick={handleDeleteClick(row[`${dynamicDeleteKey}`], row)}
                    color='inherit'
                    sx={{ fontSize: '20px' }}
                  />
                </Tooltip>
              ]
              return actions
            }
          }
        ]
      : []]

  const [rows, setRows] = useState(data)
  let constraintData = []
  const deleteFieldData = []

  useEffect(() => {
    setRows(data)
    constraintData = []

    const updatedData = {}
    rows.forEach((d) => {
      updatedData[d?.id] = apiRef.current.getRowWithUpdatedValues(d?.id)
    })

    setRows(prevData => prevData.map((row, index) => (
      updatedData[row?.id] && (updatedData[row?.id]?.error || updatedData[row?.id]?.minError || updatedData[row?.id]?.maxError) &&
    row?.id === updatedData[row?.id]?.id)
      ? {
          ...row,
          error: updatedData[row?.id]?.error,
          errorMessage: updatedData[row?.id]?.errorMessage,
          minError: !!updatedData[row?.id]?.minError,
          maxError: !!updatedData[row?.id]?.maxError
        }
      : row))
  }, [data])

  const tempData = {}
  useEffect(() => {
    Object.keys(rowModesModel).forEach((d) => {
      tempData[d] = apiRef.current.getRowWithUpdatedValues(d)
    })
    setUpdatedRowValues(tempData)
    if (setRowModes) {
      setRowModes(rowModesModel)
    }
  }, [rowModesModel])

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleCellEditStart = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleCellEditStop = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleEditClick = (id, row) => () => {
    const updatedData = {}
    rows.forEach((d) => {
      updatedData[d?.id] = apiRef.current.getRowWithUpdatedValues(d?.id)
    })
    // change to edit mode for both cash level fields
    if (row.propertyCode === 'MIN_CASH_LEVEL' || row.propertyCode === 'MAX_CASH_LEVEL') {
      const minCashIndex = rows?.findIndex((item) => item.propertyCode === 'MIN_CASH_LEVEL')
      const maxCashIndex = rows?.findIndex((item) => item.propertyCode === 'MAX_CASH_LEVEL')
      setRowModesModel((prevState) => ({
        ...prevState,
        [rows[minCashIndex].id]: { mode: GridRowModes.Edit },
        [rows[maxCashIndex].id]: { mode: GridRowModes.Edit }
      }))
    } else {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
      setRows(prevData => prevData.map((row, index) => (
        updatedData[row?.id] && (updatedData[row?.id]?.error || updatedData[row?.id]?.minError || updatedData[row?.id]?.maxError) &&
      row?.id === updatedData[row?.id]?.id && row?.id !== id)
        ? {
            ...row,
            error: updatedData[row?.id]?.error,
            errorMessage: updatedData[row?.id]?.errorMessage || '',
            minError: !!updatedData[row?.id]?.minError,
            maxError: !!updatedData[row?.id]?.maxError
          }
        : (row?.id === id && updatedData[row?.id]?.error)
            ? { ...row, error: false, minError: false, maxError: false }
            : row))
    }
  }

  const handleSaveClick = (id, row) => () => {
  // save two fields simultaneously else only one field
    if (row.propertyCode === 'MIN_CASH_LEVEL' || row.propertyCode === 'MAX_CASH_LEVEL') {
      const minCashIndex = rows?.findIndex((item) => item.propertyCode === 'MIN_CASH_LEVEL')
      const maxCashIndex = rows?.findIndex((item) => item.propertyCode === 'MAX_CASH_LEVEL')
      if (!row.error && !row.minError && !row.maxError) {
        setRowModesModel((prevState) => ({
          ...prevState,
          [rows[minCashIndex].id]: { mode: GridRowModes.View },
          [rows[maxCashIndex].id]: { mode: GridRowModes.View }
        }))
      }
    } else {
      if (!row.error) {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
      }
    }
  }

  const handleConfirmDelete = (id, row) => () => {
    const updatedData = {}
    rows.forEach((d) => {
      updatedData[d?.id] = apiRef.current.getRowWithUpdatedValues(d?.id)
    })
    if (deleteKey === 'accountInstrRestrId' || deleteKey === 'aggGroupInstrRestrId') {
      if (deleteData) {
        deleteData(id)
      }
    } else {
      if (row.propertyCode === 'MIN_CASH_LEVEL' || row.propertyCode === 'MAX_CASH_LEVEL') {
        const minCashIndex = data?.findIndex((item) => item.propertyCode === 'MIN_CASH_LEVEL')
        const maxCashIndex = data?.findIndex((item) => item.propertyCode === 'MAX_CASH_LEVEL')
        if (rows?.filter((row) => row[`${deleteKey}`] === rows[minCashIndex]?.accountPropertiesId)?.length) {
          deleteFieldData?.push(rows[minCashIndex]?.accountPropertiesId)
        }
        if (rows?.filter((row) => row[`${deleteKey}`] === rows[maxCashIndex]?.accountPropertiesId)?.length) {
          deleteFieldData?.push(rows[maxCashIndex]?.accountPropertiesId)
        }
        if (deleteFieldData?.length === 2) {
          deleteData(deleteFieldData)
          setRows(rows.map((row) =>
            (row[`${deleteKey}`] === rows[minCashIndex].accountPropertiesId ||
            row[`${deleteKey}`] === rows[maxCashIndex].accountPropertiesId
              ? { ...row, [`${deleteKey}`]: null }
              : (updatedData[row?.id]?.error || updatedData[row?.id]?.minError || updatedData[row?.id]?.maxError) &&
            row?.id === updatedData[row?.id]?.id
            )
              ? {
                  ...row,
                  error: updatedData[row?.id]?.error,
                  errorMessage: updatedData[row?.id]?.errorMessage,
                  minError: updatedData[row?.id]?.maxError ? true : !!updatedData[row?.id]?.minError,
                  maxError: updatedData[row?.id]?.minError ? true : !!updatedData[row?.id]?.maxError
                }
              : row[`${deleteKey}`] === id
                ? { ...row, [`${deleteKey}`]: null }
                : row))
        }
      } else {
        if (!row.error) {
          setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
        }
        if (rows.filter((row) => (row[`${deleteKey}`] === id))?.length) {
          deleteData(id)
          setRows(prevData => prevData.map((row, index) => (
            (updatedData[row?.id]?.error || updatedData[row?.id]?.minError || updatedData[row?.id]?.maxError) &&
          row?.id === updatedData[row?.id]?.id
          )
            ? {
                ...row,
                error: updatedData[row?.id]?.error,
                errorMessage: updatedData[row?.id]?.errorMessage,
                minError: !!updatedData[row?.id]?.minError,
                maxError: !!updatedData[row?.id]?.maxError
              }
            : row[`${deleteKey}`] === id
              ? { ...row, [`${deleteKey}`]: null }
              : row))
        }
      }
    }
    setOpenDeleteDialog(false)
    setRowToDelete(null)
  }

  const handleCancelDelete = () => {
    setOpenDeleteDialog(false)
    setRowToDelete(null)
  }

  const handleCancelClick = (id, row) => () => {
    const updatedData = {}
    rows.forEach((d) => {
      updatedData[d?.id] = apiRef.current.getRowWithUpdatedValues(d?.id)
    })
    // cancel both cash level field changes
    if (row.propertyCode === 'MIN_CASH_LEVEL' || row.propertyCode === 'MAX_CASH_LEVEL') {
      const minCashIndex = data?.findIndex((item) => item.propertyCode === 'MIN_CASH_LEVEL')
      const maxCashIndex = data?.findIndex((item) => item.propertyCode === 'MAX_CASH_LEVEL')
      setRowModesModel((prevState) => ({
        ...prevState,
        [rows[minCashIndex].id]: { mode: GridRowModes.View, ignoreModifications: true },
        [rows[maxCashIndex].id]: { mode: GridRowModes.View, ignoreModifications: true }
      }))
      setRows(rows.map((row) => {
        if (row?.id === rows[minCashIndex]?.id || row?.id === rows[maxCashIndex]?.id) {
          return { ...row, error: false }
        }
        if (updatedData[row?.id] && (updatedData[row?.id]?.error || updatedData[row?.id]?.minError || updatedData[row?.id]?.maxError) && row?.id === updatedData[row?.id]?.id) {
          return {
            ...row,
            error: updatedData[row?.id]?.error,
            errorMessage: updatedData[row?.id].errorMessage,
            minError: !!updatedData[row?.id]?.minError,
            maxError: !!updatedData[row?.id]?.maxError
          }
        }
        return row
      }))
    } else {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true }
      })
      const editedRow = rows.find((row) => row?.id === id)
      if (editedRow.isNew) {
        setRows(rows.filter((row) => row?.id !== id))
      }
      setRows(prevData => prevData.map((row, index) => (
        (updatedData[row?.id]?.error || updatedData[row?.id]?.minError || updatedData[row?.id]?.maxError) &&
      row?.id === updatedData[row?.id]?.id && row?.id !== id
      )
        ? {
            ...row,
            error: updatedData[row?.id]?.error,
            errorMessage: updatedRowValues[row?.id]?.errorMessage || updatedData[row?.id]?.errorMessage,
            minError: !!(((updatedRowValues[row?.id]?.minError) || updatedData[row?.id]?.minError)),
            maxError: !!((updatedRowValues[row?.id]?.maxError || updatedData[row?.id]?.maxError))
          }
        : (row?.id === id && updatedData[row?.id]?.error)
            ? { ...row, error: false, minError: false, maxError: false }
            : row))
    }
  }

  const processRowUpdate = (newRow) => {
    const updatedData = {}
    rows.forEach((d) => {
      updatedData[d?.id] = apiRef.current.getRowWithUpdatedValues(d?.id)
    })
    if (newRow.propertyCode === 'MIN_CASH_LEVEL' || newRow.propertyCode === 'MAX_CASH_LEVEL') {
      const updatedRow = { ...newRow }
      // setRows(prevData => prevData.map((row) => (row?.id === newrow?.id ? updatedRow : row)))
      setRows(prevData =>
        prevData.map((row) =>
          row?.id === newRow?.id
            ? updatedRow
            : (updatedData[row?.id] && (updatedData[row?.id]?.error || updatedData[row?.id]?.minError || updatedData[row?.id]?.maxError) &&
            row?.id === updatedData[row?.id]?.id)
                ? {
                    ...row,
                    error: updatedData[row?.id]?.error,
                    errorMessage: updatedData[row?.id]?.errorMessage,
                    minError: !!updatedData[row?.id]?.minError,
                    maxError: !!updatedRowValues[row?.id]?.maxError
                  }
                : row
        )
      )
      constraintData.push(updatedRow)
      // pass multiple field to editData
      // ex. here we are passing two fields min cash and max cash in array form else pass only single field
      if (constraintData?.length === 2) {
        if (customValidations) {
          if (!customValidations(constraintData)) {
            editData(constraintData)
          }
        } else {
          editData(constraintData)
        }
      }
      return updatedRow
    } else {
      const updatedRow = { ...newRow }
      setRows(prevData =>
        prevData.map((row) =>
          row?.id === updatedRow?.id
            ? updatedRow
            : (updatedData[row?.id] && (updatedData[row?.id]?.error || updatedData[row?.id]?.minError || updatedData[row?.id]?.maxError) &&
            row?.id === updatedData[row?.id]?.id)
                ? {
                    ...row,
                    error: updatedData[row?.id]?.error,
                    errorMessage: updatedData[row?.id]?.errorMessage,
                    minError: !!updatedData[row?.id]?.minError,
                    maxError: !!updatedData[row?.id]?.maxError
                  }
                : row
        )
      )
      if (customValidations) {
        if (!customValidations(updatedRow)) {
          editData(updatedRow)
        }
      } else {
        editData(updatedRow)
      }
      return updatedRow
    }
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  function getRowId (row) {
    return row?.id
  }

  return (
    <>
      <Box
        sx={{
          '.MuiDataGrid-footerContainer': {
            border: 'none'
          },
          '.MuiDataGrid-root': {
            border: 'none'
          },
          '.MuiDataGrid-row--editing': {
            background: 'rgba(25, 118, 210, 0.08) !important'
          },
          '.MuiDataGrid-row--editing .MuiDataGrid-cell': {
            backgroundColor: 'inherit !important'
          },
          '.MuiDataGrid-cell--editing': {
            padding: '0 10px !important'
          }
        }}
      >
        <div style={{ width: '100%' }}>
          <DataGridPro
            autoHeight
            rows={rows}
            columns={columns}
            getRowId={getRowId}
            pageSizeOptions={[10]}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } }
            }}
            pagination={showPagination}
            disableRowSelectionOnClick
            editMode='row'
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            apiRef={apiRef}
            onCellEditStart={handleCellEditStart}
            onCellEditStop={handleCellEditStop}
            processRowUpdate={processRowUpdate}
            getRowClassName={(params) => rowModesModel[params.row?.id]?.mode === GridRowModes.Edit ? 'MuiDataGrid-row--editing' : ''}
            isCellEditable={(params) => {
              if (params?.field === 'weight') {
                return params?.row?.restrictionDesc === 'Hold Minimum' || params?.row?.restrictionDesc === 'Hold Maximum'
              } else {
                return true
              }
            }}
            sx={{
              fontFamily: 'Open Sans',
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 600,
                fontFamily: 'Open Sans',
                color: '#34475A'
              },
              '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                fontWeight: 600,
                fontFamily: 'Open Sans',
                color: '#74788d'
              }
            }}
          />
        </div>
      </Box>
      <Modal
        open={openDeleteDialog}
        onClose={handleCancelDelete}
      >
        <Box sx={deletePopupStyle}>
          <Typography
            sx={{ fontWeight: 600, color: '#002A59', fontFamily: 'Open Sans', fontSize: '18px' }}
          >
            Remove Restriction
          </Typography>
          <Box>
            <Typography sx={{ fontSize: '16px' }}>
              Please review restriction details before removing
            </Typography>
            <Divider sx={{ padding: '4px', mb: 1 }} />
            {initialColumns?.map((column) => {
              if (rowToDelete?.row?.hasOwnProperty(column.field)) {
                let value = rowToDelete.row[column.field]
                // Check if the column has a custom valueFormatter or valueGetter
                if (column.valueFormatter) {
                  value = column.valueFormatter({ value })
                } else if (column.valueGetter) {
                  value = column.valueGetter({ row: rowToDelete.row })
                }
                if (column.field === 'sectorExclude' || column.field === 'industryExclude' || column.field === 'isDefault') {
                  value = value === 1 ? 'Yes' : 'No'
                }
                return (
                  <Box sx={{ display: 'flex' }} key={column.field}>
                    <Typography sx={{ color: '#74788D', width: '50%' }}>
                      {column.headerName}
                    </Typography>
                    <Typography sx={{ color: '#2F2F2F', width: '50%' }}>
                      {value !== null && value !== undefined ? value : '-'}
                    </Typography>
                  </Box>
                )
              }
              return null
            })}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <Button onClick={handleCancelDelete} color='primary' size='small' sx={{ fontWeight: 600, fontSize: '12px' }}>
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete(rowToDelete?.id, rowToDelete)} variant='contained' size='small' sx={{ fontSize: '12px' }}>
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>

    </>
  )
}

export default DataTable

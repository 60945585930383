import React, { useEffect } from 'react'
import { InputAdornment, MenuItem, Select, TextField, Box } from '@mui/material'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined'
import { useGridApiContext, GridRowModes } from '@mui/x-data-grid-pro'
import { isNumberWithSign, numberWithSignPasteHandler, isWholeNumber, wholeNumberPasteHandler } from '../../../utils/NumberUtils'
import { TaxSensitivity } from '../../../contstants/constants'
import { InputAdornmentTextField } from '../../../pages/AccountOnboarding/components/inputAdornmentTextfield'

const startAdornmentFields = ['MINCASH', 'CASH_RAISE', 'MIN_BUY_SIZE', 'maxShortTermGain', 'maxLongTermGain']
const endAdornmentFields = ['MIN_CASH_LEVEL', 'MAX_CASH_LEVEL', 'MIN_BUY_WEIGHT', 'MIN_TRD_SIZE']

export const CustomEditConstraintField = (props) => {
  const { id, value, field, row, disabled, filteredScenarios } = props
  const apiRef = useGridApiContext()

  let error = false
  let errorMessage = ''

  const data = []

  apiRef.current.getRowModels().forEach((row) => {
    data.push(JSON.parse(JSON.stringify(row)))
  })

  useEffect(() => {
    handleBlur()
  }, [value])

  const handleMinMaxCashChange = (numericValue, checkEmptyFieldError = false) => {
    const minCashIndex = data?.findIndex((item) => item.propertyCode === 'MIN_CASH_LEVEL')
    const maxCashIndex = data?.findIndex((item) => item.propertyCode === 'MAX_CASH_LEVEL')
    const minCashRow = apiRef.current.getRow(data[minCashIndex]?.id)
    const maxCashRow = apiRef.current.getRow(data[maxCashIndex]?.id)

    if (row.propertyCode === 'MAX_CASH_LEVEL') {
      apiRef.current.updateRows([{ id: data[maxCashIndex]?.id, propertyValue: parseFloat(numericValue) }])
    } else if (row.propertyCode === 'MIN_CASH_LEVEL') {
      apiRef.current.updateRows([{ id: data[minCashIndex]?.id, propertyValue: parseFloat(numericValue) }])
    }

    // find value for both field by index
    const maxCashValueData = parseFloat(maxCashRow?.propertyValue)
    const minCashValueData = parseFloat(minCashRow?.propertyValue)

    if ((row.propertyCode === 'MIN_CASH_LEVEL' && (numericValue > 100 || numericValue < 0)) ||
      (row.propertyCode === 'MAX_CASH_LEVEL' && (numericValue > 100 || numericValue < 0))) {
      error = true
      errorMessage = 'Value must be between 0 and 100'
    }

    if (row.propertyCode === 'MAX_CASH_LEVEL') {
      if (numericValue <= minCashValueData && (numericValue > 0 && numericValue < 100)) {
        error = true
        errorMessage = 'Maximum cash level should be greater than min cash level'
        // find min cash field and update its error
        apiRef.current.updateRows([{
          id: data[minCashIndex]?.id,
          error,
          errorMessage: (minCashValueData < 100 && minCashValueData > 0)
            ? 'Minimum cash level should be less than max cash level'
            : data[minCashIndex]?.errorMessage,
          maxError: maxCashRow.error
        }])
      } else if ((minCashValueData >= 0 && minCashValueData < 100)) {
        // Clear the error for Min Cash if Max Cash value is valid
        apiRef.current.updateRows([{
          id: data[minCashIndex]?.id,
          error: false,
          errorMessage: '',
          maxError: maxCashRow.error
        }])
      }
    } else if (row.propertyCode === 'MIN_CASH_LEVEL') {
      if (numericValue >= maxCashValueData &&
        (maxCashValueData >= 0 && maxCashValueData < 100)) {
        error = true
        errorMessage = (numericValue > 0 && numericValue < 100) ? 'Minimum cash level should be less than max cash level' : 'Value must be between 0 and 100'
        // find max cash field and update
        apiRef.current.updateRows([{
          id: data[maxCashIndex]?.id,
          error,
          errorMessage: 'Maximum cash level should be greater than min cash level',
          minError: minCashRow.error
        }])
      } else if ((maxCashValueData >= 0 && maxCashValueData < 100)) {
        // Clear the error for Max Cash if Min Cash value is valid
        apiRef.current.updateRows([{ id: data[maxCashIndex]?.id, error: false, errorMessage: '', minError: minCashRow.error }])
      }
    }

    // check for diff if max > min cash >> 0.5
    if (apiRef.current.getRow(data[maxCashIndex]?.id)?.propertyValue > apiRef.current.getRow(data[minCashIndex]?.id)?.propertyValue) {
      if ((Math.abs(apiRef.current.getRow(data[maxCashIndex]?.id)?.propertyValue - apiRef.current.getRow(data[minCashIndex]?.id)?.propertyValue)).toFixed(2) < 0.5) {
        error = true
        errorMessage = 'Difference between min and max cash level should be a minimum of 0.5%'
        apiRef.current.updateRows([
          { id: data[minCashIndex]?.id, error, errorMessage },
          { id: data[maxCashIndex]?.id, error, errorMessage }
        ])
      }
    }
  }

  // add error message on blur
  const handleBlur = () => {
    if (row.propertyCode === 'MAX_CASH_LEVEL' || row.propertyCode === 'MIN_CASH_LEVEL') {
      const minCashIndex = data?.findIndex((item) => item.propertyCode === 'MIN_CASH_LEVEL')
      const maxCashIndex = data?.findIndex((item) => item.propertyCode === 'MAX_CASH_LEVEL')
      const minCashRow = apiRef.current.getRow(data[minCashIndex]?.id)
      const maxCashRow = apiRef.current.getRow(data[maxCashIndex]?.id)
      if (!minCashRow.propertyValue && !maxCashRow.propertyValue) {
        apiRef.current.updateRows([{
          id: data[minCashIndex].id,
          error: false,
          errorMessage: '',
          maxError: false
        },
        {
          id: data[maxCashIndex].id,
          error: false,
          errorMessage: '',
          minError: false
        }])
      } else {
        apiRef.current.updateRows([{
          id: data[minCashIndex].id,
          error: minCashRow.error || Boolean(!minCashRow.propertyValue && maxCashRow.propertyValue),
          errorMessage: !minCashRow.error && (!minCashRow.propertyValue && maxCashRow.propertyValue) ? 'Min cash is required' : minCashRow.errorMessage,
          maxError: maxCashRow.error || Boolean(!maxCashRow.propertyValue && minCashRow.propertyValue)
        },
        {
          id: data[maxCashIndex].id,
          error: maxCashRow.error || Boolean(!maxCashRow.propertyValue && minCashRow.propertyValue),
          errorMessage: !maxCashRow.error && (!maxCashRow.propertyValue && minCashRow.propertyValue) ? 'Max cash is required' : maxCashRow.errorMessage,
          minError: minCashRow.error || Boolean(!minCashRow.propertyValue && maxCashRow.propertyValue)
        }])
      }
    }
  }

  const handleValueChange = (event) => {
    const newValue = event.target.value
    let error = false
    let errorMessage = ''
    if (row.propertyCode === 'DEFAULT_SCENARIO_CODE') {
      if (!newValue || newValue === 'NA') {
        error = true
        errorMessage = 'Please select a valid scenario.'
      } else {
        const selectedScenario = filteredScenarios.find(
          (scenario) => scenario.scenarioCode === newValue
        )
        if (!selectedScenario) {
          error = true
          errorMessage = 'Selected scenario is invalid.'
        }
      }
    } else {
      // Handle other propertyCode validations
      const numericValue = parseFloat(newValue)

      if (event.target.value === '-') {
        error = true
      } else {
        if (row.propertyCode === 'CASH_RAISE') {
          if (numericValue <= 0) {
            error = true
            errorMessage = 'Value must be greater than 0'
          }
        } else if (
          row.propertyCode === 'MAX_CASH_LEVEL' ||
          row.propertyCode === 'MIN_CASH_LEVEL'
        ) {
          handleMinMaxCashChange(numericValue)
        } else if (
          row.propertyCode === 'WASHSALE_RES_HOLDING_PERIOD') {
          if (numericValue < 1 || numericValue > 30) {
            error = true
            errorMessage = 'Value must be between 1 and 30 days'
          }
        } else if (row.propertyCode === 'MIN_BUY_WEIGHT') {
          if (newValue < 0 || newValue > 100) {
            error = true
          }
        } else if (
          row.propertyCode === 'MINCASH' ||
          row.propertyCode === 'MIN_BUY_SIZE'
        ) {
          if (newValue <= 0) {
            error = true
            errorMessage = 'Value must be greater than 0'
          }
        } else if (
          row.propertyCode === 'maxLongTermGain' ||
          row.propertyCode === 'maxShortTermGain' ||
          row.propertyCode === 'MIN_TRD_SIZE' ||
          row.propertyCode === 'TAX_LAMBDA' ||
          row.propertyCode === 'RISK_LAMBDA' ||
          row.propertyCode === 'TXN_COST_LAMBDA' ||
          row.propertyCode === 'FIXED_TXN_COST_LAMBDA'
        ) {
          if (
            numericValue < 0 ||
            (row.propertyCode === 'MIN_TRD_SIZE' && event.target.value.includes('.'))
          ) {
            error = true
            errorMessage = 'Value cannot be lower than 0'
          }
        } else if (
          row.propertyCode === 'MIN_BETA' ||
          row.propertyCode === 'MAX_BETA'
        ) {
          if (numericValue < -2 || numericValue > 2) {
            error = true
            errorMessage = 'Value must be between -2 and 2'
          }
        } else if (row.propertyCode === 'MAX_HOLDINGS') {
          if (numericValue < 1 || event.target.value.includes('.')) {
            error = true
          }
        }
      }
    }

    // Update the value in the grid and set errors if any
    apiRef.current.setEditCellValue({ id, field, value: newValue })
    apiRef.current.updateRows([{ id, error, errorMessage }])
  }

  return (
    <>
      {row.propertyCode === 'taxSensitivity'
        ? (
          <Select
            labelId='tax-sen-label'
            disabled={disabled}
            size='small'
            value={value || 'NA'}
            sx={{ width: '250px' }}
            onChange={(event) => handleValueChange(event)}
          >
            <MenuItem value='NA'>None</MenuItem>
            {Object.keys(TaxSensitivity).map((option, i) => (
              <MenuItem key={i} value={option}>
                {TaxSensitivity[option]}
              </MenuItem>
            ))}
          </Select>
          )
        : row.propertyCode === 'WASHSALE_RES_HOLDING_PERIOD'
          ? (
            <TextField
              label='Value'
              size='small'
              sx={{ width: '250px' }}
              disabled={disabled}
              value={value !== null && value !== undefined ? value : 30}
              onKeyDown={(event) => isWholeNumber(event)}
              onPaste={wholeNumberPasteHandler}
              onChange={(event) => handleValueChange(event)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Box sx={{ fontSize: 12, color: 'rgba(0, 0, 0, 0.6)' }}>days</Box>
                  </InputAdornment>
                )
              }}
              inputProps={{
                min: 1,
                max: 30
              }}
              error={Boolean(row.error)}
            />
            )
          : row.propertyCode === 'DEFAULT_SCENARIO_CODE'
            ? <Select
                labelId='scenario-select-label'
                disabled={disabled}
                size='small'
                value={value}
                sx={{ width: '250px' }}
                onChange={(event) => handleValueChange(event)}
              >
              {filteredScenarios?.map((scenario, index) => (
                <MenuItem key={index} value={scenario.scenarioCode}>
                  {scenario.scenarioDescription}
                </MenuItem>
              ))}
              </Select>
            : startAdornmentFields.includes(row.propertyCode)
              ? <InputAdornmentTextField
                  value={value !== null ? value : ''}
                  label='Value'
                  size='small'
                  name={row.propertyCode}
                  sx={{ width: '250px' }}
                  disabled={disabled}
                  onKeyDown={(event) => isNumberWithSign(event)}
                  onPaste={numberWithSignPasteHandler}
                  onChange={(event) => handleValueChange(event)}
                  onBlur={handleBlur}
                  error={Boolean(row?.error && (row?.propertyValue || row?.value))}
                  adornment={startAdornmentFields.includes(row.propertyCode) ? <AttachMoneyOutlinedIcon /> : null}
                />
              : <TextField
                  label='Value'
                  size='small'
                  sx={{ width: '250px' }}
                  disabled={disabled}
                  value={value !== null ? value : ''}
                  onKeyDown={(event) => isNumberWithSign(event)}
                  onPaste={numberWithSignPasteHandler}
                  onChange={(event) => handleValueChange(event)}
                  onBlur={handleBlur}
                  InputProps={{
                    error: Boolean(row.error),
                    endAdornment: endAdornmentFields.includes(row.propertyCode)
                      ? <InputAdornment
                          position='end'
                        >
                        {
                      row.propertyCode === 'MIN_TRD_SIZE'
                        ? <MinTradeIcon
                            title='bps'
                          />
                        : <PercentOutlinedIcon sx={{
                          width: 18,
                          height: 18
                        }}
                          />
                    }
                        </InputAdornment>
                      : ''
                  }}
                />}
    </>
  )
}

const MinTradeIcon = ({ title }) => (
  <Box
    sx={{
      width: 27,
      height: 22
    }}
  >
    {title}
  </Box>
)

export const CustomConstraintField = ({ params, rowModesModel, filteredScenarios }) => {
  const value = ((params.row.propertyCode === 'maxLongTermGain' || params.row.propertyCode === 'maxShortTermGain') && params.value === 0) ? null : params.value
  return (
    <>
      {params.row.propertyCode === 'taxSensitivity'
        ? <Select
            labelId='tax-sen-label'
            disabled={rowModesModel[params.row.id]?.mode !== GridRowModes.Edit}
            size='small'
            value={value || 'NA'}
            sx={{
              width: '250px',
              '.Mui-disabled': {
                WebkitTextFillColor: value !== null && rowModesModel[params.row.id]?.mode !== GridRowModes.Edit ? '#34475A !important' : 'rgba(0, 0, 0, 0.38)'
              }
            }}
          >
          <MenuItem value='NA'>
            None
          </MenuItem>
          {Object.keys(TaxSensitivity).map((option, i) => (
            <MenuItem key={i} value={option}>{TaxSensitivity[option]}</MenuItem>
          ))}
        </Select>
        : params.row.propertyCode === 'DEFAULT_SCENARIO_CODE'
          ? (
            <Select
              labelId='scenario-select-label'
              disabled={rowModesModel[params.row.id]?.mode !== GridRowModes.Edit}
              size='small'
              value={params.row.propertyValue}
              sx={{
                width: '250px',
                '.Mui-disabled': {
                  WebkitTextFillColor:
                params.row.propertyValue !== 'NA' && rowModesModel[params.row.id]?.mode !== GridRowModes.Edit
                  ? '#34475A !important'
                  : 'rgba(0, 0, 0, 0.38)'
                }
              }}
              onChange={(event) => {
                const newValue = event.target.value
                params.row.propertyValue = newValue
              }}
            >
              {filteredScenarios?.map((scenario, index) => (
                <MenuItem key={index} value={scenario.scenarioCode}>
                  {scenario.scenarioDescription}
                </MenuItem>
              ))}
            </Select>)
          : params.row.propertyCode === 'WASHSALE_RES_HOLDING_PERIOD'
            ? (
              <TextField
                label='Value'
                size='small'
                sx={{
                  width: '250px',
                  '.Mui-disabled': {
                    WebkitTextFillColor:
                  params.row.propertyValue !== 'NA' && rowModesModel[params.row.id]?.mode !== GridRowModes.Edit
                    ? '#34475A !important'
                    : 'rgba(0, 0, 0, 0.38)'
                  }
                }}
                value={value !== null && value !== undefined ? value : 30}
                onKeyDown={(event) => isWholeNumber(event)}
                onPaste={wholeNumberPasteHandler}
                disabled={rowModesModel[params.row.id]?.mode !== GridRowModes.Edit}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Box sx={{ fontSize: 12, color: 'rgba(0, 0, 0, 0.6)' }}>days</Box>
                    </InputAdornment>
                  )
                }}
                error={Boolean(params.row.error)}
              />
              )
            : startAdornmentFields.includes(params.row.propertyCode)
              ? <InputAdornmentTextField
                  value={String(value !== null ? value : '')}
                  label='Value'
                  size='small'
                  sx={{
                    width: '250px',
                    '.Mui-disabled': {
                      WebkitTextFillColor: value !== null && rowModesModel[params.row.id]?.mode !== GridRowModes.Edit ? '#34475A !important' : 'rgba(0, 0, 0, 0.38)'
                    }
                  }}
                  name={params.row.propertyCode}
                  disabled={rowModesModel[params.row.id]?.mode !== GridRowModes.Edit}
                  onKeyDown={(event) => isNumberWithSign(event)}
                  onPaste={numberWithSignPasteHandler}
                  adornment={startAdornmentFields.includes(params.row.propertyCode) ? <AttachMoneyOutlinedIcon /> : null}
                />
              : <TextField
                  label='Value'
                  size='small'
                  sx={{
                    width: '250px',
                    '.Mui-disabled': {
                      WebkitTextFillColor: value !== null && rowModesModel[params.row.id]?.mode !== GridRowModes.Edit ? '#34475A !important' : 'rgba(0, 0, 0, 0.38)'
                    }
                  }}
                  disabled={rowModesModel[params.row.id]?.mode !== GridRowModes.Edit}
                  value={value !== null ? value : ''}
                  onKeyDown={(event) => isNumberWithSign(event)}
                  onPaste={numberWithSignPasteHandler}
                  InputProps={{
                    error: Boolean(params.row.error),
                    endAdornment: endAdornmentFields.includes(params.row.propertyCode)
                      ? <InputAdornment
                          position='end'
                        >{
                      params.row.propertyCode === 'MIN_TRD_SIZE'
                        ? <MinTradeIcon
                            title='bps'
                          />
                        : <PercentOutlinedIcon sx={{
                          width: 18,
                          height: 18
                        }}
                          />
                    }
                        </InputAdornment>
                      : ''
                  }}
                />}
    </>

  )
}

import React, { useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import { API } from 'aws-amplify'
import { Button, DialogContent, DialogTitle, TextField, Autocomplete, debounce } from '@mui/material'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'

dayjs.extend(utc)

const PortfolioSpecificationInputForm = ({ savedData, type, sponsorId }) => {
  const [loading, setLoading] = useState(false)
  const { user } = useAuth()
  const [strategyOptions, setStrategyOptions] = useState([])
  const [selectedStrategyValue, setSelectedStrategyValue] = useState(null)
  const { showError } = useErrorToast()
  const [strategyInputValue, setStrategyInputValue] = useState('')

  const initialFormValues = {
    portSpecCode: '',
    portSpecName: '',
    strategyId: selectedStrategyValue,
    sponsorId: sponsorId || ''
    // startDate: dayjs.utc(null).format('YYYY-MM-DD')
  }

  const formik = useFormik({
    initialValues: initialFormValues,
    onSubmit: (values) => {
      savedData(values)
    }
  })

  const getAllStrategy = async (strategyName) => {
    return API.get('baseStrategyURL', `strategy/v1/${user.userGroup}/strategy-master`, {
      queryStringParameters: {
        ...{
          ...strategyName
            ? {
                strategyName
              }
            : {}
        },
        resources: encodeURIComponent(JSON.stringify({
          serviceId: 'model',
          resourceId: 'port-spec'
        }))
      }
    })
  }

  const handleInputChange = useMemo(
    () =>
      debounce(async (request) => {
        if (request) {
          // prevent search API call which is triggered on option click
          if (!selectedStrategyValue || !request?.trim()?.startsWith(selectedStrategyValue?.strategyId)) {
            const optionsList = await getAllStrategy(request?.trim())
            if (optionsList?.error) {
              showError(optionsList?.error, false, {}, 'Failed to load strategy options.')
            } else if (optionsList?.data) {
              setStrategyOptions([...optionsList.data])
            }
            setLoading(false) 
          }
          else {
            setLoading(false)
          }
         
        }
      }, 400),
    [selectedStrategyValue]
  )

  useEffect(() => {
    if (strategyInputValue.trim().length >= 3) {
      setLoading(true)
      handleInputChange(strategyInputValue)
    }
  }, [strategyInputValue])

  return (
    <>
      <DialogTitle>Add Data</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Autocomplete
            disablePortal
            isOptionEqualToValue={(option, value) => option?.strategyId === value?.strategyId}
            id='str'
            disableClearable
            loading={loading}
            fullWidth
            value={selectedStrategyValue}
            onChange={(event, newValue) => {
              formik.setFieldValue('strategyId', newValue?.strategyId)
              setSelectedStrategyValue(newValue)
            }}
            getOptionLabel={(option) => (`${option.strategyId} (${option?.strategyName})`)}
            onInputChange={(_, newInputValue) => setStrategyInputValue(newInputValue)}
            inputValue={strategyInputValue}
            options={strategyOptions}
            renderInput={(params) => <TextField {...params} label='strategyId' variant='standard' fullWidth margin='dense' />}
          />
          <Autocomplete
            id='spn'
            readOnly
            value={null}
            defaultValue={sponsorId || null}
            inputValue={sponsorId}
            renderInput={(params) => (
              <TextField {...params} label='sponsorId' variant='standard' fullWidth margin='dense' />
            )}
          />
          <TextField
            margin='dense'
            id='portSpecCode'
            name='portSpecCode'
            label='Port Code'
            type='text'
            fullWidth
            variant='standard'
            onChange={formik.handleChange}
            value={formik.values.portSpecCode}
          />
          <TextField
            margin='dense'
            id='portSpecName'
            name='portSpecName'
            label='Port Name'
            type='text'
            fullWidth
            variant='standard'
            onChange={formik.handleChange}
            value={formik.values.portSpecName}
          />
          {/* <TextField
            margin='dense'
            id='start date'
            name='start date'
            label='Start Date'
            type='date'
            fullWidth
            variant='standard'
            value={formik.values.startDate}
            onChange={(e) => {
        	  formik.setFieldValue('startDate', dayjs.utc(e.target.value).format('YYYY-MM-DD'))
        	  }}
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { min: dayjs.utc().format('YYYY-MM-DD') } }}
          /> */}
          <Button color='primary' variant='contained' fullWidth type='submit' sx={{ mt: 2 }}>
            Create
          </Button>
        </form>
      </DialogContent>
    </>
  )
}

export default PortfolioSpecificationInputForm

import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import { useDispatch, useSelector } from 'react-redux'
import { Autocomplete, Box, FormControl, Grid, TextField, Typography } from '@mui/material'
import { useAuth } from '../../../contexts/AuthContext'
import { SponsorIdContext } from '../../../contexts/IPSSponsorContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { storeSelectedSponsor, storeSponsorList } from '../../../store/user-reducer/user-reducer'
import Loader from '../../Loader'

const IPSPage = ({ title, className, children }) => {
  const { sendSentryError } = useErrorToast()
  const storedSponsorId = localStorage.getItem('ips-sponsor-id')
  const { allowedSponsorList, selectedSponsor } = useSelector(state => state.user)
  const dispatch = useDispatch()
  const [sponsorId, setSponsorId] = useState(storedSponsorId ? (typeof storedSponsorId === 'object' && JSON.parse(storedSponsorId).sponsorId) ? storedSponsorId : { sponsorId: '-1' } : { sponsorId: '-1' })
  const [sponsorsList, setSponsorsList] = useState([])
  const { user } = useAuth()
  const [loading, setLoading] = useState(false)

  const getSponserIds = async () => {
    setLoading(true)
    if (allowedSponsorList?.length) {
      setSponsorsList(allowedSponsorList)
      if (user?.userGroup === 'adv-classic') {
        setSponsorId(allowedSponsorList[0])
        localStorage.setItem('ips-sponsor-id', JSON.stringify(allowedSponsorList[0]))
      }
      if (typeof storedSponsorId === 'string') {
        const selectedSponsor = allowedSponsorList.find((sponsor) => sponsor.sponsorId === storedSponsorId)
        if (selectedSponsor) {
          setSponsorId(selectedSponsor)
          localStorage.setItem('ips-sponsor-id', JSON.stringify(selectedSponsor))
        }
      }
      setLoading(false)
    } else {
      API.get('baseSponserURL', `data-maintenance/v1/${user?.userGroup}/sponsorIds`)
        .then(response => {
          if (user?.userGroup === 'adv-classic') {
            setSponsorId(response?.data?.data[0])
            localStorage.setItem('ips-sponsor-id', JSON.stringify(response?.data?.data[0]))
          }
          if (response.data.data) {
            setSponsorsList(response.data.data)
            dispatch(storeSponsorList(response.data.data))
            if (typeof storedSponsorId === 'string') {
              const selectedSponsor = response.data.data.find((sponsor) => sponsor.sponsorId === storedSponsorId)
              if (selectedSponsor) {
                setSponsorId(selectedSponsor)
                localStorage.setItem('ips-sponsor-id', JSON.stringify(selectedSponsor))
              }
            }
          }
        })
        .catch(error => {
          sendSentryError(error)
        })
        .finally(() => setLoading(false))
    }
  }

  useEffect(() => {
    if (user) {
      getSponserIds()
    }
  }, [user])

  // Effect to execute when sponsor is selected from search
  useEffect(() => {
    if (selectedSponsor && selectedSponsor) {
      const selectedSponsorObj = allowedSponsorList.find((sponsor) => sponsor.sponsorId === selectedSponsor)
      if (selectedSponsor) {
        setSponsorId(selectedSponsorObj)
        localStorage.setItem('ips-sponsor-id', JSON.stringify(selectedSponsorObj))
      }
    }
  }, [selectedSponsor])

  return (
    <SponsorIdContext.Provider value={{ selectedSponsorId: sponsorId }}>
      {loading ? <Loader /> : ''}
      <Box className={className} width='100%' mt={3}>
        <Grid container xs={12} justifyContent='space-between' alignItems='center' mb={2}>
          <Grid item>
            <Typography
              sx={{
                color: '#34475A',
                fontFamily: 'Lora',
                fontWeight: 400,
                fontSize: '26px',
                marginTop: '0px'
              }}
            >
              {title}
            </Typography>
          </Grid>
          {user?.userGroup === 'pm'
            ? <Grid item>
              <FormControl>
                <Autocomplete
                  id='sponsor-autocomplete'
                  options={sponsorsList}
                  size='small'
                  disableClearable
                  sx={{ minWidth: '160px' }}
                  getOptionLabel={(option) => option.sponsorName}
                  value={
                        sponsorsList.find((sponsor) => sponsor.sponsorId === sponsorId.sponsorId) || null
                      }
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setSponsorId(newValue)
                    } else {
                      setSponsorId({ sponsorId: '-1' })
                      newValue = { sponsorId: '-1' }
                    }
                    localStorage.setItem('ips-sponsor-id', JSON.stringify(newValue))
                  }}
                  filterOptions={(options, { inputValue }) =>
                    options.filter(
                      (option) =>
                        option.sponsorName.toLowerCase().includes(inputValue.toLowerCase())
                    )}
                  renderInput={(params) => (
                    <TextField {...params} label='Sponsor' variant='outlined' />
                  )}
                />
              </FormControl>
            </Grid>
            : ''}
        </Grid>
        {children}
      </Box>
    </SponsorIdContext.Provider>
  )
}

export default IPSPage
import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CancelIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Box, Button, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'

const CreateWashSaleRestrictionForm = ({ handleCancelClick, savedData, createApiLoading, setCreateApiLoading, sourceAccountsList }) => {
  const { showError } = useErrorToast()
  const [isLoadingSource, setIsLoadingSource] = useState(false)
  const [securityOptions, setSecurityOptions] = useState([])
  const [restrictionCodes, setRestrictionCodes] = useState([])
  const { user } = useAuth()

  const createFormValidationSchema = Yup.object().shape({
    instrId: Yup.string().trim()
      .required('Instrument Id Required'),
    restrictionId: Yup.string().trim()
      .required('Restriction Type Required'),
    sourceAccountCd: Yup.string().trim()
      .required('Source Account Code Required'),
    startDate: Yup.date()
      .nullable()
      .required('Start Date is required'),
    endDate: Yup.date()
      .nullable()
      .min(Yup.ref('startDate'), 'End Date must be after the Start Date'),
    isExternalRestriction: Yup.boolean().required()
  })

  const initialFormValues = {
    instrId: '',
    restrictionId: '',
    sourceAccountCd: null,
    isExternalRestriction: false,
    startDate: null,
    endDate: null
  }

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: createFormValidationSchema,
    onSubmit: (values) => {
      setCreateApiLoading(true)
      savedData(values)
    }
  })

  useEffect(() => {
    if (user) {
      getRestrictionCodes()
    }
  }, [user])

  const onAutocompleteValueChange = (e, newValue, type) => {
    if (type === 'Source Account Code') {
      const accountCode = newValue?.accountCd || null
      formik.setFieldValue('sourceAccountCd', accountCode)
      formik.setFieldError('sourceAccountCd', '')
    } else if (type === 'Security Name') {
      const instrId = newValue?.instrId || ''
      formik.setFieldValue('instrId', instrId)
      formik.setFieldError('instrId', '')
    }
  }

  const onSecurityInputChange = (e) => {
    const query = e?.target?.value?.toString().trim() || ''
    if (query?.length >= 2) {
      setIsLoadingSource(true)
      API.get('baseUriTransactionalMaster', `transactional-master/v1/${user?.userGroup}/instruments`, {
        queryStringParameters: { search: query }
      })
        .then((response) => {
          if (response?.data) {
            setSecurityOptions([...response?.data])
            setIsLoadingSource(false)
          }
        })
        .catch((error) => {
          showError(error, false, {}, 'Failed to load instruments')
        })
        .finally(() => setIsLoadingSource(false))
    }
  }

  const getRestrictionCodes = async () => {
    API.get(
      'baseUriTransactionalMaster',
      `transactional-master/v1/${user?.userGroup}/restriction-codes`
    )
      .then((response) => {
        if (response.data) {
          const filteredRestrictionCodes = response.data.filter((res) => res.restrictionCode === 'ONB' || res.restrictionCode === 'ONS')
          setRestrictionCodes([...filteredRestrictionCodes])
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load restriction codes')
      })
  }

  const onRestrictionCodeChange = (event) => {
    if (event.target.value !== '') {
      formik.setFieldValue('restrictionId', event.target.value)
      formik.setFieldError('restrictionId', '')
    }
  }

  return (
    <>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', paddingBottom: 0 }}>
        <Typography
          variant='h6'
          textAlign='center'
          color='#34475A'
          fontWeight={400}
        >
          Add Restriction
        </Typography>
        <IconButton onClick={() => { handleCancelClick() }}>
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} direction='column' sx={{ mt: '1px' }}>
            <Grid item>
              <Autocomplete
                size='small'
                id='instrId'
                options={securityOptions}
                loading={isLoadingSource}
                getOptionLabel={(option) => `${option?.name} - ${option?.isoCountryCode}` || ''}
                onInputChange={onSecurityInputChange}
                onChange={(e, newValue) => onAutocompleteValueChange(e, newValue, 'Security Name')}
                value={securityOptions.find(option => option.instrId === formik.values.instrId) || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Security Name'
                    error={Boolean(formik.errors.instrId && formik.touched.instrId)}
                    helperText={
                      formik.errors.instrId &&
                      formik.touched.instrId &&
                      String(formik.errors.instrId)
                    }
                    variant='outlined'
                    InputProps={{ ...params.InputProps }}
                    FormHelperTextProps={{
                      style: { marginLeft: 0 }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <FormControl fullWidth error={Boolean(formik.errors.restrictionId && formik.touched.restrictionId)}>
                <InputLabel
                  id='label-id' sx={{
                    '&.MuiInputLabel-shrink': {
                      transform: 'translate(14px, -9px) scale(0.75)'
                    },
                    transform: 'translate(14px, 8px) scale(1)',
                    color: Boolean(formik.errors.restrictionId && formik.touched.restrictionId) && formik.values.restrictionId === '' ? '#d32f2f' : ''
                  }}
                >Restriction Type
                </InputLabel>
                <Select
                  labelId='label-id'
                  size='small'
                  id='restrictionId'
                  label='Restriction Type'
                  name='restrictionId'
                  value={formik?.values?.restrictionId}
                  onChange={onRestrictionCodeChange}
                >
                  {restrictionCodes?.map((option, index) => {
                    if (option.restrictionCode) {
                      return (
                        <MenuItem key={option.restrictionId} value={option.restrictionId}>
                          {option.restrictionDesc}
                        </MenuItem>
                      )
                    }
                  })}
                </Select>
                {Boolean(formik.errors.restrictionId && formik.touched.restrictionId) && formik.values.restrictionId === '' && (
                  <FormHelperText sx={{ color: '#d32f2f', marginLeft: 0 }}>Restriction Type Required</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item sx={{ marginBottom: '-4px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <input
                  type='checkbox'
                  checked={formik.values.isExternalRestriction || false}
                  onChange={(e) => {
                    const checked = e.target.checked
                    formik.setFieldValue('isExternalRestriction', checked)
                    formik.setFieldValue('sourceAccountCd', null)
                  }}
                  name='externalRestrictionCode'
                  style={{
                    marginRight: '8px'
                  }}
                />
                <Typography sx={{ fontSize: '14px' }}>External Restriction</Typography>
              </Box>
            </Grid>
            <Grid item>
              {formik.values.isExternalRestriction
                ? <TextField
                    id='externalRestrictionCode'
                    name='externalRestrictionCode'
                    label='Source Account Code'
                    variant='outlined'
                    size='small'
                    fullWidth
                    value={formik.values.sourceAccountCd ? formik.values.sourceAccountCd : ''}
                    onChange={(e) => {
                      const value = e.target.value
                      formik.setFieldValue('sourceAccountCd', value)
                    }}
                    error={Boolean(formik.errors.sourceAccountCd && formik.touched.sourceAccountCd)}
                    helperText={
                    formik.errors.sourceAccountCd &&
                    formik.touched.sourceAccountCd &&
                    String(formik.errors.sourceAccountCd)
                  }
                    FormHelperTextProps={{
                      style: { marginLeft: 0 }
                    }}
                  />
                : <Autocomplete
                    size='small'
                    id='accountCd'
                    options={sourceAccountsList}
                    getOptionLabel={(option) => `${option?.accountCd} - ${option?.accountName}` || ''}
                    onChange={(e, newValue) => onAutocompleteValueChange(e, newValue, 'Source Account Code')}
                    value={sourceAccountsList.find(option => option.accountCd === formik.values.sourceAccountCd) || null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Source Account Code'
                        error={Boolean(formik.errors.sourceAccountCd && formik.touched.sourceAccountCd)}
                        helperText={
                        formik.errors.sourceAccountCd &&
                        formik.touched.sourceAccountCd &&
                        String(formik.errors.sourceAccountCd)
                      }
                        variant='outlined'
                        InputProps={{ ...params.InputProps }}
                        FormHelperTextProps={{
                          style: { marginLeft: 0 }
                        }}
                      />
                    )}
                  />}
            </Grid>
            <Grid item>
              <TextField
                id='startDate'
                name='startDate'
                label='Start Date'
                type='date'
                fullWidth
                variant='outlined'
                size='small'
                value={formik?.values?.startDate}
                disablePast
                error={Boolean(formik.errors.startDate && formik.touched.startDate)}
                helperText={
                  formik.errors.startDate &&
                  formik.touched.startDate &&
                  String(formik.errors.startDate)
                }
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputProps: {
                    min: new Date().toISOString().split('T')[0]
                  }
                }}
                FormHelperTextProps={{
                  style: { marginLeft: 0 }
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                id='endDate'
                name='endDate'
                label='End Date'
                type='date'
                fullWidth
                variant='outlined'
                size='small'
                value={formik?.values?.endDate}
                onChange={formik.handleChange}
                disablePast
                error={Boolean(formik.errors.endDate && formik.touched.endDate)}
                helperText={
                  formik.errors.endDate &&
                  formik.touched.endDate &&
                  String(formik.errors.endDate)
                }
                InputProps={{
                  inputProps: {
                    min: formik?.values?.startDate
                      ? new Date(new Date(formik.values.startDate).setDate(new Date(formik.values.startDate).getDate() + 1))
                        .toISOString()
                        .split('T')[0]
                      : new Date().toISOString().split('T')[0]
                  }
                }}
                InputLabelProps={{ shrink: true }}
                FormHelperTextProps={{
                  style: { marginLeft: 0 }
                }}
              />
            </Grid>
            <Grid item>
              {
                createApiLoading
                  ? (
                    <LoadingButton
                      loading
                      loadingPosition='start'
                      variant='contained'
                      fullWidth
                      type='submit'
                    >
                      Create
                    </LoadingButton>
                    )
                  : (
                    <Button color='primary' variant='contained' fullWidth type='submit'>
                      Create
                    </Button>
                    )
              }
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </>
  )
}

export default CreateWashSaleRestrictionForm

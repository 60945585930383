import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Divider, Grid, Modal, Skeleton, Typography } from '@mui/material'
import { useAuth } from '../contexts/AuthContext.js'
import { changeEditorName, changePreviousEditorName, reportDataSetPage, selectedTemplates } from '../store/pdf-customizer-reducer/Action/chartDropAction'
import { isLoadingSet, userClickMode, pageChange } from '../store/pdf-customizer-reducer/Action/allPageAction'
import { useErrorToast } from '../hooks/useErrorToast'
import ReportIcon from '../assets/images/report-template-icon.svg'
import { getElements } from '../utils/pdf-customizer/_data'
import { chartDataState } from '../store/pdf-customizer-reducer/Action/chartStateAction.js'
import { storeTemplateData } from '../store/pdf-customizer-reducer/Reducer/template-reducer.js'

const TemplateSelectionModal = ({ open, handleClose, reportType, optId, id }) => {
  const params = useParams()
  const dispatch = useDispatch()
  const { user } = useAuth()
  const navigate = useNavigate()
  const [allTemplatesData, setAllTemplatesData] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [isTemplateListLoading, setIsTemplateListLoading] = useState(false)
  const [isTemplateElementsFetching, setIsTemplateElementsFetching] = useState(false)
  const { showError } = useErrorToast()

  const getAllTemplate = async () => {
    setIsTemplateListLoading(true)
    API.get('baseUriReportBuilder', `report-builder/v1/${user.userGroup}/templates/all-templates`,
      reportType ? { queryStringParameters: { report_type: reportType } } : null)
      .then(response => {
        if (response.data.length > 0) {
          setAllTemplatesData(response.data)
        }
        setIsTemplateListLoading(false)
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to load all templates data.')
        setIsTemplateListLoading(false)
      })
  }

  useEffect(() => {
    getAllTemplate()
  }, [])

  const handlePreviewClick = async () => {
    try {
      setIsTemplateElementsFetching(true)
      const templateData = allTemplatesData[selectedTemplate]
      dispatch(isLoadingSet(true))
      dispatch(userClickMode('preview'))
      dispatch(selectedTemplates({ name: templateData.report_type }))
      dispatch(pageChange(
        templateData?.orientation === 'landscape'
          ? {
              width: 602,
              height: 465,
              orientation: 'l'
            }
          : {
              width: 465,
              height: 602,
              orientation: 'p'
            }
      ))
      dispatch(changeEditorName(templateData.report_name))
      dispatch(changePreviousEditorName({ editorName: templateData?.report_name, identifier: templateData?.identifier }))
      dispatch(storeTemplateData({
        accountId: params.accountId,
        identifier: templateData.identifier,
        optId,
        reportType,
        apiData: {
          accountId: params.accountId,
          optId,
          aggregateGroupId: id
        }
      }))
      const response = await getElements(templateData.report_name, templateData.identifier, user.userGroup)
      if (response?.success) {
        dispatch(reportDataSetPage(response?.data))
        navigate('/pdf-customizer/report')
      }
    } catch (error) {
      console.error(error)
    } finally {
      dispatch(isLoadingSet(false))
      dispatch(chartDataState(true, 'IS_API_CALL'))
      setIsTemplateElementsFetching(false)
    }
  }

  const renderLoader = () => {
    return (
      <Box sx={{ padding: '20px 10px' }}>
        <Grid container spacing={4}>
          {Array.from({ length: 6 }).map(_ => (
            <Grid item sm={4}>
          <Box
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', borderRadius: '5px', backgroundColor: '#f5f5f5', height: '150px' }}
        >
          <Skeleton variant='rectangular' height='100%' width='100%' />
        </Box>
        </Grid>
          ))}
        </Grid>
      </Box>
    )
  }

  return (
    <Modal
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      open={open}
      onClose={handleClose}
    >
      <Box sx={{ width: '700px', backgroundColor: 'white', paddingBlock: '20px', paddingInline: '5px', borderRadius: '5px', '&:focus': { outline: 'none' } }}>
        <Box sx={{ position: 'sticky', top: 0 }}>
          <Typography sx={{ fontWeight: 600, pb: '5px', paddingInline: '10px', color: '#34475A' }}>Select Templates</Typography>
          <Divider sx={{ backgroundColor: '#000000' }} />
        </Box>
        {
					isTemplateListLoading
					  ? renderLoader()
					  : <Box sx={{ padding: '20px 10px', overflowY: 'auto', scrollbarGutter: 'stable', maxHeight: '80vh' }}>
  {allTemplatesData.length > 0
							  ? <Grid container spacing={4}>
  {allTemplatesData?.map((obj, index) => (
    <Grid item sm={4} key={index}>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', borderRadius: '5px', backgroundColor: '#f5f5f5', height: '150px', cursor: 'pointer', outline: selectedTemplate === index ? '2px solid #8cb4ff' : 'none' }}
        onClick={() => setSelectedTemplate(index)}
      >
        <img src={ReportIcon} alt='Report Icon' />
        <Typography sx={{ fontSize: '12px', fontWeight: 600, color: '#727474', pt: '5px' }}>{obj.report_name}</Typography>
      </Box>
    </Grid>
  ))}
</Grid>
							  : <Box
    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}
							    >
  <Typography sx={{ fontSize: '14px', fontWeight: 600, color: '#34475A', pt: '5px' }}>No Template Found!</Typography>
</Box>}
</Box>
				}
        <Box sx={{ display: 'flex', justifyContent: 'end', bottom: 0, paddingInline: '10px', position: 'sticky', pt: '10px' }}>
          {
						isTemplateElementsFetching
						  ?							<LoadingButton
    loading
    loadingPosition='start'
    size='small'
    variant='outlined'
    sx={{ textTransform: 'capitalize', width: '130px' }}
						          >
  Preview
                </LoadingButton>
						  :							<Button disabled={isTemplateListLoading || selectedTemplate === null} variant='contained' size='small' sx={{ width: '130px', textTransform: 'capitalize' }} onClick={() => handlePreviewClick()}>Preview</Button>
					}
        </Box>
      </Box>
    </Modal>
  )
}

export default TemplateSelectionModal

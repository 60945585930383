import React, { useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Box, Card, CardContent, Divider, Grid, Tab, Typography } from '@mui/material'
import CustomKeyFieldSearch from '../../../components/CustomKeyFieldSearch'
import { useAuth } from '../../../contexts/AuthContext'
import { moduleConfig } from '../../../contexts/data'
import { ACCESS_LEVEL } from '../../../contstants/constants'
import ErrorFallback from '../../ErrorFallback'
import Loader from '../../Loader'
import AllAccountRequest from './AccountRequest'
import SponsorRequest from './SponsorRequest'

export default function AllRequestTabs () {
  const [value, setValue] = useState(0)
  const { user, checkAccess } = useAuth()
  const screen = 'not main'
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const [isLoading, setIsLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const handleSearchTextChange = (searchTerm) => {
    setSearchTerm(searchTerm?.trim())
  };

  const AllAccountTabs = [
    ...(checkAccess(moduleConfig.All_REQUESTS, ACCESS_LEVEL.COMPONENT_ACCESS,
      { subModuleName: moduleConfig.All_REQUESTS, component_name: moduleConfig.VIEW_REQUEST })
      ? ['Account', 'Sponsor']
      : [])
  ]

  const filteredTabs = user?.userGroup === 'adv-classic' ? ['Account'] : AllAccountTabs

  const renderTabPanel = (item) => {
    switch (item) {
      case 'Account':
        return (
          <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
            <AllAccountRequest searchTerm={searchTerm} />
          </ErrorBoundary>
        )
      case 'Sponsor':
        return (
          <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
            <SponsorRequest searchTerm={searchTerm} />
          </ErrorBoundary>
        )
      default:
        return 'Something went wrong'
    }
  }

  return (
    <Grid container xs={12} sm={12}>
      <Grid className='account-dashboard' container alignItems='center' justifyContent='space-between' sx={{ mb: 1 }}>
        <Grid item>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '26px',
              color: '#34475A',
              fontFamily: 'Lora'
            }}
            gutterBottom
          >
            All Requests
          </Typography>
        </Grid>
        <Grid item>
          <CustomKeyFieldSearch
            fieldName='userRequestId'
            handleSearchTextChange={handleSearchTextChange}
            fieldLabel='Request ID'
          />
        </Grid>
      </Grid>
      {(isLoading)
        ? <Loader />
        : checkAccess(moduleConfig.All_REQUESTS, ACCESS_LEVEL.COMPONENT_ACCESS,
          { subModuleName: moduleConfig.All_REQUESTS, component_name: moduleConfig.VIEW_REQUEST })
          ? (
            <Grid xs={12} sm={12}>
              <Box
                className='reportPage' sx={{
                  flexGrow: 1,
                  maxWidth: { xs: '100%' },
                  bgcolor: 'white',
                  paddingTop: '0px'
                }}
              >
                <Card className='card-layout'>
                  <CardContent sx={{ position: 'relative' }}>
                    <TabContext value={value}>
                      <TabList
                        onChange={handleChange}
                        variant='scrollable'
                        scrollButtons={false}
                        className='report-tab-header'
                        TabIndicatorProps={{
                          style: {
                            backgroundColor: '#34475A'
                          }
                        }}
                        indicatorColor='#34475A'
                      >
                        {filteredTabs.map((item, index) => (
                          <Tab className='reportCardNav' key={index} value={index} label={item} />
                        ))}
                      </TabList>
                      <Divider sx={{
                        bgcolor: 'rgba(116, 120, 141, 0.15)'
                      }}
                      />
                      {filteredTabs.map((item, index) => (
                        <TabPanel
                          key={index}
                          value={index}
                          sx={{ padding: '10px' }}
                        >
                          {renderTabPanel(item)}
                        </TabPanel>
                      ))}
                    </TabContext>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            )
          : (
            <></>
            )}
    </Grid>
  )
}

import { Link } from 'react-router-dom'
import { Box, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import redirect from '../../../assets/images/pop-out-line.svg'
import { formatCurrencyWithSymbol } from '../../../utils/FormateCurrenyInMilion'
import CustomGridTreeDataGroupingCell from './CustomGridTreeDataGroupingCell'

const columns = [
  {
    field: 'marketValue',
    headerName: 'Market Value',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
    valueFormatter: (params) => params?.value !== undefined ? params?.value !== null ? formatCurrencyWithSymbol(params?.value, 0, '$') : 'NA' : '',
  },
  {
    field: 'weight',
    headerName: 'Weight',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.row?.weight !== undefined && params?.row?.weight !== null ? params?.row?.weight?.toFixed(2) : 'NA'
  },
  {
    field: 'shares',
    headerName: 'Shares',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.row?.shares ?? '',
    valueFormatter: (params) => params?.value != null ? params?.value?.toString() : ''
  },
  {
    field: 'purchaseDate',
    headerName: 'Purchase Date',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.row?.purchaseDate !== undefined ? params?.row?.purchaseDate !== null ? params?.row?.purchaseDate?.split('T')[0] : 'NA' : ''
  },
  {
    field: ' ',
    headerName: ' ',
    width: 50,
    sortable: false,
    disableColumnMenu: true,
    align: 'right',
    renderCell: (params) => (
      params?.row?.hierarchy?.length === 2
        ? <Link to={`/account-review/account-dashboard/${params?.row?.accountId}`} style={params?.row?.accountName === 'Untagged' ? { opacity: '0.3', pointerEvents: 'none' } : {}}>
          <img src={redirect} alt='' />
        </Link>
        : ''
    )
  }
]

const PortfolioUMA = ({ data, loading }) => {
  return (
    <>
      <Box sx={{ position: 'relative' }} className='aggregate-portfolio-table'>
        {
          loading
            ? <TableContainer mt={5}>
              <Table className='risk-page-table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    {columns.map((item, index) => {
                      return (
                        <TableCell key={index}>{item.headerName}</TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.from({ length: 5 }).map((_, i) => (
                    <TableRow key={i}>
                      {Array.from({ length: columns.length }).map((_, j) => (
                        <TableCell key={j}>
                          <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                        </TableCell>
                      ))}
                      <TableCell><Skeleton variant='text' /></TableCell>
                    </TableRow>))}
                </TableBody>
              </Table>
            </TableContainer>
            : <DataGridPro
              density='compact'
              treeData
              rows={data}
              columns={columns}
              autoHeight
              getRowId={(row) => row.id}
              disableRowSelectionOnClick
              defaultGroupingExpansionDepth={1}
              getTreeDataPath={(row) => row.hierarchy}
              groupingColDef={{
                headerName: 'Name',
                width: 250,
                filterable: true,
                sortable: true,
                disableColumnMenu: false,
                valueGetter: (params) => params?.row?.name,
                renderCell: (params) => (
                  <CustomGridTreeDataGroupingCell
                    showDropdown={(descendantCount, hierarchy = []) => descendantCount > 0 && hierarchy.length > 1}
                    {...params}
                  />
                )
              }}
              localeText={{ noRowsLabel: 'No Portfolio Found' }}
              hideFooter={data?.length === 0}
              getRowClassName={(params) => !params?.row?.noHighlight && params?.row?.currLevel === 0 ? 'datagrid-main-row' : ''}
              pageSizeOptions={[10]}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } }
              }}
              pagination
              sx={(theme) => ({
                [`.${gridClasses.main}`]: {
                  overflow: 'unset'
                },
                [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                  color: '#74788d',
                  fontWeight: 600
                },
                [`.${gridClasses.columnHeaders}`]: {
                  position: 'sticky',
                  top: '-20px',
                  zIndex: 3,
                  backgroundColor: 'white'
                },
                [`.${gridClasses.footerContainer}`]: {
                  position: 'sticky',
                  bottom: '-21px',
                  zIndex: 3,
                  backgroundColor: 'white'
                },
              })}
            />
        }
      </Box>
    </>
  )
}

export default PortfolioUMA
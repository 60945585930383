import { useRef, useState } from 'react'
import { API } from 'aws-amplify'
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { Add, Cancel, Close, DeleteOutlined, Edit, Save, SettingsOutlined } from '@mui/icons-material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, GridActionsCellItem, GridRowEditStopReasons, GridRowModes, GridToolbarContainer, gridClasses } from '@mui/x-data-grid-pro'
import addIcon from '../../assets/images/icon-add-plus-circle.svg'
import fileIcon from '../../assets/images/icon-file.svg'
import { useAuth } from '../../contexts/AuthContext'
import { ENV_MAP } from '../../../src/contstants/constants'
import { useErrorToast } from '../../hooks/useErrorToast'
import { useSuccessToast } from '../../hooks/useSuccessToast'
import Loader from '../../pages/Loader'
import CustomMigrationField from './CustomMigrationField'
import ImportResultModal from './ImportResultModal'

// Set the current environment using an environment variable.
const currentEnvironment = process.env.REACT_APP_ENV_NAME || 'development'
const DEFAULT_TARGET_ENV = ENV_MAP[currentEnvironment] || currentEnvironment

// Renders a table skeleton with specified headers and hidden columns.
const renderTableSkeleton = (header, hiddenColumns) => {
  if (hiddenColumns) { header = header.filter(col => !hiddenColumns.includes(col.field)) }
  return (
    <TableContainer mt={5}>
      <Table className='risk-page-table'>
        <TableHead>
          <TableRow>
            {header.map((item, index) => {
              return (
                <TableCell key={index}>{item.headerName}</TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: 5 }).map((_, index) => (
            <TableRow key={index}>
              {Array.from({ length: header.length }).map((_, index) => (
                <TableCell key={index}>
                  <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                </TableCell>))}
            </TableRow>))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const ImportAccount = ({ accounts, accountsLoading }) => {
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const fileInputRef = useRef(null)
  const [accountImporting, setAccountImporting] = useState(false)
  const [isAccountMapLoading, setIsAccountMapLoading] = useState(false)
  const [isApiLoading, setIsApiLoading] = useState(false)
  const { showSuccess } = useSuccessToast()
  const [selectedFile, setSelectedFile] = useState(null)
  const [isMappingModalVisible, setIsMappingModalVisible] = useState(false)
  const [rowModesModel, setRowModesModel] = useState({})
  const [accountMappingRows, setAccountMappingRows] = useState([])
  const [importResults, setImportResults] = useState({ success: [], failed: [] })
  const [isImportResultModalOpen, setImportResultModalOpen] = useState(false)

  // Function to import account migration data
  const importAccountMigration = () => {
    setIsAccountMapLoading(true)
    API.get(
      'baseUriTransactionalMaster',
            `transactional-master/v1/${user?.userGroup}/migration/account-mapping`
    ).then((response) => {
      if (response?.success) {
        setAccountMappingRows(response?.data?.map(obj => ({ ...obj, id: randomId() })))
      }
    }).catch((error) => {
      showError(error, false, {}, 'Failed to load account data.')
    }).finally(() => {
      setIsAccountMapLoading(false)
    })
  }

  // Function to create an account migration row.
  const createAccountMigration = (newRow) => {
    const payload = {
      sourceAccountId: newRow?.sourceAccountId,
      targetAccountId: newRow?.targetAccountId,
      sourceEnv: newRow?.sourceEnv,
      targetEnv: newRow?.targetEnv
    }

    return API.post(
      'baseUriTransactionalMaster',
            `transactional-master/v1/${user.userGroup}/migration/account-mapping`,
            { body: payload }
    )
  }

  // Function to update an account migration row.
  const updateAccountMigration = (newRow) => {
    return API.patch(
      'baseUriTransactionalMaster',
            `transactional-master/v1/${user?.userGroup}/migration/account-mapping/${newRow?.accMigrationId}`,
            {
              body: {
                sourceEnv: newRow?.sourceEnv,
                sourceAccountId: newRow?.sourceAccountId
              }
            }
    )
  }

  // Function to delete an account migration row.
  const deleteAccountMigrationRow = (accMigrationId) => {
    setIsApiLoading(true)
    API.del(
      'baseUriTransactionalMaster',
            `transactional-master/v1/${user?.userGroup}/migration/account-mapping/${accMigrationId}`
    ).then((response) => {
      if (response?.success) {
        setAccountMappingRows(accountMappingRows.filter((row) => row.accMigrationId !== accMigrationId))
        showSuccess('Account migration deleted successfully')
      }
    })
      .catch((error) => {
        showError(error, false, {}, 'Failed to delete account data.')
      })
      .finally(() => {
        setIsApiLoading(false)
      })
  }

  // Defines the configuration for the columns in the account migration table
  const accountMigrationColumns = [
    {
      field: 'targetAccountId',
      headerName: 'Target Account Id',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      editable: true
    },
    {
      field: 'targetAccountName',
      headerName: 'Target Account',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      editable: true,
      renderEditCell: (params) => (
        <CustomMigrationField
          params={params}
          accountsLoading={accountsLoading}
          accounts={accounts}
        />
      )
    },
    {
      field: 'targetEnv',
      headerName: 'Target Env',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      editable: false,
      renderCell: (params) => params?.targetEnv
    },
    {
      field: 'sourceAccountId',
      headerName: 'Source Account ID',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      editable: true
    },
    {
      field: 'sourceEnv',
      headerName: 'Source Env',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      editable: true,
      type: 'singleSelect',
      valueOptions: Object.values(ENV_MAP)
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      // Define actions based on the edit mode of the row
      getActions: (params) => {
        // Check if the row is in edit mode
        const isInEditMode = rowModesModel[params?.id]?.mode === GridRowModes.Edit

        // If in edit mode, show Save and Cancel actions
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<Save />}
              label='Save'
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(params?.id)}
            />,
            <GridActionsCellItem
              icon={<Cancel />}
              label='Cancel'
              className='textPrimary'
              onClick={handleCancelClick(params?.id)}
              color='inherit'
            />
          ]
        }

        // If not in edit mode, show Edit and Delete actions
        return [
          <GridActionsCellItem
            icon={<Edit />}
            label='Edit'
            className='textPrimary'
            onClick={handleEditClick(params?.id)}
            color='inherit'
          />,
          <GridActionsCellItem
            icon={<DeleteOutlined />}
            label='Delete'
            onClick={handleDeleteClick(params?.row?.accMigrationId)}
            color='inherit'
          />
        ]
      }
    }
  ]

  // Handlers for row actions(EDIT)
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  // Handlers for row actions(SAVE)
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  // Handlers for row actions(DELETE)
  const handleDeleteClick = (accMigrationId) => () => {
    deleteAccountMigrationRow(accMigrationId)
  }

  // Handlers for row actions(CANCEL)
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })
    const editedRow = accountMappingRows.find((row) => row?.id === id)
    if (editedRow?.isNew) {
      setAccountMappingRows(accountMappingRows.filter((row) => row?.id !== id))
    }
  }

  // Check if any of the specified fields in the newRow object have different values from the corresponding fields in the oldRow object
  function isValueChanged (newRow, oldRow) {
    const fieldsToCompare = ['targetAccountName', 'targetAccountId', 'sourceAccountId', 'sourceEnv']
    let valueChangeFlag = 0
    for (let i = 0; i < fieldsToCompare.length; i++) {
      if (newRow[fieldsToCompare[i]] !== oldRow[fieldsToCompare[i]]) {
        valueChangeFlag = 1
        break
      }
    }
    return valueChangeFlag
  }

  // Process the update of a row in the table
  const processRowUpdate = (newRow, oldRow) => {
    const newRowWithoutTrailingSpaces = {
      ...newRow,
      targetAccountId: newRow?.targetAccountId?.trim() || '',
      targetAccountName: newRow?.targetAccountName?.trim() || '',
      sourceAccountId: newRow?.sourceAccountId?.trim() || ''
    }
    if (newRowWithoutTrailingSpaces?.targetAccountId === '' || newRowWithoutTrailingSpaces?.targetAccountName === '' || !Object.values(ENV_MAP).includes(newRowWithoutTrailingSpaces?.sourceEnv) || newRowWithoutTrailingSpaces?.sourceAccountId === '' || !isValueChanged(newRowWithoutTrailingSpaces, oldRow)) {
      // Set the row mode to Edit and return the oldRow without performing the update
      setRowModesModel({ ...rowModesModel, [newRow?.id]: { mode: GridRowModes.Edit } })
      return newRow
    }

    setIsApiLoading(true)
    if (newRow?.isNew) {
      return createAccountMigration(newRow)
        .then((response) => {
          if (response?.success) {
            // If the account migration creation is successful, update the row in the table
            const updatedRow = { ...newRow, isNew: false, ...response?.data }
            setAccountMappingRows(accountMappingRows.map((row) => (row?.id === newRow?.id ? updatedRow : row)))
            showSuccess(response?.message)
            return updatedRow
          }
        })
        .catch((error) => {
          showError(error, false, {}, 'Failed to update account data.')
          setRowModesModel({ ...rowModesModel, [newRow?.id]: { mode: GridRowModes.Edit } })
          return newRow
        })
        .finally(() => {
          setIsApiLoading(false)
        })
    } else {
      return updateAccountMigration(newRow)
        .then((response) => {
          if (response?.success) {
            // If the account migration update is successful, update the row in the table
            const updatedRow = {
              ...newRow,
              isNew: false,
              ...response?.data
            }
            setAccountMappingRows(accountMappingRows.map((row) => (row.id === newRow.id ? updatedRow : row)))
            showSuccess(response?.message)
            return updatedRow
          }
        })
        .catch((error) => {
          // Handle and log the error, set the row mode to Edit, and return the oldRow
          showError(error, false, {}, 'Failed to update account data.')
          setRowModesModel({ ...rowModesModel, [newRow.id]: { mode: GridRowModes.Edit } })
          return newRow
        })
        .finally(() => {
          setIsApiLoading(false)
        })
    }
  }

  // // Handle stopping of row editing in a grid
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut || params.reason === GridRowEditStopReasons.escapeKeyDown || params.reason === GridRowEditStopReasons.enterKeyDown || params.reason === GridRowEditStopReasons.tabKeyDown) {
      event.defaultMuiPrevented = true
    }
  }

  const handleRowDoubleClick = (params, event) => {
    event.defaultMuiPrevented = true
  }

  // Open a modal and trigger the account migration import process
  const openModal = () => {
    setIsMappingModalVisible(true)
    // Call the importAccountMigration function to start the import process
    importAccountMigration()
  }

  // Resets the form by clearing the selected account, selected file, and hiding the file upload section.
  const resetForm = () => {
    setSelectedFile(null)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  // Cancels the current file selection by clearing the selected file.
  const handleCancel = () => {
    setSelectedFile(null)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  // Imports account data from a JSON file by sending a POST request to the specified API endpoint.
  const importAccountJson = (data) => {
    setAccountImporting(true)
    // Converting data to an array
    const dataArray = Array.isArray(data) ? data : Object.values(data)
    API.post(
      'baseUriTransactionalMaster',
            `transactional-master/v1/${user?.userGroup}/import/account`,
            { body: dataArray }
    )
      .then((res) => {
        if (res?.success) {
          showSuccess(res?.message)
          setImportResults(res?.data)
          setImportResultModalOpen(true)
          resetForm()
        }
      }).catch((error) => {
        showError(error, false, {}, 'Failed to load import account data.')
      }).finally(() => {
        setAccountImporting(false)
      })
  }

  // Handles the file upload process. Validates the file format and sets the selected file.
  const handleFileUpload = (event) => {
    const file = event.target.files[0]
    if (!file) {
      return
    }

    // Check file extension
    const validExtensions = ['json']
    const fileExtension = file.name.split('.').pop().toLowerCase()
    if (!validExtensions.includes(fileExtension)) {
      showError('Invalid file format. Please upload a JSON file.')
      return
    }
    setSelectedFile(file)
  }

  // Handles the submission of the form. Validates the selected account and file, reads the file content, and calls the importAccountJson function to import the account data.
  const handleSubmit = async () => {
    if (!selectedFile) {
      showError('Please upload a file.')
      return
    }
    // Read the contents of the selected file
    const fileReader = new FileReader()
    fileReader.onload = async (e) => {
      try {
        const fileContent = JSON.parse(e.target.result)
        if (typeof fileContent !== 'object') {
          showError('Invalid file format. Please upload a JSON file with an array of objects.')
          return
        }
        // Call the importAccountJson function with the parsed file content
        importAccountJson(fileContent)
      } catch (error) {
        showError('Error reading file content. Please try again.')
        console.error('Error reading file content:', error)
      }
    }
    fileReader.readAsText(selectedFile)
  }

  const handleModalClose = () => {
    setImportResultModalOpen(false)
  }

  return (
    <>
      {isApiLoading ? <Loader /> : ''}
      <Box mb={2}>
        {/* Title of the page */}
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography className='page-title' mb={2}>
            Import Account
          </Typography>
          <Box>
            <IconButton onClick={openModal}>
              <SettingsOutlined />
            </IconButton>
          </Box>
        </Box>
        {/* Main container for the grid layout */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* Card layout for the form */}
            <Card className='card-layout'>
              {/* Container for the Autocomplete and File Upload components */}
              <CardContent>
                {/* Conditional rendering of the file upload box */}
                <Grid container>
                  <Grid item xs={12}>
                    {/* File upload box with drag-and-drop functionality */}
                    <FormControl sx={{ marginY: '16px' }}>
                      <Box
                        onDragEnter={(e) => e.preventDefault()}
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={(e) => {
                          e.preventDefault()
                          const droppedFile = e.dataTransfer.files[0]
                          setSelectedFile(droppedFile)
                        }}
                        sx={{
                          display: 'flex',
                          justifyContent: selectedFile ? 'space-between' : 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                          background: 'rgba(116, 120, 141, 0.05)',
                          border: '0.5px dashed rgba(116, 120, 141, 0.5)',
                          borderRadius: '5px',
                          padding: '0 24px',
                          height: '130px',
                          width: '800px',
                          position: 'relative'
                        }}
                      >
                        {/* File input and label */}
                        <label htmlFor='file-upload-input' style={{ cursor: 'pointer' }}>
                          <input
                            id='file-upload-input'
                            type='file'
                            style={{ position: 'absolute', width: '100%', height: '100%', cursor: 'pointer', top: 0, left: 0, right: 0, bottom: 0, opacity: 0 }}
                            accept='.json'
                            ref={fileInputRef}
                            onChange={handleFileUpload}
                          />
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <img
                              style={{ cursor: 'pointer' }}
                              src={addIcon}
                              alt='add'
                              height={30}
                              width={30}
                            />
                            <Typography
                              pl={1}
                              sx={{ color: '#34475a', fontWeight: 500, cursor: 'pointer' }}
                            >
                              Drop your files here or browse
                            </Typography>
                          </Box>
                        </label>
                        {/* Display selected file details and action buttons */}
                        {selectedFile && (
                          <Box sx={{ width: '450px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', borderRadius: '5px', padding: '8px !important' }}>
                            <Box sx={{ backgroundColor: 'rgba(23, 92, 194, 0.5)', padding: '7px', color: 'white', width: '24px', height: '24px', display: 'flex', justifyContent: 'center', borderRadius: '5px', border: 'none' }} align='center' mr={1}>
                              <img
                                src={fileIcon}
                                alt=''
                                height={10}
                                width={10}
                              />
                            </Box>
                            <Box>
                              <Typography sx={{ fontSize: '12px !important' }}>Name: {selectedFile.name}</Typography>
                              <Typography sx={{ fontSize: '12px !important' }}>Size: {selectedFile.size}</Typography>
                              <Typography sx={{ fontSize: '12px !important' }}>
                                Last Modified:{' '}
                                {selectedFile
                                  ? selectedFile.lastModifiedDate.toLocaleDateString()
                                  : ''}
                              </Typography>
                            </Box>
                            <Box sx={{ display: 'flex' }} p={1}>

                              <Button
                                type='button'
                                onClick={handleSubmit}
                                size='small'
                                variant='contained'
                                sx={{ fontSize: '12px !important' }}
                              >
                                Upload
                              </Button>
                              <Button
                                type='button'
                                onClick={handleCancel}
                                size='small'
                                variant='text'
                                sx={{
                                  fontSize: '12px !important',
                                  marginLeft: '10px',
                                  background: '#F05F5F26',
                                  border: 'none',
                                  color: '#F05F5F',
                                  fontWeight: 'bold'
                                }}
                              >
                                Cancel
                              </Button>
                            </Box>
                            {/* Loader for file uploading state */}
                            {accountImporting ? <Loader /> : null}
                          </Box>
                        )}
                      </Box>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ImportResultModal
        isImportResultModalOpen={isImportResultModalOpen}
        handleModalClose={handleModalClose}
        importResults={importResults}
      />
      {/* Dialog component displaying a modal for the mapping table */}
      <Dialog open={isMappingModalVisible} fullScreen>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '8px' }}>
          <Typography variant='h6' style={{ fontWeight: 400, color: '#34475A' }}>Mapping Table</Typography>
          {/* Close button */}
          <IconButton
            onClick={() => (setIsMappingModalVisible(false), setRowModesModel({}))}
            sx={{ display: 'flex', alignItems: 'end', justifyContent: 'end', marginLeft: 'auto' }}
          >
            <Close />
          </IconButton>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              style={{
                position: 'relative',
                overflowY: 'auto',
                scrollbarWidth: 'none',
                height: 'calc(100vh - 64px - 16px - 16px)',
                padding: '4px'
              }}
            >
              {/* Render table skeleton and the DataGridPro component */}
              {
                                isAccountMapLoading
                                  ? renderTableSkeleton(accountMigrationColumns, ['targetAccountId'])
                                  : <DataGridPro
                                      autoHeight
                                      disableRowSelectionOnClick
                                      disableMultipleRowSelection
                                      density='compact'
                                      rows={accountMappingRows}
                                      columns={accountMigrationColumns}
                                      rowModesModel={rowModesModel}
                                      getRowId={(row) => row?.id}
                                      onRowDoubleClick={handleRowDoubleClick}
                                      onRowEditStop={handleRowEditStop}
                                      processRowUpdate={processRowUpdate}
                                      editMode='row'
                                      slots={{
                                        toolbar: EditToolbar
                                      }}
                                      slotProps={{
                                        toolbar: { setAccountMappingRows, setRowModesModel, DEFAULT_TARGET_ENV }
                                      }}
                                      pagination
                                      pageSizeOptions={[20]}
                                      initialState={{
                                        columns: {
                                          columnVisibilityModel: {
                                            targetAccountId: false
                                          }
                                        },
                                        pagination: { paginationModel: { pageSize: 20 } }
                                      }}
                                      sx={(theme) => ({
                                        '.MuiInputBase-input': {
                                          px: '10px'
                                        },
                                        '.MuiSelect-select': {
                                          fontWeight: 600,
                                          fontSize: '14px'
                                        },
                                        [`.${gridClasses.main}`]: {
                                          overflow: 'unset'
                                        },
                                        [`.${gridClasses.columnHeaders}`]: {
                                          position: 'sticky',
                                          backgroundColor: theme.palette.background.paper,
                                          top: '40px',
                                          zIndex: 1
                                        },
                                        [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                                          color: '#74788d',
                                          fontWeight: 600
                                        },
                                        [`.${gridClasses.footerContainer}`]: {
                                          position: 'sticky',
                                          bottom: '0px',
                                          backgroundColor: theme.palette.background.paper,
                                          zIndex: 1
                                        }
                                      })}
                                    />
                            }
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}

// EditToolbar component for the DataGridPro component
function EditToolbar (props) {
  const { setAccountMappingRows, setRowModesModel, DEFAULT_TARGET_ENV } = props

  // Add a new record to the table
  const handleClick = () => {
    const id = randomId()
    setAccountMappingRows((oldRows) => [
      {
        id,
        isNew: true,
        sourceAccountId: '',
        targetAccountId: '',
        sourceEnv: '',
        targetEnv: DEFAULT_TARGET_ENV
      },
      ...oldRows
    ])
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit }
    }))
  }

  return (
    <GridToolbarContainer sx={{ zIndex: 1, top: 0, position: 'sticky', width: '100%', backgroundColor: 'white' }}>
      <Button color='primary' startIcon={<Add />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  )
}

export default ImportAccount

import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined'
import { useAuth } from '../../contexts/AuthContext'
import { validateExpression } from '../../utils/ZscoreValidator'
import Loader from '../Loader'
import Industry from './Industry'
import Sector from './Sector'
import SecurityRestictions from './SecurityRestictions'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
  Stack,
  Divider,
  createTheme
} from '@mui/material'
import { API } from 'aws-amplify'
import { useErrorToast } from '../../hooks/useErrorToast'
import { isNumberOnly, isNumberWithSign, numberOnlyPasteHandler, numberWithSignPasteHandler, isWholeNumber, wholeNumberPasteHandler } from '../../utils/NumberUtils'
import CustomTooltipIcon from '../Personalization/components/CustomTooltipIcon'
import Substitutions from './Substitution'
import { InputAdornmentTextField } from './components/inputAdornmentTextfield'

const decTwo = (string, decimal) => {
  return (Number(string)).toFixed(decimal).toString()
}

const headers = ['Factor', 'Strategy', 'Range', 'Default', 'Absolute/Benchmark Relative']
const cashConstraints = ['Name', 'Value']
const taxConstraints = ['Name', 'Value']
const otherConstraints = ['Name', 'Value']
const theme = createTheme({
  typography: {
    fontFamily: 'Open Sans'
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: 'Open Sans',
          backgroundColor: 'rgba(23, 92, 194, 0.8)',
          color: 'white',
          fontSize: '12px',
          fontWeight: '400'
        }
      }
    }
  }
})

const PersonalizationTab = ({ accountDetails, taxlotData, strategy, setPersonalizationData, personalization, selectedStrategy, allScenarioList }) => {
  const { user } = useAuth()
  const [expanded, setExpanded] = React.useState(false)
  const [factorTiltsColor, setfactorTiltsColor] = useState(false)
  const [sectorList, setSectorList] = useState(personalization?.sectorData ? personalization.sectorData : [])
  const [industryList, setIndustryList] = useState(personalization?.industryData ? personalization.industryData : [])
  const [factorUtilData, setFactorUtilData] = useState(personalization?.factorUtilData ? personalization.factorUtilData : [])
  const [subColorFlag, setSubColorFlag] = useState(personalization?.subColorFlag ? personalization.subColorFlag : false)
  const { showError } = useErrorToast()
  const [totalSectorSecurities, setTotalSectorSecurities] = useState()
  const [sectorSecurities, setSectorSecurities] = useState()
  const [totalIndustrySecurities, setTotalIndustrySecurities] = useState()
  const [industrySecurities, setIndustrySecurities] = useState()
  const [securityDataColor, setSecurityDataColor] = useState(false)
  const [sectorBorderColor, setSectorBorderColor] = useState(personalization?.sectorBorderColor ? personalization.sectorBorderColor : false)
  const [industryBorderColor, setIndustryBorderColor] = useState(personalization?.industryBorderColor ? personalization.industryBorderColor : false)
  const [minCashError, setMinCashError] = useState('')
  const [maxCashError, setMaxCashError] = useState('')
  const minCashValue = 0
  const maxCashValue = 100
  const [error, setError] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [dataCashConstraint, setDataCashConstraint] = useState(personalization?.cashConstraintData)
  const [dataTaxConstraint, setDataTaxConstraint] = useState(personalization?.taxConstraintData
    ? personalization?.taxConstraintData
    : [
        {
          name: 'Tax Sensitivity',
          value: '',
          options: ['High', 'Medium', 'Low'] // Add options for the dropdown
        },
        {
          name: 'Max. Short Term Gain',
          value: '',
          adornment: <AttachMoneyOutlinedIcon />
        },
        {
          name: 'Max. Long Term Gain',
          value: '',
          adornment: <AttachMoneyOutlinedIcon />
        }
      ])

  const [dataOtherConstraint, setDataOtherConstraint] = useState(personalization?.constraintData)
  const [objectiveFunctionData, setObjectiveFunctionData] = useState(personalization?.objectiveConstraintData)

  useEffect(() => {
    if (user) {
      getConstraints()
      getSectorIndustry()
      getFactorUtils()
    }
  }, [user])

  const sortFunction = (data) => {
    return data?.slice()?.sort((a, b) => {
      const customOrder = [
        'Minimum Beta',
        'Maximum Beta',
        'Min Buy Size',
        'Min Buy Weight',
        'Min Trade Weight',
        'Max Holdings'
      ]
      const minIndex = customOrder.indexOf(a?.propertyDesc)
      const maxIndex = customOrder.indexOf(b?.propertyDesc)
      return minIndex - maxIndex
    })
  }

  // GET API for sector and industry data
  const getSectorIndustry = async () => {
    setIsLoading(true)
    API.get('baseStrategyURL', `strategy/v1/${user?.userGroup}/sector-industry/${strategy?.strategyId}`, {
      queryStringParameters: {}
    })
      ?.then((response) => {
        if (response?.data) {
          if (response?.data?.sectorData) {
            const sectorData = response?.data?.sectorData?.map((item, index) => {
              return {
                assetClass: item?.assetClass,
                sectorName: item?.assetClassDesc,
                sumWeight: (item?.sumWeight * 100).toFixed(2),
                count: item?.count,
                exclude: false,
                min: '',
                max: ''
              }
            })
            if (personalization?.sectorData) {
              setSectorList(personalization?.sectorData)
            } else {
              setSectorList(sectorData)
              setPersonalizationData({ ...personalization, sectorData })
            }
            if (response?.data?.sectorDataInd) {
              const industryData = response?.data?.sectorDataInd?.map((item, index) => {
                return {
                  assetClass: item.assetClass,
                  sectorName: item.assetClassDesc,
                  industry: item.subIndName,
                  sumWeight: (item?.sumWeight * 100).toFixed(2),
                  count: item?.count,
                  exclude: false,
                  min: '',
                  max: ''
                }
              })
              if (personalization?.industryData) {
                setIndustryList(personalization?.industryData)
              } else {
                setIndustryList(industryData)
                setPersonalizationData({ ...personalization, industryData })
              }
              setIsLoading(false)
            }
          }
        }
      })
      .catch((error) => {
        setIsLoading(false)
        showError(error, false, {}, 'Failed to load sector.')
      })
  }

  // GET API for Factor tilts data
  const getFactorUtils = async () => {
    setIsLoading(true)
    API.get('baseStrategyURL', `strategy/v1/${user?.userGroup}/models/factor-tilts/${strategy?.strategyId}`, {
      queryStringParameters: {}
    })
      ?.then((response) => {
        if (response?.data) {
          const factorUtils = response?.data?.map((item, index) => {
            return {
              attrLabel: item?.attrLabel,
              statsLevelValue: item?.statsLevelValue,
              benchmarkValue: item?.benchmarkValue,
              selected: false,
              minValue: item?.minValue,
              maxValue: item?.maxValue,
              value: '',
              riskModelVerId: item?.riskModelVerId
            }
          })
          if (personalization?.factorUtilData) {
            setFactorUtilData(personalization.factorUtilData)
          } else {
            setFactorUtilData(factorUtils)
            setPersonalizationData({ ...personalization, factorUtilData: factorUtils })
          }
          setIsLoading(false)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        showError(error, false, {}, 'Failed to load factor utils data.')
      })
  }

  // GET API for Cash Constraints and Other Constraints data
  const getConstraints = async () => {
    setIsLoading(true)

    const cashQueryParams = { propertyType: 'CASH_CONSTRAINT' }
    const otherQueryParams = { propertyType: 'OTHER_CONSTRAINT' }

    const cashPromise = API.get('baseUriTransactionalMaster', `transactional-master/v1/${user?.userGroup}/property-codes`, { queryStringParameters: cashQueryParams })
    const otherPromise = API.get('baseUriTransactionalMaster', `transactional-master/v1/${user?.userGroup}/property-codes`, { queryStringParameters: otherQueryParams })

    Promise.all([cashPromise, otherPromise])
      ?.then((responses) => {
        const cashResponse = responses[0]
        const otherResponse = responses[1]
        if (personalization?.cashConstraintData) {
          setDataCashConstraint(personalization?.cashConstraintData)
          validateMinMaxCashLevel(personalization?.cashConstraintData, true)
        } else if (cashResponse?.data) {
          setDataCashConstraint(cashResponse.data)
        }
        if (personalization?.constraintData && personalization?.objectiveConstraintData) {
          setDataOtherConstraint(sortFunction(personalization?.constraintData))
          setObjectiveFunctionData(personalization?.objectiveConstraintData)
        } else if (otherResponse?.data) {
          const sortedList = sortFunction(otherResponse.data.filter(elem =>
            elem.propertyCode !== 'FIXED_TXN_COST_LAMBDA' &&
            elem.propertyCode !== 'RISK_LAMBDA' &&
            elem.propertyCode !== 'TAX_LAMBDA' &&
            elem.propertyCode !== 'TXN_COST_LAMBDA'
          ))
          const defaultScenarioIndex = sortedList.findIndex((elem) => elem.propertyCode === 'DEFAULT_SCENARIO_CODE')
          const defaultScenarioValue = personalization?.otherConstraintData.find((elem) => elem.propertyCode === 'DEFAULT_SCENARIO_CODE')
          if (defaultScenarioValue) {
            sortedList[defaultScenarioIndex].value = defaultScenarioValue.value
          }
          setDataOtherConstraint(sortedList)
          setObjectiveFunctionData(
            otherResponse.data.filter(elem =>
              elem.propertyCode === 'FIXED_TXN_COST_LAMBDA' ||
              elem.propertyCode === 'RISK_LAMBDA' ||
              elem.propertyCode === 'TAX_LAMBDA' ||
              elem.propertyCode === 'TXN_COST_LAMBDA'
            )
          )
        }
      })
      .catch((error) => {
        setIsLoading(false)
        showError(error, false, {}, 'Failed to load cash and other constraints.')
      })
  }

  const MinTradeIcon = ({ title }) => (
    <Box
      sx={{
        width: 27,
        height: 22
      }}
    >
      {title}
    </Box>
  )

  // handle onChange for Factor Tilts and validation
  const handleChangeMinMax = (event, rowIndex) => {
    const newValue = event.target.value
    const isInValid = newValue ? validateExpression(newValue) : false
    const newData = [...factorUtilData]
    newData[rowIndex].value = newValue
    setFactorUtilData(newData)
    setPersonalizationData({ ...personalization, factorUtilData: newData, error: { ...personalization.error, factorTilts: isInValid } })
    if (newValue) {
      setfactorTiltsColor(true)
    } else if (factorUtilData?.some(item => item.value !== '')) {
      setfactorTiltsColor(true)
    } else if (personalization?.factorUtilData?.some(item => item.value !== '')) {
      setfactorTiltsColor(true)
    } else {
      setfactorTiltsColor(false)
    }
  }

  // Pass checkEmptyFieldError as true on blur and from useEffect
  const validateMinMaxCashLevel = (updatedCashConstraint, checkEmptyFieldError = false) => {
    // find index of min and max cash level
    const maxCashIndex = updatedCashConstraint.findIndex(item => item.propertyCode === 'MAX_CASH_LEVEL')
    const minCashIndex = updatedCashConstraint.findIndex(item => item.propertyCode === 'MIN_CASH_LEVEL')
    // parse value of min and max cash level input
    const minCashValue = parseFloat(updatedCashConstraint[minCashIndex].value)
    const maxCashValue = parseFloat(updatedCashConstraint[maxCashIndex].value)
    // validate for min cash level for value between 0 to 100
    if (minCashValue < 0 || minCashValue > 100) {
      updatedCashConstraint[minCashIndex].error = true
      setMinCashError('Value for Minimum cash level should be between 0 to 100')
    } else {
      updatedCashConstraint[minCashIndex].error = (checkEmptyFieldError && maxCashValue && !minCashValue) || false
      setMinCashError(checkEmptyFieldError && maxCashValue && !minCashValue ? 'Min cash is required' : '')
    }
    // validate for max cash level for value between 0 to 100
    if (maxCashValue < 0 || maxCashValue > 100) {
      updatedCashConstraint[maxCashIndex].error = true
      setMaxCashError('Maximum cash level should be between 0 to 100')
    } else {
      updatedCashConstraint[maxCashIndex].error = (checkEmptyFieldError && minCashValue && !maxCashValue) || false
      setMaxCashError(checkEmptyFieldError && minCashValue && !maxCashValue ? 'Max cash is required' : '')
    }
    // if either of field is valid then check for min level>= max level and difference error
    if (!updatedCashConstraint[minCashIndex].error || !updatedCashConstraint[maxCashIndex].error) {
      if (minCashValue >= maxCashValue) {
        // if there is an existing error for value between 0 to 100,this step will be skipped
        if (!updatedCashConstraint[minCashIndex].error) {
          updatedCashConstraint[minCashIndex].error = true
          setMinCashError('Minimum cash level should be less than max cash level')
        }
        if (!updatedCashConstraint[maxCashIndex].error) {
          updatedCashConstraint[maxCashIndex].error = true
          setMaxCashError('Maximum cash level should be greater than min cash level')
        }
      } else if (maxCashValue - minCashValue < 0.5) { // if max level > min cash level, check for difference error
        updatedCashConstraint[minCashIndex].error = true
        setMinCashError('Difference between min and max cash level should be minimum of 0.5%')
        updatedCashConstraint[maxCashIndex].error = true
        setMaxCashError('Difference between min and max cash level should be minimum of 0.5%')
      }
    }

    // set updated constraint to cash constraint and personalization data
    setDataCashConstraint(updatedCashConstraint)
    setPersonalizationData(prevPersonalization => ({
      ...prevPersonalization,
      cashConstraintData: updatedCashConstraint,
      error: { ...personalization.error, cashConstraint: updatedCashConstraint.some(item => item.error) }
    }))
  }

  // handle onChange for Cash Constraints and validation
  const handleChangeCashConstraint = (event, rowIndex) => {
    const newData = [...dataCashConstraint]
    newData[rowIndex].value = event.target.value
    if (event?.target?.value?.split('.')[1]?.length > 2) {
      event.preventDefault()
    } else {
      if (newData[rowIndex].propertyCode === 'MIN_CASH_LEVEL' || newData[rowIndex].propertyCode === 'MAX_CASH_LEVEL') {
        validateMinMaxCashLevel(newData)
      } else {
        setDataCashConstraint(newData)
        setPersonalizationData(prevPersonalization => ({
          ...prevPersonalization,
          cashConstraintData: newData,
          error: { ...personalization.error, cashConstraint: newData.some(item => item.error) }
        }))
      }
    }
  }

  // handle onChange for Tax Constraints
  const handleChangeTaxConstraint = (event, rowIndex) => {
    const newData = [...dataTaxConstraint]
    newData[rowIndex].value = event.target.value
    setDataTaxConstraint(newData)
    setPersonalizationData({ ...personalization, taxConstraintData: newData })
  }

  // handle onChange for Other Constraints and validation
  const handleChangeOtherConstraint = (event, rowIndex) => {
    const newData = [...dataOtherConstraint]
    newData[rowIndex].value = event.target.value
    // max and min Beta values to be between +2 to -2
    if (newData[rowIndex]?.value?.length > 0 && (newData[rowIndex].propertyDesc === 'Minimum Beta' || newData[rowIndex].propertyDesc === 'Maximum Beta')) {
      const isValid = parseFloat(event.target.value) >= -2 && parseFloat(event.target.value) <= 2
      newData[rowIndex].error = !isValid
    } else if (newData[rowIndex]?.value?.length > 0 && newData[rowIndex].propertyCode === 'MIN_BUY_SIZE'
    ) {
      const isValid = parseFloat(event.target.value) > 0
      newData[rowIndex].error = !isValid
    } else if (newData[rowIndex]?.value?.length > 0 && newData[rowIndex].propertyCode === 'MIN_TRD_SIZE') {
      const isValid = parseFloat(event.target.value) >= 0 && !event.target.value.includes('.')
      newData[rowIndex].error = !isValid
    } else if (newData[rowIndex]?.value !== null && newData[rowIndex]?.value !== undefined && newData[rowIndex].propertyCode === 'DEFAULT_SCENARIO_CODE') {
      const isValid = event.target.value.trim() !== ''
      newData[rowIndex].error = !isValid
    } else if (newData[rowIndex]?.value?.length > 0 && newData[rowIndex].propertyCode === 'MAX_HOLDINGS') {
      const isValid = parseInt(event.target.value) >= 1 && !event.target.value.includes('.')
      newData[rowIndex].error = !isValid
    } else if (
      newData[rowIndex]?.value?.length > 0 &&
      newData[rowIndex].propertyCode === 'WASHSALE_RES_HOLDING_PERIOD'
    ) {
      const isValid = parseInt(event.target.value) >= 1 && parseInt(event.target.value) <= 30
      newData[rowIndex].error = !isValid
      newData[rowIndex].errorMessage = isValid ? '' : 'Value must be at least 1 day.'
    } else {
      const isValid =
        (newData[rowIndex].uom === '%' && event.target.value !== '')
          ? parseFloat(event.target.value) >= minCashValue && parseFloat(event.target.value) <= maxCashValue
          : true
      newData[rowIndex].error = !isValid
    }
    setDataOtherConstraint(newData)

    setPersonalizationData((prevPersonalization) => ({
      ...prevPersonalization,
      constraintData: newData,
      objectiveConstraintData: objectiveFunctionData,
      otherConstraintData: [...newData, ...objectiveFunctionData],
      error: { ...personalization.error, otherConstraint: newData?.some((item) => item.error) }
    }))
  }

  const handleObjectiveConstraint = (event, rowIndex) => {
    const newObjectiveData = [...objectiveFunctionData]
    newObjectiveData[rowIndex].value = event.target.value
    setObjectiveFunctionData(newObjectiveData)
    setPersonalizationData((prevPersonalization) => ({
      ...prevPersonalization,
      constraintData: dataOtherConstraint,
      objectiveConstraintData: newObjectiveData,
      otherConstraintData: [...dataOtherConstraint, ...newObjectiveData],
      error: { ...personalization.error, otherConstraint: newObjectiveData?.some((item) => item.error) }
    }))
  }

  const handleCheckboxClick = (event, row) => {
    const updatedData = factorUtilData?.map((x) => {
      if (x.attrLabel === row.attrLabel) {
        return {
          ...x,
          selected: !x.selected,
          value: x?.selected ? '' : x?.attrLabel === 'Volatility' || x?.attrLabel === 'Size' ? 'B-0.15' : 'B+0.15'
        }
      }
      return x
    })
    if (updatedData?.some(item => item.value !== '')) {
      setfactorTiltsColor(true)
    } else {
      setfactorTiltsColor(false)
    }
    setFactorUtilData(updatedData)
    setPersonalizationData({ ...personalization, factorUtilData: updatedData })
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleChipClick = (panel) => {
    setExpanded(panel)
  }

  const handleSecurityData = (data) => {
    setPersonalizationData({ ...personalization, securityData: data })
    if (data?.length > 0) {
      setSecurityDataColor(true)
    }
  }

  const handleSubstitutionData = (data) => {
    setPersonalizationData({ ...personalization, substitutionData: data })
  }

  useEffect(() => {
    const borderColor = sectorList?.filter((sector) => sector?.exclude || sector?.min !== '' || sector?.max !== '')?.length > 0
    setSectorBorderColor(borderColor)
    setPersonalizationData(prevData => ({ ...prevData, sectorData: sectorList, sectorBorderColor: borderColor }))
    const checkedSectors = sectorList?.filter((sector) => sector.exclude)?.map((sector) => { return sector.sectorName })
    const data = industryList?.map((industry) => {
      if (checkedSectors?.length > 0 && checkedSectors.includes(industry.sectorName)) {
        return { ...industry, exclude: true, disabled: true }
      } else if (industry.disabled) {
        return { ...industry, exclude: false, disabled: false }
      } else {
        return { ...industry }
      }
    })
    setIndustryList(data)
    const allSectorSecurities = sectorList?.filter((sector) => sector.sectorName === 'All Sectors')?.map((sector) => {
      return sector.count
    })
    let count = 0
    sectorList?.filter((sector) => !sector.exclude && sector.sectorName !== 'All Sectors')?.map((sector) => {
      count = count + sector.count
    })

    setSectorSecurities(count)
    setTotalSectorSecurities(allSectorSecurities)
  }, [sectorList])

  useEffect(() => {
    if (personalization?.factorUtilData) {
      setfactorTiltsColor(personalization?.factorUtilData?.some(item => item.value !== '' || item.selected !== false))
    }
  }, [])

  useEffect(() => {
    setPersonalizationData(prevData => ({ ...prevData, error }))
  }, [error])

  useEffect(() => {
    const allIndustrySecurities = industryList?.filter((industry) => industry.sectorName === 'All Sectors')?.map((industry) => {
      return industry.count
    })
    let count = 0
    industryList?.filter((industry) => !industry.exclude && industry.sectorName !== 'All Sectors')?.map((industry) => {
      count = count + industry.count
    })
    const borderColor = industryList?.filter((sector) => sector?.exclude || sector?.min !== '' || sector?.max !== '')?.length > 0
    setIndustrySecurities(count)
    setTotalIndustrySecurities(allIndustrySecurities)
    setIndustryBorderColor(borderColor)
    setPersonalizationData(prevData => ({ ...prevData, industryData: industryList, industryBorderColor: borderColor }))
  }, [industryList])

  const dataFlag = (data) => {
    setSubColorFlag(data)
    setPersonalizationData(prevData => ({ ...prevData, subColorFlag: data }))
  }

  // Filter the scenario list based on accountDetails
  const filteredScenarioList = Array.isArray(allScenarioList) && allScenarioList?.length > 0
    ? allScenarioList.filter((scenario) => {
      // taxable and strType is SIMPLE or DERIVED, scenarioGroupId 1
      if (accountDetails?.taxManaged && (strategy?.strType === 'SIMPLE' || strategy?.strType === 'DERIVED')) {
        return scenario.scenarioGroupId === 1
      }
      return scenario.scenarioGroupId === 2
    })
    : []

  return (

    <div>
      <Box sx={{ flexGrow: 1, mt: 2 }} className='modelSection'>
        <Box className='trade-table-list-header' mb={2}>Constraints</Box>
        <hr style={{ borderBottom: '1px solid #dee2e6', borderTop: '0' }} />
        <ThemeProvider theme={theme}>
          <Grid container item spacing={3}>
            <Grid item xs={12} sm={9} order={{ xs: 2, sm: 1 }} mt={1}>
              <Grid container item spacing={3}>
                <Grid item xs={12}>
                  <Box px={0} py={1}>
                    <Accordion
                      className='accordion-layout'
                      expanded={expanded === 'panel1'}
                      onChange={handleChange('panel1')}
                      sx={{ border: sectorBorderColor ? '1px solid #175CC2' : '' }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1bh-content'
                        id='panel1bh-header'
                        sx={{ height: '52px' }}
                      >
                        <Typography sx={{ width: '16%', flexShrink: 0 }}>
                          Sector
                        </Typography>
                        <Tooltip
                          disableFocusListener
                          disableTouchListener
                          placement='right'
                          title='Exclude sectors from the portfolio or restrict their Z-Score'
                        >
                          <InfoOutlinedIcon fontSize='small' />
                        </Tooltip>
                        <Typography sx={{ width: '80%', flexShrink: 0, textAlign: 'end' }}>
                          Number of securities: {sectorSecurities}/{totalSectorSecurities}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {sectorList?.length > 0 ? <Sector data={sectorList} setData={setSectorList} setError={setError} /> : <></>}
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                  <Box px={0} py={1}>
                    <Accordion
                      className='accordion-layout'
                      expanded={expanded === 'panel2'}
                      onChange={handleChange('panel2')}
                      sx={{ border: industryBorderColor ? '1px solid #175CC2' : '' }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel2bh-content'
                        id='panel2bh-header'
                        sx={{ height: '52px' }}
                      >
                        <Typography sx={{ width: '16%', flexShrink: 0 }}>
                          Industry
                        </Typography>
                        <Tooltip
                          disableFocusListener
                          disableTouchListener
                          placement='right'
                          title='Exclude sectors from the portfolio or restrict their Z-Score'
                        >
                          <InfoOutlinedIcon fontSize='small' />
                        </Tooltip>
                        <Typography sx={{ width: '80%', flexShrink: 0, textAlign: 'end' }}>
                          Number of securities: {industrySecurities}/{sectorSecurities}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Industry data={industryList} setData={setIndustryList} setError={setError} />
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                  <Box px={0} py={1}>
                    <Accordion
                      className='accordion-layout'
                      expanded={expanded === 'panel4'}
                      onChange={handleChange('panel4')}
                      sx={{ border: securityDataColor ? '1px solid #175CC2' : '' }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel4bh-content'
                        id='panel4bh-header'
                        sx={{ height: '52px' }}
                      >
                        <Typography sx={{ width: '16%', flexShrink: 0 }}>
                          Security
                        </Typography>
                        <Tooltip
                          disableFocusListener
                          disableTouchListener
                          placement='right'
                          title='Apply restrictions at a security level'
                        >
                          <InfoOutlinedIcon fontSize='small' />
                        </Tooltip>
                      </AccordionSummary>
                      <AccordionDetails>
                        <SecurityRestictions onData={handleSecurityData} personalization={personalization} setError={setError} />
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                  <Box px={0} py={1}>
                    <Accordion
                      className='accordion-layout'
                      expanded={expanded === 'panelS2'}
                      onChange={handleChange('panelS2')}
                      sx={{ border: factorTiltsColor ? '1px solid #175CC2' : '' }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel4bh-content'
                        id='panel4bh-header'
                        sx={{ height: '52px' }}
                      >
                        <Typography sx={{ width: '16%', flexShrink: 0 }}>
                          Factor Tilts
                        </Typography>
                        <Tooltip
                          disableFocusListener
                          disableTouchListener
                          placement='right'
                          title='Tune various portfolio factors for better risk adjusted returns'
                        >
                          <InfoOutlinedIcon fontSize='small' />
                        </Tooltip>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table size='small'>
                          <TableHead>
                            <TableRow>
                              {headers?.map((header) => (
                                <TableCell key={header} sx={{ fontWeight: 600 }}>
                                  {header}
                                  {header === 'Absolute/Benchmark Relative' || header === 'Range'
                                    ? (
                                      <span style={{ verticalAlign: 'sub', marginLeft: '4px' }}>
                                        <Tooltip
                                          disableFocusListener
                                          disableTouchListener
                                          placement='top'
                                          title={
                                            header === 'Absolute/Benchmark Relative'
                                              ? 'You can indicate a relative value by using the format B(+/-)(value). For example,  B-0.58 or B+0.58.'
                                              : header === 'Range'
                                                ? 'Range is based on two ETFs, find the ETFs used by hovering on numbers below'
                                                : ''
                                          }
                                        >
                                          <InfoOutlinedIcon fontSize='small' />
                                        </Tooltip>
                                      </span>
                                      )
                                    : ''}
                                </TableCell>

                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {factorUtilData?.map((row, index) => {
                              let tooltipValue = ''
                              let textColor = ''
                              switch (row?.attrLabel) {
                                case 'Value':
                                  tooltipValue = 'S&P 500 pure growth - S&P 500 pure value'
                                  textColor = '#34475A'
                                  break
                                case 'Growth':
                                  tooltipValue = 'S&P 500 pure value - S&P 500 pure growth'
                                  textColor = '#34475A'
                                  break
                                case 'Volatility':
                                  tooltipValue = 'S&P 500 minimum volatility - S&P 500'
                                  textColor = '#34475A'
                                  break
                                case 'Dividend Yield':
                                  tooltipValue = 'S&P 500 - S&P 500 high dividend'
                                  textColor = '#34475A'
                                  break
                                case 'Size':
                                  tooltipValue = 'S&P 500 small cap - S&P 500 mega cap'
                                  textColor = '#34475A'
                                  break
                                case 'Quality':
                                  tooltipValue = 'S&P 500 - S&P 500 quality'
                                  textColor = '#34475A'
                                  break
                                default:
                                  tooltipValue = ''
                                  textColor = '#34475A'
                                  break
                              }
                              return (
                                <TableRow key={index}>
                                  <TableCell sx={{ fontWeight: 600, color: textColor }}>{row?.attrLabel}</TableCell>
                                  <TableCell sx={{ fontWeight: 600, color: textColor }}>{decTwo(row?.benchmarkValue, 2)}</TableCell>
                                  {tooltipValue !== ''
                                    ? <Tooltip title={tooltipValue}>
                                      <TableCell sx={{ fontWeight: 600, color: textColor }}>
                                        {decTwo(row?.minValue, 2)} - {decTwo(row?.maxValue, 2)}
                                      </TableCell>
                                    </Tooltip>
                                    : <TableCell sx={{ fontWeight: 600, color: textColor }}>
                                      {decTwo(row?.minValue, 2)} - {decTwo(row?.maxValue, 2)}
                                    </TableCell>}
                                  <TableCell sx={{ fontWeight: 600, color: textColor }}>
                                    <Checkbox
                                      checked={row?.selected}
                                      onClick={(event) => handleCheckboxClick(event, row)}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      label='Z-Score'
                                      size='small'
                                      sx={{ fontWeight: 600, color: textColor }}
                                      value={row?.value}
                                      onChange={(event) => handleChangeMinMax(event, index)}
                                      error={row?.value ? validateExpression(row?.value) : ''}
                                    />
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                  <Box px={0} py={1}>
                    <Accordion
                      className='accordion-layout'
                      expanded={expanded === 'panelS1'}
                      onChange={handleChange('panelS1')}
                      sx={{ border: subColorFlag ? '1px solid #175CC2' : '' }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel4bh-content'
                        id='panel4bh-header'
                        sx={{ height: '52px' }}
                      >
                        <Typography sx={{ width: '16%', flexShrink: 0 }}>
                          Substitutions
                        </Typography>
                        <Tooltip
                          disableFocusListener
                          disableTouchListener
                          placement='right'
                          title='Replace securities in the portfolio with certain other preferred securities'
                        >
                          <InfoOutlinedIcon fontSize='small' />
                        </Tooltip>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Substitutions onData={handleSubstitutionData} personalization={personalization} funct={dataFlag} setError={setError} />
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                  <Box px={0} py={1}>
                    <Accordion
                      className='accordion-layout'
                      expanded={expanded === 'panelC1'}
                      onChange={handleChange('panelC1')}
                      sx={{
                        border: dataCashConstraint?.some(item => !!item.value) ? '1px solid #175CC2' : ''
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel4bh-content'
                        id='panel4bh-header'
                        sx={{ height: '52px' }}
                      >
                        <Typography sx={{ width: '16%', flexShrink: 0 }}>
                          Cash Constraints
                        </Typography>
                        <Tooltip
                          disableFocusListener
                          disableTouchListener
                          placement='right'
                          title='Enforce constraints on cash in the portfolio'
                        >
                          <InfoOutlinedIcon fontSize='small' />
                        </Tooltip>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table size='small' sx={{ marginTop: '-20px' }}>
                          <TableHead>
                            <TableRow>
                              {cashConstraints?.map((header) => (
                                <TableCell sx={{ fontWeight: 600, color: '#34475A' }} key={header}>{header}</TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(dataCashConstraint || [])?.map((row, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell sx={{ color: '#34475A' }}>
                                    {row?.propertyDesc === 'Minimum Cash Level' || row?.propertyDesc === 'Maximum Cash Level'
                                      ? (
                                        <div style={{ display: 'flex', alignItems: 'center', color: '#34475A' }}>
                                          {row.propertyDesc}
                                          <Box sx={{ visibility: row.error ? 'visible' : 'hidden' }}>
                                            <CustomTooltipIcon
                                              text={
                                                row.propertyDesc === 'Minimum Cash Level'
                                                  ? (minCashError)
                                                  : row.propertyDesc === 'Maximum Cash Level'
                                                    ? (maxCashError)
                                                    : ''
                                              } action='error'
                                            />
                                          </Box>
                                        </div>
                                        )
                                      : (
                                        <>{row?.propertyDesc || ''}</>
                                        )}
                                  </TableCell>
                                  <TableCell>
                                    {row?.uom === '$'
                                      ? <InputAdornmentTextField
                                          value={row?.value}
                                          label='Value'
                                          sx={{ fontWeight: 600, width: '220px' }}
                                          size='small'
                                          onKeyDown={(e) => isNumberOnly(e)}
                                          onPaste={numberOnlyPasteHandler}
                                          onChange={(event) => handleChangeCashConstraint(event, index)}
                                          onBlur={() => {
                                            validateMinMaxCashLevel(dataCashConstraint, true)
                                          }}
                                          adornment={<AttachMoneyOutlinedIcon />}
                                        />
                                      : <TextField
                                          label='Value'
                                          size='small'
                                          sx={{ fontWeight: 600, width: '220px' }}
                                          value={row?.value || ''}
                                          onKeyDown={(event) => isNumberOnly(event)}
                                          onPaste={numberOnlyPasteHandler}
                                          onChange={(event) => handleChangeCashConstraint(event, index)}
                                          onBlur={() => {
                                            validateMinMaxCashLevel(dataCashConstraint, true)
                                          }}
                                          error={row?.uom === '%' ? row.error : false}
                                          InputProps={{
                                            inputProps: {
                                              min: minCashValue,
                                              max: maxCashValue
                                            },
                                            endAdornment: (row?.uom === '%'
                                              ? (
                                                <InputAdornment
                                                  position='end'
                                                >
                                                  <PercentOutlinedIcon sx={{
                                                    width: 18,
                                                    height: 18
                                                  }}
                                                  />
                                                </InputAdornment>)
                                              : ''
                                            )
                                          }}
                                        />}
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                  <Box px={0} py={1}>
                    <Accordion
                      className='accordion-layout'
                      expanded={expanded === 'panelT1'}
                      onChange={handleChange('panelT1')}
                      sx={{ border: dataTaxConstraint[0]?.value || dataTaxConstraint[1]?.value || dataTaxConstraint[2]?.value ? '1px solid #175CC2' : '' }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel4bh-content'
                        id='panel4bh-header'
                        sx={{ height: '52px' }}
                      >
                        <Typography sx={{ width: '16%', flexShrink: 0 }}>
                          Tax Constraints
                        </Typography>
                        <Tooltip
                          disableFocusListener
                          disableTouchListener
                          placement='right'
                          title='Enforce constraints on portfolio tax management'
                        >
                          <InfoOutlinedIcon fontSize='small' />
                        </Tooltip>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table size='small' sx={{ marginTop: '-20px' }}>
                          <TableHead>
                            <TableRow>
                              {taxConstraints?.map((header) => (
                                <TableCell key={header} sx={{ fontWeight: 600, color: '#34475A' }}>{header}</TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dataTaxConstraint?.map((row, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell sx={{ fontWeight: 600, color: '#34475A' }}>{row?.name}</TableCell>
                                  <TableCell sx={{ fontWeight: 600, color: '#34475A' }}>
                                    {row?.name === 'Tax Sensitivity'
                                      ? // Render a Select component for Tax Sensitivity
                                        <Select
                                          labelId='tax-sen-label'
                                          size='small'
                                          sx={{ width: '220px' }} // customizing width to match TextField
                                          value={row?.value}
                                          onChange={(event) => handleChangeTaxConstraint(event, index)}
                                        >
                                          <MenuItem value=''>
                                            <em>None</em>
                                          </MenuItem>
                                          {row?.options?.map((option, i) => (
                                            <MenuItem key={i} value={option}>{option}</MenuItem>
                                          ))}
                                        </Select>
                                      : <InputAdornmentTextField
                                          value={row?.value}
                                          label='Value'
                                          size='small'
                                          sx={{ fontWeight: 600, width: '220px', color: '#34475A' }}
                                          onKeyDown={(e) => isNumberOnly(e)}
                                          onPaste={numberOnlyPasteHandler}
                                          onChange={(event) => handleChangeTaxConstraint(event, index)}
                                          adornment={<AttachMoneyOutlinedIcon />}
                                        />}
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                  <Box px={0} py={1}>
                    <Accordion
                      className='accordion-layout'
                      expanded={expanded === 'panelGE1'}
                      onChange={handleChange('panelGE1')}
                      disabled
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel4bh-content'
                        id='panel4bh-header'
                        sx={{ height: '52px' }}
                      >
                        <Typography sx={{ width: '16%', flexShrink: 0 }}>
                          Geography
                        </Typography>
                        <InfoOutlinedIcon fontSize='small' />
                      </AccordionSummary>
                    </Accordion>
                  </Box>
                  <Box px={0} py={1}>
                    <Accordion
                      className='accordion-layout'
                      expanded={expanded === 'panelOC1'}
                      onChange={handleChange('panelOC1')}
                      sx={{
                        border: dataOtherConstraint?.some(item => !!item.value) ? '1px solid #175CC2' : ''
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel4bh-content'
                        id='panel4bh-header'
                        sx={{ height: '52px' }}
                      >
                        <Typography sx={{ width: '16%', flexShrink: 0 }}>
                          Other Constraints
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack>
                          <Table size='small' sx={{ marginTop: '-20px' }}>
                            <TableHead>
                              <TableRow>
                                {otherConstraints?.map((header) => (
                                  <TableCell key={header} sx={{ fontWeight: 600, color: '#34475A' }}>{header}</TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {(dataOtherConstraint || [])
                                ?.map((row, index) => {
                                  return (
                                    <TableRow key={index}>
                                      <TableCell sx={{ fontWeight: 600, color: '#34475A', width: '50%', ...{ ...index === dataOtherConstraint.length - 1 ? { borderBottom: 'none' } : {} } }}>
                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                          {row?.propertyDesc}
                                          <CustomTooltipIcon
                                            text={
                                              (() => {
                                                switch (row.propertyCode) {
                                                  case 'MIN_BUY_SIZE':
                                                    return 'Value must be greater than 0'
                                                  case 'MIN_BUY_WEIGHT':
                                                    return 'Value must be between 0 to 100'
                                                  case 'MAX_BETA':
                                                    return 'Value must be between -2 to 2'
                                                  case 'MIN_BETA':
                                                    return 'Value must be between -2 to 2'
                                                  default:
                                                    return row.propertyLongText
                                                }
                                              })()
                                            } action='info'
                                          />
                                        </Box>
                                      </TableCell>
                                      <TableCell sx={{ width: '50%', ...{ ...index === dataOtherConstraint.length - 1 ? { borderBottom: 'none' } : {} } }}>
                                        {row.propertyCode === 'DEFAULT_SCENARIO_CODE'
                                          ? (
                                            <Select
                                              size='small'
                                              value={row?.value || ''}
                                              onChange={(event) => handleChangeOtherConstraint(event, index)}
                                              sx={{ width: '220px' }}
                                              disabled={
                                                !(
                                                  accountDetails?.taxManaged &&
                                                  (selectedStrategy?.strType === 'SIMPLE' || selectedStrategy?.strType === 'DERIVED')
                                                )
                                              }
                                            >
                                              {filteredScenarioList?.map((scenario) => (
                                                <MenuItem key={scenario.scenarioCode} value={scenario.scenarioCode}>
                                                  {scenario.scenarioDescription}
                                                </MenuItem>
                                              ))}
                                            </Select>
                                            )
                                          : row.propertyCode === 'WASHSALE_RES_HOLDING_PERIOD'
                                            ? (
                                              <TextField
                                                label='Value'
                                                size='small'
                                                sx={{ width: '220px' }}
                                                value={row.value || ''}
                                                onKeyDown={(event) => isWholeNumber(event)}
                                                onPaste={wholeNumberPasteHandler}
                                                onChange={(event) => handleChangeOtherConstraint(event, index)}
                                                InputProps={{
                                                  endAdornment: (
                                                    <InputAdornment position='end'>
                                                      <Box sx={{ fontSize: 12, color: 'rgba(0, 0, 0, 0.6)' }}>days</Box>
                                                    </InputAdornment>
                                                  )
                                                }}
                                                error={row?.error}
                                              />
                                              )
                                            : row?.uom === '$'
                                              ? (
                                                <InputAdornmentTextField
                                                  value={row?.value}
                                                  label='Value'
                                                  size='small'
                                                  sx={{ fontWeight: 600, width: '220px', color: '#34475A' }}
                                                  onPaste={numberOnlyPasteHandler}
                                                  onKeyDown={(event) => isNumberOnly(event)}
                                                  onChange={(event) => handleChangeOtherConstraint(event, index)}
                                                  error={row?.error && row?.value}
                                                  adornment={<AttachMoneyOutlinedIcon />}
                                                />
                                                )
                                              : row?.uom === '%'
                                                ? (
                                                  <TextField
                                                    label='Value'
                                                    size='small'
                                                    value={row?.value}
                                                    sx={{ fontWeight: 600, width: '220px', color: '#34475A' }}
                                                    onKeyDown={(event) => isNumberOnly(event)}
                                                    onPaste={numberOnlyPasteHandler}
                                                    onChange={(event) => handleChangeOtherConstraint(event, index)}
                                                    error={row?.error && row?.value}
                                                    InputProps={{
                                                      inputProps: {
                                                        min: minCashValue,
                                                        max: maxCashValue
                                                      },
                                                      endAdornment: (
                                                        <InputAdornment
                                                          position='end'
                                                        >
                                                          <PercentOutlinedIcon sx={{
                                                            width: 18,
                                                            height: 18
                                                          }}
                                                          />
                                                        </InputAdornment>
                                                      )
                                                    }}
                                                  />
                                                  )
                                                : (
                                                  <TextField
                                                    label='Value'
                                                    size='small'
                                                    value={row?.value}
                                                    sx={{ fontWeight: 600, width: '220px', color: '#34475A' }}
                                                    onKeyDown={(event) => isNumberWithSign(event)}
                                                    onPaste={numberWithSignPasteHandler}
                                                    onChange={(event) => handleChangeOtherConstraint(event, index)}
                                                    error={row?.error && row?.value}
                                                    InputProps={{
                                                      endAdornment: (
                                                        row.propertyCode === 'MIN_TRD_SIZE'
                                                          ? <InputAdornment
                                                              position='end'
                                                            >
                                                            <MinTradeIcon title='bps' />
                                                          </InputAdornment>
                                                          : ''
                                                      )
                                                    }}
                                                  />
                                                  )}
                                      </TableCell>
                                    </TableRow>
                                  )
                                })}
                            </TableBody>
                          </Table>
                          <Divider sx={{ bgcolor: 'rgba(116, 120, 141, 0.3)', margin: '10px 0' }} />
                          <Box mY={0}>Objective Function Priorities</Box>
                          <Table size='small'>
                            <TableHead>
                              <TableRow>
                                {otherConstraints?.map((header) => (
                                  <TableCell key={header} sx={{ fontWeight: 600, color: '#34475A' }}>{header}</TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {(objectiveFunctionData || [])?.map((row, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell sx={{ fontWeight: 600, color: '#34475A', width: '50%' }}>
                                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        {row?.propertyDesc}
                                        <CustomTooltipIcon
                                          text={row.propertyLongText} action='info'
                                        />
                                      </Box>
                                    </TableCell>
                                    <TableCell sx={{ width: '50%' }}>
                                      {row?.uom === '$'
                                        ? (<InputAdornmentTextField
                                            value={row?.value}
                                            label='Value'
                                            size='small'
                                            sx={{ fontWeight: 600, width: '220px', color: '#34475A' }}
                                            onKeyDown={(e) => isNumberOnly(e)}
                                            onPaste={numberOnlyPasteHandler}
                                            onChange={(event) => handleObjectiveConstraint(event, index)}
                                            error={row?.error && row?.value}
                                            adornment={<AttachMoneyOutlinedIcon />}
                                           />
                                          )
                                        : <TextField
                                            label='Value'
                                            size='small'
                                            value={row?.value}
                                            sx={{ fontWeight: 600, width: '220px', color: '#34475A' }}
                                            onKeyDown={(event) => isNumberOnly(event)}
                                            onPaste={numberOnlyPasteHandler}
                                            onChange={(event) => handleObjectiveConstraint(event, index)}
                                            error={row?.error && row?.value}
                                            InputProps={{
                                              inputProps: {
                                                min: minCashValue,
                                                max: maxCashValue
                                              },
                                              endAdornment: (row?.uom === '%'
                                                ? (
                                                  <InputAdornment
                                                    position='end'
                                                  >
                                                    <PercentOutlinedIcon sx={{
                                                      width: 18,
                                                      height: 18
                                                    }}
                                                    />
                                                  </InputAdornment>)
                                                : ''
                                              )
                                            }}
                                          />}
                                    </TableCell>
                                  </TableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {isLoading ? <Loader /> : ''}
            <Grid item xs={12} sm={3} order={{ xs: 1, sm: 2 }} mt={1}>
              <Grid container item spacing={3} pt={1}>
                <Grid item xs={12}>
                  <Card p={4} className='card-layout'>
                    <CardContent sx={{ backgroundColor: 'white', marginTop: '-0.8em', p: 0 }}>
                      <Grid xs={12} sm={12}>
                        <Box sx={{ px: 2, pt: 3, pb: 0 }}>
                          <Typography variant='outlined' sx={{ fontSize: 16, color: '#34475A', fontWeight: 600 }}>
                            Account Details
                          </Typography>
                        </Box>
                      </Grid>
                      <hr style={{ borderBottom: '1px solid #dee2e6', borderTop: '0' }} />
                      <Grid xs={12} sm={12} mt={1} style={{ color: '#34475A' }}>
                        <Box ml={2}>
                          <Typography variant='outlined' className='modelCardSub'>
                            Name: {accountDetails?.name}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid xs={12} sm={12} mt={1} style={{ color: '#34475A' }}>
                        <Box ml={2}>
                          <Typography variant='outlined' className='modelCardSub'>
                            Strategy: {strategy?.portSpecName}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid xs={12} sm={12} mt={1} style={{ color: '#34475A' }}>
                        <Box ml={2}>
                          <Typography variant='outlined' className='modelCardSub'>
                            Type: {accountDetails && accountDetails?.taxManaged === true ? 'Taxable' : 'Non-Taxable'}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid xs={12} sm={12} mt={1} style={{ color: '#34475A' }}>
                        <Box ml={2}>
                          <Typography variant='outlined' className='modelCardSub'>
                            Cash: {taxlotData?.totalCashValue
                            ? Number(taxlotData?.totalCashValue) < 0
                              ? `-$${Math.abs(taxlotData?.totalCashValue)?.toLocaleString('en')}`
                              : `$${Number(taxlotData?.totalCashValue)?.toLocaleString('en')}`
                            : '-'}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid xs={12} sm={12} mt={1} style={{ color: '#34475A' }}>
                        <Box ml={2}>
                          <Typography variant='outlined' className='modelCardSub'>
                            Total Market Value:
                            {(taxlotData?.totalPortfolioMarketValue !== null || taxlotData?.totalPortfolioMarketValue !== '')
                              ? Number(taxlotData?.totalPortfolioMarketValue) < 0
                                ? `-$${Math.abs(taxlotData?.totalPortfolioMarketValue)?.toLocaleString('en')}`
                                : `$${Number(taxlotData?.totalPortfolioMarketValue)?.toLocaleString('en')}`
                              : '-'}
                          </Typography>
                        </Box>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <Grid container item spacing={2} marginTop={1}>
                <Grid item xs={12}>
                  <Card p={4} className='card-layout'>
                    <CardContent sx={{ backgroundColor: 'white', marginTop: '-0.8em', p: 0 }}>
                      <Grid xs={12} sm={12}>
                        <Box sx={{ px: 2, pt: 3, pb: 0 }}>
                          <Typography variant='outlined' sx={{ fontSize: 16, color: '#34475A', fontWeight: 600 }}>
                            Selected Constraints
                          </Typography>
                        </Box>
                      </Grid>
                      <hr style={{ borderBottom: '1px solid #dee2e6', borderTop: '0' }} />
                      <Grid xs={12} sm={12} mt={1} style={{ color: '#74788D' }}>
                        <Box ml={2}>
                          <Typography variant='outlined' className='modelCardSub'>
                            Sector:
                            {personalization?.sectorData?.filter((sector) => sector.exclude || sector.min !== '' || sector.max !== '')?.map((chip, index) => {
                              return (
                                <>
                                  {index < 1 ? <><Chip label={chip.sectorName} size='small' mx={2} sx={{ marginLeft: '10px', color: '#34475A', backgroundColor: 'white' }} onClick={() => handleChipClick('panel1')} /></> : ''}
                                  {index === 1 ? <Chip label='...' key='ellipsis' size='small' sx={{ backgroundColor: 'white', marginTop: '5px' }} onClick={() => handleChipClick('panel1')} /> : ''}
                                </>
                              )
                            })}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid xs={12} sm={12} mt={1}>
                        <Box ml={2}>
                          <Typography variant='outlined' className='modelCardSub'>
                            Industry:
                            {personalization?.industryData?.filter((industry) => industry.exclude || industry.min !== '' || industry.max !== '')?.map((chip, index) => {
                              return (
                                <>
                                  {index < 1 ? <><Chip label={chip.industry} size='small' mx={2} sx={{ marginLeft: '10px', color: '#34475A', backgroundColor: 'white' }} onClick={() => handleChipClick('panel2')} /></> : ''}
                                  {index === 1 ? <Chip label='...' key='ellipsis' size='small' sx={{ backgroundColor: 'white', marginTop: '5px' }} onClick={() => handleChipClick('panel2')} /> : ''}
                                </>
                              )
                            })}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid xs={12} sm={12} mt={1} style={{ color: '#74788D' }}>
                        <Box ml={2}>
                          <Typography variant='outlined' className='modelCardSub'>
                            Security:
                            {personalization?.securityData?.map((chip, index) => {
                              return (
                                <>
                                  {index < 1 ? <><Chip label={chip.actionKey?.split('-')[0] || ''} size='small' mx={2} sx={{ marginLeft: '10px', color: '#34475A', backgroundColor: 'white' }} onClick={() => handleChipClick('panel4')} /></> : ''}
                                  {index === 1 ? <Chip label='...' key='ellipsis' size='small' sx={{ backgroundColor: 'white', marginTop: '5px' }} onClick={() => handleChipClick('panel4')} /> : ''}
                                </>
                              )
                            })}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid xs={12} sm={12} mt={1} style={{ color: '#74788D' }}>
                        <Box ml={2}>
                          <Typography variant='outlined' className='modelCardSub'>
                            Factor Tilts:
                            {personalization?.factorUtilData?.filter((factor) => factor.selected || factor.value !== '')?.map((chip, index) => {
                              return (
                                <>
                                  {index < 1 ? <><Chip label={chip.attrLabel} size='small' mx={2} sx={{ marginLeft: '10px', color: '#34475A', backgroundColor: 'white' }} onClick={() => handleChipClick('panelS2')} /></> : ''}
                                  {index === 1 ? <Chip label='...' key='ellipsis' size='small' sx={{ backgroundColor: 'white', marginTop: '5px' }} onClick={() => handleChipClick('panelS2')} /> : ''}
                                </>
                              )
                            })}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid xs={12} sm={12} mt={1} style={{ color: '#74788D' }}>
                        <Box ml={2}>
                          <Typography variant='outlined' className='modelCardSub'>
                            Substitutions:
                            {personalization?.substitutionData?.map((chip, index) => {
                              return (
                                <>
                                  {index < 1 ? <><Chip label={`${chip.srcId?.split('-')[0] || ''} -> ${chip.trgId?.split('-')[0] || ''}`} size='small' mx={2} sx={{ marginLeft: '10px', color: '#34475A', backgroundColor: 'white' }} onClick={() => handleChipClick('panelS1')} /></> : ''}
                                  {index === 1 ? <Chip label='...' key='ellipsis' size='small' sx={{ backgroundColor: 'white', marginTop: '5px' }} onClick={() => handleChipClick('panelS1')} /> : ''}
                                </>
                              )
                            })}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid xs={12} sm={12} mt={1} style={{ color: '#74788D' }}>
                        <Box ml={2}>
                          <Typography variant='outlined' className='modelCardSub'>
                            Cash Constraints:
                            {personalization?.cashConstraintData?.filter((cashConstraint) => cashConstraint.value !== null && cashConstraint.value !== undefined && cashConstraint.value !== '')?.map((chip, index) => {
                              if (chip?.value !== null && chip?.value !== undefined && chip?.value !== '') {
                                return (
                                  <>
                                    {index < 1 ? <><Chip label={chip.propertyDesc} size='small' mx={2} sx={{ marginLeft: '10px', color: '#34475A', backgroundColor: 'white' }} onClick={() => handleChipClick('panelC1')} /></> : ''}
                                    {index === 1 ? <Chip label='...' key='ellipsis' size='small' sx={{ backgroundColor: 'white', marginTop: '5px' }} onClick={() => handleChipClick('panelC1')} /> : ''}
                                  </>
                                )
                              }
                            })}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid xs={12} sm={12} mt={1} style={{ color: '#74788D' }}>
                        <Box ml={2}>
                          <Typography variant='outlined' className='modelCardSub'>
                            Tax Constraints:
                            {
                              personalization?.taxConstraintData?.filter((taxConstraint) => taxConstraint?.value !== '')?.map((chip, index) => {
                                return (
                                  <>
                                    {index < 1 ? <><Chip label={chip.name} size='small' mx={2} sx={{ marginLeft: '10px', color: '#34475A', backgroundColor: 'white' }} onClick={() => handleChipClick('panelT1')} /></> : ''}
                                    {index === 1 ? <Chip label='...' key='ellipsis' size='small' sx={{ backgroundColor: 'white', marginTop: '5px' }} onClick={() => handleChipClick('panelT1')} /> : ''}
                                  </>
                                )
                              })
                            }
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid xs={12} sm={12} mt={1} style={{ color: '#74788D' }}>
                        <Box ml={2}>
                          <Typography variant='outlined' className='modelCardSub'>
                            Other Constraints:
                            {personalization?.otherConstraintData?.filter((otherConstraint) => otherConstraint.value !== null && otherConstraint.value !== undefined && otherConstraint.value !== '')?.map((chip, index) => {
                              if (chip?.value !== null && chip?.value !== undefined && chip?.value !== '') {
                                return (
                                  <>
                                    {index < 1 ? <><Chip label={chip.propertyDesc} size='small' mx={2} sx={{ marginLeft: '10px', color: '#34475A', backgroundColor: 'white' }} onClick={() => handleChipClick('panelOC1')} /></> : ''}
                                    {index === 1 ? <Chip label='...' key='ellipsis' size='small' sx={{ backgroundColor: 'white', marginTop: '5px' }} onClick={() => handleChipClick('panelOC1')} /> : ''}
                                  </>
                                )
                              }
                            })}
                          </Typography>
                        </Box>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ThemeProvider>
      </Box>
    </div>
  )
}

export default PersonalizationTab

import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import { Autocomplete, Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import './AccountOnboarding.scss'
import { checkInstrumentSearchQuery } from '../../utils/searchQueryUtils'

function Substitutions (props) {
  const { showError } = useErrorToast()
  const { user } = useAuth()
  const [sourceOptions, setSourceOptions] = useState([])
  const [sourceName, setSourceName] = useState([])
  const [customErrors2, setCustomErrors2] = useState({})
  const [isLoadingSource, setIsLoadingSource] = useState(false)
  const [addClicked, setAddClicked] = useState(!!props.personalization?.substitutionData)
  const [sourceValue, setSourceValue] = useState('')
  const [targetValue, setTargetValue] = useState('')
  const [selectedValues, setSelectedValues] = useState([])
  const [tableRows, setTableRows] = useState(props.personalization?.substitutionData
    ? props.personalization.substitutionData?.map((substitution) => {
      const subData = { sourceValue: { name: substitution.source }, targetValue: { name: substitution.target } }
      return subData
    })
    : [])
  const [errorName, setErrorName] = useState(false)
  const [errorCode, setErrorCode] = useState(false)
  const [substitutionList, setSubstitutionList] = useState(props.personalization?.substitutionData ? props.personalization.substitutionData : [])

  // handle onChange for source name
  const onSourceNameChange = (query, newValue) => {
    setIsLoadingSource(true)
    setSourceValue(newValue)
    setSelectedValues([...selectedValues, newValue])
    setErrorName(false)
  }

  // handle onChange for target name
  const onTargetNameChange = (query, newValue) => {
    setIsLoadingSource(true)
    setTargetValue(newValue)
    setSelectedValues([...selectedValues, newValue])
    setErrorCode(false)
  }

  // API to render the list of securities when searched for security name
  const onInputChange = (event) => {
    const query = (event?.target?.value || '').toString().trim()
    if (query === '') {
      setSourceName([])
    }
    if (sourceName?.length === 0 && query?.toString()?.trim() === '') {
      setCustomErrors2({ sourceName: { message: 'Source Name required' } })
    } else {
      if (checkInstrumentSearchQuery(query)) {
        setIsLoadingSource(true)
        API.get('baseUriTransactionalMaster', `transactional-master/v1/${user?.userGroup}/instruments`, {
          queryStringParameters: { search: query }
        })
          .then((response) => {
            if (response?.data) {
              setSourceOptions([...response.data])
              const tempErrors = { ...customErrors2 }
              delete tempErrors.sourceName
              setCustomErrors2(tempErrors)
            }
          })
          .catch((error) => {
            showError(error, false, {}, 'Failed to load securities.')
          })
          .finally(() => {
            setIsLoadingSource(false)
          })
      }
    }
  }

  // handle substitution restriction with validations
  const handleSubmitSubstitution = (event) => {
    const isSourceValueEmpty = !sourceValue?.name
    const isTargetValueEmpty = !targetValue?.name
    setErrorName(isSourceValueEmpty)
    setErrorCode(isTargetValueEmpty)
    if (!isSourceValueEmpty && !isTargetValueEmpty) {
      if (targetValue.name === sourceValue.name) {
        setErrorName(true)
        setErrorCode(true)
        showError('Target instrument ID must not be the same as the source instrument ID.')
      } else {
        const isDuplicate = substitutionList.some(
          (item) =>
            item.source === sourceValue.name && item.target === targetValue.name
        )
        if (isDuplicate) {
          showError('An active entry already exists. Please choose different securities.')
          setErrorName(true)
          setErrorCode(true)
        } else {
          event.preventDefault()
          setAddClicked(true)
          props.funct(true)
          setErrorName(false)
          setErrorCode(false)
          setSubstitutionList([
            ...substitutionList,
            { source: sourceValue.name, target: targetValue.name, srcId: sourceValue.instrId, trgId: targetValue.instrId }
          ])
          setTableRows([
            ...tableRows,
            {
              sourceValue,
              targetValue
            }
          ])
          setSourceValue('')
          setTargetValue('')
          setSelectedValues([])
        }
      }
    }
  }

  useEffect(() => {
    props.onData([...substitutionList])
  }, [substitutionList])

  return (
    <Box sx={{ width: '100%', marginTop: '-30px' }}>
      <Box>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className='table-cell-styles'>
                <Autocomplete
                  autoWidth
                  id='my-autocomplete'
                  size='small'
                  options={sourceOptions}
                  loading={isLoadingSource}
                  getOptionLabel={(option) =>
                    option?.name && option?.isoCountryCode
                      ? `${option.name} - ${option.isoCountryCode}`
                      : option?.name || ''}
                  onInputChange={onInputChange}
                  onChange={onSourceNameChange}
                  sx={{
                    width: '80%'
                  }}
                  value={sourceValue}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Source Security Name'
                      error={errorName}
                      variant='outlined'
                      InputProps={{ ...params.InputProps, placeholder: 'Source Security Name' }}
                      required
                    />
                  )}
                />
              </TableCell>
              <TableCell className='table-cell-styles'>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Autocomplete
                    autoWidth
                    id='my-autocomplete'
                    size='small'
                    options={sourceOptions}
                    loading={isLoadingSource}
                    getOptionLabel={(option) =>
                      option?.name || option?.isoCountryCodes
                        ? `${option?.name || ''} - ${option?.isoCountryCode || ''}`
                        : ''}
                    onInputChange={onInputChange}
                    onChange={onTargetNameChange}
                    sx={{
                      width: '80%'
                    }}
                    value={targetValue}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Target Security Name'
                        error={errorCode}
                        variant='outlined'
                        InputProps={{ ...params.InputProps, placeholder: 'Target Security Name' }}
                        required
                      />
                    )}
                  />
                  <AddCircleIcon sx={{ mx: 2, color: 'primary.main', marginTop: '8px' }} onClick={(e) => handleSubmitSubstitution(e)} />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <TableContainer component={Paper} className='table-body'>
        <Table>
          <TableHead>
            <TableRow className='table-header'>
              <TableCell sx={{ fontWeight: 600 }}>Source Security Name</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Target Security Name</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {addClicked
              ? tableRows?.map((value, index) => {
                return (
                  <TableRow key={index}>
                    {value.sourceValue.name && value.targetValue.name
                      ? <>
                        <TableCell className='table-cell-styles' sx={{ fontWeight: 600 }}>{value?.sourceValue.name}</TableCell>
                        <TableCell className='table-cell-styles' sx={{ fontWeight: 600 }}>{value?.targetValue.name}</TableCell>
                        <TableCell className='table-cell-styles' sx={{ fontWeight: 600 }}>
                          <IconButton
                            aria-label='delete' size='medium' onClick={() => {
                              const temp = [...tableRows]
                              const security = [...substitutionList]
                              temp.splice(index, 1)
                              security.splice(index, 1)
                              setTableRows(temp)
                              setSubstitutionList(security)
                            }}
                          >
                            <DeleteIcon sx={{ width: '20px', height: '20px', color: '#34475A' }} />
                          </IconButton>
                        </TableCell>
                      </>
                      : ''}
                  </TableRow>
                )
              })
              : ''}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default Substitutions

import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { API } from 'aws-amplify';
import Skeleton from "@mui/material/Skeleton";
import Stack from '@mui/material/Stack';
import { useAuth } from "../../../contexts/AuthContext";
import { RequestStratagy } from "../../../contstants/constants";
import { useErrorToast } from "../../../hooks/useErrorToast";
import { useSuccessToast } from "../../../hooks/useSuccessToast";
import "./RequestModal.scss";

const RequestForm = ({ getAccountRequests, handleClose, name }) => {
  const inputType = useRef();
  const inputTitle = useRef();
  const inputAmount = useRef();
  const inputSeletedStrategy = useRef();
  const inputComment = useRef();
  const { user } = useAuth();
  const { showError } = useErrorToast();
  const { showSuccess } = useSuccessToast();
  const [select, setSelect] = useState();
  const [loading, setLoading] = useState(false);

  const params = useParams();
  //handler function
  const handleCapacity = (e) => {
    setSelect(e.target.value);
  }

  const onSubmitHandler = (event) => {
    event.preventDefault();
    setLoading(true)
    if (select === "CASH_RAISE") {
      const enteredType = inputType.current.value;
      const enteredTitle = inputTitle.current.value;
      const enteredcomment = inputComment.current.value;
      const enteredAmount = inputAmount.current.value;
      let data = {
        requestType: enteredType,
        title: enteredTitle,
        comment: enteredcomment,
        amount: enteredAmount,
      }
      API
        .post("baseRequestURL", `request/v1/${user.userGroup}/requests/${params.accountId}`, { body: { ...data } })
        .then(response => {
          if (response) {
            setLoading(true)
            handleClose();
            getAccountRequests();
            showSuccess(response.message);
            setLoading(false)

          }
        }).catch(error => {
          showError(error, false, {}, 'Failed to create request.');
          setLoading(false)
        })
      inputType.current.value = "";
      inputTitle.current.value = "";
      inputAmount.current.value = "";
      inputComment.current.value = "";
    } else if (select === "YTD_EXTERNAL_GAIN_LOSS") {
      const enteredType = inputType.current.value;
      const enteredTitle = inputTitle.current.value;
      const enteredcomment = inputComment.current.value;
      const enteredAmount = inputAmount.current.value;
      const ytdType = inputSeletedStrategy.current.value;
      let data = {
        requestType: enteredType,
        title: enteredTitle,
        comment: enteredcomment,
        amount: enteredAmount,
        ytdType: ytdType
      }
      API
        .post("baseRequestURL", `request/v1/${user.userGroup}/requests/${params.accountId}`, { body: { ...data } })
        .then(response => {
          if (response) {
            setLoading(true)
            showSuccess(response.message);
            handleClose();
            getAccountRequests();
            setLoading(false)

          }
        }).catch(error => {
          showError(error, false, {}, 'Failed to create request.');
          setLoading(false)
        })
      inputType.current.value = "";
      inputTitle.current.value = "";
      inputAmount.current.value = "";
      inputComment.current.value = "";
    } else {
      const enteredType = inputType.current.value;
      const enteredTitle = inputTitle.current.value;
      const enteredcomment = inputComment.current.value;
      let data = {
        requestType: enteredType,
        title: enteredTitle,
        comment: enteredcomment,
      }
      API
        .post("baseRequestURL", `request/v1/${user.userGroup}/requests/${params.accountId}`, { body: { ...data } })
        .then(response => {
          if (response) {
            setLoading(true)
            showSuccess(response.message);
            handleClose();
            getAccountRequests();
            setLoading(false)

          }
        }).catch(error => {
          showError(error, false, {}, 'Failed to create request.');
          setLoading(false)
        })
      inputType.current.value = "";
      inputTitle.current.value = "";
      inputAmount.current.value = "";
      inputComment.current.value = "";
    }
  }

  return (
    <>
      {!loading ? (
        <form className="form-req" onSubmit={onSubmitHandler} disabled={loading}>
          <div className="text-center modal_area">

            <div className='d-flex flex-column flex-md-row align-items-start align-items-md-center my-5'>
              <div className='page-title me-5'>New Request</div>
              <div className='divider me-4'></div>
              <div className='text-xl'>{name}
              </div>
            </div>

          </div>
          <div className="form-group">
            <label className="text-title">Request Type *</label>
            <select className="form-control"
              select={select} required ref={inputType} onChange={handleCapacity}>
              <option value=''>
                Select
              </option>
              {
                Object.keys(RequestStratagy).map((option, index) => {
                  return (<option key={index} required value={option}>{RequestStratagy[option]}</option>)
                })
              }
            </select>
          </div>
          {select === "YTD_EXTERNAL_GAIN_LOSS" &&
            <div className="form-group">
              <label className="text-title">Gain / Loss *</label>

              <select className="form-control" required placeholder="Select"
                ref={inputSeletedStrategy}>
                <option disabled value="Income Builder">
                  Select
                </option>
                <option value="LOSS">LOSS</option>
                <option value="GAIN">GAIN</option>
              </select>
            </div>
          }
          <div className="form-group">
            <label className="text-title">Request Title *</label>
            <input className="form-control" placeholder="Title Here" maxLength={100} required
              ref={inputTitle} />

          </div>
          {select === "CASH_RAISE" || select === "YTD_EXTERNAL_GAIN_LOSS" ?
            <div className="form-group">
              <label className="text-title">Enter Amount *</label>
              <i class="fa fa-dollar icon"></i>

              <input className="form-control" required placeholder=""
                type="number" ref={inputAmount} />
            </div> : ""
          }
          <div className="form-group">
            <label className="text-title">Comment *</label>
            <textarea className="form-control" required maxLength={500} ref={inputComment}>
            </textarea>
          </div>
          <div className="form-group">
            <p>
              {select === "GENERAL" &&
                "Estimated request completion time: 72 hours"
              }
              {select === "CLOSE_ACCOUNT" &&
                "Estimated request completion time: 24 hours"
              }
              {select === "GENERATE_PROPOSAL" &&
                "Estimated request completion time: 72 hours"
              }
              {select === "CASH_RAISE" &&
                "Estimated request completion time: 72 hours"
              }
              {select === "YTD_EXTERNAL_GAIN_LOSS" &&
                "Estimated request completion time: Immediate"
              }
            </p>
          </div>
          <div className="confirm-btn text-center">
            <button
              type="submit"
              className="submit_btn">
              Submit
            </button>
          </div>
        </form>
      ) :
        <Stack spacing={3}>
          <Skeleton variant='rounded' animation="wave" height={90} />
          <Skeleton variant='rounded' animation="wave" height={40} />
          <Skeleton variant='rounded' animation="wave" height={40} />
          <Skeleton variant='rounded' animation="wave" height={40} />
          <Skeleton variant='rounded' animation="wave" height={90} />
        </Stack>
      }
    </>
  );
};
export default RequestForm;

import React, { useState } from 'react'
import { API } from 'aws-amplify'
import { v4 } from 'uuid'
import { useDispatch } from 'react-redux'
import { MdCloudUpload } from 'react-icons/md'
import { styled } from '@mui/material/styles'
import { coverNameEditor, upDatePageHeadingFooter } from '../../../../store/pdf-customizer-reducer/Action/chartDropAction'
import { pushDocumentInS3 } from '../../../../utils/pdf-customizer/_data'
import { useAuth } from '../../../../contexts/AuthContext'
import { useErrorToast } from '../../../../hooks/useErrorToast'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})

const ImageHolderEdit = ({ isUploadImage = false }) => {
  const dispatch = useDispatch()
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const [isLoading, setIsLoading] = useState(false)

  const uploadImage = (e) => {
    const fileSizeInMB = (e.target.files[0]?.size / (1024 * 1024))
    const FILE_UPLOAD_SIZE_LIMIT_MB = 20
    setIsLoading(true)
    if (Math.ceil(fileSizeInMB) > FILE_UPLOAD_SIZE_LIMIT_MB) {
      showError('File size should not exceed 20MB.')
      setIsLoading(false)
      e.target.value = null
      return
    }
    const fileKey = `${v4()}.${e.target.files[0]?.name?.split('.').pop()}`
    API.get('baseUriReportBuilder', `report-builder/v1/${user?.userGroup}/image/custom/import/${fileKey}`)
      .then((response) => {
        if (response?.success) {
          const preSignedUrl = response?.data
          pushDocumentInS3(response, e.target.files[0])
            .then((res) => {
              if (res.success) {
                if (isUploadImage) {
                  dispatch(
                    upDatePageHeadingFooter({
                      value: null,
                      label: 'svgCode'
                    })
                  )
                  setIsLoading(false)
                  return dispatch(
                    upDatePageHeadingFooter({
                      value: `${preSignedUrl?.url}${preSignedUrl?.fields?.key}`,
                      label: 'companyLogoUrl'
                    })
                  )
                } else {
                  dispatch(
                    coverNameEditor({
                      value: null,
                      label: 'svgCode'
                    })
                  )
                  dispatch(
                    coverNameEditor({
                      value: `${preSignedUrl?.url}${preSignedUrl?.fields?.key}`,
                      label: 'image'
                    })
                  )
                  setIsLoading(false)
                }
              }
            })
            .catch((error) => {
              showError(error, false, {}, 'Failed to upload image.')
              setIsLoading(false)
            })
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to upload image.')
        setIsLoading(false)
      })
      .finally(() => {
        e.target.value = null
      })
  }
  return (
    <div>
      <label
        type='button'
        className='common-btn margin-x-2 flexOrAlign showInCenter margin-top-2'
      >
        {isLoading
          ? 'Uploading...'
          : <><VisuallyHiddenInput
              type='file'
              accept='image/*'
              onChange={(e) => uploadImage(e)}
              />
            <MdCloudUpload className='margin-x-2' size={15} />
            Upload Image
          </>}
      </label>
    </div>
  )
}

export default ImageHolderEdit

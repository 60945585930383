import { useEffect, useState, useMemo } from 'react'
import { Autocomplete, TextField, debounce } from '@mui/material'
import { useErrorToast } from '../../hooks/useErrorToast'
import { GetAccountData, GetAllStrategies, GetStrategyData } from '../../pages/Data Maintenance/GetKeyData'
import { GetAllStrategyBenchmark } from '../../pages/Data Maintenance/Strategy/GetStrategyData'
import { getInstrumentsList } from '../../pages/Data Maintenance/Instrument/GetInstrumentData'
import { sponsorAPI } from '../../pages/Data Maintenance/Sponsor/GetSponsorData'
import { optimizationDetailsAPI } from '../../pages/Data Maintenance/Optimization/GetOptimizationData'
import { checkInstrumentSearchQuery } from '../../utils/searchQueryUtils'

export const FormikAutocomplete = (props) => {
  const { name, label, onHandleChange, fieldValues, disabled, optionValueKey, optionKey, accessKey, moduleName, filterFunction } = props
  const [inputValue, setInputValue] = useState(fieldValues)
  const [value, setValue] = useState(null)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const { showError } = useErrorToast()
  const [disabledSearch, setDisableSearch] = useState(disabled)

  // stores default value as selected value
  useEffect(() => {
    setInputValue(fieldValues)
    setValue(prevState => ({ ...prevState, [optionKey]: fieldValues }))
  }, [fieldValues])

  useEffect(() => {
    getOptions()
  }, [optionKey])

  // Initial options fetching based on field option key
  const getOptions = async () => {
    switch (optionKey) {
      case 'strategyId': {
        // fetch derived and simple both strategy in strategy module
        const optionsList = await GetAllStrategies()
        // pass different strategyType if needed for the second call
        if (optionsList.error) {
          showError(optionsList.error, false, {}, 'Failed to load strategies.')
        } else if (optionsList?.data?.data) {
          if (filterFunction) {
            setOptions([...(filterFunction(optionsList?.data?.data))])
          } else {
            setOptions([...optionsList?.data?.data])
          }
          if (fieldValues) {
            const selectedValue = optionsList?.data?.data?.find(option => option[optionKey] === fieldValues)
            setValue(selectedValue || null)
            if (!selectedValue) {
              setDisableSearch(false)
            }
          }
        }
        setLoading(false)
        break
      }
      case 'benchmarkId': {
        const optionsList = await GetAllStrategyBenchmark()
        if (optionsList.error) {
          showError(optionsList.error, false, {}, 'Failed to load strategy benchmark.')
        } else if (optionsList?.data?.data) {
          setOptions([...optionsList?.data?.data])
          if (fieldValues) {
            const selectedValue = optionsList?.data?.data?.find(option => option[optionKey] === fieldValues)
            setValue(selectedValue || null)
            if (!selectedValue) {
              setDisableSearch(false)
            }
          }
        }
        setLoading(false)
        break
      }
      case 'instrId':
      case 'sourceInstrId':
      case 'targetInstrId':
        break
      case 'sponsorId':{
        const sponsorIds = await sponsorAPI()
        if (sponsorIds.error) {
          showError(sponsorIds.error, false, {}, 'Failed to load sponsor Ids.')
        } else if (sponsorIds?.data?.data) {
          setOptions([...sponsorIds?.data?.data])
          if (fieldValues) {
            const selectedValue = sponsorIds?.data?.data?.find(option => option[optionKey] === fieldValues)
            setValue(selectedValue || null)
          }
        }
        setLoading(false)
        break
      }
      case 'accountId' : {
        const accountIds = await GetAccountData()
        if (accountIds.error) {
          showError(accountIds.error, false, {}, 'Failed to load account data.')
        } else if (accountIds?.data.data) {
          setOptions([...accountIds?.data?.data])
          if (fieldValues) {
            const selectedValue = accountIds?.data?.data?.find(option => option[optionKey] === fieldValues)
            setValue(selectedValue || null)
            if (!selectedValue) {
              setDisableSearch(false)
            }
          }
        }
        setLoading(false)
        break
      }
      case 'scenarioId': {
        const optionsList = await optimizationDetailsAPI('all-scenario-list')
        if (optionsList.error) {
          showError(optionsList.error, false, {}, 'Failed to load all scenario list.')
        } else if (optionsList?.data?.data) {
          setOptions([...optionsList?.data?.data])
          if (fieldValues) {
            const selectedValue = optionsList?.data?.data?.find(option => option[optionKey] === fieldValues)
            setValue(selectedValue || null)
            if (!selectedValue) {
              setDisableSearch(false)
            }
          }
        }
        setLoading(false)
        break
      }
      default:
        setOptions([])
        break
    }
  }

  // Uses debounce time to avoid subsequent API calls and calls API after 400ms
  const handleInputChange = useMemo(
    () =>
      debounce(async (request) => {
        // prevent API call for default selection of option passed from props to show all option list
        if (fieldValues?.length && request.includes(fieldValues)) {
          setLoading(false)
          return
        }
        // checks field name and call search API
        switch (optionKey) {
          case 'strategyId': {
            // fetch derived and simple both strategy in strategy module
            const optionsList = await GetStrategyData('', request)
            // pass different strategyType if needed for the second call
            if (optionsList.error) {
              showError(optionsList.error, false, {}, 'Failed to load strategy data.')
            } else if (optionsList?.data?.data) {
              if (filterFunction) {
                setOptions([...(filterFunction(optionsList?.data?.data))])
              } else {
                setOptions([...optionsList?.data?.data])
              }
            }
            setLoading(false)
            break
          }
          case 'benchmarkId':
            break
          case 'instrId':
          case 'sourceInstrId':
          case 'targetInstrId': {
            const optionsList = await getInstrumentsList(request)
            if (optionsList.error) {
              showError(optionsList.error, false, {}, 'Failed to load instruments.')
            } else if (optionsList?.data) {
              setOptions([...optionsList?.data])
            }
            setLoading(false)
            break
          }
          case 'accountId':
            break
          case 'scenarioId':
            break
          default:
            setOptions([])
            break
        }
      }, 400),
    []
  )

  // checks length of input to call search API
  useEffect(() => {
    const isInstrumentSearch = optionKey === 'instrId' || optionKey === 'sourceInstrId' || optionKey === 'targetInstrId'
    // For instrument search, use checkInstrumentSearchQuery
    if (isInstrumentSearch && inputValue?.trim()?.length) {
      if (checkInstrumentSearchQuery(inputValue)) {
        setLoading(true)
        handleInputChange(inputValue)
      } else {
        setLoading(false)
      }
    } else if ((typeof inputValue === 'string' && inputValue?.trim()?.length >= 3) ||
        (typeof inputValue === 'number' && inputValue >= 0)) {
      setLoading(true)
      handleInputChange(inputValue)
    } else {
      if (!(inputValue && inputValue?.trim()?.length)) {
        // If default value is passed from input form, it should not set parent state to empty string
        if (!(value && Object.keys(value)?.length === 1 && value[optionKey] === fieldValues)) {
          setValue(null)
          onHandleChange({
            target: {
              id: optionKey,
              value: ''
            }
          })
        }
      }
      setLoading(false)
    }
  }, [inputValue, optionKey])

  return (
    <Autocomplete
      id={name}
      isOptionEqualToValue={(option, value) => option[optionKey] === value[optionKey]}
      value={value}
      name={name}
      disableClearable
      options={options}
      onChange={(event, newValue) => {
        // Pass value and id field same as HTML onchange event
        onHandleChange({
          ...event,
          target: {
            ...event.target,
            id: name,
            value: newValue?.[optionKey] || newValue?.[accessKey]
          }
        })
        setValue(newValue)
      }}
      getOptionLabel={(option) =>
      `${option[optionKey] || option[accessKey]} ${option[optionValueKey] ? `(${option[optionValueKey]})` : ''}`}
      loading={loading}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue)
      }}
      fullWidth
      inputValue={inputValue}
      sx={{
        pointerEvents: disabledSearch ? 'none' : 'all' // avoid all pointer events if input is disabled. It will have same UI as not disabled inputs
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant='standard'
          fullWidth
          margin='dense'
        />
      )}
    />

  )
}

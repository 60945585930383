import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { CardContent, Card, Typography } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import PortSpecTable from './PortSpecTable'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'

const AllSponsorsList = () => {
  const { user } = useAuth()
  const { sendSentryError } = useErrorToast()
  const [rows, setRows] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [portSpecListView, setPortSpecListView] = useState(false)
  const [sponsorId, setSponsorId] = useState('')
  const [activeTab, setActiveTab] = useState('')

  useEffect(() => {
    setLoading(true)
    if (user) {
      API.get('baseSponserURL', `data-maintenance/v1/${user?.userGroup}/sponsorIds`)
        .then(response => {
          if (response.data.data) {
            setRows(response.data.data)
          }
        })
        .catch(error => {
          sendSentryError(error)
        })
        .finally(() => setLoading(false))
    }
  }, [user])

  const columns = [
    {
      field: 'sponsorName',
      headerName: 'Sponsor Name',
      flex: 4
    },
    {
      field: 'sponsorCd',
      headerName: 'Sponsor Code',
      flex: 2
    }
  ]

  const handleRowClick = (params) => {
    setPortSpecListView(true)
    setActiveTab('')
    setSponsorId(params?.row?.sponsorId)
  }

  return (
    <>
      {portSpecListView && activeTab !== 'str-tab'
        ? (
          <PortSpecTable sponsorId={sponsorId} setActiveTab={setActiveTab} />
        )
        : (
          <>
            <Typography className='page-title' mb={2}>Portfolio Specification</Typography>
            <Card>
              <CardContent>
                <DataGridPro
                  rows={rows}
                  columns={columns}
                  loading={loading}
                  getRowId={(row) => row.sponsorId}
                  autoHeight
                  pageSizeOptions={[10]}
                  onPaginationModelChange={(event) => setCurrentPage(event.page)}
                  paginationModel={{ pageSize: 10, page: currentPage }}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 10, page: currentPage } }
                  }}
                  sx={{
                    '& .MuiDataGrid-row:hover': {
                      cursor: 'pointer'
                    }
                  }}
                  pagination
                  onRowClick={handleRowClick}
                />
              </CardContent>
            </Card>
          </>
        )}
    </>
  )
}

export default AllSponsorsList

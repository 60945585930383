import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Box, Chip, Grid, Skeleton, Typography } from '@mui/material'

const AggregateTitle = ({ title, name, number, accountCodeShow, loading, slug, chipText }) => {
  const location = useLocation()

  return (
    <Grid container justifyContent='space-between' alignItems='center' mb={2}>
      <Grid item display='flex' flexDirection={{ xs: 'column', md: 'row' }} alignItems={{ xs: 'flex-start', md: 'center' }}>
        {
          loading
            ? <Skeleton variant='text' sx={{ width: '100px', fontSize: '40px' }} />
            : <Typography component='div' className='page-title' sx={{ paddingTop: '0 !important' }}>{title === 'IPS' ? 'Aggregate' : title}</Typography>
        }
        <Box className='client' mt={{ xs: 3, md: 0 }}>
          <Box sx={{
            borderRadius: '4px',
            width: '3.05px',
            height: '53.8px',
            mx: 2,
            background: '#EFAE3A'
          }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {
              loading
                ? <>
                  <Skeleton variant='text' sx={{ width: '150px', fontSize: '20px' }} />
                </>
                : <>
                  <Typography variant='h6' display='flex' alignItems='center'>
                    {name}
                    {
                      chipText
                        ? <Chip label={chipText} size='small' sx={{ background: '#50CAF1', color: 'white', fontSize: '10px', fontWeight: '500', ml: 1, height: '16px' }} />
                        : ''
                    }
                  </Typography>
                </>
            }
            {
              loading
                ? <Skeleton variant='text' sx={{ width: '50px', fontSize: '14px' }} />
                : accountCodeShow
                  ? <>
                    <Box>
                      <Typography variant='small' lineHeight='1.25rem' fontSize='0.875rem' mr={1}> {number || ''}</Typography>
                      <Link to={location.pathname.replace(slug, '')} style={{ color: '#3A76D4' }}>
                        Switch Aggregate
                      </Link>
                    </Box>
                  </>
                  : ''
            }
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default AggregateTitle
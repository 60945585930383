import { createContext, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { API } from 'aws-amplify'
import { useErrorToast } from '../hooks/useErrorToast'
import arisLogoA from '../assets/images/ArisLogoA.svg'
import NewArisLogoWhite from '../assets/images/arisNewLogoWhite.svg'
import { useAuth } from './AuthContext'
import { setThemeSettings } from '../store/custom-sponsor-design-reducer/custom-sponsor-design-reducer'

const SponsorLogoContext = createContext(null)

export const LogoProvider = ({ children }) => {
  const themeData = useSelector((state) => state.theme)
  const [sponsorMainLogo, setSponsorMainLogo] = useState(themeData?.mainLightLogoUrl || null)
  const [sponsorMobileLogo, setSponsorMobileLogo] = useState(themeData?.mobLightLogoUrl || null)
  const [backgroundColor, setBackGroundColor] = useState(themeData?.primaryColor || null)
  const [primaryColorText, setPrimaryColorText] = useState(themeData?.brandTextColor || null)
  const [secondaryColorText, setSecondaryColorText] = useState(themeData?.brandTextColor || null)

  const { user } = useAuth()
  const dispatch = useDispatch()
  const { showError } = useErrorToast()

  const handleSetLogoInnerHTML = () => {
    const favicon = document.getElementById('favicon')
    if (favicon) {
      favicon.href = './ArisFavicon.svg'
    }
    const title = document.getElementById('title')
    if (title) {
      title.innerText = 'Aris Investing'
    }
  }

  const storeSponsorLogo = (value) => {
    // set logo details api response in redux for dynamic left sidebar design
    setSponsorMainLogo(!value?.mainLightLogoUrl ? NewArisLogoWhite : value?.mainLightLogoUrl)
    setSponsorMobileLogo(!value?.mobLightLogoUrl ? arisLogoA : value?.mobLightLogoUrl)
    setBackGroundColor(value?.primaryColor)
    setPrimaryColorText(value?.brandTextColor)
    setSecondaryColorText(value?.brandTextColor)
    const spnDetails = {
      sponsorCd: value?.sponsorCd,
      sponsorName: value?.sponsorName,
      primaryColor: value?.primaryColor,
      secondaryColor: value?.secondaryColor,
      brandTextColor: value?.brandTextColor,
      mainLightLogoUrl: !value?.mainLightLogoUrl ? NewArisLogoWhite : value?.mainLightLogoUrl,
      mainDarkLogoUrl: value?.mainDarkLogoUrl,
      mobLightLogoUrl: !value?.mobLightLogoUrl ? arisLogoA : value?.mobLightLogoUrl,
      mobDarkLogoUrl: value?.mobDarkLogoUrl,
      opacity: value?.opacity || 0.5,
      favIconUrl: value?.favIconUrl
    }
    dispatch(setThemeSettings(spnDetails))
    const mainLogoImage = new window.Image()
    mainLogoImage.src = !value?.mainLightLogoUrl ? NewArisLogoWhite : value?.mainLightLogoUrl
    mainLogoImage.onerror = () => {
      console.error('Error loading logo image')
    }
    const mobileLogoImage = new window.Image()
    mobileLogoImage.src = !value?.mobLightLogoUrl ? arisLogoA : value?.mobLightLogoUrl
    mobileLogoImage.onerror = () => {
      console.error('Error loading logo image')
    }
  }

  useEffect(() => {
    if (user) {
      const mainLogo = themeData?.mainLightLogoUrl
      const mobileLogo = themeData?.mobLightLogoUrl
      const favIcon = themeData?.favIconUrl
      const backgroundColor = themeData?.primaryColor
      const primaryText = themeData?.brandTextColor
      const secondaryText = themeData?.brandTextColor
      const sponsorName = themeData?.sponsorName
      const userGroup = window.localStorage.getItem('userGroup')

      mainLogo && setSponsorMainLogo(mainLogo)
      mobileLogo && setSponsorMobileLogo(mobileLogo)
      backgroundColor && setBackGroundColor(backgroundColor)
      primaryText && setPrimaryColorText(primaryText)
      secondaryText && setPrimaryColorText(secondaryText)

      if (favIcon) {
        const favicon = document.getElementById('favicon')
        if (favicon) {
          favicon.href = favIcon
        }
      }

      if (sponsorName) {
        const title = document.getElementById('title')
        if (title) {
          title.innerText = sponsorName
        }
      }

      const fetchSponsorData = (retries = 2) => {
        let hasError = false
        API.get('baseSponserURL', `data-maintenance/v1/logo-details/${user?.sub}`)
          .then(response => {
            if (response?.data) {
              if (Array.isArray(response.data) && response?.data?.length) {
                storeSponsorLogo(response.data[0])
                const favicon = document.getElementById('favicon')
                if (favicon) {
                  favicon.href = response.data[0].favIconUrl ? response.data[0].favIconUrl : './ArisFavicon.svg'
                }
                const title = document.getElementById('title')
                if (title && response.data[0].sponsorName) {
                  title.innerText = response.data[0].sponsorName ? response.data[0].sponsorName : 'Aris Investing'
                }
              } else {
                storeSponsorLogo(null)
                handleSetLogoInnerHTML()
              }
            }
          })
          .catch(error => {
            hasError = true
            showError(error, false, {}, 'Failed to load sponsor data.')
          }).finally(() => {
            // retry api call 2 more times if first api call fails
            if (hasError && retries > 0) {
              setTimeout(() => {
                fetchSponsorData(retries - 1)
              }, 1000)
            } else if (hasError && retries === 0) {
              storeSponsorLogo(null)
              handleSetLogoInnerHTML()
            }
          })
      }

      if (!mainLogo && !mobileLogo && !favIcon && user && user?.sub) {
        fetchSponsorData()
      }
    }
  }, [user])

  return (
    <SponsorLogoContext.Provider value={{ sponsorMainLogo, sponsorMobileLogo, handleSetLogoInnerHTML, storeSponsorLogo, backgroundColor, primaryColorText, secondaryColorText }}>
      {children}
    </SponsorLogoContext.Provider>
  )
}

export const useLogo = () => useContext(SponsorLogoContext)

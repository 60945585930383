import React, { useEffect, useState } from 'react'
import { Box, IconButton, MenuItem, Select, Stack } from '@mui/material'
import { Close, Edit, Save } from '@mui/icons-material'
import { useAuth } from '../../../../contexts/AuthContext'
import { moduleConfig } from '../../../../contexts/data'
import { ACCESS_LEVEL } from '../../../../contstants/constants'
import { getColorProperties } from '../getColorProperties'

const FixConnectivityStatusChip = ({ fixTradeStatus, fixFlyerStatusList, updateFixConnectivityStatus }) => {
  const [fixConnectivityStatus, setFixConnectivityStatus] = useState(fixTradeStatus)
  const [isEditMode, setIsEditMode] = useState(false)
  const { checkAccess } = useAuth()

  const hasEditFixConnectivityAccess = checkAccess(
    moduleConfig.ARIS_INTERNAL,
    ACCESS_LEVEL.COMPONENT_ACCESS,
    { subModuleName: moduleConfig.ARIS_INTERNAL, component_name: moduleConfig.EDIT_FIX_CONNECTIVITY_STATUS }
  )

  useEffect(() => {
    setFixConnectivityStatus(fixTradeStatus)
    setIsEditMode(false)
  }, [fixTradeStatus])

  const discardFixConnectivityStatusChange = () => {
    setFixConnectivityStatus(fixTradeStatus)
    setIsEditMode(false)
  }

  return (
    <Stack direction='row' alignItems='center'>
      {
        !isEditMode
          ? <>
            <Box sx={{ borderRadius: '20px', fontSize: '10px', px: '10px', py: '2px', ...getColorProperties(fixConnectivityStatus), fontWeight: 600 }}>
              {fixConnectivityStatus}
            </Box>
            {
              hasEditFixConnectivityAccess && fixConnectivityStatus
                ? <IconButton size='small' onClick={() => setIsEditMode(true)}>
                  <Edit sx={{
                    height: '16px',
                    width: '16px'
                  }}
                  />
                  </IconButton>
                : ''
            }
            </>
          : <>
            <Select
              margin='dense'
              size='small'
              sx={{
                '.MuiSelect-select': {
                  padding: '3px 10px',
                  fontSize: '14px'
                }
              }}
              fullWidth
              id='fix-flyer-status-chip'
              name='fix-flyer-status-chip'
              value={fixConnectivityStatus === 'NA' ? '' : (fixConnectivityStatus || '')}
              onChange={(e) => setFixConnectivityStatus(e.target.value)}
            >
              <MenuItem value=''>Select</MenuItem>
              {
                (fixFlyerStatusList || [])?.map((option, index) => (<MenuItem value={option} key={index}>{option}</MenuItem>))
              }
            </Select>
            <IconButton size='small' onClick={() => updateFixConnectivityStatus(fixConnectivityStatus)}>
              <Save sx={{
                height: '16px',
                width: '16px'
              }}
              />
            </IconButton>
            <IconButton size='small' onClick={() => discardFixConnectivityStatusChange()}>
              <Close sx={{
                height: '16px',
                width: '16px'
              }}
              />
            </IconButton>
          </>
      }
    </Stack>
  )
}

export default FixConnectivityStatusChip

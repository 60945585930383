import { useEffect, useState } from 'react'
import dayjs, { utc } from 'dayjs'
import { API } from 'aws-amplify'
import { Box, Button, Chip, Typography } from '@mui/material'
import { DataGridPro, GridActionsCellItem, GridRowEditStopReasons, GridRowModes } from '@mui/x-data-grid-pro'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import CancelIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import CustomEditDate from '../../../components/CustomEditDate'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { useSuccessToast } from '../../../hooks/useSuccessToast'
import { useAuth } from '../../../contexts/AuthContext'
import Loader from '../../Loader'

dayjs.extend(utc)

const MemberTable = (props) => {
  const {
    selectedMembers,
    setShowMemberPopup,
    deleteMember,
    isInEditMode,
    setSelectedMembers,
    apiRef,
    storeDataGridState,
    restoreMemberTableState
  } = props
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const { showSuccess } = useSuccessToast()
  const [rowModesModel, setRowModesModel] = useState({})
  const [showFullScreenLoader, setShowFullScreenLoader] = useState(false)

  useEffect(() => {
    restoreMemberTableState()
  }, [rowModesModel, showFullScreenLoader])

  const memberCols = [
    {
      field: 'aggGrpMemberId',
      headerName: 'Member Id',
      flex: 1
    },
    {
      field: 'memberRefName',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.memberRefName}
          {
            isInEditMode && params?.row?.isActive === 0 ? (
              <Chip label={'INACTIVE'} size='small' sx={{ background: '#ff616199', color: '#79454f', fontSize: '10px', fontWeight: '500', ml: 1, height: '16px' }} />
            ) : ''
          }
        </>
      )
    },
    {
      field: 'memberType',
      headerName: 'Type',
      flex: 1
    },
    ...(isInEditMode
      ? ([
          {
            field: 'startDate',
            headerName: 'Start Date',
            flex: 1,
            type: 'date',
            valueGetter: (params) => {
              const startDate = params?.row?.startDate ? params?.row?.startDate || '' : null
              if (!startDate) return null
              const date = new Date(startDate)
              return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
            },
            renderCell: (params) => params?.row?.startDate ? params?.row?.startDate?.split(' ')[0] || '' : ''
          },
          {
            field: 'endDate',
            headerName: 'End Date',
            flex: 1,
            type: 'date',
            editable: true,
            valueFormatter: (params) => params.value ? dayjs.utc(params.value).format('YYYY-MM-DD') : '',
            renderEditCell: (params) => <CustomEditDate {...params} minDate={dayjs.utc().format('YYYY-MM-DD')} />
          },
          {
            field: 'action',
            headerName: ' ',
            type: 'actions',
            sortable: false,
            align: 'center',
            disableColumnMenu: true,
            width: 100,
            getActions: (params) => {
              const isInEditMode = rowModesModel[params?.id]?.mode === GridRowModes.Edit
              if (isInEditMode) {
                return [
                  <GridActionsCellItem
                    icon={<SaveIcon />}
                    label='Save'
                    key='Save'
                    onClick={() => handleSaveClick(params?.row?.aggGrpMemberId)}
                    color='inherit'
                  />,
                  <GridActionsCellItem
                    icon={<CancelIcon />}
                    label='Cancel'
                    key='Cancel'
                    onClick={() => handleCancelClick(params?.row?.aggGrpMemberId)}
                    color='inherit'
                  />
                ]
              } else {
                return [
                  <GridActionsCellItem
                    icon={<EditIcon />}
                    label='Edit'
                    key='Edit'
                    onClick={() => handleEditClick(params?.row?.aggGrpMemberId)}
                    color='inherit'
                  />,
                  <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label='Delete'
                    key='Delete'
                    onClick={() => handleDeleteClick(params)}
                    color='inherit'
                  />
                ]
              }
            }
          }
        ])
      : ([{
          field: ' ',
          headerName: ' ',
          sortable: false,
          align: 'center',
          disableColumnMenu: true,
          width: 50,
          renderCell: (params) => (
            <Box onClick={() => deleteMember(params)}>
              <DeleteIcon style={{ fontSize: '20px', color: '#34475A', cursor: 'pointer' }} />
            </Box>
          )
        }
        ]))
  ]

  const processRowUpdate = async (newRow, oldRow) => {
    const endDate = dayjs.utc(newRow?.endDate).format('YYYY-MM-DD')
    if (newRow?.endDate === null || newRow?.endDate === '' || newRow?.endDate === undefined || endDate === oldRow?.endDate) {
      return oldRow
    }
    setShowFullScreenLoader(true)
    return await API.patch('baseUriAggregateMaster', `aggregate-master/v1/${user?.userGroup}/aggregate-member`, {
      body: {
        endDate,
        aggGrpMemberId: newRow?.aggGrpMemberId
      }
    })
      .then((response) => {
        if (response?.success && response?.data?.length) {
          showSuccess(response?.message)
          setSelectedMembers(
            selectedMembers.map(member => member?.aggGrpMemberId === newRow?.aggGrpMemberId ? ({
              ...member,
              ...response?.data[0]
            }) : member))
          return newRow
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to update aggregate member.')
        return oldRow
      })
      .finally(() => {
        setShowFullScreenLoader(false)
      })
  }

  const handleSaveClick = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleCancelClick = (id) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })
  }

  const handleEditClick = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleDeleteClick = (params) => {
    deleteMember(params)
  }

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const handleDoubleClick = (params, e) => {
    e.defaultMuiPrevented = true
  }

  return (
    <>
      {
        showFullScreenLoader ? <Loader /> : ''
      }
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '8px' }}>
        <Typography sx={{ display: 'flex', flexDirection: 'column', color: '#74788D', fontSize: '16px', fontWeight: 600, justifyContent: 'center' }}>Members</Typography>
        <Button
          size='small'
          variant='text'
          onClick={() => setShowMemberPopup(true)}
          sx={{ fontSize: '14px' }}
        >
          + Add Member
        </Button>
      </Box>
      <DataGridPro
        density='compact'
        apiRef={apiRef}
        rows={selectedMembers}
        columns={memberCols}
        getRowId={(row) => isInEditMode ? row?.aggGrpMemberId : row?.memberRefId}
        autoHeight
        pagination
        pageSizeOptions={[10, 15, 25, 50, 100]}
        disableRowSelectionOnClick
        {
        ...(isInEditMode ? {
          rowModesModel: rowModesModel,
          onRowModesModelChange: handleRowModesModelChange,
          onRowEditStop: handleRowEditStop,
          processRowUpdate: processRowUpdate,
          onCellDoubleClick: handleDoubleClick
        } : {})
        }
        onColumnWidthChange={storeDataGridState}
        onColumnOrderChange={storeDataGridState}
        onColumnVisibilityModelChange={storeDataGridState}
        onSortModelChange={storeDataGridState}
        onPaginationModelChange={storeDataGridState}
        initialState={{
          ...selectedMembers?.initialState,
          pagination: { paginationModel: { pageSize: 10 } },
          columns: {
            columnVisibilityModel: {
              aggGrpMemberId: false
            }
          }
        }}
        sx={{
          border: 'none',
          fontFamily: 'Open Sans',
          '& .MuiDataGrid-cell': {
            cursor: 'default'
          }
        }}
      />
    </>
  )
}

export default MemberTable

import React, { useRef, useState } from 'react'
import Chart from 'react-apexcharts'
import { formatCurrency, formatCurrencyWithSymbol } from '../Report/components/dataProcess/DataProcess'
import '../../assets/styles/book-of-business.scss'
import { Box, Card, CardContent, Typography, Skeleton, IconButton, Icon } from '@mui/material'
import { RefreshOutlined } from '@mui/icons-material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const MarketValueChart = ({ data, isLoading }) => {
  const chartRef = useRef(null)
  const [isZoomed, setIsZoomed] = useState(false)
  // let openTooltipId = null // id of the currently open tooltip
  const resetZoom = () => {
    if (chartRef.current && series && series.length > 0 && series[0].data && series[0].data.length > 0) {
      // Get the initial x-axis range based on the first and last data points in the series
      const minX = new Date(series[0].data[0]?.x).getTime()
      const maxX = new Date(series[0].data[series[0].data.length - 1]?.x).getTime()
      // Reset the zoom by updating the x-axis range
      chartRef.current.chart.updateOptions({
        xaxis: {
          min: minX,
          max: maxX
        }
      })
      setIsZoomed(false)
    }
  }

  // const minYValue = Math.min(...data?.map(point => point?.totalMV || 0)) || 0
  // const annotationsArray = []

  // data?.forEach((point) => {
  //   if (point?.onboardedAccounts) {
  //     const onboardedAccounts = point?.onboardedAccounts || []
  //     const dataDate = point?.dataDate

  //     annotationsArray.push({ annotations: onboardedAccounts, date: dataDate })
  //   }
  // })

  // const dividendTooltip = (annotationObj) => {
  //   const annotations = annotationObj?.annotations
  //   if (annotations?.length) {
  //     // Create a row for each annotation
  //     const rows = annotations.map((annotation, index) => {
  //       const isLastRow = index === annotations.length - 1
  //       return annotationObj?.annotations?.length
  //         ? `<div>
  //     <div id='date-row' style='font-size: 12px'><p>Creation Date</p><b>${annotation?.accStartDate}</b></div>
  //     <div id='data-row' style='font-size: 12px;'><p>Name</p><b>${annotation?.accountName}</b></div>
  //     <div id='data-row' style='margin-bottom : 8px; font-size: 12px'><p>Amount</p><b>${formatCurrencyWithSymbol(annotation?.accountTotalMvData, 2, '$')}</b></div>
  //     ${!isLastRow ? "<hr style='margin: 4px 0; border-width: 0.2px !important; color: #CCD4DE !important'>" : ''} <!-- Add an <hr> element unless it's the last row -->
  //     </div>`
  //         : ''
  //     })
  //     // Combine rows into the tooltip content
  //     return `<div class='green-border'>
  //     <p id='heading' style='font-size : 14px'>Account</p>
  //     ${rows.join('')} </div>`
  //   } else {
  //     return ''
  //   }
  // }

  // function isClickedOnTooltipOrChildren (clickedElement, tooltipId) {
  //   const tooltipElement = document.getElementById(tooltipId)
  //   return tooltipElement && (clickedElement === tooltipElement || tooltipElement.contains(clickedElement))
  // }

  // const annotationsObj = {
  //   points: annotationsArray?.flatMap((annotObj, index) => {
  //     return annotObj.annotations.map((annotation, subIndex) => {
  //       const tooltipId = `tooltip-${index}-${subIndex}`
  //       return {
  //         id: `tooltip-${index}-${subIndex}`,
  //         x: new Date(annotObj?.date).getTime(),
  //         y: minYValue,
  //         label: {
  //           click: (point, event) => {
  //             const tooltipId = `tooltip-${index}-${subIndex}`
  //             const tooltipDiv = document.getElementById(tooltipId)
  //             if (openTooltipId === tooltipId) {
  //               // If clicking on the same point, close the tooltip
  //               tooltipDiv?.parentNode?.removeChild(tooltipDiv)
  //               openTooltipId = null
  //             } else {
  //               const chartContainer = document.getElementById('market-chart')
  //               // Close any existing tooltip
  //               if (openTooltipId) {
  //                 const currentTooltip = document.getElementById(openTooltipId)
  //                 currentTooltip?.parentNode?.removeChild(currentTooltip)
  //               }
  //               // Create and append the new tooltip
  //               const newTooltipDiv = document.createElement('div')
  //               newTooltipDiv.innerHTML = dividendTooltip(annotObj)
  //               newTooltipDiv.id = tooltipId
  //               newTooltipDiv.className = 'custom-tooltip'

  //               const chartRect = chartContainer?.getBoundingClientRect()
  //               const chartLeft = chartRect?.left
  //               const chartTop = chartRect?.top

  //               // Set the position of the tooltip
  //               newTooltipDiv.style.zIndex = '15'
  //               newTooltipDiv.style.position = 'absolute'
  //               newTooltipDiv.style.left = event?.clientX - chartLeft - 100 + 'px'
  //               newTooltipDiv.style.top = event?.clientY - chartTop - 150 + 'px'
  //               newTooltipDiv.style.boxShadow = 'rgba(116, 120, 141, 0.5) 0px 0px 4px'
  //               // Check if the tooltip exceeds the right edge of the chart container
  //               const tooltipWidth = 250
  //               const chartWidth = chartRect.width
  //               if (event?.clientX - chartLeft - 100 + tooltipWidth > chartWidth) {
  //                 newTooltipDiv.style.left = chartWidth - tooltipWidth + 'px'
  //               }
  //               newTooltipDiv.style.maxHeight = '140px'
  //               newTooltipDiv.style.overflowY = 'auto'
  //               // Check if the tooltip exceeds the left edge of the chart container
  //               if (event?.clientX - chartLeft - 100 < 0) {
  //                 newTooltipDiv.style.left = '0'
  //               }
  //               // Append the tooltip to the body
  //               chartContainer?.appendChild(newTooltipDiv)
  //               openTooltipId = tooltipId

  //               // event listener to the document to close the tooltip when clicked outside
  //               const handleGlobalClick = (globalEvent) => {
  //                 const clickedOnAnnotation = globalEvent.target.classList.contains(`tooltip-${index}-${subIndex}`)
  //                 const clickedOnTooltipOrChildren = isClickedOnTooltipOrChildren(globalEvent.target, tooltipId)

  //                 if (!clickedOnAnnotation && !clickedOnTooltipOrChildren) {
  //                   // Close the tooltip when clicked outside and not on the annotation points or the tooltip
  //                   newTooltipDiv?.parentNode?.removeChild(newTooltipDiv)
  //                   openTooltipId = null
  //                   document.removeEventListener('click', handleGlobalClick)
  //                 }
  //               }
  //               document.addEventListener('click', handleGlobalClick)
  //             }
  //           },
  //           text: 'A',
  //           borderColor: '#33A68D',
  //           borderRadius: '50%',
  //           style: {
  //             color: '#33A68D',
  //             fontSize: '9.5px',
  //             fontWeight: 400,
  //             padding: {
  //               left: 5.5,
  //               right: 5.5,
  //               top: 4,
  //               bottom: 4
  //             }
  //           }
  //         }
  //       }
  //     })
  //   })
  // }

  const series = [
    {
      name: 'Market Value Returns',
      data: data?.map((point) => {
        const dataDate = point?.dataDate
        const totalMV = point?.totalMV || 0

        return {
          x: dataDate,
          y: totalMV
        }
      }) || []
    }
  ]

  const options = {
    chart: {
      events: {
        zoomed: function (chartContext, { xaxis, yaxis }) {
          setIsZoomed(true)
        }
      },
      toolbar: {
        show: false
      }
    },
    xaxis: {
      type: 'datetime',
      tooltip: {
        enabled: true
      }
    },
    yaxis: [
      {
        labels: {
          offsetX: -16,
          formatter: function (value) {
            return formatCurrencyWithSymbol(value, 1, '$')
          }
        },
        tickAmount: 7
      }
    ],
    markers: {
      size: 0
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      custom: function ({ dataPointIndex, w }) {
        const preTaxValue = w.globals.series[0][dataPointIndex]?.toFixed(2)
        return `<div style="padding: 8px; border-radius: 4px; color: #000; background-color: #fff;">
                      <div>
                        <span style="display: inline-block; width: 10px; height: 10px; background-color: ${w.config.colors[0]}; border-radius: 50%; margin-right: 5px;"></span>
                        <span>$${formatCurrency(preTaxValue, 1)}</span>
                      </div>
                  </div>`
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        enabled: true,
        opacityFrom: 0.4,
        opacityTo: 0
      }
    },
    stroke: {
      curve: 'straight',
      width: 1.8
    },
    grid: {
      padding: {
        left: -5
      }
    },
    colors: ['#002A59'],
    noData: {
      text: 'No data available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '18px',
        fontFamily: undefined
      }
    }
    // annotations: annotationsObj
  }

  return (
    <Card>
      <CardContent>
        <Box display='flex' justifyContent='space-between' height='28px'>
          <Typography
            component='p'
            className='text-title header-text'
            sx={{ mt: '4px' }}
          >
            AUM Summary
          </Typography>
          {isZoomed
            ? <IconButton
                onClick={resetZoom}
                sx={{
                ':hover': {
                  background: 'transparent'
                }
              }}
                className='tooltip-trade'
              >
              <RefreshOutlined sx={{ height: 20, width: 20, color: '#74788D' }} />
              <span
                className='tooltiptext'
                style={{ marginLeft: '-15px' }}
              >
                Reset Chart
              </span>
            </IconButton>
            : ' '}
        </Box>
        {isLoading
          ? <Skeleton
              variant='rectangle'
              animation='wave'
              width='100%'
              height={270}
              sx={{ marginTop: '8px !important' }}
            />
          : <>
            <Chart ref={chartRef} options={options} series={series} type='area' height={250} id='market-chart' />
            <Box display='flex' justifyContent='end' alignItems='center'>
              <Icon
                color='#002959' px={1} style={{ paddingBottom: '18px', fontSize: '16px' }}
              >
                <InfoOutlinedIcon fontSize='10px' />
              </Icon>
              <Typography sx={{ fontSize: '12px', color: '#74788d', marginTop: '2px', marginLeft: '4px' }}>All sponsor accounts are displayed in the chart</Typography>
            </Box>
          </>}
      </CardContent>
    </Card>
  )
}
export default MarketValueChart

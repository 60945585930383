import { Skeleton, TableCell } from '@mui/material'

// all const headers for trade approval && Trade History personalization popup screen tabs

export const propertiesTableHeaders = [
  { field: 'scenarioDescription', headerName: 'Scenario Description', flex: 1 },
  { field: 'propertyCode', headerName: 'Property Code', flex: 1 },
  { field: 'propertyValue', headerName: 'Property Value', flex: 1, align: 'right', headerAlign: 'right' },
  { field: 'propertySource', headerName: 'Property Source', flex: 1, align: 'right', headerAlign: 'right' }
]

export const sectorTableHeaders = [
  { field: 'assetClassDesc', headerName: 'Sector', flex: 1 },
  { field: 'sectorExclude', headerName: 'Exclude', flex: 1, align: 'right', headerAlign: 'right' },
  { field: 'min', headerName: 'Min', flex: 1, align: 'right', headerAlign: 'right' },
  { field: 'max', headerName: 'Max', flex: 1, align: 'right', headerAlign: 'right' }
]

export const industryTableHeaders = [
  { field: 'assetClassDesc', headerName: 'Sector', flex: 1 },
  { field: 'industryId', headerName: 'Industry', flex: 1 },
  { field: 'industryExclude', headerName: 'Exclude', flex: 1, align: 'right', headerAlign: 'right' },
  { field: 'min', headerName: 'Min', flex: 1, align: 'right', headerAlign: 'right' },
  { field: 'max', headerName: 'Max', flex: 1, align: 'right', headerAlign: 'right' }

]

export const factorTiltsTableHeaders = [
  { field: 'attrLabel', headerName: 'Name', flex: 1 },
  { field: 'statsValue', headerName: 'Value', flex: 1, align: 'right', headerAlign: 'right' }
]

export const substitutionsTableHeaders = [
  { field: 'sourceName', headerName: 'Source Instrument', flex: 1 },
  { field: 'targetName', headerName: 'Target Instrument', flex: 1 }
]

export const instrumentTableHeaders = [
  { field: 'name', headerName: 'Security', flex: 1 },
  { field: 'instrId', headerName: 'Instrument Id', flex: 1 },
  { field: 'localSymbol', headerName: 'Symbol', flex: 1 },
  { field: 'restrictionDesc', headerName: 'Restriction Type', flex: 1 },
  { field: 'source', headerName: 'Source', flex: 1 },
  { field: 'sourceName', headerName: 'Source Name', flex: 1 }
]

// setting up skeleton(loading screen while fetching api) for trade approval && Trade History pop up all tabs
export const tableData = {
  Properties: {
    tableHeaderHTML: propertiesTableHeaders.map(({ headerName }, index) => <TableCell key={index}>{headerName}</TableCell>),
    rowSkeleton: propertiesTableHeaders.map(({ headerName }, index) => <TableCell key={index}><Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} /></TableCell>)
  },
  Sector: {
    tableHeaderHTML: sectorTableHeaders.map(({ headerName }, index) => <TableCell key={index}>{headerName}</TableCell>),
    rowSkeleton: sectorTableHeaders.map(({ headerName }, index) => <TableCell key={index}><Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} /></TableCell>)
  },
  Industry: {
    tableHeaderHTML: industryTableHeaders.map(({ headerName }, index) => <TableCell key={index}>{headerName}</TableCell>),
    rowSkeleton: industryTableHeaders.map(({ headerName }, index) => <TableCell key={index}><Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} /></TableCell>)
  },
  'Factor Tilts': {
    tableHeaderHTML: factorTiltsTableHeaders.map(({ headerName }, index) => <TableCell key={index}>{headerName}</TableCell>),
    rowSkeleton: factorTiltsTableHeaders.map(({ headerName }, index) => <TableCell key={index}><Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} /></TableCell>)
  },
  Substitutions: {
    tableHeaderHTML: substitutionsTableHeaders.map(({ headerName }, index) => <TableCell key={index}>{headerName}</TableCell>),
    rowSkeleton: substitutionsTableHeaders.map(({ headerName }, index) => <TableCell key={index}><Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} /></TableCell>)
  },
  Instrument: {
    tableHeaderHTML: instrumentTableHeaders.map(({ headerName }, index) => <TableCell key={index}>{headerName}</TableCell>),
    rowSkeleton: instrumentTableHeaders.map(({ headerName }, index) => <TableCell key={index}><Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} /></TableCell>)
  }
}

import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Chip, Drawer, IconButton, Tab, Typography } from '@mui/material'
import { FaChartColumn } from 'react-icons/fa6'
import { FiCalendar, FiFilter } from 'react-icons/fi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import { performanceDataProcess } from '../Report/DataFormatting'
import BenchmarkComparisonChart from './BenchmarkComparisonChart'
import ChartTypeSelection from './ChartTypeSelection'
import CustomizationTab from './CustomizationTab'
import MarketValueAnnotationChart from './MarketValueAnnotationChart'
import TaxPerformanceChart from './TaxPerformanceChart'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CancelIcon from '@mui/icons-material/Cancel'
import './expanded-view.css'
import dayjs from 'dayjs'
import Loader from '../Loader'
import DateSelection from './DateSelection'
import { useDispatch } from 'react-redux'
import { storeExpandedChartName } from '../../store/user-reducer/user-reducer'
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined'
import TableData from './TableData'

const chartOptions = {
  MV: 'Market Value',
  PERFORMANCE: 'Performance',
  BENCHMARK_RETURNS: 'Benchmark Returns'
}

const ExpandableView = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { showError } = useErrorToast()
  const [chartName, setChartName] = useState(location?.state?.chartName)
  const [chartData, setChartData] = useState(location?.state?.chartData || {})
  const [minStartDate, setMinStartDate] = useState(location?.state?.chartData?.startDate || new Date().toISOString().slice(0, 10))
  const [minEndDate, setMinEndDate] = useState(location?.state?.chartData?.endDate || new Date().toISOString().slice(0, 10))
  const [activeTabFilter, setActiveTabFilter] = useState('chart-type')
  const { user } = useAuth()
  const params = useParams()
  const [loading, setLoading] = useState(true)
  const [showMenu, setShowMenu] = useState(false)
  const [dateFilterApplied, setDateFilterApplied] = useState(false)
  const [tradeDetails, setTradeDetails] = useState({})

  const dispatch = useDispatch()

  const handleFilterChange = (event, newValue) => {
    setActiveTabFilter(newValue)
  }

  useEffect(() => {
    document.getElementById('main-content')?.classList.add('expanded')
    return () => {
      document.getElementById('main-content')?.classList.remove('expanded')
    }
  }, [])

  useEffect(() => {
    setChartData(location?.state?.chartData || {})
    setLoading(false)
    setMinStartDate(location?.state?.chartData?.startDate || new Date().toISOString().slice(0, 10))
    setMinEndDate(location?.state?.chartData?.endDate || new Date().toISOString().slice(0, 10))
  }, [location?.state?.chartData])

  useEffect(() => {
    setChartName(location?.state?.chartName)
  }, [location?.state?.chartName])

  const goBack = () => {
    document.getElementById('main-content')?.classList.remove('expanded')
    dispatch(storeExpandedChartName(location?.state?.chartName))
    navigate(-1)
  }

  const fetchPerformanceData = (chartType, startDate = null, endDate = null) => {
    const payload = {
      startDate: startDate || chartData?.startDate,
      endDate: endDate || chartData?.endDate
    }
    API.post('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/performance/${chartData?.accountId || params?.accountId}`, { body: { ...payload } })
      .then(response => {
        if (response) {
          const graphData = performanceDataProcess(response)
          setChartData(prevState => ({
            ...prevState,
            data: (graphData?.preTaxReturns || []).map((dataPoint, index) => ({
              x: new Date(graphData.date[index]).getTime(),
              y: dataPoint,
              postTaxReturns: graphData?.postTaxReturns ? graphData?.postTaxReturns[index] : ''
            })).filter((point) => point.y !== null)
          }))
          setChartName(chartType)
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to load performance data.')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const fetchBenchmarkData = (chartType) => {
    applyBenchmark(chartData?.selectedBenchmark)
    // setChartName(chartType)
    // API.post('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/benchmark-returns/account/${chartData?.accountId || params?.accountId}/strategy/${chartData?.strategyId}`)
    // .then(response => {
    //   if (response) {
    //     const graphData = benchmarkDataProcess(response)
    //     setChartData(prevState => ({
    //       ...prevState,
    //       // startDate: graphData?.startDate,
    //       // endDate: graphData?.endDate,
    //       strategyList: graphData?.strategyList,
    //       data: (graphData?.preTaxReturns || []).map((dataPoint, index) => ({
    //         x: new Date(graphData.date[index]).getTime(),
    //         y: dataPoint,
    //         accPreTax: chartData?.accountType === 'TRANSITION' ? null : graphData?.accPreTaxReturns[index]
    //       })).filter((point) => point.y !== null)
    //     }))
    //     // setMinStartDate(graphData?.startDate)
    //     // setMinEndDate(graphData?.endDate)
    //   }
    // })
    // .catch(error => {
    //     showError(error, false, {}, 'Failed to load benchmark data.')
    // })
    // .finally(() => {
    //   setChartName(chartType)
    //   setLoading(false)
    // })
  }

  const fetchMetricData = (chartType, startDate = null, endDate = null) => {
    const payload = {
      startDate: startDate || chartData?.startDate,
      endDate: endDate || chartData?.endDate
    }
    API.post('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/metric-data/${chartData?.accountId || params?.accountId}`, { body: { ...payload } })
      .then(response => {
        if (response) {
          const graphData = response?.data?.marketData
          // set present data as selected annotation
          const annotationsArray = graphData ? graphData?.flatMap(entry => ({ annotations: entry.metric })) : []
          const uniqueAnnotations = [...new Set(annotationsArray.flatMap(entry => Object.keys(entry.annotations)))]
          setChartData(prevState => ({
            ...prevState,
            annotationList: uniqueAnnotations,
            selectedAnnotations: uniqueAnnotations,
            calculatedMvPlusCashData: response?.data?.calculatedMvPlusCashData,
            // filter data-points which has valid market value and store it for chart data point
            data: (graphData || [])?.filter(obj => obj.metric.NET_MV_PLUS_CASH !== undefined && obj.metric.NET_MV_PLUS_CASH !== null)?.map((dataPoint, index) => ({
              x: new Date(dataPoint.dataDate).getTime(),
              y: dataPoint.metric.NET_MV_PLUS_CASH,
              annotation: dataPoint.metric ? dataPoint.metric : ''
            })).filter((point) => point.y !== null)
          }))
          setChartName(chartType)
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to load  market value data.')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const changeChartType = (chartType) => {
    if (chartType !== chartName) {
      setLoading(true)
      setShowMenu(false)
      // setChartData(prevState => ({ ...prevState, data: null }))
      switch (chartType) {
        case 'PERFORMANCE': {
          fetchPerformanceData(chartType)
          return
        }
        case 'BENCHMARK_RETURNS': {
          fetchBenchmarkData(chartType)
          return
        }
        case 'MV': {
          fetchMetricData(chartType)
          return
        }
        default: {
          setChartName(chartType)
          setLoading(false)
        }
      }
    }
  }

  const removeDateSelection = () => {
    setChartData(prevState => ({
      ...prevState,
      startDate: dayjs(minStartDate).format('YYYY-MM-DD'),
      endDate: dayjs(minEndDate).format('YYYY-MM-DD')
    }))
    setLoading(true)
    if (chartName === 'BENCHMARK_RETURNS') {
      applyBenchmark(chartData?.selectedBenchmark, dayjs(minStartDate).format('YYYY-MM-DD'), dayjs(minEndDate).format('YYYY-MM-DD'), chartData?.selectedAccountIds)
    } else if (chartName === 'MV') {
      fetchMetricData(chartName, dayjs(minStartDate).format('YYYY-MM-DD'), dayjs(minEndDate).format('YYYY-MM-DD'))
    } else if (chartName === 'PERFORMANCE') {
      fetchMetricData(chartName, dayjs(minStartDate).format('YYYY-MM-DD'), dayjs(minEndDate).format('YYYY-MM-DD'))
    }
    setDateFilterApplied(false)
  }

  const applyDateFilter = (dateRange, reset = false) => {
    setChartData(prevState => ({
      ...prevState,
      startDate: dayjs(dateRange[0]).format('YYYY-MM-DD'),
      endDate: dayjs(dateRange[1]).format('YYYY-MM-DD')
    }))
    setLoading(true)
    setDateFilterApplied(!reset)
    if (chartName === 'BENCHMARK_RETURNS') {
      applyBenchmark(chartData?.selectedBenchmark, dayjs(dateRange[0]).format('YYYY-MM-DD'), dayjs(dateRange[1]).format('YYYY-MM-DD'), chartData?.selectedAccountIds)
    } else if (chartName === 'MV') {
      fetchMetricData(chartName, dayjs(dateRange[0]).format('YYYY-MM-DD'), dayjs(dateRange[1]).format('YYYY-MM-DD'))
    } else if (chartName === 'PERFORMANCE') {
      fetchPerformanceData(chartName, dayjs(dateRange[0]).format('YYYY-MM-DD'), dayjs(dateRange[1]).format('YYYY-MM-DD'))
    }
    setShowMenu(false)
  }

  const applyBenchmark = async (selectedBenchmark, startDate = null, endDate = null, selectedAccounts) => {
    let payload
    if (chartData?.accountType === 'TRANSITION') {
      payload = {
        accountId: [],
        benchmarkId: selectedBenchmark || [],
        startDate: startDate || chartData?.startDate,
        endDate: endDate || chartData?.endDate
      }
    } else {
      payload = {
        primaryAccountId: chartData?.accountId || params?.accountId,
        accountId: selectedAccounts && selectedAccounts?.length ? [...selectedAccounts?.map(account => account.accountId)] : [chartData?.accountId || params?.accountId],
        benchmarkId: selectedBenchmark || [],
        startDate: startDate || chartData?.startDate,
        endDate: endDate || chartData?.endDate
      }
    }
    setLoading(true)
    API.post('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/benchmark-returns-comparison`, { body: { ...payload } })
      .then((response) => {
        if (response && response?.data) {
          setShowMenu(false)
          setChartData(prevState => ({
            ...prevState,
            data: (response?.data || []).map((dataPoint, index) => {
              const resultObject = {
                x: new Date(dataPoint?.dataDate).getTime()
                // accPreTax: dataPoint?.accCumulatedPreTaxDailyReturns
              }
              dataPoint?.accountReturn?.forEach(item => {
                if (item.accountId === params?.accountId) {
                  resultObject.accPreTax = item?.accCumulatedPreTaxDailyReturns
                } else {
                  resultObject[item.accountId] = item?.accCumulatedPreTaxDailyReturns
                }
              })
              dataPoint?.benchMarksReturn?.forEach(item => {
                resultObject[item.strategyId] = item?.strCumulatedPreTaxDailyReturns
              })
              return resultObject
            }).filter((point) => Object.keys(point).every(key => point[key] !== null)),
            selectedBenchmark: selectedBenchmark || [],
            selectedAccountIds: selectedAccounts || [{ accountId: chartData?.accountId || params?.accountId }]
          }))
          if (chartName !== 'BENCHMARK_RETURNS') {
            setChartName('BENCHMARK_RETURNS')
          }
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load benchmark data.')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const applySelectedAnnotations = (selectedAnnotations) => {
    setChartData(prevState => ({ ...prevState, selectedAnnotations: selectedAnnotations || [] }))
    setShowMenu(false)
  }

  const handleTradeDetailsUpdate = (details) => {
    setShowMenu(true)
    setTradeDetails(details)
    setActiveTabFilter('trade-details')
  }

  // clear the trade details data when the filter menu is closed or clicked on a different tab
  useEffect(() => {
    if (!showMenu || activeTabFilter !== 'trade-details') {
      setTradeDetails({})
    }
  }, [showMenu, activeTabFilter])

  return (
    <Box display='flex'>
      <Box sx={{ flexGrow: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px 10px 5px 5px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              sx={{
                background: '#eaeaea',
                padding: '5px 5px 5px 4px',
                '&:hover': {
                  background: '#eaeaea'
                }
              }} onClick={goBack}
            ><ArrowBackIosNewOutlinedIcon sx={{ fontSize: '14px !important', color: 'black' }} />
            </IconButton>
            <Typography variant='h6' sx={{ textTransform: 'capitalize', display: 'inline-block', fontSize: '20px', fontWeight: '500', marginLeft: '5px', fontFamily: 'Lora' }}>{chartOptions[chartName] + ' Chart'}</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '12px', paddingRight: '116px' }}>
            {/* {chartName === 'MV' && chartData?.calculatedMvPlusCashData?.latestMvPlusCash !== null
              ? (
                <Box sx={{
                  border: 1,
                  padding: '3px',
                  fontWeight: 400,
                  color: '#2F2F2F',
                  borderRadius: '5px',
                  fontSize: '12px',
                  fontFamily: 'Open Sans',
                  borderColor: chartData?.calculatedMvPlusCashData?.MvplusCashPercentage > 0 ? '#33A68D' : chartData?.calculatedMvPlusCashData?.MvplusCashPercentage < 0 ? '#F46A6A' : 'black'
                }}
                >Total Market Value: {formatCurrencyWithSymbol(chartData?.calculatedMvPlusCashData?.latestMvPlusCash, 2, '$')} {(chartData?.calculatedMvPlusCashData?.MvplusCashPercentage !== null || chartData?.calculatedMvPlusCashData?.MvplusCashPercentage !== undefined) ? <span style={{ color: chartData?.calculatedMvPlusCashData?.MvplusCashPercentage > 0 ? 'green' : chartData?.calculatedMvPlusCashData?.MvplusCashPercentage < 0 ? 'red' : 'black' }}>({chartData?.calculatedMvPlusCashData?.MvplusCashPercentage?.toFixed(2)}%)</span> : ''}
                </Box>
                )
              : ''} */}
            {dateFilterApplied
              ? <Chip
                  label={`${chartData?.startDate} - ${chartData?.endDate}`}
                  id='chip-date-selection'
                  variant='outlined'
                  sx={{
                    borderRadius: '5px',
                    fontSize: '12px',
                    height: 'auto',
                    background: '#3A76D426',
                    border: 0,
                    '.MuiChip-label': {
                      padding: '4px'
                    },
                    '.MuiChip-deleteIcon': {
                      position: 'absolute',
                      left: '100%',
                      top: 0,
                      transform: 'translate(-50%,-50%)',
                      margin: 0,
                      width: '16px',
                      height: '16px'
                    }
                  }}
                  onDelete={() => { removeDateSelection() }}
                  deleteIcon={<CancelIcon />}
                />
              : ''}
          </Box>
        </Box>
        <Box id='chart-container-expanded'>
          {loading
            ? <Loader />
            : ''}
          {chartName === 'PERFORMANCE' ? <TaxPerformanceChart data={chartData?.data} /> : ''}
          {chartName === 'MV' ? <MarketValueAnnotationChart data={chartData?.data} selectedAnnotations={chartData?.selectedAnnotations} onTradeDetailsUpdate={handleTradeDetailsUpdate} /> : ''}
          {chartName === 'BENCHMARK_RETURNS' ? <BenchmarkComparisonChart data={chartData?.data} strategyList={chartData?.strategyList || []} selectedAccountIds={chartData?.selectedAccountIds} isTransitionAccount={chartData?.accountType === 'TRANSITION'} /> : ''}
        </Box>
      </Box>
      <Drawer
        variant='permanent'
        open
        anchor='right'
        sx={{
          '.MuiDrawer-paper': {
            position: 'absolute',
            top: '10px',
            zIndex: '97',
            overflow: 'hidden'
          }
        }}
      >

        <TabContext value={activeTabFilter}>
          <Box sx={{ display: 'flex', height: '100%' }}>
            <Box sx={{
              width: showMenu ? '300px' : 0,
              display: showMenu ? 'block' : 'none',
              '.MuiTabPanel-root': {
                padding: '12px 16px'
              }
            }}
            >
              <TabPanel value='chart-type'>
                <ChartTypeSelection chartName={chartName} changeChartType={changeChartType} chartOptions={chartOptions} isTransitionAccount={chartData?.accountType === 'TRANSITION'} />
              </TabPanel>
              <TabPanel value='chart-date'>
                <DateSelection chartName={chartName} selectedStartDate={chartData?.startDate} selectedEndDate={chartData?.endDate} minStartDate={minStartDate} minEndDate={minEndDate} applyDateFilter={applyDateFilter} />
              </TabPanel>
              <TabPanel value='default'>
                <CustomizationTab chartName={chartName} chartData={chartData} applyBenchmark={applyBenchmark} isTransitionAccount={chartData?.accountType === 'TRANSITION'} applySelectedAnnotations={applySelectedAnnotations} />
              </TabPanel>
              <TabPanel value='trade-details'>
                <TableData chartName={chartName} tradeDetails={tradeDetails} />
              </TabPanel>
            </Box>
            <Box sx={{ borderLeft: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleFilterChange}
                aria-label='filter-tabs'
                sx={{
                  '.MuiTab-root': {
                    minWidth: 'auto',
                    width: '50px',
                    maxWidth: '50px'
                  },
                  '.MuiTabs-indicator': {
                    display: activeTabFilter === 'trade-details' ? 'none' : 'block'
                  }
                }}
                orientation='vertical'
                TabIndicatorProps={{
                  sx: {
                    left: 0
                  }
                }}
              >
                <Tab icon={<FaChartColumn size={18} />} value='chart-type' onClick={() => setShowMenu(true)} />
                <Tab icon={<FiCalendar size={22} />} value='chart-date' onClick={() => setShowMenu(true)} />
                <Tab icon={<FiFilter size={22} />} value='default' onClick={() => setShowMenu(true)} />
                {chartData?.selectedAnnotations?.includes('TRADE_DETAILS') ? <Tab value='trade-details' disabled /> : ''}
              </TabList>
            </Box>
          </Box>
        </TabContext>
      </Drawer>
      {showMenu
        ? <IconButton
            sx={{
              background: '#eaeaea',
              position: 'absolute',
              top: '45px',
              right: '340px',
              padding: 0,
              zIndex: '98',
              '&:hover': {
                background: '#eaeaea'
              }
            }}
            onClick={() => setShowMenu(!showMenu)}
          >
          <ChevronRightIcon sx={{ color: 'black' }} />
          </IconButton>
        : ''}
    </Box>
  )
}

export default ExpandableView
